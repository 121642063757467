import React, { useState, useEffect } from "react";
import noimage from '../img/noimage.png'
// import Modal from "../components/Modal";

const titleBarStyle = {
  borderLeft:"10px solid #428158",
};
const horizontalListStyle = {
  whiteSpace: "nowrap",
  backgroundColor:"white",
};

function CustomScrollImageItems(props) {
  const { name, title, options, multiSelectable, modeReset, setSelected, onSelectChange, onClick, } = props;
  const [ val, setVal ] = React.useState([]);
  const handleClick = e => {
		if ( onClick ) {
      onClick( e );
		}
  };

	const handleChange = e => {
		if ( onSelectChange ) {
      onSelectChange( e );
			e.checked = false;
      setVal( val.filter( item => item !== e.target.value ) );
			return false;
		}
		if ( multiSelectable ) {
			if ( val.length && val.includes( e.target.value ) ) {
        setVal( val.filter( item => item !== e.target.value ) );
			} else {
        setVal( [ ...val, e.target.value ] );
      }
		} else {
      setVal( [ e.target.value ] )
		}
  };

  useEffect( () => {
		if ( setSelected ) {
      setSelected( val );
		}
  }, [ val ]);
  
  const GET_DESCRIPTION_URL =  'https://www.i-koike.jp/description/cgct/';

  const handleClear = e =>{
    var boxes = document.getElementsByName( options.length );
    var cnt = boxes.length;
    setVal( [] );
    setSelected( [] );
    for( var i=0; i<cnt; i++ ){
      if( boxes.item( i ).checked ){
        boxes.item( i ).checked = false;
      }
    } 
  }

  
  
	return (
    <div className="container my-0">
      <div className="row my-1">
        {
          title === "Taste"?
          (
            <>
              <div
                className="col-6 align-self-center"
                style={ titleBarStyle }
              >
                <div className="Floor_color font-weight-bold text-secondary">
                  { title }
                </div>
              </div>
              <div className="col-6 text-right">
                <div
                  className="btn btn-secondary my-0 py-0"
                  onClick={ handleClear }
                >
                  <small className="">
                    { title } をクリア
                  </small>
                </div>
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  {
                    options.map( ( option, index ) => (
                    <div
                      className = "floor col-6 w-100 text-center px-1 mt-1 h-100"
                      key = { index }
                    >
                      <input
                        className="radio-input"
                        type={ multiSelectable ? 'checkbox' : 'radio' }
                        name={ name }
                        onClick={ handleClick }
                        onChange={ handleChange }
                        value={ option.value }
                        id={ option.value + "_" + options.length }
                        checked={ !modeReset && val.includes( option.value ) }
                      />
                      <label
                        htmlFor={ option.value + "_" + options.length } 
                        className="w-100 text-center btn"
                      >
                        <img
                          src={ option.image }
                          alt={ option.image }
                          className="col-12 w-100 text-center px-1"
                          onError={ ( e ) => e.target.src = noimage }
                        />
                        <div className="text-center w-100 text-secondary mx-0 row my-2 align-items-center">
                          <small className="text-center col-12 font-weight-bold w-100">
                            { option.dispName }
                          </small>
                          <small className="col-12 w-100">
                            <a
                              href={ GET_DESCRIPTION_URL + "taste_" + option.value + ".png" }
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn btn-outline-secondary btn-light text-primary w-100 py-0"
                            >
                              <small>
                                イメージ写真
                              </small>
                            </a>
                          </small>
                        </div>
                      </label>
                      {/* <Modal
                        taste = { option.value }
                      /> */}
                    </div>
                    ))
                  }
                </div>
              </div>
            </>
          )
        :(
          <div className="container">
            <div className="row">
              <div
                className="col-12 align-self-center"
                style={ titleBarStyle }
              >
                <div className="Floor_color text-dark">
                  { 
                    title==="抽出商品"?
                    <div  className="font-weight-bold">
                      { options.length }
                      <small className="font-weight-nomal">
                        点
                      </small>
                    </div>
                    :
                    <>
                    {
                      name === "color1Products"?
                      <div className="container">
                        <div className="row">
                          <div className="col-9 w-100 text-left">
                          <span className="font-weight-bold">
                            選択色
                          </span>
                          <small>
                            商品&ensp;(色差順表示)
                          </small>
                          </div>
                          <div className="col-3 w-100 text-right">
                          <span className="font-weight-bold">
                            { options.length }
                          </span>
                          <span>
                            <small>
                              点
                            </small>
                          </span>
                          </div>
                        </div>
                      </div>
                      :
                      name === "color2Products"?
                      <div className="container">
                        <div className="row">
                          <div className="col-9 w-100 text-left">
                            <small>
                              選択色
                            </small>
                            <span className="font-weight-bold">
                              同色相
                              </span>
                            <small>
                              商品
                            </small>
                          </div>
                          <div className="col-3 w-100 text-right">
                            <span className="font-weight-bold">
                              { options.length }
                            </span>
                            <span>
                              <small>
                                点
                              </small>
                            </span>
                          </div>
                        </div>
                      </div>
                      :
                      name === "color3Products"?
                      <div className="container">
                        <div className="row">
                          <div className="col-9 w-100 text-left">
                            <small>
                              選択色
                            </small>
                            <span className="font-weight-bold">
                              同トーン
                              </span>
                            <small>
                              商品
                            </small>
                          </div>
                          <div className="col-3 w-100 text-right">
                            <span className="font-weight-bold">
                              { options.length }
                            </span>
                            <small>
                              点
                            </small>
                          </div>
                        </div>
                      </div>
                    :
                    title === "lace"?
                    <>
                      <span className="font-weight-bold">
                        { options.length }
                      </span>
                      点
                    </>
                    :
                    <div className="text-secondary font-weight-bold">
                      品番指定商品
                    </div>
                    }
                    
                    </>
                  }
                </div>
              </div>
              <div 
                className="scroll-areaーyoko bg-light"
                data-simplebar
                data-simplebar-auto-hide="false"
              >
                <ul
                  className="horizontal-list px-0 w-100 mx-1 my-0"
                  style={ horizontalListStyle }
                >
                  {
                    options.map( ( option, index ) => (
                    <li
                      className="floor col-5 w-100 text-center px-1 mt-1"
                      style = { { display: "inline-block" } }
                      key = { index }
                    >    
                      <input
                        className="radio-input"
                        type={ multiSelectable ? 'checkbox' : 'radio' }
                        name={ name }
                        onClick={ handleClick }
                        onChange={ handleChange }
                        value={ option.value } 
                        id={ option.value + "_" + index }
                        checked={ !modeReset && val.includes( option.value ) }
                      />
                      <label
                        htmlFor={ option.value + "_" + index }
                        className={ "w-100 text-center" }
                      >
                        <img
                          src={ option.image }
                          alt="" className="border w-100"
                          onError={ (e) => e.target.src = noimage }
                        />
                          <div
                            className="text-center w-100 text-secondary mx-0"
                          >
                            <small>
                              { option.dispName }
                            </small>
                          </div>
                      </label>
                      <small className="my-0 py-0">
                        <a
                          href={ GET_DESCRIPTION_URL + option.value + "-l.png" }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <small>
                            生地拡大
                          </small>
                        </a>
                      </small>
                    </li>
                    ) )
                  }
                </ul>
              </div>
            </div>
          </div>
          )
        }
      </div>
    </div>
	);
}
export default CustomScrollImageItems;
import React,{ useState, useEffect } from 'react';

export const RoomArranger = ( props ) => {
  const { imagePathSetters } = props;
  const imgPathSetter = imagePathSetters[ "furniture" ];
  const [ roomtype, setRoomtype ] = useState( "living" );
  const [ furnitures, setFurnitures ] = useState( "1" );
  

  useEffect( () => {
    if ( !roomtype || !furnitures ) return;
    imgPathSetter(
      "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/Simulation/Funiture/"
      +
      roomtype
      +
      "_"
      +
      furnitures
      +
      ".png"
    );
  }, [ roomtype, furnitures ]); 

  const Types = [
    { "name":"リビング", value:"living", check:true },
    { "name":"寝室", value:"bed", check:false },
    { "name":"子供部屋", value:"kids", check:false },
  ];
  const FurnitureNumber = [
    { name:"A", value:1, check:true },
    { name:"B", value:2, check:false },
    { name:"C", value:3, check:false },
    { name:"D", value:4, check:false },
    { name:"E", value:5, check:false },
  ];
  const FurnitureNumber2 = [
    { name:"A", value:6, check:false },
    { name:"B", value:7, check:false },
    { name:"C", value:8, check:false },
    { name:"D", value:9, check:false },
    { name:"E", value:10, check:false },
  ];
  
  const TitleBar = {
    borderLeft:"10px solid gray",
    backgroundColor:"#a0c0ac",
  };

  const handleChange1 = event1 => {
    let value = event1.target.value;
    let name = event1.target.name;
    if ( name === "RoomType" ) {
      setRoomtype( value );
      if( furnitures >= 6 && value === "bed" ){
      document.getElementById( "Furniture_A1" ).checked = true;
      setFurnitures( 1 );
      }
    } else if ( name === "Furniture" ) {
      setFurnitures( value );
    }
  }
  
  return(
    <div className="container">
      <div className="row justify-content-between">
        <div
          className = "col-12 align-self-center mb-1"
          style = { TitleBar }
        >
          <div className="Floor_color font-weight-bold text-white mx-0">
            部屋タイプ
          </div>
        </div>
        {
          Types.map( ( types, index ) => (
          <div
            className = "RoomType col-4 w-100 text-center px-0"
            key = { index }
          >
            <input 
              className = { "RoomType_" + types.name } 
              type = "radio" 
              name = "RoomType" 
              onChange = { handleChange1 }
              value = { types.value } 
              defaultChecked = { types.check }
              id = { "RoomType_"+ types.name }
            />
            <label
              htmlFor = { "RoomType_" + types.name }
              className = "text-secondary"
            >
              <div className = { "color-tip" + types.name } >
                { types.name }
              </div>
            </label>
          </div>
          ) )
        }
      </div>
      <div className="row justify-content-around">
        <div
          className = "col-12 align-self-center mb-1"
          style = { TitleBar }
        >
          <div className="Floor_color font-weight-bold text-white">
            家具
          </div>
        </div>
        {
          FurnitureNumber.map( ( furniture, index ) => (
            <div
              className = "Furniture col-2 w-100 text-center px-0"
              key = { index }
            >
              <input 
                className = { "Furniture_" + furniture.name }
                type = "radio"
                name = "Furniture" 
                value = { furniture.value }
                onChange = { handleChange1 }
                defaultChecked = { furniture.check }
                id = { "Furniture_" + furniture.name + furniture.value }
              />
              <label
                htmlFor = { "Furniture_" + furniture.name + furniture.value }
                className = "text-secondary"
              >
                <div className = { "color-tip" + furniture.name }>
                  &ensp;{ furniture.name }&ensp;
                </div>
              </label>
            </div>
          ) )
        }
      </div>
      {
      roomtype === "bed" ?
      <>
      </>
      :
      <div className="row justify-content-around">
        {
          FurnitureNumber2.map( ( furniture, index ) => (
            <div
              className = "Furniture col-2 w-100 text-center px-0"
              key = { index }
            >
              <input 
                className = { "Furniture_" + furniture.name }
                type = "radio"
                name = "Furniture" 
                value = { furniture.value }
                onChange = { handleChange1 }
                defaultChecked = { furniture.check }
                id = { "Furniture_" + furniture.name　+ furniture.value }
              />
              <label
                htmlFor = { "Furniture_" + furniture.name　+ furniture.value }
                className = "text-secondary"
              >
                <div className = { "w-100 color-tip" + furniture.name }>
                  &ensp;{ furniture.name }&ensp;
                  <br/>
                  <small
                   style={ { lineHeight:"0px" } }
                  >
                    (正面)
                  </small>
                </div>
              </label>
            </div>
          ) )
        }
      </div>
      }
    </div>
  );
}

import React, { useEffect, useState } from "react";

function WindowType({
  selectedType,
  onSelectWindowType,
  onNextClick,
  onBackClick,
}) {
  const koshi = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/koshimado.png";
  const hakidashi_small = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/hakidashi_s.png";
  const hakidashi_big = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/hakidashi_b.png";
  const [ elementWidth, setElementWidth ] = useState( null );
  const [ currentSelectedType, setCurrentSelectedType ] = useState( "koshi" );
  const windowTypes = [
    { dispName: "腰窓", size: "1間腰高窓", value: "koshi" },
    { dispName: "掃き出し", size: "1間掃出窓", value: "hakidashi_small" },
    { dispName: "掃き出し", size: "1間半掃出窓", value: "hakidashi_big" },
  ];

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768;
      const newElementWidth = isMobile ? screenWidth * 0.90 : screenWidth / 2.4;
      setElementWidth( newElementWidth );
    }
    handleResize();
    window.addEventListener( "resize", handleResize );
    return () => {
      window.removeEventListener( "resize", handleResize );
    };
  }, []);

  const Shadow = {
    border: "1px solid #056b5e",
    boxShadow: "0px 5px 3px #95BAB5",
  };

  const containerStyle = {
    ...Shadow,
    width: `${ elementWidth }px`,
  };

  const handleTypeClick = ( value ) => {
    setCurrentSelectedType( value );
    onSelectWindowType( value );
  };

  // 戻るボタンをクリックした時の処理
  const handleBackClick = () => {
    onBackClick();
  };
  // 次へボタンをクリックした時の処理
  const handleNextClick = () => {
    onNextClick();
  };

  return (
    <div
      className="container curtain_type bg-white mt-3 mb-5"
      style={ containerStyle }
    >
      <div className="row">
        <div
          className="col-12 text-white text-center mb-3 py-3"
          style={{ background: "#056b5e" }}
        >
          まずは基本設定
        </div>
      </div>
      <div className="row">
        <div
          className="col-12 text-center"
          style={{ color: "#056b5e" }}
        >
          窓のサイズ
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 col-12 mb-3">
          <hr />
        </div>
      </div>
      <div className="row justify-content-center px-0">
        {windowTypes.map(( type, index ) => (
          <div
            className="col-md-4 col-4 text-center"
            key={ index }
            onClick={() => handleTypeClick( type.value )}
          >
            {
              type.value === "koshi" && 
              <img
                src={ koshi }
                alt="koshi"
                className="w-100"
                style={{
                  border: 
                    type.value === currentSelectedType ? "3px solid #046B5E" : "2px solid rgb(204, 233, 221)",
                }}
              />
            }
            {
              type.value === "hakidashi_small" &&
              <img
                src={ hakidashi_small }
                alt="hakidashi_small"
                className="w-100"
                style={{
                  border: 
                    type.value === currentSelectedType ? "3px solid #046B5E" : "2px solid rgb(204, 233, 221)",
                }}
              />
            }
            {
              type.value === "hakidashi_big" &&
              <img
                src={ hakidashi_big }
                alt="hakidashi_big"
                className="w-100"
                style={{
                  border: 
                    type.value === currentSelectedType ? "3px solid #046B5E" : "2px solid rgb(204, 233, 221)",
                }}
              />
            }
            <p className="mt-3">
              { type.dispName }
              <br/>
              <span style={{ fontSize: "9px" }}>
              { type.size }
              </span>
            </p>
          </div>
        ))}
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 col-12">
          <hr />
        </div>
      </div>
      <div className="row justify-content-around mx-3 py-3">
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px"
          }}
          onClick={ handleBackClick }
        >
          <span>
            戻る
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          ></div>
        </div>
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px",
          }}
          onClick={ handleNextClick }
        >
          <span>
            次へ
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default WindowType;


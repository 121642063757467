import React, { useState, useEffect, useRef, useCallback } from "react";

import "./simulator.css";

function ColorSwatch(
  {
    onGetColor = () => {},
    onSetColor = () => {}
  }
)
{
  const canvasRef = useRef();
    const [ state, setState ] = useState(
      {
        color: { r: 0, g: 0, b: 0, hex: "" },
      }
    );

    const drawCanvas = useCallback(() => {
      let canvas = canvasRef.current;
      let context = canvas.getContext( "2d" );
      let Img = new Image();
      Img.crossOrigin = "Anonymous";
      Img.src = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/ColorSwtch/palette/%E3%82%AB%E3%83%A9%E3%83%BC%E3%83%81%E3%83%A3%E3%83%BC%E3%83%88.png";
      Img.onload = () => {
        canvas.width = Img.width;
        canvas.height = Img.height;
        context.drawImage( Img, 0, 0, Img.width, Img.height );
      };
    }, [ canvasRef ]);

    useEffect( () => { drawCanvas(); }, [ drawCanvas ] );

    const getColor = ( e ) => {
      let canvas = canvasRef.current;
      let context = canvas.getContext( "2d" );
      let rect = canvas.getBoundingClientRect();
      let clientX, clientY;
      if ( e.touches ) {
        clientX = e.touches[ 0 ].clientX;
        clientY = e.touches[ 0 ].clientY;
      } else {
        clientX = e.clientX;
        clientY = e.clientY;
      }
      let x = clientX - rect.left;
      let y = clientY - rect.top;
      x = x * ( canvas.width / rect.width );
      y = y * ( canvas.height / rect.height );
      // 小池さん指定レンジ
      const Range = 5;
      const colorRange = ( Range * 2 ) + 1;
      let color = context.getImageData( x, y, colorRange, colorRange ).data;
      if ( color[ 0 ] !== 0 && color[ 1 ] !== 0 && color[ 2 ] !== 0 ) {
        onGetColor({
          r: color[ 0 ],
          g: color[ 1 ],
          b: color[ 2 ],
          a: color[ 3 ],
          hex: rgbToHex( color[ 0 ], color[ 1 ], color[ 2 ] ),
        });
        setState({
          ...state,
          color: {
            r: color[ 0 ],
            g: color[ 1 ],
            b: color[ 2 ],
            a: color[ 3 ] ,
            hex: rgbToHex( color[ 0 ], color[ 1 ], color[ 2 ] ),
          },
        });
      }
      return false;
    };

    function componentToHex( c ) {
      var hex = c.toString( 16 );
      return hex.length === 1 ? "0" + hex : hex;
    }
    function rgbToHex( r, g, b ) {
      return "#" + componentToHex( r ) + componentToHex( g ) + componentToHex( b );
    }
    return (
      <div>
        <div className="processed-image-container">
          <div className="processed-image-base">
            <canvas
              ref = { canvasRef }
              id = "result-canvas"
              style={
                {
                  border:"1px solid #333",
                  width:"100%",
                  cursor:"crosshair",
                }
              }
              onClick={
                () => {
                  onSetColor( state.color );
                }
              }
              onMouseMove={
                ( e ) => {
                  getColor( e );
                }
              }
              onTouchMove = { getColor }
            />
          </div>
        </div>
      </div>
    );
  }
export default ColorSwatch;

import React, { useState, useEffect } from "react";
import * as ColorPanel from "./ColorPanels";

export const FrontWallArranger = ( props ) => {
  const { imagePathSetters } = props;
  // ベース
  const imgPathSetter = imagePathSetters[ "base" ];
  // const imgPathSetter2 = imagePathSetters["rail"];
  const [ base, setBase ] = useState( "baseD" );
  const [ floor, setFloor ] = useState( "_1_" );
  const [ wall, setWall ] = useState( "1" );
  useEffect(() => {
    // 条件が揃った時表示
    if ( !base || !floor || !wall ) return;
    imgPathSetter(
        "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/Simulation/Base/" +
        base +
        floor +
        wall +
        ".png"
    );
  }, [ base, floor, wall ]);

  const TitleBar = {
    borderLeft:"10px solid gray",
    backgroundColor:"#a0c0ac",
  };
  const Room = [
    {
      name: "正面壁",
      value: "accent",
      check: true,
    },
  ];
  const Floors = [
    {
      name: "ﾎﾜｲﾄ",
      id: "Floor_white",
      value: "_1_",
      style: ColorPanel.White,
      check: true,
    },
    {
      name: "ｸﾞﾚｲｯｼｭ",
      id: "Floor_gray",
      value: "_6_",
      style: ColorPanel.Gray,
      check: false,
    },
    {
      name: "ﾅﾁｭﾗﾙ",
      id: "Floor_natural",
      value: "_2_",
      style: ColorPanel.Natural,
      check: false,
    },
    {
      name: "ﾗｲﾄﾌﾞﾗｳﾝ",
      id: "Floor_cherry",
      value: "_3_",
      style: ColorPanel.LightBrown,
      check: false,
    },
    {
      name: "ﾌﾞﾗｳﾝ",
      id: "Floor_brown",
      value: "_4_",
      style: ColorPanel.Brown,
      check: false,
    },
    {
      name: "ﾀﾞｰｸﾌﾞﾗｳﾝ",
      id: "Floor_dark",
      value: "_5_",
      style: ColorPanel.DarkBrown,
      check: false,
    },
  ];
  const Walls = [
    {
      name: "ﾎﾜｲﾄ",
      id: "Wall_white",
      value: "1",
      style: ColorPanel.W_1,
      check: true,
    },
    {
      name: "ﾌﾞﾙｰｸﾞﾘｰﾝ",
      id: "Wall_natural",
      value: "2",
      style: ColorPanel.W_2,
      check: false,
    },
    // {
    //   name: "ﾊﾟｰﾌﾟﾙ",
    //   id: "Wall_cherry",
    //   value: "3",
    //   style: ColorPanel.W_3,
    //   check: false,
    // },
    {
      name: "ｸﾞﾚｲｯｼｭｲｴﾛｰ",
      id: "Wall_brown",
      value: "4",
      style: ColorPanel.W_4,
      check: false,
    },
    // {
    //   name: "ﾋﾟﾝｸ",
    //   id: "Wall_dark",
    //   value: "5",
    //   style: ColorPanel.W_5,
    //   check: false,
    // },
    // {
    //   name: "ｸﾞﾘｰﾝ",
    //   id: "Wall_dark",
    //   value: "6",
    //   style: ColorPanel.W_6,
    //   check: false,
    // },
    {
      name: "ﾊﾟｰﾌﾟﾘｯｼｭﾌﾞﾙｰ",
      id: "Wall_dark",
      value: "7",
      style: ColorPanel.W_7,
      check: false,
    },
    // {
    //   name: "ｸﾞﾚｰ",
    //   id: "Wall_dark",
    //   value: "8",
    //   style: ColorPanel.W_8,
    //   check: false,
    // },
    {
      name: "ｸﾞﾚｲｯｼｭﾊﾟｰﾌﾟﾙ",
      id: "Wall_dark",
      value: "9",
      style: ColorPanel.W_9,
      check: false,
    },
    {
      name: "ｸﾞﾚｲｯｼｭｸﾞﾘｰﾝ",
      id: "Wall_dark",
      value: "10",
      style: ColorPanel.W_10,
      check: false,
    },
  ];
  // ベース
  const handleChange = ( event1 ) => {
    let value = event1.target.value;
    if ( value === "accent" ) {
      setBase( "baseD" );
    } else if (
      value === "_1_" ||
      value === "_2_" ||
      value === "_3_" ||
      value === "_4_" ||
      value === "_5_" ||
      value === "_6_"
    ) {
      setFloor( value );
    } else if (
      value === "1" ||
      value === "2" ||
      value === "3" ||
      value === "4" ||
      value === "5" ||
      value === "6" ||
      value === "7" ||
      value === "8" ||
      value === "9" ||
      value === "10"
    ) {
      setWall( value );
    }
  };

  const none = {
    display:"none",
  };

  return (
    <div className="container w-100">
      <div
        className="row justify-content-between"
        style={ none }
      >
        <div
          className="col-12 align-self-center my-3"
          style={ TitleBar }
        >
          <div className="Floor_color font-weight-bold text-secondary">
            アクセントウォール
          </div>
        </div>
        <div className="col-12 align-self-center my-3">
          <div className="container w-100">
            <div className="row justify-content-between">
              {
                Room.map( ( room, index ) => (
                  <div
                    className="col-4 w-100 mx-0 px-1"
                    key={ index }
                  >
                    <input
                      className = "radio-input"
                      type = "radio"
                      name = "room_type"
                      onChange = { handleChange }
                      value = { room.value }
                      defaultChecked = { room.check }
                      id = { room.value }
                    />
                    <label
                      className="btn w-100 text-center"
                      htmlFor={ room.value }
                    >
                      <span className="text-secondary">
                        { room.name }
                      </span>
                    </label>
                  </div>
                ) )
              }
            </div>
          </div>
        </div>
      </div>
      <div className = "row justify-content-between">
        <div 
          className = "col-12 align-self-center"
          style = { TitleBar }
        >
          <div className = "Floor_color font-weight-bold text-white">
              床
            </div>
        </div>
        <div className = "container">
          <div className = "row my-1 justify-content-around">
            {
              Floors.map( ( floor, index ) => (
                <div
                  className = "floor col-4 text-center"
                  key={ index }
                >
                  <input
                    className = "radio-input"
                    type = "radio"
                    name = "floor_type"
                    onChange = { handleChange }
                    value = { floor.value }
                    id = { floor.value }
                    defaultChecked = { floor.check }
                  />
                  <label
                    className = "w-100 text-center"
                    htmlFor = { floor.value }
                  >
                    <div 
                      className = "text-center w-100"
                      style = { floor.style }
                    >
                    </div>
                    <div className="text-center w-100 text-secondary mx-0">
                      <nobr>
                        <small>
                          { floor.name }
                        </small>
                      </nobr>
                    </div>
                  </label>
                </div>
              ) )
            }
          </div>
        </div>
      </div>
      <div className="row justify-content-between">
        <div 
          className = "col-12 align-self-center"
          style = { TitleBar }
        >
          <div className="Wloor_color font-weight-bold text-white">
            壁
          </div>
        </div>
        <div className="container">
          <div className="row my-1 justify-content-around">
            {
              Walls.map( ( wall, index ) => (
                <div
                  className = "wall col-4 w-100 text-center"
                  key = { index }
                >
                  <input
                    className = "radio-input"
                    type = "radio"
                    name = "wall_type"
                    onChange ={ handleChange }
                    value = { wall.value }
                    id = { wall.value }
                    defaultChecked = { wall.check }
                  />
                  <label
                    className = "text-center w-100"
                    htmlFor = { wall.value }>
                    <div
                      className = "text-center w-100"
                      style = { wall.style }
                    >
                    </div>
                    <div className="text-center w-100 text-secondary">
                      <nobr>
                        <small>
                          { wall.name }
                        </small>
                      </nobr>
                    </div>
                  </label>
                </div>
              ) )
            }
          </div>
        </div>
      </div>
    </div>
  );
};


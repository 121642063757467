import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import html2canvas from "html2canvas";

export const CompareImage = withRouter(props => {
  const [ state, setState ] = useState( { images: [], viewType:"landscape" } );
  const [ test, setTest ] = useState( "0" );
  const [ test2, setTest2 ] = useState( "0" );
  const handleFile = ( e ) => {
    const { files } = e.target;
    if ( files.length === 1 ) {
      setState( { images: [], viewType:"landscape" } );
      setTest( "0" );
      setTest2( "0" );
      alert( "ファイルが1個しか選択されていません。\n2個以上のファイルを選択し直してください。" );
      return false;
    }
    try {
      let images = [];
      for ( let i = 0; i < files.length; i++ ) {
        const file = files[ i ];
        const { type } = file;
        if ( type.split( "/" )[ 0 ] !== "image" ) {
          throw new Error( "file is not an Image" );
        }
        images.push( URL.createObjectURL( file ) );
      }
      setState( { ...state, images: images } );
    } catch ( e ) {
      console.log( e );
      alert( e );
    }
  };

  const onClickBackToTop = () => {
     props.history.push( "/" );
  }

  var ua = window.navigator.userAgent.toLowerCase();
  const PC = () => {
    if( test === "0" ){
      setTest( "1" )
    };
    setTest2( "1" )
  }

  function downloadImage ( data ) {
		var fname = "image.png";
		var encdata = atob( data.replace(/^.*,/, '') );
		var outdata = new Uint8Array( encdata.length );
		for ( var i = 0; i < encdata.length; i++ ) {
			outdata[ i ] = encdata.charCodeAt( i );
		}
		var blob = new Blob( [ outdata ], [ "image/png" ] );
		if ( window.navigator.msSaveBlob ) {
			window.navigator.msSaveOrOpenBlob( blob, fname );
		} else {
      document.getElementById( "getImage" ).href = data;
			document.getElementById( "getImage" ).download = fname;
			document.getElementById( "getImage" ).click();
		}
  }

  const onClickSave = () => {
    window.scrollTo( 0, 0 );
    let canvasW = document.getElementById( "CompareImage" ).clientWidth;
    let canvasH = document.getElementById( "CompareImage" ).clientHeight;
    html2canvas( document.querySelector( "#CompareImage" ), {
        scrollX: -7,
        width: canvasW,
        height: canvasH,
        allowTaint : false,
        useCORS: true
    } ).then( canvas => { 
        downloadImage( canvas.toDataURL() );
    } );
  }

  const onClear = () => {
    setState( { images: [], viewType:"landscape" } );
    setTest( "0" );
    setTest2( "0" );
  }
  

  return (
    <div
      className="page-container"
      style={ { padding: 12 } }
    >
      <h3 className="text-center my-1 text-secondary font-weight-bold">
        比較画面
      </h3>
      <div className="container">
        <div className="row">
          <div
            className="col-12"
            style={ test2==="1"?{ display:"none" }:{} }
          >
            <label 
              htmlFor="file_photo"
              className="btn btn-success w-100 py-3"
            >
              ２つの保存写真を選択してください
              <input
                type = "file"
                id = "file_photo"
                style = { { display: "none" } }
                name = "attachment[]"
                accept = "image/*"
                multiple
                onChange = { handleFile }
                onClick = { PC }
              />  
            </label>
          </div>
            {test === "0" ?
              (
                <div className="col-12 w-100 border border-danger bg-white">
                  <div className="PC">
                    {
                      ua.indexOf( "windows nt" ) !== -1 ?
                      (
                        <div className="text-center">
                          <div className="text-center">
                            PCをご利用のお客様へ
                          </div>
                          保存写真を選択される際は,[control]キーを押しながら、画像を選択して下さい。
                        </div>
                      )
                      :
                      ua.indexOf( "apple" ) !== -1 ?
                      (
                        <div className="text-center">
                          <div className="text-center">
                            PCをご利用のお客様へ
                          </div>
                          保存写真を選択される際は,[control]キー+[alt]キーを押しながら、画像を選択して下さい。
                        </div>
                      )
                      :
                      (
                        <div className="text-center">
                          <div className="text-center">
                            PCをご利用のお客様へ
                          </div>
                          <div>
                            windows:保存写真を選択される際は,[command]キーを押しながら、画像を選択して下さい。
                          </div>
                          <div>
                            mac:保存写真を選択される際は,[control]キー+[alt]キーを押しながら、画像を選択して下さい。
                          </div>
                        </div>
                      )
                    }
                  </div>
                  
                </div>
              )
              :(
                <>
                </>
              )
            }
          <div
            className="row bg-light border border-dark align-items-center"
            id="CompareImage"
          >
            {
              state.images.map( ( img, i ) => (
                <div
                  key={ i }
                  className="col-md-6 col-12"
                  style={
                    {
                      maxWidth:"100%",
                      width:"100%",
                      maxHeight:"100%",
                      height:"auto",
                    }
                  }
                >
                  <img
                    style={
                      {
                        height:"auto",
                        width:"100%",
                      }
                    }
                    src={ img }
                    alt = ""
                    id={ i }
                  />
                </div>
              ))
            }
          </div>


          <div
            className="container border border-dark my-5"
            style={
              test==="1"?
              {}
              :
              { display:"none" }
            }
          >
            <div className="row justify-content-around py-2">
              <div className="col-3 w-100">
                <button
                  id = "btnBackToTop"
                  className = "btn btn-secondary w-100"
                  onClick = { onClickBackToTop }
                >
                  トップに戻る
                </button>
              </div>
              <div className="col-3 w-100">
                <button
                  className="btn btn-danger w-100"
                  onClick = { onClear }
                >
                  画像をクリア
                </button>
              </div>
              <div className="col-3 w-100">
                <button 
                  id = "btnSave"
                  className = "w-100 btn btn-dark h-100"
                  onClick = { onClickSave }
                >
                  保　存
                </button>
                <a 
                  id="getImage"
                  href="" 
                  style={ { display:"none" } } 
                  download="image.png"
                >
                  画像保存
                </a>
              </div>
              <div className="col-3 w-100">
                <a
                  href="https://www.i-koike.jp/contact/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button 
                    id="" 
                    className="w-100 btn btn-info h-100"
                  >
                    来店予約
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
})

import React from 'react';
import { useLocation } from "react-router-dom";

const topstyle = {
  fontFamily:"'M PLUS Rounded 1c', sans-serif"
};

const description = "https://www.i-koike.jp/description/cgct/attention.png"


function HomeWord() {
  const isLightVersion = window.location.href.includes("/light");
  const location = useLocation().pathname;
  return(
      <div className="container mt-3">
        {isLightVersion === false?
        <>
          <div className="row justify-content-center d-none d-md-block">
            <div 
              className="col-12 text-dark text-center font-weight-bold my-0 h2"
              style={ topstyle }
            >
                カーテン提案シミュレータ
            </div>
            <div className="col-12 w-100 text-center">
              <img
                src="https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/TOP/Cureco_logo.png"
                alt="カリコロゴ"
                className="text-center"
                style={{ height:"150px", width:"auto" }}
              />
            </div>
            <div className="col-12 text-dark text-center">
              <a
                href={ description }
                target="_blank"
                rel="noopener noreferrer"
              >
                <small className="text-dark">
                  この文字をクリックし、使用上の注意を確認してからご使用ください。
                </small>
              </a>
            </div>
            <div className="container">
              <div className="row justify-content-center my-1">
                {
                  location === "/light"?
                  <div
                    className="balloon col-md-6 col-12"
                  >
                    まずは、ベースの画像を選択してください。
                  </div>
                  :
                  <></>
                }
              </div>
            </div>
          </div>
          {/* phone */}
          <div className="row justify-content-center d-block d-md-none">
            <div
              className="col-12 text-dark text-center font-weight-bold h5"
              style={ topstyle }
            >
                カーテン提案シミュレータ
            </div>
            <div className="col-12 w-100 text-center">
              <img
                src="https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/TOP/Cureco_logo.png"
                alt="カリコロゴ"
                className="text-center"
                style={{ height:"100px",width:"auto" }}
              />
            </div>
            <div className="text-dark text-center small">
              <a
                href={ description }
                target="_blank"
                rel="noopener noreferrer"
                className="col-12 text-dark text-center mt-0"
              >
                この文字をクリックし、
                <br/>
                使用上の注意を確認してからご使用ください。
              </a>
            </div>
          </div>
        </>
        :
        <>
          <div className="row justify-content-center d-none d-md-block">
            <div className="col-12 w-100 text-center">
              <img
                src="https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/TOP/Cureco_logo.png"
                alt="カリコロゴ"
                className="text-center"
                style={{ height:"auto", width:"300px" }}
              />
            </div>
            <div
              className="text-dark text-center small my-3"
              style={{ fontSize:"14px" }}
            >
              カーテン提案シュミレータを使って、
              あなたのお部屋に合うカーテンを見つけましょう。
            </div>
            <div className="container">
              <div className="row justify-content-center my-1">
                {
                  location === "/light"?
                  <div
                    className="balloon col-md-6 col-12"
                  >
                    まずは、ベースの画像を選択してください。
                  </div>
                  :
                  <></>
                }
              </div>
            </div>
          </div>
          {/* phone */}
          <div className="row justify-content-center d-block d-md-none">
            <div className="col-12 w-100 text-center">
              <img
                src="https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/TOP/Cureco_logo.png"
                alt="カリコロゴ"
                className="text-center"
                style={{ height:"auto",width:"200px" }}
              />
            </div>
            <div
              className="text-dark text-center small my-3"
              style={{ fontSize:"14px" }}
            >
              カーテン提案シュミレータを使って、
              <br/>
              あなたのお部屋に合うカーテンを見つけましょう。
            </div>
            <div className="container">
              <div className="row justify-content-center my-1">
                {
                  location === "/light"?
                  <div
                    className="balloon col-md-6 col-12"
                  >
                    まずは、ベースの画像を選択してください。
                  </div>
                  :
                  <></>
                }
              </div>
            </div>
          </div>
        </>
      }
      </div>
  );
}

export default HomeWord;

import React, { useState, useRef, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import html2canvas from "html2canvas";
import { ImageProvider } from "../context/imageContext";
import { RoomArranger } from '../components/RoomArranger';
import { WindowArranger } from '../components/WindowArranger';
import SimulationImage from '../components/SimulationImage';
import ProductNumber from '../components/ProductNumber';
import ColorSelector from '../components/ColorSelector';
import { ItemSelectorDivideByColor } from '../components/ItemSelectorDivideByColor';
import { CustomCheckBoxes } from '../parts/CustomCheckBoxes';
import CustomDropDown from '../parts/CustomDropDown';
import CustomScrollImageItems from '../parts/CustomScrollImageItems';
import { ConstructionArranger } from "../components/construction";

export const RollSimulator = withRouter(props => {

  const { baseFrom, brandsData, pricesData, roomImageFile } = props;

  const [ rollFunctions, setRollFunctions ] = useState( { hits: [] } );
  const [ selectedFunctions, setSelectedFunctions ] = useState( [] );
  const [ selectedTastes, setSelectedTastes ] = useState( [] );
  const [ selectedPrice, setSelectedPrice ] = useState( "0" );
  const [ selectedRoll, setSelectedRoll ] = useState( null );

  const [rgb, setRGB] = useState(null);
  const [ baseImgPath, setBaseImgPath ] = useState( "" );
  const [ furnitureImgPath, setFurnitureImgPath ] = useState( "" );
  const [ pointerImgPath, setPointerImgPath ] = useState( "" );
  const [ rollImgPath, setRollImgPath ] = useState( "" );
  const imgPathSetters = {
    base: setBaseImgPath,
    furniture: setFurnitureImgPath,
    roll: setRollImgPath
  };
  const [ roomBaseImageFile, setRoomBaseImageFile ] = useState( roomImageFile );
  const [ roomBaseImage, setRoomBaseImage ] = useState( null );

  const cgProducts = [
    { id:"img_base", src:baseImgPath, className:"RoomBase w-100" },
    { id:"img_funiture", src:furnitureImgPath, className:"Funiture w-100" },
    { id:"img_roll", src:rollImgPath, className:"Roll w-100" }
  ];

  const photoProducts = [
    { id:"img_roll", src:rollImgPath, className:"Roll w-100" },
    { id:"img_pointer", src:pointerImgPath, className:"PointerBase w-100" },
  ];
  const constructionProducts = [
    { id: "img_base", src: baseImgPath, className:"RoomBase w-100" },
    { id: "img_roll", src: rollImgPath, className:"Roll w-100" },
    { id: "img_pointer", src: pointerImgPath, className:"PointerBase w-100" },
  ];

  const [ windowType, setWindowType ] = useState( "koshi" );
  const [ rollCode, setRollCode ] = useState( null );
  const [ specifiedRoll, setSpecifiedRoll ] = useState( null );
  const [ specifiedRollItems, setSpecfiedRollItems ] = useState( [] );
  const [ selectedSpecifiedRoll, setSelectedSpecifiedRoll ] = useState( [] );
  const [ choosedColor1Rolls, setChoosedColor1Rolls ] = useState( null );
  const [ choosedColor2Rolls, setChoosedColor2Rolls ] = useState( null );
  const [ choosedColor3Rolls, setChoosedColor3Rolls ] = useState( null );
  const [ resetColor, setResetColor ] = useState( { "color1":false, "color2":false, "color3":false } );
  const [ choosedRolls, setChoosedRolls ] = useState( null );
  const [ choosedRollItems, setChoosedRollItems ] = useState( [] );
  const [ saved, setSaved ] = useState( true );

  const ROLL_IMAGE_URL_BASE = "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/RollScreens/Rolls/" 

  useEffect(() => {
    if ( !windowType || !rollCode ) return;
    let imgName = 
      rollCode
      +
      '_'
      +
      (
        windowType === "hakidashi_small"?
        "1"
        :
        windowType === "hakidashi_big"?
        "2"
        :
        "3"
      )
      +
      "_roll.png";
    setRollImgPath( ROLL_IMAGE_URL_BASE + imgName );
  }, [ windowType, rollCode ]) ;

  useEffect( () => {
    if ( !roomBaseImageFile ) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      setRoomBaseImage( reader.result );
    };
    if ( roomBaseImageFile ) {
      reader.readAsDataURL( roomBaseImageFile );
      setRoomBaseImage( reader.result );
    } else {
      setRoomBaseImage( null );
    }
  }, []);
  const WINOW_POINTER = "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/";
  useEffect( () => {
    if (!windowType) return;
    let imgName2 = 
    (
      windowType === "hakidashi_small"?
      "A"
      :
      windowType === "hakidashi_big"?
      "B"
      :
      "C"
    ) 
    +".png";
    setPointerImgPath( WINOW_POINTER + "Pointer/Roll/" + imgName2 );
  }, [ windowType ] );

  let rollFuncOptions = [];
  rollFunctions.length && rollFunctions.forEach( ( func, index ) =>
    rollFuncOptions.push( { id:func.name, name:func.disp_name, func_description:func.func_description } ) );

    const GET_GROUPED_PRODUCTS_FROM_RGB_URL = 'https://i-koike2.sakura.ne.jp/rgbcgroll.php?rgb=';

  // スクロール画像部品に渡す項目群を更新
  const resetRollItems = ( rollScreens ) => {
      let items = [];
      const rolls = !( rollScreens instanceof Array )? [ rollScreens ] : rollScreens;

      rolls.forEach( ( roll, index ) =>
        items.push( {
          "name": roll.code,
          "dispName": roll.code,
          "image": "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/RollScreens/icon/" + roll.code + "-i.png",
          "value": roll.code
        } )  
      );
      return items;    
    }
  
    useEffect( async () => {
    const result = await axios.get("/api/functions", {
      params: {
         clothType: 'roll'
      }      
    });
    setRollFunctions( result.data );
  }, [] ); 
  
  useEffect( () => {
    setSaved( false );
  }, [ baseImgPath, furnitureImgPath ]); 

  useEffect(() => {
    if ( specifiedRoll === null ) {
      return false;
    }
    if ( specifiedRoll === "" ) {
      alert( "対象の生地は存在しません。" );
    }
    let items = resetRollItems( specifiedRoll === "" ? [] : specifiedRoll );
    setSpecfiedRollItems( items );
  }, [ specifiedRoll ]); 
  const [ alertcounter, setAlertcounter ] = useState( 0 );
  useEffect(() => {
    if (choosedColor1Rolls && !choosedColor1Rolls.length &&
        choosedColor2Rolls && !choosedColor2Rolls.length &&
        choosedColor3Rolls && !choosedColor3Rolls.length) {
          setAlertcounter( alertcounter + 1 )
          if((alertcounter % 3) === 0){
            alert( "該当の生地がありません。\n条件を変更し、もう一度お試しください" );
          }
          if((alertcounter % 3) !== 0){
            alert( "該当の生地がありません。\n条件を変更し、もう一度お試しください" );
          }
        return false;
    }
  }, [choosedColor1Rolls, choosedColor2Rolls, choosedColor3Rolls]); 

  useEffect(() => {
    if (choosedRolls === null) return false;
    if (!choosedRolls.length) {
      alert( "該当の生地がありません。\n条件を変更し、もう一度お試しください。" );
    }

    let items = resetRollItems( choosedRolls );
    setChoosedRollItems( items );
  }, [ choosedRolls ]); 

  const onSave = () => {
    setSaved( true );
  }

  const queryRolls = ( codes, condition, setter ) => {
    if ( codes && codes.length ) {
      condition[ "codes" ] = codes.join( "," );
    }
    axios.get( "/api/roll_screens", {
      params: condition
    } ).then(result => {
      if( codes === null ){
        setter( result.data )
      }
      else{
      const resItems = result.data;
      console.log( resItems );
      const reqItems = codes;
      console.log( reqItems );
      const TESTab = [];

      for( var i = 0; i <= reqItems.length - 1; i++ ){
        for( var a = 0; a <= resItems.length - 1; a++ ){
          if( reqItems[ i ] === resItems[ a ].code){
            console.log( reqItems[ i ] + "/" + resItems[ a ].code )
            TESTab.push( resItems[ a ] )
          }
        }
      }
      setter( TESTab )
    }
     });
  }

  const onClickConfirm = () => {
    let funcs = '';
    selectedFunctions.map(( f, idx ) => funcs += ( ( funcs !== '' ? ',' : '') + f ) );

    let condition = {};
    if ( funcs !== '' ) {
      condition[ "functions" ] = funcs;
    }
    if ( selectedPrice !== '0' ) {
      condition[ "price" ] = selectedPrice;
    }

    if ( rgb ) {
      setChoosedColor1Rolls( null );
      setChoosedColor2Rolls( null );
      setChoosedColor3Rolls( null );
      axios.get( "/api/queryProducts", {
       params: {
         url: GET_GROUPED_PRODUCTS_FROM_RGB_URL + [ rgb.r, rgb.g, rgb.b ].join(",")
       }      
     } ).then(result => {
      // console.log( "確認用:" + GET_GROUPED_PRODUCTS_FROM_RGB_URL + [ rgb.r, rgb.g, rgb.b ] )
       const colors = [ "color1", "color2", "color3" ];
       colors.forEach(( color, idx ) => {
         let setter = color === "color1"
                  ? setChoosedColor1Rolls
                  : color === "color2"
                  ? setChoosedColor2Rolls
                  : setChoosedColor3Rolls;
         if ( !result.data[color].length ) {
           setter([]);
         } else {
           queryRolls( result.data[color], condition, setter );
         }
       });
      });
    } else {
      queryRolls( null, condition, setChoosedRolls );
    }
  }; 

  const onClickBackToTop = () => {
    if ( !saved && !window.confirm( 'シミュレーションデータが保存されていません。ページを移動してよろしいですか？' ) ) {
      return;
     }
     props.history.push( "/" );
  }

    // 窓形状変更ハンドラ
    const onChangeWindowType = ( e ) => {
    setWindowType( e.target.value );
  }

  const onSelectRoll = ( e ) => {
    setRollCode( e.target.value );
  }

  const simulation = {
    height: "0px",
    width: "100%",
  };
  const styleColor = {
    backgroundColor:"#046B5E",
  }
  const backStyleColor = {
    backgroundColor:"rgba(4,107,94,0.3)",
  }
  const bgstyle = {
    backgroundColor:"rgba(62,58,57,0.1)",
  };

  function downloadImage ( data ) {
		var fname ="image.png";
		var encdata= atob( data.replace(/^.*,/, '') );
		var outdata = new Uint8Array( encdata.length );
		for ( var i = 0; i < encdata.length; i++ ) {
			outdata[ i ] = encdata.charCodeAt( i );
		}
		var blob = new Blob( [ outdata ], [ "image/png" ] );
		
		if (window.navigator.msSaveBlob) {
			// IE用
			window.navigator.msSaveOrOpenBlob( blob, fname );
		} else {
      document.getElementById( "getImage" ).href = data;
			document.getElementById( "getImage" ).download = fname;
			document.getElementById( "getImage" ).click();
		}
  }
  const onClickSave = () => {
    document.querySelector( "#logo" ).style.display = "block";
    document.querySelector( "#date_time" ).style.display = "block";
    document.querySelector( "#products" ).style.display = "block";
    window.scrollTo(0,0);
    let canvasW = document.getElementById( "simulationArea" ).clientWidth;
    let canvasH = canvasW / 1.5;
    html2canvas(document.querySelector( "#simulationArea" ), {
        scrollX:-7,
        width: canvasW,
        height: canvasH,
        allowTaint : false,
        useCORS: true
    }).then(canvas => { 
        downloadImage(canvas.toDataURL());
    });
  }

  const test = 
    ( rgb === null )?
    {}
    :
    { 
      background:
        'rgba('
        +
        rgb.r
        +
        ','
        +
        rgb.g
        +
        ','
        +
        rgb.b
        +
        ','
        +
        1
        +
        ')',
        textShadow:"1px 1px 0 #000" 
    };


  const [ dropClickRoom, setDropClickRoom ] = useState(0);
  const [ dropClickColor, setDropClickColor ] = useState(0);
  const [ dropClickFunc, setDropClickFunc ] = useState(0);
  const [ dropClickPN, setDropClickPN ] = useState(0);
  const [ dropClickResult, setDropClickResult ] = useState(0);
  const [ dropClickConstruction, setDropClickConstruction ] = useState(0);
  const [ dropClickConstWindow, setDropClickConstWindow ] = useState(0);
  const [ dropClickWindow, setDropClickWindow ] = useState(0);
  const onClickDropRoom = () =>{
    setDropClickRoom( dropClickRoom + 1 )
  }
  const onClickDropColor = () =>{
    setDropClickColor( dropClickColor + 1 )
  }
  const onClickDropFunc = () =>{
    setDropClickFunc( dropClickFunc + 1 )
  }
  const onClickDropPN = () =>{
    setDropClickPN( dropClickPN + 1 )
  }
  const onClickDropResult = () =>{
    setDropClickResult( dropClickResult + 1 )
  }
  const onClickDropConstruction = () =>{
    setDropClickConstruction( dropClickConstruction + 1 )
  }
  const onClickDropConstWindow = () =>{
    setDropClickConstWindow( dropClickConstWindow + 1 )
  }
  const onClickDropWindow = () =>{
    setDropClickWindow( dropClickWindow + 1 )
  }

  return(
    <ImageProvider>
      <div className="CGSeletRoll">
        <div className="container">
          <div className="row">
            {baseFrom === "cg"?
              (
                <div 
                  className="CGspace col-12 col-md-8 px-0" 
                  style={ simulation }
                >
                  <SimulationImage
                    baseFrom={ baseFrom }
                    productsImage={ cgProducts }
                    productsCode={ [ { name: "roll", code: rollCode } ] }
                    onSaveHandler={ onSave }
                  />
                </div>
              ):
              baseFrom === "photo"?
              (
                <SimulationImage
                  baseFrom={ baseFrom}
                  productsImage={ photoProducts } 
                  productsCode={ [ { name: "roll", code: rollCode } ] }
                  roomBaseImage={ roomBaseImage }
                  onSaveHandler={ onSave }
                />
              )
              :
              (
                <SimulationImage
                  baseFrom={ baseFrom }
                  productsImage={ constructionProducts } 
                  productsCode={ [ { name: "roll", code: rollCode } ] }
                  roomBaseImage={ baseImgPath }
                  onSaveHandler={ onSave} 
                />
              )
            }
            <div className="Choices col-12 col-md-4"
              style={ 
                ( window.innerWidth >= 767 )?
                {
                }
                :
                (
                  baseFrom==="cg"?
                  {
                    top:( window.innerWidth / 1.5 ),
                    scrollY:( window.innerWidth / 1.5 )
                  }
                  :
                  {
                    top:( window.innerWidth / 1.5 )
                  }
                )
              }
            >
              <div className="CGRoll text-center bg-dark text-white py-1">
                {
                  baseFrom === "cg"?
                  "背景:CG  ロールスクリーン"
                  :
                  baseFrom === "photo"?
                  "背景:写真  ロールスクリーン"
                  :
                  "背景:施工例  ロールスクリーン"
                }
              </div>
              {
                baseFrom === "cg"?
                (
                  <div style={ bgstyle }>
                    <div className="box_Room">
                      <label 
                        htmlFor="inbox_Room"
                        className="border border-dark text-center my-0"
                        onClick = { onClickDropRoom }
                        style={
                          dropClickRoom === 0?
                          {
                            backgroundColor:"#6c757d"
                          }
                          :
                          ((dropClickRoom % 2) == 0)?
                          {
                            backgroundColor:"#6c757d"
                          }
                          :
                          {
                            backgroundColor:"white"
                          }
                        }
                      >
                        {
                          dropClickRoom === 0?
                          <div className="text-white container py-2">
                            <div className="row">
                              <div className="col-10 w-100 text-left">
                              部屋(シミュレーション背景)
                              </div>
                              <div className="col-2 w-100 text-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-caret-down-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                </svg>
                              </div>
                            </div>
                          </div>
                          :
                          ((dropClickRoom % 2) == 0)?
                          <div className="text-white container py-2">
                            <div className="row">
                              <div className="col-10 w-100 text-left">
                                部屋(シミュレーション背景)
                              </div>
                              <div className="col-2 w-100 text-center">
                                <svg 
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-caret-down-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                </svg>
                              </div>
                            </div>
                          </div>
                          :
                          <div className="container py-2">
                            <div className="row">
                              <div className="col-10 w-100 text-left">
                                部屋(シミュレーション背景)
                              </div>
                              <div className="col-2 w-100 text-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-caret-up-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                </svg>
                              </div>
                            </div>
                          </div>
                        }
                      </label>
                      <input
                        type="checkbox"
                        id="inbox_Room"
                        className="on-off_Room"
                      />
                      <div
                        className="pb-3"
                        style={
                          {
                            overflowY:"scroll",
                            height:"260px"
                          }
                        }
                      >
                        <RoomArranger
                          imagePathSetters = { imgPathSetters }
                        />
                        <WindowArranger
                          imagePathSetters = { { "base":setBaseImgPath } }
                          onWindowTypeChangeHandler = { onChangeWindowType }
                        />
                      </div>
                    </div>
                  </div>
                )
                :
                baseFrom ==="photo"?
                (
                  <div className="box_Window">
                  <label 
                    htmlFor="inbox_Window"
                    className="border border-dark text-center my-0"
                    onClick = { onClickDropWindow }
                    style={
                      dropClickWindow === 0?
                      {
                        backgroundColor:"#6c757d"
                      }
                      :
                      ((dropClickWindow % 2) == 0)?
                      {
                        backgroundColor:"#6c757d"
                      }
                      :
                      {
                        backgroundColor:"white"
                      }
                    }
                  >
                    {
                      dropClickWindow === 0?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓形状
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg 
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      ((dropClickWindow % 2) == 0)?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓形状
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓形状
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg 
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-up-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    }
                  </label>
                  <input
                    type="checkbox"
                    id="inbox_Window"
                    className="on-off_Window"
                  />
                  <div className="pb-3">
                    <WindowArranger
                      hideFlags={ { floor: true, wall: true } }
                      onWindowTypeChangeHandler={ onChangeWindowType }
                    />
                  </div>
                </div>
                  // <WindowArranger
                  //   hideFlags={ { floor: true, wall: true } }
                  //   onWindowTypeChangeHandler={ onChangeWindowType }
                  // />
                )
                :(
                  <div style={ bgstyle }>
                    <div className="box_Construction">
                    <label 
                      htmlFor="inbox_Construction"
                      className="border border-dark text-center my-0"
                      onClick = { onClickDropConstruction }
                      style={
                        dropClickConstruction === 0?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        ((dropClickConstruction % 2) == 0)?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        {
                          backgroundColor:"white"
                        }
                      }
                    >
                    {
                      dropClickConstruction === 0?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            サンプル写真
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      ((dropClickConstruction % 2) == 0)?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            サンプル写真
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            サンプル写真
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    }
                    </label>
                    <input
                      type="checkbox"
                      id="inbox_Construction"
                      className="on-off_Construction"
                    />
                    <div className="pb-3" style={{overflowY:"scroll",height:"260px"}}>
                      <ConstructionArranger
                        imagePathSetters={ { "base": setBaseImgPath } }
                      />
                    </div>
                  </div>
                  <div className="box_ConstWindow">
                    <label 
                      htmlFor="inbox_ConstWindow"
                      className="border border-dark text-center my-0"
                      onClick = { onClickDropConstWindow }
                      style={
                        dropClickConstWindow === 0?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        ((dropClickConstWindow % 2) == 0)?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        {
                          backgroundColor:"white"
                        }
                      }
                    >
                    {
                      dropClickConstWindow === 0?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓サイズ
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* 窓形状
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> */}
                      </div>
                      :
                      ((dropClickConstWindow % 2) == 0)?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓サイズ
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* 窓形状
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> */}
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓サイズ
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* 窓形状
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg> */}
                      </div>
                    }
                    </label>
                    <input
                      type="checkbox"
                      id="inbox_ConstWindow"
                      className="on-off_ConstWindow"
                    />
                    <div>
                      <WindowArranger
                        hideFlags={ { floor: true, wall: true } }
                        onWindowTypeChangeHandler={ onChangeWindowType }
                      />
                    </div>
                  </div>
                    {/* <ConstructionArranger
                      imagePathSetters={ { "base": setBaseImgPath } }
                    />
                    <WindowArranger
                      hideFlags={ { floor: true, wall: true } }
                      onWindowTypeChangeHandler={ onChangeWindowType }
                    /> */}
                  </div>
                )
              }
              {/* <div className="row">
                <div className="col-12 text-center text-white">
                  <h3 
                    className="w-100 mb-0"
                    style={ styleColor }
                  >
                    RollScreen
                  </h3>
                </div>
              </div> */}
              <div style={ backStyleColor }>
                <div className="box_ColorPicker">
                  <label 
                    htmlFor="inbox_ColorPicker"
                    className="border border-dark text-center my-0"
                    onClick = { onClickDropColor}
                    style={
                      dropClickColor === 0?
                      {
                        backgroundColor:"#cce9dd"
                      }
                      :
                      test
                    }
                  >
                    {
                      dropClickColor === 0?
                      <div className="text-dark container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            ロール  カラー
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* ロールスクリーン  カラー
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> */}
                      </div>
                      :
                      ((dropClickColor % 2) == 0)?
                      <div className="text-dark container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            ロール  カラー
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* ロールスクリーン  カラー
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> */}
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            ロール  カラー
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* ロールスクリーン  カラー
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg> */}
                      </div>
                    }
                  </label>
                  <input
                    type="checkbox"
                    id="inbox_ColorPicker"
                    className="on-off_ColorPicker"
                  />
                  <div className="pb-3" style={{overflowY:"scroll",height:"315px"}}>
                    <ColorSelector
                      colorSetter = { setRGB } 
                      roomBaseImage = { roomBaseImage }
                      baseImgPath = { baseImgPath }
                      baseFrom = { baseFrom }
                    />
                  </div>
                </div>
                  {/* <ColorSelector
                    colorSetter={ setRGB }
                  /> */}
                  {
                    rollFuncOptions.length &&
                    (
                      <div className="box_func">
                        <label 
                          htmlFor="inbox_func"
                          className="border border-dark text-center my-0 w-100"
                          onClick = { onClickDropFunc }
                          style={
                            dropClickFunc === 0?
                            {
                              backgroundColor:"#cce9dd"
                            }
                            :
                              ((dropClickFunc % 2) == 0)?
                            {
                              backgroundColor:"#cce9dd"
                            }
                            :
                            {
                              backgroundColor:"white"
                            }
                          }
                        >
                          {
                            dropClickFunc === 0?
                            <div className="text-dark container py-2">
                              <div className="row">
                                <div className="col-10 w-100 text-left">
                                  ロール  機能
                                </div>
                                <div className="col-2 w-100 text-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                  </svg>
                                </div>
                              </div>
                              {/* ロールスクリーン  機能
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg> */}
                            </div>
                            :
                            ((dropClickFunc % 2) == 0)?
                            <div className="text-dark container py-2">
                              <div className="row">
                                <div className="col-10 w-100 text-left">
                                  ロール  機能
                                </div>
                                <div className="col-2 w-100 text-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                  </svg>
                                </div>
                              </div>
                              {/* ロールスクリーン  機能
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg> */}
                            </div>
                            :
                            <div className="container py-2">
                              <div className="row">
                                <div className="col-10 w-100 text-left">
                                  ロール  機能
                                </div>
                                <div className="col-2 w-100 text-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                    <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                  </svg>
                                </div>
                              </div>
                              {/* ロールスクリーン  機能
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                              </svg> */}
                            </div>
                          }
                        </label>
                        <input
                          type="checkbox"
                          id="inbox_func"
                          className="on-off_func"
                        />
                        <div className="pb-3" style={{overflowY:"scroll",height:"260px"}}>
                        <CustomCheckBoxes
                          title="機能"
                          options={ rollFuncOptions }
                          setSelected={ setSelectedFunctions }
                        />
                        </div>
                      </div>
                      // <CustomCheckBoxes
                      //   title="機能"
                      //   options={ rollFuncOptions }
                      //   setSelected={ setSelectedFunctions }
                      // />
                    )
                  }
                  <div className="box_PN">
                    <label 
                      htmlFor="inbox_PN"
                      className="border border-dark text-center my-0 w-100"
                      onClick = { onClickDropPN }
                      style={
                        dropClickPN === 0?
                        {
                          backgroundColor:"#cce9dd"
                        }
                        :
                        ((dropClickPN % 2) == 0)?
                        {
                          backgroundColor:"#cce9dd"
                        }
                        :
                        {
                          backgroundColor:"white"
                        }
                      }
                    >
                      {
                        dropClickPN === 0?
                        <div className="text-dark container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                              ロール  価格帯/品番検索
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                          {/* ロールスクリーン<br/>価格帯/品番検索
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                          </svg> */}
                        </div>
                        :
                        ((dropClickPN % 2) == 0)?
                        <div className="text-dark container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                              ロール  価格帯/品番検索
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                          {/* ロールスクリーン<br/>価格帯/品番検索
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                          </svg> */}
                        </div>
                        :
                        <div className="container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                              ロール  価格帯/品番検索
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                          {/* ロールスクリーン<br/>価格帯/品番検索
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                            <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                          </svg> */}
                        </div>
                      }
                    </label>
                    <input
                      type="checkbox"
                      id="inbox_PN"
                      className="on-off_PN"
                    />
                    <div>
                      <CustomDropDown
                        title="Price"
                        options={ pricesData }
                        defaultId="0"
                        setSelected={ setSelectedPrice }
                      />
                      <ProductNumber
                        apiPath="/api/roll_screens/"
                        choosedSetter={ setSpecifiedRoll }
                      />
                      <CustomScrollImageItems
                        name="targetProduct"
                        title="対象商品"
                        options={ specifiedRollItems }
                        multiSelectable={ false }
                        onClick={ onSelectRoll }
                      />
                    </div>
                  </div>
                  {/* <CustomDropDown
                    title="Price"
                    options={ pricesData }
                    defaultId="0"
                    setSelected={ setSelectedPrice }
                  />
                  <ProductNumber
                    apiPath="/api/roll_screens/"
                    choosedSetter={ setSpecifiedRoll }
                  />
                  <CustomScrollImageItems
                    name="targetProduct"
                    title="対象商品"
                    options={ specifiedRollItems }
                    multiSelectable={ false }
                    onClick={ onSelectRoll }
                  /> */}
                  <div className="box_result">
                    <label 
                      htmlFor="inbox_result"
                      className="border border-dark text-center my-0 w-100"
                      onClick = { onClickDropResult }
                      style={
                        dropClickResult === 0?
                        {
                          backgroundColor:"#cce9dd"
                        }
                        :
                        ((dropClickResult % 2) == 0)?
                        {
                          backgroundColor:"#cce9dd"
                        }
                        :
                        {
                          backgroundColor:"white"
                        }
                      }
                    >
                      {
                        dropClickResult === 0?
                        <div className="text-dark container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                              ロール  結果表示
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                          {/* ロールスクリーン  結果表示
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                          </svg> */}
                        </div>
                        :
                        ((dropClickResult % 2) == 0)?
                        <div className="text-dark container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                              ロール  結果表示
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                          {/* ロールスクリーン  結果表示
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                          </svg> */}
                        </div>
                        :
                        <div className="container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                              ロール  結果表示
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                          {/* ロールスクリーン  結果表示
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                            <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                          </svg> */}
                        </div>
                      }
                    </label>
                    <input
                      type="checkbox"
                      id="inbox_result"
                      className="on-off_result"
                    />
                    <div>
                      <div className="container my-3">
                        <div className="row justify-content-center">
                          <button
                            id="btnConfirm"
                            className="col-8 text-center btn btn-secondary py-3 font-weight-bold"
                            onClick={ onClickConfirm }
                            style={ test }
                          >
                            ロールスクリーンをみる
                          </button>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-12 text-white text-center">
                          <div 
                            className="w-100 py-1 my-3"
                            style={ styleColor }
                          >
                            ロールスクリーン一覧
                          </div>
                          {
                            rgb && choosedColor1Rolls && choosedColor2Rolls && choosedColor3Rolls &&
                            (
                              <ItemSelectorDivideByColor
                                color1Data={ choosedColor1Rolls }
                                color2Data={ choosedColor2Rolls }
                                color3Data={ choosedColor3Rolls }
                                iconURL="https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/RollScreens/icon/"
                                selectedSetter={ setRollCode }
                              />
                            )
                          }
                          {
                            !rgb && choosedRolls &&
                            (
                              <CustomScrollImageItems
                                name="choosedProducts"
                                title="抽出商品"
                                options={ choosedRollItems }
                                multiSelectable={ false }
                                onClick={ onSelectRoll }
                              />
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="container my-3">
                    <div className="row justify-content-center">
                      <button
                        id="btnConfirm"
                        className="col-8 text-center btn btn-secondary py-3 font-weight-bold"
                        onClick={ onClickConfirm }
                        style={ test }
                      >
                        ロールスクリーンをみる
                      </button>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12 text-white text-center">
                      <div 
                        className="w-100 py-1 my-3"
                        style={ styleColor }
                      >
                          ロールスクリーン一覧
                      </div>
                      {
                        rgb && choosedColor1Rolls && choosedColor2Rolls && choosedColor3Rolls &&
                        (
                          <ItemSelectorDivideByColor
                            color1Data={ choosedColor1Rolls }
                            color2Data={ choosedColor2Rolls }
                            color3Data={ choosedColor3Rolls }
                            iconURL="https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/RollScreens/icon/"
                            selectedSetter={ setRollCode }
                          />
                        )
                      }
                      {
                        !rgb && choosedRolls &&
                        (
                          <CustomScrollImageItems
                            name="choosedProducts"
                            title="抽出商品"
                            options={ choosedRollItems }
                            multiSelectable={ false }
                            onClick={ onSelectRoll }
                          />
                        )
                      }
                    </div>
                  </div> */}
              </div>


              <div
                className="container mt-0 py-3 border border-dark"
                style={ bgstyle }
              >
                <div className="row">
                  <div className="col-6">
                    <button
                      id="btnSave"
                      className="w-100 btn btn-dark"
                      onClick={ onClickSave }
                    >
                      保　存
                    </button>
                    <a 
                      id="getImage"
                      href=""
                      style={ { display: "none" } }
                      download="image.png"
                    >
                      画像保存
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      href="https://www.i-koike.jp/contact/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        id=""
                        className="w-100 btn btn-info"
                      >
                        来店予約
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ImageProvider>
  );
})

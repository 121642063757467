import React, { useState, useEffect } from "react";
import noimage from '../img/noimage.png'
export const ConstructionArranger = ( props ) => {
  const { imagePathSetters, } = props;
  const [ roomBaseImage, setRoomBaseImage ] = useState( "A/1" );
  const imgPathSetter = imagePathSetters ? imagePathSetters[ "base" ] : null;

  const TitleBar = {
    borderLeft:"10px solid #428158",
  };
  const style1 = {
    whiteSpace: "nowrap",
    backgroundColor:"white",
  };
  const company = [
    { name:"A", value:0 },
    { name:"B", value:1 },
    { name:"C", value:1 },
    { name:"D", value:1 },
    { name:"E", value:1 },
  ]
  const ConstructionImages = [
    { id:"1", src:"1", value:0 },
    { id:"2", src:"2", value:1 },
    { id:"3", src:"3", value:1 },
    // 増枚したい場合に下のコメントを外す
    // { id:"4", src:"4", value:"1" },
    // { id:"5", src:"5", value:"1" },
    // { id:"6", src:"6", value:"1" },
    // { id:"7", src:"7", value:"1" },
    // { id:"8", src:"8", value:"1" },
    // { id:"9", src:"9", value:"1" },
    // { id:"10", src:"10", value:"1" }
  ];

  const deleteImg = [
    { name:"A/2" },
    { name:"A/3" },
    { name:"E/2" }
  ];
  const handleClick = e => {
    let value = e.target.value;
    setRoomBaseImage( value )
  };
  const handleChange = e => {
    let value = e.target.value;
    setRoomBaseImage( value );
  };

  useEffect(() => {
    if ( !imgPathSetter ) return;
    imgPathSetter(
      "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/constructions/"+
        roomBaseImage +
        ".jpg"
    );
  }, [ roomBaseImage ]);


  return (
      <div className="container" cors>
        <div className="row">
          <div
            className="col-12 align-self-center my-3"
            style={ TitleBar }
          >
            <div className="Floor_color font-weight-bold text-secondary">
              Sample Image
            </div>
          </div>
        </div>
        {
          company.map(( company, index ) => (
            <ul
              className = "horizontal-list px-0 w-100 mx-1"
              style = { style1 }
              key = { index }
              data-simplebar
              data-simplebar-auto-hide="false"
            >
              {
              ConstructionImages.map(( CI, index ) => (
                <div
                  className = "floor col-5 w-100 text-center px-1 mt-1"
                  style = { { display: "inline-block" } }
                  key = { index }
                >
                  <input
                    className = "radio-input"
                    type = "radio"
                    name = "ConstructionImages"
                    onClick = { handleClick }
                    onChange = { handleChange }
                    value = { company.name + "/" + CI.id }
                    id = { company.name + "/" + CI.id }
                    defaultChecked = {
                      ( company.value === 0 && CI.value === 0 )?true:false
                    }
                  />
                  <label
                    htmlFor = { company.name + "/" + CI.id }
                    className = { "w-100 text-center" }
                  >
                    <img
                      src = { 
                        "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/constructions/"
                        +
                        company.name
                        +
                        "/"
                        +
                        CI.src
                        +
                        ".jpg?"
                        +
                        new Date()
                      }
                      alt = { 
                        company.name + "/" + CI.src + ".jpg"
                      }
                      AccessControlAllowOrigin="https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/constructions/"
                      className = "w-100"
                      crossOrigin = "anonymous"
                      style = { { useCORS:true} }
                      onError={ (e) => e.target.src = noimage }
                    />
                  </label>
                </div>
              ))
              }
            </ul>
          ))
        }
      </div>
  );
};

import React from "react";

const description = "https://www.i-koike.jp/description/cgct/attention.png"

function TopWord(){
  return(
    <div className="HomeWord container">
      {/* PC */}
      <div className="row justify-content-center d-none d-md-block">
        <div className="col-12 w-100 text-center my-3">
          <img 
            src="https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/TOP/Cureco_logo.png"
            alt="カリコロゴ"
            className="text-center"
            style={
              {
                height:"auto",
                width:"300px"
              }
            }
          />
        </div>
        <div
          className="text-dark text-center small my-3"
          style={{ fontSize:"16px" }}
        >
          カーテン提案シュミレータを使って、あなたのお部屋に合うカーテンを見つけましょう。
        </div>
        <div className="col-12 text-dark text-center">
          <a
            href={ description }
            target="_blank"
            rel="noopener noreferrer"
          >
          </a>
        </div>
      </div>
      {/* phone */}
      <div className="row justify-content-center d-block d-md-none">
        <div className="col-12 w-100 text-center my-3">
          <img
            src="https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/TOP/Cureco_logo.png"
            alt="カリコロゴ"
            className="text-center"
            style={{
                height:"auto",
                width:"200px"
            }}
          />
        </div>
        <div
          className="text-dark text-center small my-3"
          style={{ fontSize:"14px" }}
        >
          カーテン提案シュミレータを使って、
          <br/>
          あなたのお部屋に合うカーテンを見つけましょう。
        </div>
        <div className="col-12 text-center">
        </div>
      </div>
    </div>
  )
}

export default TopWord;

import React from 'react';

const TitleBar = {
  borderLeft:"10px solid #3F8257",
};

function CustomDropDown( props ) {

  const { title, options, defaultId, setSelected } = props;
  const handleChange = e => {
    setSelected( e.currentTarget.value );
  }
  return(
    <div className="container">
      <div className="row justify-content-between">
        <div
          className = "col-12 align-self-center"
          style = { TitleBar }
        >
          <div className="Wall_color font-weight-bold text-secondary">
            { title }
          </div>
        </div>
        {
          title === "Price"?
          (
            <div className="col-12 priceComment text-right">
              <div
                className="Price text-secondary"
                style={
                  {
                    fontSize:"9px",
                    lineHeight:1.1,
                  }
                }
              > 
                W2000×H2200まで
                (当社推奨生地使い縫製基準)
              </div>
            </div>
          )
          :
          (
            <></>
          )
        }
        <select
          className = "col-12 align-self-center my-1"
          onChange = { handleChange } 
        >
          {
            options.map(( opt, index ) => {
              if ( defaultId === opt.id )
              return <option value={ opt.id } selected key={ index }>{ opt.name }</option>;
              else
              return <option value={ opt.id } key={ index }>{ opt.name }</option>;
            })
          }
        </select>
      </div>
    </div>
  );
}

export default CustomDropDown;
import React from 'react';


function HowToSimulation() {
  return (
    <div className="container">
      <div className="row justify-content-between mt-3">
        <div className="col-12 text-secondary py-1">
          <span style= { { color:"#046B5E" } }>
              <i className="fas fa-question-circle"></i>
          </span>
          シミュレーションの使い方
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center text-secondary w-100 px-0 embed-responsive embed-responsive-16by9 border border-light">
            <iframe
              width="100%"
              height="auto"
              min-height="300px"
              src="https://www.youtube.com/embed/tcIgaqOznNE"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen>
            </iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowToSimulation;
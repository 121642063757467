import React, { useState, useEffect, useRef, useCallback } from "react";

import "./simulator.css";

function BackImgColorSwatch(
  { 
    onGetColor = () => {}, 
    onSetColor = () => {}, 
    roomBaseImage = (props) => roomBaseImage, 
    baseImgPath = (props) => baseImgPath
  }
)
  {
    const canvasRef = useRef();
      const [ state, setState ] = useState(
        {
          color: { r: 0, g: 0, b: 0, hex: "" },
        }
      );

      const drawCanvas = useCallback(() => {
        let canvas = canvasRef.current;
        let context = canvas.getContext( "2d" );
        let Img = new Image();
        Img.crossOrigin = "Anonymous";
        Img.src = roomBaseImage;
        Img.onload = () => {
          canvas.width = Img.width;
          canvas.height = Img.height;
          context.drawImage( Img, 0, 0, Img.width, Img.height );
        };
      }, [ canvasRef ]);

      useEffect( () => { drawCanvas(); }, [ drawCanvas ] );

      const getColor = ( e ) => {
        let canvas = canvasRef.current;
        let context = canvas.getContext( "2d" );
        let rect = canvas.getBoundingClientRect();
        let clientX, clientY;
        if ( e.touches ) {
          clientX = e.touches[ 0 ].clientX;
          clientY = e.touches[ 0 ].clientY;
        } else {
          clientX = e.clientX;
          clientY = e.clientY;
        }
        let x = clientX - rect.left;
        let y = clientY - rect.top;
        x = x * ( canvas.width / rect.width );
        y = y * ( canvas.height / rect.height );
        const Range = 5;
        const colorRange = ( Range * 2 ) + 1;
        let color = context.getImageData( x, y, colorRange, colorRange ).data;
        if ( color[ 0 ] !== 0 && color[ 1 ] !== 0 && color[ 2 ] !== 0 ) {
          onGetColor({
            r: color[ 0 ],
            g: color[ 1 ],
            b: color[ 2 ],
            a: color[ 3 ],
            hex: rgbToHex( color[ 0 ], color[ 1 ], color[ 2 ] ),
          });
          setState({
            ...state,
            color: {
              r: color[ 0 ],
              g: color[ 1 ],
              b: color[ 2 ],
              a: color[ 3 ] ,
              hex: rgbToHex( color[ 0 ], color[ 1 ], color[ 2 ] ),
            },
          });
        }
        return false;
      };

      function componentToHex( c ) {
        var hex = c.toString( 16 );
        return hex.length === 1 ? "0" + hex : hex;
      }
      function rgbToHex( r, g, b ) {
        return "#" + componentToHex( r ) + componentToHex( g ) + componentToHex( b );
      }
      return (
        <div>
          <div className="scrolltest">
            <div className="processed-image-base">
              <canvas
                ref = { canvasRef }
                id = "result-canvas"
                className = "ColorPickImage"
                style={
                  {
                    cursor:"crosshair",
                  }
                }
              />
              <div
                id="canvas-controller"
                className="canvas-controller"
                
                onClick={
                  () => {
                    onSetColor( state.color );
                  }
                }
                
                onMouseMove={
                  ( e ) => {
                    getColor( e );
                  }
                }
                
              />
            </div>
          </div>
        </div>
      );
  }

export default BackImgColorSwatch;

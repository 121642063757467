import React, { useState, useEffect } from "react";
import { RgbSelector } from './RgbSelector';

const TitleBar = {
  borderLeft:"10px solid #3F8257",
};

function ColorSelector( props ) {
  const { colorSetter, roomBaseImage, baseImgPath , baseFrom } = props;
  const [ colorFrom, setColorFrom ] = useState( 'palette' );
  const [ selectedColor, setSelectedColor ] = useState( null );
  useEffect(() => {
    colorSetter( selectedColor );
  }, [ selectedColor ]); 

  const onClickFromPalette = () => {
    setColorFrom( 'palette' );
    setSelectedColor( null );
  };
  const onClickFromPhoto = () => {
    setColorFrom( 'photo' );
    setSelectedColor( null );
  };
  const onClickFromImage = () => {
    setColorFrom( 'image' );
    setSelectedColor( null );
  };
  const onClickBackImage = e =>{
    setColorFrom( 'back' );
    setSelectedColor( null );
  }
  const handleClear = e =>{
    setColorFrom( 'palette' );
    setSelectedColor( null );
  }

  return(
    <div className="ColorPicker">
      <div className="container">
        <div className="row justify-content-between">
          <div
            className="col-6 align-self-center"
            style={ TitleBar }
          >
            <div className="Furniture_color font-weight-bold text-secondary">
              Color
            </div>
          </div>
          <div className="col-6 text-right">
            <div
              className="btn btn-secondary my-0 py-0"
              onClick={ handleClear }
            >
              <small className="">
                Colorクリア
              </small>
            </div>
          </div>
        </div>
      </div>
      <div 
        id="color-selector"
        className="d-flex flex-row"
        style={ { margin: '5px 0' } }
      >
        <div 
          id='color_conds'
          className="d-flex flex-column div_margined container mx-0"
        >
          <div className="row justify-content-around">
          {
              roomBaseImage === null?
              <div
                className="col-3 w-100 my-1 btn btn-secondary"
                id='btnFromPalette'
                onClick={ onClickFromPalette }
              >
                <small>
                  パレット
                </small>
              </div>
              :
              <div
                className="col-3 w-100 my-1 btn btn-secondary"
                id='btnFromPalette'
                onClick={ onClickFromPalette }
              >
                <small>
                  パレット
                </small>
              </div>
            }
            {
              roomBaseImage === null?
              <div
                className="col-3 w-100 my-1 btn btn-secondary"
                id='btnFromPhoto'
                onClick={ onClickFromPhoto }
              >
                <small>
                  写真
                </small>
              </div>
              :
              <div
                className="col-3 w-100 my-1 btn btn-secondary"
                id='btnFromPhoto'
                onClick={ onClickFromPhoto }
              >
                <small>
                  写真
                </small>
              </div>
            }
            
            {
              roomBaseImage === null?
              <div
                className="col-3 w-100 my-1 btn btn-secondary"
                id='btnFromImage'
                onClick={ onClickFromImage }
              >
                <small>
                  サンプル
                </small>
              </div>
              :
              <div
                className="col-3 w-100 my-1 btn btn-secondary"
                id='btnFromImage'
                onClick={ onClickFromImage }
              >
                <small>
                  サンプル
                </small>
              </div>
            }
            {
              (roomBaseImage !== null && baseFrom === "photo")?
              <div
                className="col-3 my-1 w-100 btn btn-secondary"
                id='btnFromBack'
                onClick={ onClickBackImage }
              >
                <small>
                  背景画像
                </small>
              </div>
              :
              (baseFrom!=="construction")?
              <></>
              :
              <div
                className="col-3 my-1 w-100 btn btn-secondary"
                id='btnFromBack'
                onClick={ onClickBackImage }
              >
                <small>
                  背景画像
                </small>
              </div>
            }
          </div>
          <RgbSelector
            colorFrom = { colorFrom }
            rgbSetter = { setSelectedColor }
            roomBaseImage = { roomBaseImage === null? baseImgPath : roomBaseImage }
            baseFrom = { baseFrom }
          />
        </div>
      </div>
    </div>
  );
}

export default ColorSelector;

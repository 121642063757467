export const White = {
  width:"50px",
  height:"30px",
  background:"#ffffff",
};
export const Beige = {
  width:"50px",
  height:"30px",
  background:"#FDEAC6",
};
export const Gray = {
  width:"50px",
  height:"30px",
  background:"#D8D8D8",
};
export const Natural = {
    width:"50px",
    height:"30px",
    background:"#FDEAC6",
  };
export const Cherry = {
  width:"50px",
  height:"30px",
  background:"#DDB678",
};
export const Yellow = {
  width:"50px",
  height:"30px",
  background:"#FAEC9D",
};
export const Pink = {
  width:"50px",
  height:"30px",
  background:"#F7DCF1",
};
export const Green = {
  width:"50px",
  height:"30px",
  background:"#BFDEC7",
};
export const Navy = {
  width:"50px",
  height:"30px",
  background:"#485B7B",
};
export const Brown = {
  width:"100%",
  height:"30px",
  background:"#9F7A63",
};
export const Blue = {
  width:"50px",
  height:"30px",
  background:"#BCD2F0",
};
export const Dark = {
  width:"50px",
  height:"30px",
  background:"#6A5A4C",
};
export const Black = {
  width:"50px",
  height:"30px",
  background:"#313131",
};
export const LightBrown = {
  width:"100%",
  height:"30px",
  background:"#A98867",
};
export const DarkBrown = {
  width:"100%",
  height:"30px",
  background:"#362700",
};

// Wall

export const W_1= {
  width:"50px",
  height:"30px",
  background:"#ffffff",
};
export const W_2= {
  width:"50px",
  height:"30px",
  background:"#faffff",
};
export const W_3= {
  width:"50px",
  height:"30px",
  background:"#fffaff",
};
export const W_4= {
  width:"50px",
  height:"30px",
  background:"#fffffa",
};
export const W_5= {
  width:"50px",
  height:"30px",
  background:"#fffafa",
};
export const W_6= {
  width:"50px",
  height:"30px",
  background:"#fafffa",
};
export const W_7= {
  width:"50px",
  height:"30px",
  background:"#fafaff",
};
export const W_8= {
  width:"50px",
  height:"30px",
  background:"#fafafa",
};
export const W_9= {
  width:"50px",
  height:"30px",
  background:"#fffffc",
};
export const W_10= {
  width:"50px",
  height:"30px",
  background:"#fcfffc",
};


import React, { useState, useRef, useEffect } from "react";
import "./Simulator.css";

var previousTouch = null;
function ImageController({
  children,
  windowType,
  onDragStateChange,
  onZoomChange,
  onImageLoad,
  onSizeChange
}){
  const objectRef = useRef();
  const [ zoomState, setZoom ] = useState({ zoom: 1, minZoom: 0.1, maxZoom: 3 });
  const [ objectState, setObject ] = useState({ width: 0, height: 0 });
  const [ dragState, setDrag ] = useState({
    dragged: false,
    position: { x: 0, y: 0 },
    lastPosition: { x: 0, y: 0 },
    startPosition: { x: 0, y: 0 }
  });
  useEffect(() => {
    if (objectRef.current) {
      let width = objectRef.current.offsetWidth * 100;
      let height = objectRef.current.offsetHeight * 100;
      if (width > 0 && (width !== objectState.width || height !== objectState.height)) {
        setObject({ width: width, height: height });
        if (onSizeChange && width && height) {
          onSizeChange({ width, height });
        }
      }
    }
  }, [objectRef, objectState.width, objectState.height, onSizeChange]);
  useEffect(() => {
    let currentZoom = parseFloat( zoomState.zoom );
    let x = dragState.position.x;
    let y = dragState.position.y;
    if ( x !== dragState.position.x || y !== dragState.position.y ) {
      setDrag({
        ...dragState,
        position: {
          x: x,
          y: y,
        },
      });
    }
  }, [ dragState.position, zoomState.zoom ]);
  const startDrag = ( e ) => {
    setDrag({
      ...dragState,
      dragged: true
    });
    return false;
  };
  const dragObject = ( e ) => {
    let movX = 0;
    let movY = 0;
    let currentZoom = parseFloat( zoomState.zoom );
    if ( dragState.dragged ) {
      if ( !e.touches ) {
        movX = e.movementX;
        movY = e.movementY;
      } else if ( e.touches.length == 1 ) {
        let touch = e.touches[ 0 ];
        if ( previousTouch ) {
          movX = touch.pageX - previousTouch.pageX;
          movY = touch.pageY - previousTouch.pageY;
        }
        previousTouch = touch;
      } else if ( e.touches.length == 2 ) {
        let touch = e.touches[ 0 ];
        let diffX = 0;
        if( previousTouch ){ diffX = touch.pageX - previousTouch.pageX; }
        previousTouch = touch;
        let diff;
        diff = diffX;
        let zoom = currentZoom + diff / 25;
        if( zoom < zoomState.minZoom ){ zoom = zoomState.minZoom; }
        if ( zoom > zoomState.maxZoom ){ zoom = zoomState.maxZoom; }
        setZoom({
          ...zoomState,
          zoom: zoom
        });
      }
      const scale = currentZoom;
      onZoomChange( scale );
      const x = dragState.position.x + movX;
      const y = dragState.position.y + movY;
      setDrag({ 
        ...dragState,
        position: { 
          x: x,
          y: y
        }
      });
      onDragStateChange({ x, y });
    }
    return false;
  };

  const endDrag = ( e ) => {
    previousTouch = null;
    setDrag({
      ...dragState,
      dragged: false
    });
    return false;
  };

  const imageRef = useRef();
  useEffect(() => {
    const width = imageRef.current.clientWidth;
    const height = imageRef.current.clientHeight;
    if( onImageLoad && width > 0 && height > 0 ){
      onImageLoad({
        width,
        height
      });
    }
  }, [ onImageLoad ]);

  const ZoomRate = Math.round( zoomState.zoom * 100 )
  
  return (
    <>
      <div className="container range">
        <div
          className="row justify-content-between d-flex align-items-center mx-0"
          style={{ backgroundColor: "#404040" }}
        >
          <div 
            className="col-12 processed-component-base bg-light px-0"
            style={{
              aspectRatio: "3/2", 
              border: "1px solid #404040", 
              display: "block", 
              position: "relative", 
              overflow: "hidden" 
            }}
          >
            <img 
              src={ `https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/${ windowType }.png` }
              style={{ 
                zIndex: 1,
                position: "absolute",
                pointerEvents: "none",
              }} 
              className="w-100 windowType"
              alt={ `https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/${ windowType }.png` }
              ref={ imageRef }
            />
            <div
              ref={ objectRef }
              style={{
                transform:`
                translateX( ${ dragState.position.x }px )
                translateY( ${ dragState.position.y }px )
                scale( ${ zoomState.zoom } )
                `,
              }}
            >
              { children }
            </div>
            <div
              className="component-controller"
              onMouseMove={ dragObject }
              onMouseLeave={ endDrag }
              onMouseDown={ startDrag }
              onMouseUp={ endDrag }
              onDrag={ dragObject }
              onTouchStart={ startDrag }
              onTouchMove={ dragObject }
              onTouchEnd={ endDrag }
            >
            </div>
          </div>
        </div>
      </div>
      {/* Range */}
      {(
        <div className="container range">
          <div
            className="row justify-content-between d-flex align-items-center mx-0"
            style={{ backgroundColor: "#404040" }}
          >
            <div className="col-1 d-flex justify-content-center align-items-center mx-0 px-0">
              <small className="text-white text-center">
                -
              </small>
            </div>
            <div className="col-8 d-flex align-items-center">
              <input
                type= "range"
                className= "full d-flex align-items-center"
                step={ 0.01 }
                value={ zoomState.zoom }
                min={ zoomState.minZoom }
                max={ zoomState.maxZoom }
                onChange={( e ) => {
                  const zoom = e.target.value;
                  setZoom({ 
                    ...zoomState,
                    zoom: zoom
                  });
                  onZoomChange( zoom );
                  onDragStateChange({
                    x: dragState.position.x,
                    y: dragState.position.y
                  });
                }}
              />
            </div>
            <div className="col-1 d-flex justify-content-center align-items-center mx-0 px-0">
              <small className="text-white text-center">
                +
              </small>
            </div>
            <div className="col-2 d-flex justify-content-center align-items-center mx-0 px-0">
              <small className="text-white">
                { ZoomRate }%
              </small>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ImageController;

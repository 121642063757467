import React, { useState, useEffect, useRef } from "react";

function SideMenu({
  hideSidemenu,
  onSelect,
  onDecision,
  selectedRoomInfo,
  selectedRailInfo,
  selectedCurtainType,
  receivedNewFunctions,
  selectedData,
  selectedPriceRange,
  selectedColors,
  finalfurnitureNumber,
  finalroomType,
  finalCurtainType,
  finalRailColor,
  finalFunctionPatterns,
  finalPriceRange,
  finalColor,
}){
  // サイドメニュー
  const isMobile = window.matchMedia( "(max-width: 768px)" ).matches;
  const spStyle = { position: "fixed", top: 0, right: 0, width: "90%", height: "100%", background: "rgba(255,255,255,1)", boxShadow: "0 0 10px rgba(0, 0, 0, 1)", zIndex: 999, overflowY: "scroll", WebkitOverflowScrolling:"touch" };
  const pcStyle = { position: "fixed", top: 0, right: 0, width: "27.5%", height: "100%", background: "rgba(255,255,255)", boxShadow: "0 0 10px rgba(0, 0, 0, 1)", zIndex: 999, overflowY: "scroll", WebkitOverflowScrolling:"touch" };
  // サイドメニュー(スピナー)
  const isMobile2 = window.matchMedia( "(max-width: 768px)" ).matches;
  const spStyle2 = { position: "fixed", top: "0%", right: "0%", width: "100%", height: "100%", zIndex: 999, background: "rgba(0, 0, 0, 0.5)" };
  const pcStyle2 = { position: "fixed", top: "50%", right: "50%", width: "100%", zIndex: 999, background: "rgba(255, 255, 255, 0.562)" };
  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // // 部屋と家具
  const [ isRoomAccordionOpen, setIsRoomAccordionOpen ] = useState( false );
  const RoomtoggleAccordion = () => { setIsRoomAccordionOpen( ( prev ) => !prev ); };
  const [ currentSelectedRoomType, setCurrentSelectedRoomType ] = 
    useState( 
      window.location.pathname === '/light/cg' ?
        selectedRoomInfo.roomType
      :
        null
    );
  const roomTypeOptions = [
    { roomName: "寝室", value: "bed"},
    { roomName: "リビング", value: "living" }
  ];
  const [ currentSelectedFurnitureNumber, setCurrentSelectedFurnitureNumber ] = 
    useState( 
      window.location.pathname === '/light/cg' ?
        selectedRoomInfo.furniture
      :
        null
    );
  const furnitureNumberOptions = [
    { name: "A", value: 1 },
    { name: "B", value: 2 }
  ];
  useEffect(() => {
    if ( window.location.pathname !== '/light/cg' ) {
      return;
    }else{
      if ( finalroomType === null ) {
        setCurrentSelectedRoomType( selectedRoomInfo.roomType );
        setCurrentSelectedFurnitureNumber( selectedRoomInfo.furniture );
      } else if ( finalroomType !== selectedRoomInfo.roomType ) {
        setCurrentSelectedRoomType( finalroomType );
        setCurrentSelectedFurnitureNumber( finalfurnitureNumber );
      } else {
        return;
      }
    }
  }, [ selectedRoomInfo, finalroomType, finalfurnitureNumber ]);
  
  const handleRoomTypeChange = ( type, furniture ) => {
    if ( window.location.pathname === '/light/cg' ) {
      setCurrentSelectedRoomType( type );
      setCurrentSelectedFurnitureNumber( furniture );
      onSelect( type, furniture );
    }else{
      return;
    }
  }
  
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // カーテンタイプ
  const [ isCurtainTypeAccordionOpen, setIsCurtainTypeAccordionOpen ] = useState( false );
  const CurtainTypetoggleAccordion = () => { setIsCurtainTypeAccordionOpen( ( prev ) => !prev ); };
  const [ currentSelectedCurtainType, setCurrentSelectedCurtainType ] = useState( selectedCurtainType );

  const curtainTypesOptions = [
    { dispName: "ドレープ", value: "drape", countEndpoint: "/api/drapes/count", dataEndpoint: "/api/drapes/" },
    { dispName: "レース", value: "lace", countEndpoint: "/api/laces/count", dataEndpoint: "/api/laces/" },
    { dispName: "ロールスクリーン", value: "roll", countEndpoint: "/api/roll_screens/count", dataEndpoint: "/api/roll_screens/" },
  ];
  // 初期値とそれ以降のカーテンの値をセットを行う
  useEffect(() => {
    if( finalCurtainType === null ){
      setCurrentSelectedCurtainType( selectedCurtainType );
    }else if( finalCurtainType !== selectedCurtainType ){
      setCurrentSelectedCurtainType( finalCurtainType );
    }
  }, [ selectedCurtainType ]);

  const handleCurtainTypeChange = ( curtaintype ) => {
    setCurrentSelectedCurtainType( curtaintype );
    onSelect( curtaintype ); // 選択されたカーテンタイプを親コンポーネントに渡す
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // カーテンレール
  const [ isRailColorAccordionOpen, setIsRailColorAccordionOpen ] = useState( false );
  const RailColortoggleAccordion = () => { setIsRailColorAccordionOpen( ( prev ) => !prev );};
  const [ currentSelectedRailColor, setCurrentSelectedRailColor ] = useState( selectedRailInfo.railColours );
  const [ panelWidth, setPanelWidth ] = useState( null );
  const col2Ref = useRef( null );
  useEffect(() => {
    if ( col2Ref.current ) {
      const col2Width = col2Ref.current.offsetWidth;
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768;
      const newcol2Width = isMobile ? col2Width / 1.8 : col2Width / 2.4;
      setPanelWidth( newcol2Width );
    }
  }, []);
  const centerAlignStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const railColours = [
    { name: "ﾎﾜｲﾄ", value: "WH" },
    { name: "ｱｯｼｭｸﾞﾚｲﾝ", value: "GR" },
    { name: "ﾅﾁｭﾗﾙ", value: "NU" },
    { name: "ﾌﾞﾗｳﾝ", value: "BR" },
    { name: "ﾀﾞｰｸﾌﾞﾗｳﾝ", value: "DBR" },
  ];
  const colorPanel = [
    { name: "ﾎﾜｲﾄ", value: "WH",background: "#ffffff" },
    { name: "ｱｯｼｭｸﾞﾚｲﾝ", value: "GR",background: "#D8D8D8"},
    { name: "ﾅﾁｭﾗﾙ", value: "NU",background:"#FDEAC6" },
    { name: "ﾌﾞﾗｳﾝ", value: "BR",background:"#9F7A63" },
    { name: "ﾀﾞｰｸﾌﾞﾗｳﾝ", value: "DBR",background:"#362700" }
  ];
  // 初期値とそれ以降のカーテンの値をセットを行う
  useEffect(() => {
    if( finalRailColor === null ){
      setCurrentSelectedRailColor( selectedRailInfo.railColours );
    }else if( finalRailColor !== selectedRailInfo.railColours ){
      setCurrentSelectedRailColor( finalRailColor );
    }
  }, [ selectedRailInfo.railColours ]);

  const handleRailColorChange = ( railcolor ) => {
    setCurrentSelectedRailColor( railcolor );
    onSelect( railcolor );
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // 機能・模様
  const curtainData = {
    drape : [
      { dispName: "防炎", isActive: false, dbName: "fire", columnName: "functions" },
      { dispName: "形状記憶", isActive: false, dbName: "set", columnName: "functions" },
      { dispName: "遮光", isActive: false, dbName: "bo1aaa,bo1,bo2", columnName: "functions" },
      { dispName: "ウォッシャブル", isActive: false, dbName: "washable", columnName: "functions" },
      { dispName: "無地調", isActive: false, dbName: "Mj", columnName: "taste" },
      { dispName: "柄", isActive: false, dbName: "N,NG,S,SW,SS,V,C,CE,M,MS,Wa", columnName: "taste" },
    ],
    lace : [
      { dispName: "防炎", isActive: false, dbName: "fire", columnName: "functions" },
      { dispName: "形状記憶", isActive: false, dbName: "set", columnName: "functions" },
      { dispName: "見えにくい", isActive: false, dbName: "secret", columnName: "functions" },
      { dispName: "ウォッシャブル", isActive: false, dbName: "washable", columnName: "functions" },
      { dispName: "UVカット", isActive: false, dbName: "uvcut", columnName: "functions" },
      { dispName: "柄", isActive: false, dbName: "print", columnName: "functions" },
      { dispName: "カラー", isActive: false, dbName: "color", columnName: "functions" },
      { dispName: "裾柄レース", isActive: false, dbName: "embro", columnName: "functions" },
    ],
    roll : [
      { dispName: "遮光", isActive: false, dbName: "bo1aaa,bo1,bo2", columnName: "functions" },
      { dispName: "遮光なし", isActive: false, dbName: "", columnName: "" },
      { dispName: "見えにくい", isActive: false, dbName: "secret", columnName: "functions" },
      { dispName: "通常", isActive: false, dbName: "", columnName: "" },
    ],
  }
  const [ isFunctionPatternAccordionOpen, setIsFunctionPatternAccordionOpen ] = useState( false );
  const FunctionPatterntoggleAccordion = () => { setIsFunctionPatternAccordionOpen( ( prev ) => !prev ); };
  const [ currentFunctionPattern, setCurrentFunctionPattern ] = useState( receivedNewFunctions || curtainData[currentSelectedCurtainType] );

  useEffect(() => {
    if( selectedCurtainType !== null ){
      if( finalCurtainType === null ){
        if( selectedCurtainType === currentSelectedCurtainType){
          if( receivedNewFunctions === null ){
            setCurrentFunctionPattern( curtainData[ currentSelectedCurtainType ] );
          }else{
            setCurrentFunctionPattern( receivedNewFunctions );
          }
        }else{
          setCurrentFunctionPattern( curtainData[ currentSelectedCurtainType ] );
        }
      }else{
        if( finalCurtainType === currentSelectedCurtainType ){
          setCurrentFunctionPattern( finalFunctionPatterns );
        }else{
          setCurrentFunctionPattern( curtainData[ currentSelectedCurtainType ] );
        }
      }
    }else{
      alert( "エラーサイドメニュー[ファンクションパターン]" );
      return;
    };
  }, [ receivedNewFunctions, finalFunctionPatterns, selectedCurtainType, currentSelectedCurtainType, finalCurtainType  ]);

  const handlefunctionChange = async ( index ) => {
    const updatedFunctionPatterns = currentFunctionPattern.map(( item, i ) => {
      if ( i === index ) {
        return { ...item, isActive: !item.isActive };
      }
      return item;
    });
    setCurrentFunctionPattern( updatedFunctionPatterns );
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //価格帯
  const priceRange = [
    { dispName: "高級品", description: "(¥40,000~)", range: "1,2" },
    { dispName: "通常品", description: "(¥20,000~¥40,000)", range: "3,4" },
    { dispName: "お手頃品", description: "(~¥20,000)", range: "5" },
  ];
  
  const [ isPriceRangeAccordionOpen, setIsPriceRangeAccordionOpen ] = useState( false );
  const PriceRangetoggleAccordion = () => { setIsPriceRangeAccordionOpen( ( prev ) => !prev ); };
  const [ currentSelectedPriceRange, setCurrentSelectedPriceRange ] = useState( selectedPriceRange );

  useEffect(() => {
    setCurrentSelectedPriceRange( selectedPriceRange );
  }, [ selectedPriceRange ]);

  useEffect(() => {
    if( finalPriceRange === null ){
      setCurrentSelectedPriceRange( selectedPriceRange );
    }else if( finalPriceRange !== selectedPriceRange ){
      if( finalPriceRange === null ){
        setCurrentSelectedPriceRange( selectedPriceRange );
      }else{
        setCurrentSelectedPriceRange( finalPriceRange );
      }
    }
  }, [ selectedPriceRange ]);
  const handlePriceRangeChange = ( range ) => {
    setCurrentSelectedPriceRange( range );
    onSelect( range );
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //ExternalAPI
  const [ isColorAPIAccordionOpen, setIsColorAPIAccordionOpen ] = useState( false );
  const ColorAPItoggleAccordion = () => { setIsColorAPIAccordionOpen( ( prev ) => !prev ); };
  const [ currentSelectedColors, setCurrentSelectedColors ] = useState( selectedColors );
  const pastel = [ "#F8B8B6", "#FFFBB6", "#ABC2DF", "#DCE8B5" ];
  const vivid_cold = [ "#F59096", "#FEF88C", "#6CABD1", "#CBDD8D" ];
  const vivid_warm = [ "#DB005C", "#F4E402", "#0072AE", "#9EC700" ];
  const monotone = [ "#ffffff","#dcdcdc","#808080","#000000" ];
  const color = [
    { dispName: "ﾊﾟｽﾃﾙ", value: "pastel", colorCode: pastel, apiRGB: "179,165,202" },
    { dispName: "ﾋﾞﾋﾞｯﾄｺｰﾙﾄﾞ", value: "vivid_cold", colorCode: vivid_cold, apiRGB: "0,115,135" },
    { dispName: "ﾋﾞﾋﾞｯﾄｳｫｰﾑ", value: "vivid_warm", colorCode: vivid_warm, apiRGB: "205,45,71" },
    { dispName: "ﾓﾉﾄｰﾝ", value: "monotone", colorCode: monotone, apiRGB: "150,150,150" },
    { dispName: "ﾈｲﾋﾞｰ", value: "navy", colorCode: "#121e5a", apiRGB: "18,30,90" },
    { dispName: "ｸﾞﾘｰﾝ", value: "green", colorCode: "#24ab23", apiRGB: "36,171,35"},
    { dispName: "ﾌﾞﾗｳﾝ", value: "brown", colorCode: "#8c6b3f", apiRGB: "140,107,63" },
    { dispName: "ﾚｯﾄﾞ", value: "red", colorCode: "#a31c38", apiRGB: "163,28,56" },
  ];
  useEffect(() => {
    if( finalColor === null ){
      setCurrentSelectedColors( selectedColors );
    }else if( finalColor !== selectedColors ){
      if( finalColor === null ){
        setCurrentSelectedColors( selectedColors );
      }else{
        setCurrentSelectedColors( finalColor );
      }
    }
  }, [ selectedColors ]);
  const handleColorChange = ( color ) => {
    setCurrentSelectedColors( color );
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // 決定ボタンを選択した際のアクション
  const [ isLoading, setIsLoading ] = useState( false );
  const handleDecision = () => {
    setIsLoading( true );
      onDecision( 
        currentSelectedRoomType,
        currentSelectedFurnitureNumber,
        currentSelectedCurtainType,
        currentSelectedRailColor,
        currentFunctionPattern,
        currentSelectedPriceRange,
        currentSelectedColors,
      )
    setTimeout(() => {
      hideSidemenu();
      setIsLoading( false );
    }, 1000);
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return(
    <>
      <div className="container menu" style={ isMobile? spStyle : pcStyle }>
        <div className="row justify-content-center align-items-center border-bottom">
          <div 
            className="col-9 btn text-center align-items-center d-flex my-3" 
            onClick={ hideSidemenu }
          >
            <div className="left-align-svg align-items-center d-flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </div>
            <div
              className="w-100 text-center font-weight-bold"
              style={{
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              シミュレーション
            </div>
          </div>
        </div>
        {/* STEP01_部屋と家具 */}
        {
          window.location.pathname === '/light/cg' ? 
            <div className="container border-bottom justify-content-between align-items-center">
              <div className="row justify-content-between align-items-center mx-0 my-1">
                <div
                  className="col-5 w-100"
                  style={{
                    fontSize: "12px",
                    whiteSpace: "nowrap",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#056b5e"
                    className="bi bi-check-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                  <span>
                    <small>
                      &ensp;STEP 01
                    </small>
                  </span>
                </div>
                <div
                  className="col-5 font-weight-bold w-100"
                  style={{
                    color:"#056b5e",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}
                >
                  部屋と家具
                </div>
                <div
                  className="col-2 text-center btn"
                  onClick={ RoomtoggleAccordion }
                >
                  { isRoomAccordionOpen ? "-" : "+" }
                </div>
                {isRoomAccordionOpen && (
                  <div className="container">
                    <div className="row justify-content-end d-flex">
                      <div className="col-12 text-right w-100 justify-content-end d-flex text-secondary small">
                        一つのみ選択
                      </div>
                    </div>
                    <div className="row my-3 justify-content-around mx-0">
                      {roomTypeOptions.map(( type, findex ) => (
                        furnitureNumberOptions.map(( furniture, index ) => (
                        <button
                          key={ findex + index }
                          className={
                            `col-md-5 col-5 btn text-center px-0 my-1
                            ${ type.value === currentSelectedRoomType && furniture.value === currentSelectedFurnitureNumber ? 'active' : '' }`
                          }
                          onClick={() => handleRoomTypeChange( type.value,furniture.value )}
                          style={ {
                            border: type.value === currentSelectedRoomType && furniture.value === currentSelectedFurnitureNumber ? "1px solid #056b5e" : "1px solid #056b5e",
                            backgroundColor: type.value === currentSelectedRoomType && furniture.value === currentSelectedFurnitureNumber ? "#056b5e" : "#EEF5F1",
                            color: type.value === currentSelectedRoomType && furniture.value === currentSelectedFurnitureNumber ? "#ffffff" : "#056b5e",
                            fontSize: "12px",
                            whiteSpace: "nowrap",
                          } }
                        >
                          <small>
                            { type.roomName }{ furniture.name }
                          </small>
                        </button>
                        ))
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          :
            ""
        }
        {/* STEP02_種類 */}
        <div className="container border-bottom">
          <div className="row justify-content-between align-items-center mx-0 my-1">
            <div
              className="col-5"
              style={{
                fontSize: "12px",
                whiteSpace: "nowrap",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#056b5e"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              <span>
                <small>
                {
                  window.location.pathname === '/light/cg' ?
                    <>&ensp;STEP 02</>
                  :
                    <>&ensp;STEP 01</>
                }
                </small>
              </span>
            </div>
            <div
              className="col-5 font-weight-bold"
              style={{
                color:"#056b5e",
                fontSize: "14px",
                whiteSpace: "nowrap",
              }}
            >
              種類
            </div>
            <div
              className="col-2 text-center btn"
              onClick={ CurtainTypetoggleAccordion }
            >
              { isCurtainTypeAccordionOpen ? "-" : "+" }
            </div>
            {isCurtainTypeAccordionOpen && (
              <div className="container">
                <div className="row justify-content-end d-flex">
                  <div className="col-12 text-right w-100 justify-content-end d-flex text-secondary small">
                    一つのみ選択
                  </div>
                </div>
                <div className="row my-3 justify-content-around mx-0">
                  {curtainTypesOptions.map(( curtainType, index ) => (
                    <button
                      key={ index }
                      className={`col-md-5 col-5 btn text-center px-0 my-1 ${
                        currentSelectedCurtainType === curtainType.value ? 'selected' : ''
                      }`}
                      onClick={() => handleCurtainTypeChange( curtainType.value )}
                      style={{
                        backgroundColor:
                          currentSelectedCurtainType === curtainType.value ? '#056b5e' : '#EEF5F1',
                        borderColor:
                          currentSelectedCurtainType === curtainType.value ? '#046B5E' : '#056b5e',
                        color: currentSelectedCurtainType === curtainType.value ? '#ffffff' : '#056b5e',
                        fontSize: '12px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <small>
                        { curtainType.dispName }
                      </small>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* railColor */}
        {
          currentSelectedCurtainType !== "roll" ?
            <div className="container border-bottom">
              <div className="row justify-content-between align-items-center mx-0 my-1">
              <div
                className="col-5"
                style={{
                  fontSize: "12px",
                  whiteSpace: "nowrap",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#056b5e"
                  className="bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
                <span>
                  <small>
                  {
                    window.location.pathname === '/light/cg' ?
                      <>&ensp;STEP 02</>
                    :
                      <>&ensp;STEP 01</>
                  }
                  </small>
                </span>
              </div>
              <div
                className="col-5 font-weight-bold"
                style={{
                  color:"#056b5e",
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
              >
                カーテンレールの色
              </div>
              <div
                className="col-2 text-center btn"
                onClick={ RailColortoggleAccordion }
              >
                { isRailColorAccordionOpen ? "-" : "+" }
              </div>
                {isRailColorAccordionOpen && (
                  <div className="container">
                    <div className="row justify-content-end d-flex">
                      <div className="col-12 text-right w-100 justify-content-end d-flex text-secondary small">
                        一つのみ選択
                      </div>
                    </div>
                    <div className="row my-3 justify-content-around mx-0">
                      {railColours.map(( railcolor, index ) => (
                        colorPanel.map(( color ) => (
                          railcolor.value === color.value ? (
                            <div
                              className={`col-2 text-center color-panel px-0 ${ currentSelectedRailColor === railcolor.value ? "selected" : "" }`}
                              key={`${ railcolor.value }-${ color.value }`}
                              ref={ col2Ref }
                              style={ centerAlignStyle }
                              onClick={() => handleRailColorChange( railcolor.value )}
                            >
                              <div
                                className="color-panelborder border"
                                key={ index }
                                style={{
                                  backgroundColor: color.background,
                                  width: panelWidth + "px",
                                  minWidth: "45px",
                                  maxWidth: "50px",
                                  height: panelWidth + "px",
                                  minHeight: "45px",
                                  maxHeight: "50px",
                                  borderRadius: "5px",
                                  position: "relative",
                                }}
                              >
                                {currentSelectedRailColor === railcolor.value && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="#056b5e"
                                      className="bi bi-check-circle-fill bg-white rounded"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                    </svg>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null
                        ))
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          :
            <></>
        }
        {/* 機能・模様 */}
        <div className="container border-bottom">
          <div className="row justify-content-between align-items-center mx-0 my-1">
            <div
              className="col-5"
              style={{
                fontSize: "12px",
                whiteSpace: "nowrap",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#056b5e"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              <span>
                <small>
                  {
                    window.location.pathname === '/light/cg' ?
                      <>
                        &ensp;STEP 03
                      </>
                    :
                      <>
                        &ensp;STEP 02
                      </>
                  }
                </small>
              </span>
            </div>
            <div
              className="col-5 font-weight-bold"
              style={{
                color:"#056b5e",
                fontSize: "14px",
                whiteSpace: "nowrap",
              }}
            >
              機能・模様
            </div>
            <div
              className="col-2 text-center btn"
              onClick={ FunctionPatterntoggleAccordion }
            >
              { isFunctionPatternAccordionOpen ? "-" : "+" }
            </div>
            <div className="container">
              {isFunctionPatternAccordionOpen && (
                <>
                  <div className="row justify-content-end d-flex">
                    <div className="col-12 text-right w-100 justify-content-end d-flex text-secondary small">
                      複数選択可
                    </div>
                  </div>
                  <div className="row my-3 justify-content-around mx-0">
                    {currentFunctionPattern.map(( functionPattern, index ) => (
                      <button
                        key={ functionPattern + index }
                        className={ `col-md-5 col-5 btn text-center px-0 my-1 ${ functionPattern.isActive === true ? 'active' : ''}` }
                        onClick={ ()=> handlefunctionChange( index ) }
                        style={{
                          border: functionPattern.isActive === true ? "1px solid #056b5e" : "1px solid #056b5e",
                          backgroundColor: functionPattern.isActive === true ? "#056b5e" : "#EEF5F1",
                          color: functionPattern.isActive === true ? "#ffffff" : "#056b5e",
                          fontSize: "12px",
                        }}
                      >
                        <small>
                          { functionPattern.dispName }
                        </small>
                      </button>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* 価格帯 */}
        <div className="container border-bottom">
          <div className="row justify-content-between align-items-center mx-0 my-1">
            <div
              className="col-5"
              style={{
                fontSize: "12px",
                whiteSpace: "nowrap",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#056b5e"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              <span>
                <small>
                {
                  window.location.pathname === '/light/cg' ?
                    <>
                      &ensp;STEP 04
                    </>
                  :
                    <>
                      &ensp;STEP 03
                    </>
                }
                </small>
              </span>
            </div>
            <div
              className="col-5 font-weight-bold"
              style={{
                color:"#056b5e",
                fontSize: "14px",
                whiteSpace: "nowrap",
              }}
            >
              価格帯
            </div>
            <div
              className="col-2 text-center btn"
              onClick={ PriceRangetoggleAccordion }
            >
              { isPriceRangeAccordionOpen ? "-" : "+" }
            </div>
            {isPriceRangeAccordionOpen && (
              <div className="container">
                <div className="row justify-content-end d-flex">
                  <div className="col-12 text-right w-100 justify-content-end d-flex text-secondary small">
                    一つのみ選択
                  </div>
                </div>
                <div className="row my-3 justify-content-around mx-0">
                  {priceRange.map(( range, index ) => (
                    <button
                      key={ index }
                      className={ `col-12 btn my-1 text-center ${ range.range === currentSelectedPriceRange ? "active" : "" }` }
                      onClick={() => handlePriceRangeChange( range.range )}
                      style={ range.range === currentSelectedPriceRange ?
                        { backgroundColor: "#046B5E", color: "#FFFFFF", fontSize: "12px", whiteSpace: "nowrap" }
                        :
                        { backgroundColor: "#EEF5F1", borderColor: "#046B5E", color: "#046B5E", fontSize: "12px", whiteSpace: "nowrap", }
                      }
                    >
                      <span>
                        { range.dispName }
                      </span>
                      <small className="small">
                        { range.description }
                      </small>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* ExternalAPI */}
        {
          ( currentSelectedCurtainType !== "lace" ) ?
          <>
            <div className="container border-bottom">
              <div className="row justify-content-between align-items-center mx-0 my-1">
                <div
                  className="col-5"
                  style={{
                    fontSize: "12px",
                    whiteSpace: "nowrap",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#056b5e"
                    className="bi bi-check-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                  <span>
                    <small>
                    {
                      window.location.pathname === '/light/cg' ?
                        <>&ensp;STEP 05</>
                      :
                        <>&ensp;STEP 04</>
                    }
                    </small>
                  </span>
                </div>
                <div
                  className="col-5 font-weight-bold"
                  style={{
                    color:"#056b5e",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}
                >
                  カラー
                </div>
                <div
                  className="col-2 text-center btn"
                  onClick={ ColorAPItoggleAccordion }
                >
                  { isColorAPIAccordionOpen ? "-" : "+" }
                </div>
                {isColorAPIAccordionOpen && (
                  <div className="row my-3 justify-content-around mx-0">
                    {color.map(( colorGroup, index ) => (
                      <div
                        className="col-3 text-center color-panel d-flex justify-content-center my-3"
                        key={ index }
                        onClick={() => handleColorChange( colorGroup.value )}
                      >
                        <div
                          className="color-panelborder border position-relative"
                          style={{
                            width: panelWidth + "px",
                            minWidth: "45px",
                            maxWidth: "50px",
                            height: panelWidth + "px",
                            minHeight: "45px",
                            maxHeight: "50px",
                            borderRadius: "5px",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              zIndex: 2,
                              fontSize: "10px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {colorGroup.dispName.split( "\n" ).map(( line, i ) => (
                              <React.Fragment key={ i }>
                                { i > 0 && <br /> }
                                <span style={{ color:"#3e3a39" }}>
                                  { line }
                                </span>
                              </React.Fragment>
                            ))}
                          </div>
                          {Array.isArray( colorGroup.colorCode ) ? (
                            colorGroup.colorCode.map(( code, subIndex ) => (
                              <div
                                key={ subIndex }
                                style={{
                                  backgroundColor: code,
                                  width: "50%",
                                  height: "50%",
                                  position: "absolute",
                                  top: subIndex < 2 ? "0" : "50%",
                                  left: subIndex % 2 === 0 ? "0" : "50%",
                                }}
                              />
                            ))
                          ) : (
                            <div
                              style={{
                                backgroundColor: colorGroup.colorCode,
                                width: "100%",
                                height: "100%",
                                borderRadius: "4px",
                              }}
                            />
                          )}
                          {currentSelectedColors === colorGroup.value && (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#056b5e"
                                className="bi bi-check-circle-fill bg-white rounded"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                              </svg>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </>
          :
          <></>
        }
        {/* 決定ボタン */}
        <div className="row justify-content-around mx-3 my-3">
          <div
            className="col-12 btn border border-secondary Item-Content-Title py-2"
            onClick={ handleDecision }
            style={{
              position: "relative",
              fontSize:"12px",
            }}
          >
            <span>
              決定
            </span>
            <div
              style={{
                width: 0,
                height: 0,
                borderStyle: "solid",
                borderWidth: "0 0 10px 10px",
                borderColor: "transparent transparent #3e3a39 transparent",
                position: "absolute",
                bottom: 3,
                right: 3,
              }}
            >
            </div>
          </div>
        </div>
      </div>
      
      {isLoading && (
        <div
          className="container"
          style={ isMobile2? spStyle2 : pcStyle2 }
        >
          <div className="row d-flex h-100 justify-content-center align-items-center">
            <div className="col-12 text-center">
              <>
                <div
                  className="spinner-border text-success text-center"
                  role="status"
                  style={{
                    width: "50px",
                    height: "50px"
                  }}
                >
                  <span className="visually-hidden">
                    Loading...
                  </span>
                </div>
                <div
                  className="text-center"
                  style={{
                    position: "absolute",
                    width:"100%",
                    height:"100%",
                  }}
                >
                  Loading...
                </div>
              </>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SideMenu;

import React, { useState, useEffect } from "react";
import "./simulator.css";

function ImageCapture( props ) {
    const { baseImage, baseimage } = props;
  const [ state, setState ] = useState({
    zoom: 1,
    minZoom: 0.1,
    maxZoom: 5,
    isDragged: false,
    x: 0,
    y: 0,
  });
  const startDrag = ( e ) => {
    console.log( state.zoom )
    setState( { ...state, isDragged: true } );
    return false;
  };
  const dragImage = ( e ) => {
    if ( state.isDragged ) {
      setState({
        ...state,
        x: state.x + e.movementX,
        y: state.y + e.movementY,
      });
    }
    return false;
  };
  const endDrag = ( e ) => {
    setState( { ...state, isDragged: false } );
    return false;
  };

  return baseimage ?
  (
    <img
      alt={ baseimage }
      src={ baseimage }
      { ...props }
      className = "image-capturer"
      style = {
        {
          transform:` scale( ${ state.zoom } ) translateX( ${ state.x }px ) translateY( ${ state.y }px ) `,
          cursor: state.isDragged ? "move" : "default",
          overflowX:"hidden",
          overflowY:"hidden",
          width:"100%"
        }
      }
      onWheel={
        ( e ) => {
          if (!state.isDragged) {
            let zoom = state.zoom - e.deltaY / 100;
            if ( zoom >= state.maxZoom ) zoom = state.maxZoom;
            if ( zoom <= state.minZoom ) zoom = state.minZoom;
            setState( { ...state, zoom: zoom } );
          }
        }
      }
      onMouseDown = { startDrag }
      onMouseUp = { endDrag }
      onMouseLeave = { endDrag }
      onMouseMove = { dragImage }
      onTouchStart = { startDrag }
      onTouchMove = { dragImage }
      onTouchEnd = { endDrag }
      onTouchCancel = { endDrag }
    />
  ):
  (
    <div >
      <label
        htmlFor = "image-capture"
        className = "ant-btn"
        style = { { height:( window.innerWidth / 1.5 ) } }
      >
      </label>
    </div>
  );
}

export default ImageCapture;

import React, { useState, useEffect } from "react";
import Hakidashi_small from "../img/HP/3_size_type01_unselected_icon.jpg";
import Hakidashi_big from "../img/HP/3_size_type02_unselected_icon.jpg";
import Koshi from "../img/HP/3_size_type03_unselected_icon.jpg";
import { useImage } from "../context/imageContext";
import * as ColorPanel from "./ColorPanels";

export const WindowArranger = (props) => {
  const { imagePathSetters, hideFlags, onWindowTypeChangeHandler } = props;
  const [ image, dispatchImage ] = useImage();
  const imgPathSetter = imagePathSetters ? imagePathSetters[ "base" ] : null;
  const windowTypeHide = hideFlags ? hideFlags[ "window" ] : false;
  const floorTypeHide = hideFlags ? hideFlags[ "floor" ] : false;
  const wallTypeHide = hideFlags ? hideFlags[ "wall" ] : false;
  const [ pointer, setPointer ] = useState( "A" );
  const [ base, setBase ] = useState( "baseA" );
  const [ floor, setFloor]  = useState( "_1_" );
  const [ wall, setWall ] = useState( "1" );
  useEffect(() => {
    if ( !imgPathSetter || !base || !floor || !wall ) return;
    imgPathSetter(
        "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/Simulation/Base/" +
        base +
        floor +
        wall +
        ".png"
    );
  }, [ base, floor, wall ]);


  const TitleBar = {
    borderLeft: "10px solid gray",
    backgroundColor:"#a0c0ac",
  };
  const WindowType = [
    {
      dispName: "腰 窓",
      size: "1間腰高窓",
      value: "koshi",
      img: Koshi,
      check: true,
    },
    {
      dispName: "掃き出し",
      size: "1間掃出窓",
      value: "hakidashi_small",
      img: Hakidashi_small,
      check: false,
    },
    {
      dispName: "掃き出し",
      size: "1間半掃出窓",
      value: "hakidashi_big",
      img: Hakidashi_big,
      check: false,
    },
  ];
  const Floors = [
    {
      dispName: "ﾎﾜｲﾄ",
      id: "floor_white",
      value: "_1_",
      style: ColorPanel.White,
      check: true,
    },
    {
      dispName: "ｸﾞﾚ-",
      id: "floor_gray",
      value: "_6_",
      style: ColorPanel.Gray,
      check: false,
    },
    {
      dispName: "ﾅﾁｭﾗﾙ",
      id: "floor_natural",
      value: "_2_",
      style: ColorPanel.Natural,
      check: false,
    },
    {
      dispName: "ﾗｲﾄﾌﾞﾗｳﾝ",
      id: "floor_light_brown",
      value: "_3_",
      style: ColorPanel.LightBrown,
      check: false,
    },
    {
      dispName: "ﾌﾞﾗｳﾝ",
      id: "floor_brown",
      value: "_4_",
      style: ColorPanel.Brown,
      check: false,
    },
    {
      dispName: "ﾀﾞｰｸﾌﾞﾗｳﾝ",
      id: "floor_dark_brown",
      value: "_5_",
      style: ColorPanel.DarkBrown,
      check: false,
    },
  ];
  const Walls = [
    {
      dispName: "ﾎﾜｲﾄ",
      id: "wall_white",
      value: "1",
      style: ColorPanel.W_1,
      check: true,
    },
    {
      dispName: "ﾌﾞﾙｰｸﾞﾘｰﾝ",
      id: "wall_blue_green",
      value: "2",
      style: ColorPanel.W_2,
      check: false,
    },
    // {
    //   dispName: "ﾊﾟｰﾌﾟﾙ",
    //   id: "wall_purple",
    //   value: "3",
    //   style: ColorPanel.W_3,
    //   check: false,
    // },
    {
      dispName: "ｸﾞﾚｲｯｼｭｲｴﾛｰ",
      id: "wall_grayish_yellow",
      value: "4",
      style: ColorPanel.W_4,
      check: false,
    },
    // {
    //   dispName: "ﾋﾟﾝｸ",
    //   id: "wall_pink",
    //   value: "5",
    //   style: ColorPanel.W_5,
    //   check: false,
    // },
    // {
    //   dispName: "ｸﾞﾘｰﾝ",
    //   id: "wall_green",
    //   value: "6",
    //   style: ColorPanel.W_6,
    //   check: false,
    // },
    {
      dispName: "ﾊﾟｰﾌﾟﾘｯｼｭﾌﾞﾙｰ",
      id: "wall_purplish_blue",
      value: "7",
      style: ColorPanel.W_7,
      check: false,
    },
    // {
    //   dispName: "ｸﾞﾚｰ",
    //   id: "wall_glay",
    //   value: "8",
    //   style: ColorPanel.W_8,
    //   check: false,
    // },
    {
      dispName: "ｸﾞﾚｲｯｼｭﾊﾟｰﾌﾟﾙ",
      id: "wall_grayish_purple",
      value: "9",
      style: ColorPanel.W_9,
      check: false,
    },
    {
      dispName: "ｸﾞﾚｲｯｼｭｸﾞﾘｰﾝ",
      id: "wall_grayish_green",
      value: "10",
      style: ColorPanel.W_10,
      check: false,
    },
  ];
  const handleChange = ( event ) => {
    let name = event.target.name;
    let value = event.target.value;
    if ( name === "window_type" ) {
      if ( onWindowTypeChangeHandler ) {
        onWindowTypeChangeHandler( event );
      }
      if ( value === "koshi" ) {
        setBase( "baseA" );
        setPointer( "A" );
        dispatchImage( { type: "setRailSize", val: "_1" } );
      } else if ( value === "hakidashi_small" ) {
        setBase( "baseB" );
        setPointer( "B") ;
        dispatchImage( { type: "setRailSize", val: "_1" } );
      } else if ( value === "hakidashi_big" ) {
        setBase( "baseC" );
        setPointer( "C" );
        dispatchImage( { type: "setRailSize", val: "_2" } );
      }
    } else if ( name === "floor_type" ) {
      setFloor( value );
    } else if ( name === "wall_type" ) {
      setWall( value );
    }
  };

  return (
    <div className="container w-100">
      {
        !windowTypeHide &&
        (
          <div className="row justify-content-between">
            <div
              className = "col-12 align-self-center"
              style = { TitleBar }
            >
              <div className="Floor_color font-weight-bold text-white">
                窓サイズ
              </div>
            </div>
            <div className="col-12 align-self-center my-1">
                <div className="row justify-content-around">
                  {
                    WindowType.map( ( type, index ) => (
                      <div 
                        className = "col-4 w-100 mx-0 px-1" 
                        key = { index }
                      >
                        <input
                          className = "radio-input"
                          type = "radio"
                          name = "window_type"
                          onChange = { handleChange }
                          value = { type.value }
                          defaultChecked = { type.check }
                          id = { type.value }
                        />
                        <label
                          className = "btn w-100 text-center"
                          htmlFor = { type.value }
                        >
                          <img 
                            src={ type.img } 
                            alt={ type.dispName } 
                            className="w-100" 
                          />
                          <span 
                            className="text-secondary w-100"
                            style={ { lineHeight:"1.1" } }
                          >
                            <small>
                              { type.dispName }
                            </small>
                          </span>
                          <div>
                            <div
                              className="text-secondary w-100" 
                              style={
                                {
                                  lineHeight:"1.1",
                                  fontSize:"9px"
                                } 
                              }
                            >
                              <nobr>
                                { type.size }
                              </nobr>
                            </div>
                          </div>
                        </label>
                      </div>
                    ) )
                  }
                </div>
            </div>
          </div>
        )
      }
      {
        !floorTypeHide &&
        (
          <div className="row justify-content-between">
            <div
              className = "col-12 align-self-center" 
              style = { TitleBar }
            >
              <div className="Floor_color font-weight-bold text-white">
                床
              </div>
            </div>
            <div className="container">
              <div className="row my-1 justify-content-around">
                {
                  Floors.map( ( floor, index ) => (
                    <div 
                      className="floor col-4 text-center" 
                      key = { index }
                    >
                      <input
                        className = "radio-input"
                        type = "radio"
                        name = "floor_type"
                        onChange = { handleChange }
                        value = { floor.value }
                        id = { floor.value }
                        defaultChecked = { floor.check }
                      />
                      <label
                        className = "w-100 text-center"
                        htmlFor = { floor.value }
                      >
                        <div 
                          className="text-center w-100"
                          style={ floor.style }
                        >
                        </div>
                        <div className="text-center w-100 text-secondary mx-0">
                          <nobr>
                            <small>
                              { floor.dispName }
                            </small>
                          </nobr>
                        </div>
                      </label>
                    </div>
                  ) )
                }
              </div>
            </div>
          </div>
        )
      }
      {
      !wallTypeHide &&
      (
        <div className="row justify-content-between">
          <div
            className = "col-12 align-self-center"
            style = { TitleBar }
          >
            <div className="Wloor_color font-weight-bold text-white">
              壁
            </div>
          </div>
          <div className="container">
            <div className="row my-1 justify-content-around">
              {
                Walls.map( ( wall, index ) => (
                  <div
                    className = "wall col-4 w-100 text-center"
                    key = { index }
                  >
                    <input
                      className = "radio-input"
                      type = "radio"
                      name = "wall_type"
                      onChange = { handleChange }
                      value = { wall.value }
                      id = { wall.value }
                      defaultChecked = { wall.check }
                    />
                    <label
                      className = "text-center w-100"
                      htmlFor = { wall.value }
                    >
                      <div
                        className = "text-center w-100"
                        style = { wall.style }
                      >
                      </div>
                      <div className="text-center w-100 text-secondary">
                        <nobr>
                          <small>
                            { wall.dispName }
                          </small>
                        </nobr>
                      </div>
                    </label>
                  </div>
                ) )
              }
            </div>
          </div>
        </div>
      )
      }
    </div>
  );
};
import React, { useState, useEffect } from "react";
import * as ColorPanel from "./ColorPanels";
import Deco_Rail from "../img/HP/rail01_icon.png";
import Func_Rail from "../img/HP/rail02_icon.png";
import Box from "../img/HP/rail03_icon.png";
import { useImage } from "../context/imageContext";
export const RailArranger = ( props ) => {
  const { imagePathSetters } = props;
  const [ image, dispatchImage ] = useImage();

  // ベース
  const imgPathSetter2 = imagePathSetters[ "rail" ];
  const [ base, setBase ] = useState();
  useEffect(() => {
    if ( !image.railSize || !image.railColor ) return;
    imgPathSetter2(
      "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/Simulation/Rail/" +
      image.railColor +
      image.railSize +
      ".png"
    );
  }, [ image.railSize, image.railColor ]);

  const TitleBar = {
    borderLeft: "10px solid gray",
    backgroundColor:"#a0c0ac",
  };
  const TitleBar2 = {
    borderLeft: "5px solid gray",
  };
  const RailType = [
    { name: "機能性レール", img: Func_Rail, value: "func", check: true },
    { name: "装飾レール", img: Deco_Rail, value: "deco", check: false },
    { name: "カーテンBox", img: Box, value: "box", check: false },
  ];
  const RailsColor = [
    { name: "ﾎﾜｲﾄ", value:"WH", style:ColorPanel.White, check:true },
    { name: "ｱｯｼｭｸﾞﾚｲﾝ", value:"GR", style:ColorPanel.Gray, check:false },
    { name: "ﾅﾁｭﾗﾙ", value:"NU", style:ColorPanel.Natural, check:false },
    { name: "ﾌﾞﾗｳﾝ", value:"BR", style:ColorPanel.Brown, check:false },
    { name: "ﾀﾞｰｸﾌﾞﾗｳﾝ", value:"DBR", style:ColorPanel.DarkBrown, check:false,},
  ];
  const handleChange = ( event1 ) => {
    let value = event1.target.value;
    let name = event1.target.name;
    if ( base === "baseA" ) {
      dispatchImage( { type:"setRailSize", val:"_1" } );
    } else if ( base === "baseB" ) {
      dispatchImage( { type:"setRailSize", val:"_1" } );
    } else if ( base === "baseC" ) {
      dispatchImage( { type:"setRailSize", val:"_2" } );
    } else if ( name === "rail_type" ) {
      dispatchImage( { type:"setRailColor", val:value } );
    }
  };

  const bgstyle = {
    backgroundColor:"rgba(62,58,57,0.1)",
  };
  return (
    <div 
      className = "container w-100"
      style = { bgstyle }
    >
      <div className="row justify-content-between">
          <div
            className = "col-12 font-weight-bold text-white"
            style = { TitleBar }
          >
            カーテンレール
          </div>
          {
            RailType.map( ( railType, index ) => (
              <div
                className = "col-12 text-secondary my-1"
                key = { index }
              >
                <div style = { TitleBar2 }>
                  { railType.name }
                  <span>
                    <img 
                      src = { railType.img }
                      alt = { railType.img }
                    />
                  </span>
                </div>
                  <div className="row justify-content-around">
                    {
                      RailsColor.map( ( color, index ) => (
                        <div
                          className = "col-2 w-100 px-0"
                          key = { index }
                        >
                          <input
                            className = "radio-input w-100 mx-0"
                            type = "radio"
                            name = "rail_type"
                            onChange = { handleChange }
                            value = { railType.value + "_" + color.value }
                            id = { railType.value + color.value }
                            defaultChecked = { color.check && railType.check }
                          />
                          <label
                            className = "btn w-100 text-center mx-0"
                            htmlFor = { railType.value + color.value }
                          >
                            <div
                              className = "w-100"
                              style = { color.style }
                            >
                            </div>
                          </label>
                        </div>
                      ) )
                    }
                  </div>
              </div>
            ) )
          }
      </div>
    </div>
  );
};
import { KoikeApp } from './pages/KoikeApp';
import Simulator from "./pages/Simulator";
import { CompareImage } from './pages/CompareImage';
import { FileSelectorPage } from './pages/FileSelectorPage';
import Light from './pages/Light';
import NotFoundPage from './pages/NotFoundPage';
import CG from './pages/CG';
import Photo from './pages/Photo';

const routes = [
    { path: '/', component: KoikeApp, exact : true },
    { path: "/simulator/:productType/:baseFrom", component: Simulator },
    { path: '/compareImages', component: CompareImage},
    { path: '/imageSelector/:productType/:baseFrom/:onSelectedPath/:onCanceledPath', component: FileSelectorPage },
    { path: '/light', component: Light, exact : true },
    { path: '/light/cg', component: CG, exact : true },
    { path: '/light/photo', component: Photo, exact : true },
    { path: '/light/*', component:NotFoundPage },
    { path: '/*', component:NotFoundPage }
  ];
  export default routes;

import React, { useEffect, useState } from "react";
import TopWord from "./top_word";
// import Header from "./Header";
import PhotoFlow from "./PhotoFlow";
import CameraPhoto from "./CameraPhoto";
import Camera from "./Camera";
import WindowType from "./WindowType";
import MoveZoomInOut from "./MoveZoomInOut";
import CurtainType from "./CurtainType";
import RailColor from "./RailColor";
import FunctionPattern from "./FunctionPattern";
import PriceRange from "./PriceRange";
import ExternalAPI from "./ExternalAPI";
import Simulator from "./LightSimulator";

function Photo() {
// CameraPhoto.js
  const [ selectedimage, setSelectedimage ] = useState( null );
  const handleTakePhoto = () => {
    setSelectedimage( null )
    setCurrentIndex( currentIndex - 1 );
  };
// Camera.js
  const [ photoimage, setPhotoimage ] = useState( null );
  const handleImageCapture = ( imageData ) => { setPhotoimage( imageData ); };
  const [ selectedWindowType, setSelectedWindowType ] = useState( "koshi" );
  const handleWindowTypeSelect = ( value ) => { setSelectedWindowType( value ); };
// WindowType.js
  const [ selectedType, setSelectedType ] = useState( "koshi" );
  const WindowTypeSelect = ( value ) => { setSelectedType( value ); };
// ZoomMoveInOut.js
  const [ moveZoomData, setMoveZoomData ] = useState({
    scale: 1,
    position: { 
      x: 0,
      y: 0
    }, 
    BaseWidth: 0,
    BaseHeight:0
  });
  const handleMoveZoomDataChange = ( data ) => {
    setMoveZoomData( data );
  };
// CurtainType.js
  const [ selectedCurtainType, setSelectedCurtainType ] = useState( null );
  const handleCurtainTypeSelect = ( value ) => {
    setSelectedCurtainType( value );
  };
// RailColor.js
  const [ selectedRailInfo, setSelectedRailInfo ] = useState( null );
  const handleRailSelect = ( railInfo ) => {
    setSelectedRailInfo( railInfo );
  }
// FunctionPattern.js
  // FunctionPattern.jsで絞り込みされたデータ
  const [ apiData, setApiData ] = useState( null );
  const handleApiData = ( data ) => {
    setApiData( data );
  };
  // FunctionPattern.jsで選択したボタンの内容
  const [ selectedData, setSelectedData ] = useState( [] );
  const [ receivedNewFunctions, setReceivedNewFunctions ] = useState( null );
  const handleSelectedData = ( data, newFunctions ) => {
    setSelectedData( data );
    setReceivedNewFunctions( newFunctions );
  };
// PriceRange.jsで選択したボタンの内容
  const [ selectedPriceRange, setSelectedPriceRange ] = useState( [] );
  const updateSelectedPriceRange = ( selectedRange ) => {
    setSelectedPriceRange( selectedRange );
  };
  const [ filteredData, setFilteredData ] = useState( [] );
  const updateFilteredData = ( data ) => {
    setFilteredData( data );
  };
// ExternalAPI.jsで選択したボタンの内容
  const [ selectedColors, setSelectedColors ] = useState( [] );
  const handleColorSelect = ( selectedColor ) => {
    setSelectedColors( selectedColor );
  };
// 戻る・進むボタンの管理
  const [ currentIndex, setCurrentIndex ] = useState( 0 );
// 次へボタン
  const handleNextClick = () => {
    if ( currentIndex === 1 && selectedimage !== null ) {
      setCurrentIndex( currentIndex + 2 );
    } else if ( (currentIndex === 5 && selectedCurtainType === "roll") || (currentIndex === 8 && selectedCurtainType === "lace") ) {
      setCurrentIndex( currentIndex + 2 );
    } else {
      setCurrentIndex( currentIndex + 1 );
    }
  };
  const handleCameraNextClick = () => {
    setSelectedimage( null )
    setCurrentIndex( currentIndex + 1 );
  };
// 戻るボタン
  const handleBackClick = () => {
    // if( currentIndex === 3 && selectedCurtainType === "roll" ){
    //   setCurrentIndex( currentIndex - 2 );
    // }else{
      setCurrentIndex( currentIndex - 1 );
    // }
  };

  const [changes, setChanges] = useState( null );
  const handleChangesFromSimulator = ( newChanges ) => {
    console.log('Handle Changes called');
    console.log('Current changes:', changes);
    console.log('New changes:', newChanges);
    
    // changesと新しい値が同じでない場合にのみ変更をセットする
    if (changes !== newChanges) {
      console.log('Changes from Simulator:', newChanges);
      setChanges(newChanges);
    }
  };
  const [simulatorSize, setSimulatorSize] = useState({ width: 0, height: 0 });
  const handleSizeChange = ( size ) => {
    setSimulatorSize({ width: size.width/100, height: size.height/100 });
  };

  return (
    <>
      {/* <Header /> */}
      <div className="container">
        <div
          className="row"
          style={{ display: currentIndex === 0 ? "block" : (currentIndex === 10) ? "none" : "block" }}
        >
          <TopWord />
        </div>
        <div
          className="row justify-content-center py-5 px-0 mx-0 w-100"
          style={{
            backgroundColor: "rgb(222, 236, 237)",
            display: currentIndex === 0 ? "block" : "none"
          }}
        >
          {/* PhotoFlow.js */}
          <div
            className="px-0 my-4"
          >
            <PhotoFlow
              onNextClick={ handleNextClick }
              // onBackClick={ handleBackClick }
            />
          </div>
        </div>
        {/* CameraPhoto.js */}
        <div style={{ display: currentIndex === 1 ? "block" : "none" }}>
          <CameraPhoto
            setSelectedimage={ setSelectedimage }
            onTakePhoto={ handleTakePhoto } 
            onNextClick={ handleNextClick }
            onBackClick={ handleBackClick }
            handleCameraNextClick={ handleCameraNextClick }
          />
        </div>
        {/* Camera.js */}
        <div style={{ display: currentIndex === 2 ? "block" : "none" }}>
          <Camera
            onImageCapture={ handleImageCapture }
            onWindowTypeSelect={ handleWindowTypeSelect }
            onNextClick={ handleNextClick }
            onBackClick={ handleBackClick }
            currentIndex={ currentIndex }
          />
        </div>
        {/* WinndowType.js */}
        <div style={{ display: currentIndex === 3 ? "block" : "none" }}>
          <WindowType
            onSelectWindowType={ WindowTypeSelect }
            onNextClick={ handleNextClick }
            onBackClick={ handleBackClick }
          />
        </div>
        {/* MoveZoomInOut.js */}
        <div style={{ display: currentIndex === 4 ? "block" : "none" }}>
          <MoveZoomInOut 
            photoimage={ photoimage }
            selectedimage={ selectedimage }
            selectedWindowType={ selectedWindowType }
            selectedType={ selectedType }
            moveZoomData={ moveZoomData }
            setMoveZoomData={ setMoveZoomData }
            onMoveZoomDataChange={ handleMoveZoomDataChange }
            onNextClick={ handleNextClick }
            onBackClick={ handleBackClick }
            onSizeChange={ handleSizeChange }
          />
        </div>
        {/* CurtainType.js */}
        <div style={{ display: currentIndex === 5 ? "block" : "none" }}>
          <CurtainType
            onSelectCurtainType={ handleCurtainTypeSelect }
            onNextClick={ handleNextClick }
            onBackClick={ handleBackClick }
          />
        </div>
        {/* RailColor.js */}
        <div style={{ display: currentIndex === 6 ? "block" : "none" }}>
          <RailColor
            onSelect={ handleRailSelect }
            onNextClick={ handleNextClick }
            onBackClick={ handleBackClick }
          />
        </div>
        {/* FunctionPattern.js */}
        <div style={{ display: currentIndex === 7 ? "block" : "none" }}>
          <FunctionPattern
            selectedCurtainType={ selectedCurtainType }
            onApiData={ handleApiData }
            onSelectedData={ handleSelectedData }
            onNextClick={ handleNextClick }
            onBackClick={ handleBackClick }
          />
        </div>
        {/* PriceRange.js */}
        <div style={{ display: currentIndex === 8 ? "block" : "none" }}>
          <PriceRange
            apiData={ apiData }
            updateFilteredData={ updateFilteredData }
            updateSelectedPriceRange={ updateSelectedPriceRange }
            onNextClick={ handleNextClick }
            onBackClick={ handleBackClick }
          />
        </div>
        {/* ExternalAPI.js */}
        <div style={{ display: currentIndex === 9 ? "block" : "none" }}>
          <ExternalAPI
            selectedCurtainType={ selectedCurtainType }
            filteredData={ filteredData }
            onColorSelect={ handleColorSelect }
            onNextClick={ handleNextClick }
            onBackClick={ handleBackClick }
          />
        </div>
        {/* Simulator.js */}
        <div style={{ display: currentIndex === 10 ? "block" : "none" }}>
          <Simulator
            photoimage={ photoimage }
            selectedimage={ selectedimage }
            selectedWindowType={ selectedWindowType }
            selectedType={ selectedType }
            moveZoomData={ moveZoomData }
            selectedCurtainType={ selectedCurtainType }
            receivedNewFunctions={ receivedNewFunctions }
            selectedRailInfo={ selectedRailInfo }
            selectedData={ selectedData }
            selectedPriceRange={ selectedPriceRange }
            selectedColors={ selectedColors }
            handleChanges={ handleChangesFromSimulator }
            simulatorSize={ simulatorSize }
            currentIndex={currentIndex}
          />
        </div>
      </div>
    </>
  );
}

export default Photo;


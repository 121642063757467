import React, { Component , useState, useRef, useEffect }  from 'react';
import HomeWord from '../components/HomeWord';
// import SpeechBubble from '../components/SpeechBubble';
// import TopMenu from '../components/TopMenu';
import Screen from '../components/Screen'
import { LineUp } from '../components/LineUp';
import HowToSimulation from '../components/HowToSimulation';
import Thanks from '../components/thanks';
import Menu from '../components/top_menu';
import { WebCamTop } from '../components/webCamTop';

const btn_margin = {
  margin: "10px 0"
}

export const KoikeApp = () => {
  const [ stage, setStage ] = useState( 0 );

  const onClickCg = () => {
    setStage(1);
  };

  const onClickPhoto = () => {
    setStage(2);
  };

  return (
    <div>
      <HomeWord />
      <Menu />
      <LineUp />
      <HowToSimulation />
      <Thanks />
    </div>
  );
}

import React from 'react';

function Youtube() {
  return (
    <div className="container YouTube">
      <div className="row justify-content-between mt-3">
        <div 
          className="col-12 py-1 text-center fw-bold my-3"
          style={{ color:"#046B5E" }}
        >
          \ 動画で使い方をチェック! /
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="youtube col-8 text-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/tcIgaqOznNE"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              >
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Youtube;

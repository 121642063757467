import React, { useEffect, useState } from "react";
import axios from "axios";

function CurtainType({
  onSelectCurtainType,
  onNextClick,
  onBackClick
}){
  const [ elementWidth, setElementWidth ] = useState( null );
  const [ selectedCurtainType, setSelectedCurtainType ] = useState( "drape" );
  const [ count, setCount ] = useState( 0 );
  const CurtainTypes = [
    { dispName: "ドレープ", value: "drape", countEndpoint: "/api/drapes/count", dataEndpoint: "/api/drapes/" },
    { dispName: "レース", value: "lace", countEndpoint: "/api/laces/count", dataEndpoint: "/api/laces/" },
    { dispName: "ロールスクリーン", value: "roll", countEndpoint: "/api/roll_screens/count", dataEndpoint: "/api/roll_screens/" },
  ];
  useEffect(() => {
    // API連携
    async function fetchData() {
      try {
        const response = await axios.get( CurtainTypes.find( type => type.value === selectedCurtainType ).countEndpoint );
        setCount( response.data.length );
      } catch ( error ) {
        console.error( "Error fetching data:", error );
      }
    }
    function handleResize() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768;
      const newElementWidth = isMobile ? screenWidth * 0.90 : screenWidth / 2.4;
      setElementWidth( newElementWidth );
    }
    handleResize();
    window.addEventListener( "resize", handleResize );
    fetchData();
    if ( selectedCurtainType === "drape" ) {
      onSelectCurtainType( selectedCurtainType );
    }
    return () => {
      window.removeEventListener( "resize", handleResize );
    };
  }, [ selectedCurtainType ]);

  const Shadow = {
    border: "1px solid #056b5e",
    boxShadow: "0px 5px 3px #95BAB5",
  };
  const containerStyle = {
    ...Shadow,
    width: `${ elementWidth }px`,
  };

  const handleCurtainTypeSelect = ( value ) => {
    onSelectCurtainType( value );
    setSelectedCurtainType( value );
  };

// 戻るボタンをクリックした時の処理
  const handleBackClick = () => {
    onBackClick();
  };
// 次へボタンをクリックした時の処理
  const handleNextClick = () => {
    onNextClick();
  };

  return(
    <div
      className="container curtain_type bg-white mt-3 mb-5"
      style={ containerStyle }
    >
      <div className="row">
        <div
          className="col-12 text-white text-center mb-3 py-3"
          style={{ background: "#056b5e" }}
        >
          簡単!カーテンシミュレーション
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-10 px-0 d-flex justify-content-center">
          <div className="row w-100 mx-3">
            {/* 円グラフ */}
            <div className="col-2 px-0">
              <div className="pie1_5">
                <span
                  className="fw-bold"
                  style={{ color: "#056b5e" }}
                >
                  1
                </span>
                <span>
                  <small className="text-secondary">
                    /5
                  </small>
                </span>
              </div>
            </div>
            {/* チェックマーク */}
            <div className="col-1 text-center px-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#056b5e"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
            </div>
            {/* STEP */}
            <div className="col-9 px-0">
              <div className="row">
                <div className="col-12 text-left">
                  STEP 01
                </div>
                <div
                  className="col-12 text-left fw-bold"
                  style={{ color: "#056b5e" }}
                >
                  種類
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2 px-0 d-flex justify-content-center">
          <p
            className="text-end my-1"
            style={{ fontSize:"9px" }}
          >
            <nobr>
              { count }件
            </nobr>
            
          </p>
        </div>
      </div>
      {/*  */}
      <div className="row justify-content-between px-3 my-3">
        { CurtainTypes.map(( curtainType ) => (
          <div
            key={ curtainType.value }
            className={ `col-6 mx-0 my-1 px-3 px-md-5 ${ selectedCurtainType === curtainType.value ? "selected" : "" }` }
          >
            <button
              className="btn w-100 text-center"
              style={{
                backgroundColor: 
                  selectedCurtainType === curtainType.value
                    ? "#056b5e"
                    : "#EEF5F1",
                borderColor:
                  selectedCurtainType === curtainType.value
                    ? "#046B5E"
                    : "#056b5e",
                color: selectedCurtainType === curtainType.value ? "#ffffff" : "#056b5e",
                fontSize: "10pt",
              }}
              onClick={ () => handleCurtainTypeSelect( curtainType.value ) }
            >
              <span style={{ lineHeight: "1.1" }}>
                <small>
                  { curtainType.dispName }
                </small>
              </span>
            </button>
          </div>
        ))}
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 col-12">
          <hr />
        </div>
      </div>
      <div className="row justify-content-around mx-3 py-3">
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px",
          }}
          onClick={ handleBackClick }
        >
          <span>
            戻る
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          ></div>
        </div>
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px",
          }}
          onClick={ handleNextClick }
        >
          <span>
            次へ
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default CurtainType;

import React from "react";
import { Link } from "react-router-dom";

function menu() {
  const PhotoIcon = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/cg_icon.png";
  const CGIcon = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/cg_icon.png";
  const Cg = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/top_cg_img%402x.jpg";
  const Photo = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/top_photo_img%402x.jpg";
  const photo = [{
    name: "photo",
    mainImg: Photo,
    title: "お客様の写真を背景",
    titleImg: PhotoIcon,
    description: "お客様のお部屋からシミュレーションできます。"
  }];
  const cg = [{
    name: "cg",
    mainImg: Cg,
    title: "色を合わせたCGを背景",
    titleImg: CGIcon,
    description: "リアルなCG画像からシミュレーションできます。"
  }];
  return (
    <div className="container menu mt-3 mx-0 px-0">
      <div className="row menu justify-content-center px-0 mx-0">
        { photo.map (( part, index ) =>
          <Link
            className={ `top_menu top_menu col-md-5 col-12 px-0 ${ part.name }` }
            key={ index }
            style={{
              backgroundColor: "#046B5E",
              textDecoration: "none",
              width: "100%",
              backgroundColor: "#046B5E",
              minWidth: "48%",
            }}
            to={ "/light/" + part.name }
          >
            <div className={ part.name }>
              <img
                src={ part.mainImg }
                alt={ part.name }
                className="w-100"
              />
            </div>
            <div
              className="mt-3 text-center text-white fw-bold"
              style={{ fontSize:"20px" }}
            >
              <img
                src={ part.titleImg }
                alt={ part.titleImg }
                style={{ maxHeight: "40px" }}
              />
              { part.title }
            </div>
            <div
              className="text-center text-white font-small"
              style={{ fontSize:"13px" }}
            >
              { part.description }
            </div>
          </Link>
        )}
        <div className="col"></div>
        <div className="d-block d-md-none">
          <br />
          <br />
        </div>
        { cg.map( ( part, index ) =>
          <Link
            className={ `top_menu top_menu col-md-5 col-12 px-0 ${ part.name }` }
            key={ index }
            style={{
              backgroundColor: "#046B5E",
              textDecoration: "none",
              width: "95%",
              backgroundColor: "#046B5E",
              minWidth: "48%",
            }}
            to={ "/light/" + part.name }
          >
            <div className={ part.name }>
              <img
                src={ part.mainImg }
                alt={ part.name }
                className="w-100"
              />
            </div>
            <div
              className="mt-3 text-center text-white fw-bold"
              style={{ fontSize:"20px" }}
            >
              <img
                src={ part.titleImg }
                alt={ part.titleImg }
                style={{ maxHeight: "40px" }}
              />
              { part.title }
            </div>
            <div
              className="text-center text-white font-small"
              style={{ fontSize:"13px" }}
            >
              { part.description }
            </div>
          </Link>
        )}
      </div>
    </div>
  )
}

export default menu;

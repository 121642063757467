import React, { useEffect, useState } from "react";

function PriceRange({
  apiData,
  updateFilteredData,
  updateSelectedPriceRange,
  onNextClick,
  onBackClick
}){
  // console.log( apiData )
  const [ elementWidth, setElementWidth ] = useState( null );
  const [ selectedRange, setSelectedRange ] = useState( "3,4" );
  const [ filteredData, setFilteredData ] = useState( [] );
  const priceRange = [
    { dispName: "高級品", description: "(¥40,000~)", range: "1,2", check: false },
    { dispName: "通常品", description: "(¥20,000~¥40,000)", range: "3,4", check: false },
    { dispName: "お手頃品", description: "(~¥20,000)", range: "5", check: false }
  ];
  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768;
      const newElementWidth = isMobile ? screenWidth * 0.90 : screenWidth / 2.4;
      setElementWidth( newElementWidth );
    }
    handleResize();
    window.addEventListener( "resize", handleResize );
    return () => {
      window.removeEventListener( "resize", handleResize );
    };
  }, []);
  useEffect(() => {
    updateSelectedPriceRange( selectedRange );
  }, [ updateSelectedPriceRange, selectedRange ]);
  const Shadow = {
    border: "1px solid #056b5e",
    boxShadow: "0px 5px 3px #95BAB5",
  };
  const containerStyle = {
    ...Shadow,
    width: `${ elementWidth }px`,
  };
  useEffect(() => {
    if (apiData) {
      // console.log(apiData)
      const selectedRanges = selectedRange.split( ',' ).map( Number );
      // console.log(selectedRange)
      const filtered = apiData.filter( item => selectedRanges.includes( item.price ) );
      // console.log("filtered:",filtered)
      setFilteredData( filtered );
      updateFilteredData( filtered );
    }
  }, [ selectedRange, apiData ]);

  const handleRangeClick = ( range ) => {
    setSelectedRange( range );
    updateSelectedPriceRange( range );
  };

  const filteredCount = filteredData.length;

  // 戻るボタンをクリックした時の処理
  const handleBackClick = () => {
    onBackClick();
  };
  // 次へボタンをクリックした時の処理
  const handleNextClick = () => {
    onNextClick();
  };

  return(
    <div
      className="container curtain_type bg-white mt-3 mb-5"
      style={ containerStyle }
    >
      <div className="row">
        <div
          className="col-12 text-white text-center mb-3 py-3"
          style={{ background: "#056b5e" }}
        >
          簡単!カーテンシミュレーション
        </div>
      </div>
      <div className="row">
        <div className="col-10 px-0 d-flex justify-content-center">
          <div className="row w-100 mx-3">
            {/* 円グラフ */}
            <div className="col-2 px-0">
              <div className="pie4_5">
                <span
                  className="fw-bold"
                  style={{ color: "#056b5e" }}
                >
                  4
                </span>
                <span>
                  <small className="text-secondary">
                    /5
                  </small>
                </span>
              </div>
            </div>
            {/* チェックマーク */}
            <div className="col-1 text-center px-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#056b5e"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
            </div>
            {/* STEP */}
            <div className="col-9 px-0">
              <div className="row">
                <div className="col-12 text-left">
                  STEP 04
                </div>
                <div
                  className="col-12 text-left fw-bold"
                  style={{ color: "#056b5e" }}
                >
                  価格帯
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2">
          <p 
            className="text-end my-1"
            style={{ fontSize:"9px" }}
          >
            <nobr>
              { filteredCount }件
            </nobr>
          </p>
        </div>
      </div>
      <div className="row justify-content-center px-0 mt-3">
        {/*  */}
        {priceRange.map(( range, index ) => (
          <div
            key={ index }
            className={`col-12 mx-0 my-2 px-3 px-md-5 ${ range.range === selectedRange ? "selected" : "" }`}
          >
            <button
              className="btn w-100 text-center"
              style={{
                backgroundColor:
                  range.range === selectedRange
                    ? "#056b5e"
                    : "#EEF5F1",
                borderColor:
                  range.range === selectedRange
                    ? "#046B5E"
                    : "#056b5e",
                color: range.range === selectedRange ? "#ffffff" : "#056b5e",
                fontSize: "10pt",
              }}
              onClick={() => handleRangeClick( range.range )}
            >
              <span
                style={{ lineHeight: "1.1" }}
              >
                <small>
                  { range.dispName }{ range.description }
                </small>
              </span>
            </button>
          </div>
        ))}
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 col-12">
          <hr />
        </div>
      </div>
      <div className="row justify-content-around mx-3 py-3">
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px",
          }}
          onClick={ handleBackClick }
        >
          <span>
            戻る
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          ></div>
        </div>
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px",
          }}
          onClick={ handleNextClick }
        >
          <span>
          次へ
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default PriceRange;

import React, { useEffect, useState, useRef } from "react";

function ExternalAPI({
  selectedCurtainType,
  filteredData,
  onColorSelect,
  onNextClick,
  onBackClick
}){
  const [ elementWidth, setElementWidth ] = useState( null );
  const [ panelWidth, setPanelWidth ] = useState( null );
  const [ selectedColor, setSelectedColor ] = useState( "pastel" );
  const [ matchingCount, setMatchingCount ] = useState( 0 );
  const col2Ref = useRef( null );

  useEffect(() => {
    if ( col2Ref.current ) {
      const col2Width = col2Ref.current.offsetWidth;
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768;
      const newcol2Width = isMobile ? col2Width / 1.8 : col2Width / 2.4;
      setPanelWidth( newcol2Width );
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768;
      const newElementWidth = isMobile ? screenWidth * 0.90 : screenWidth / 2.4;
      setElementWidth( newElementWidth );
    }
    handleResize();
    window.addEventListener( "resize", handleResize );
    return () => {
      window.removeEventListener( "resize", handleResize );
    };
  }, []);

  const pastel = [ "#F8B8B6", "#FFFBB6", "#ABC2DF", "#DCE8B5" ];
  const vivid_cold = [ "#F59096", "#FEF88C", "#6CABD1", "#CBDD8D" ];
  const vivid_warm = [ "#DB005C", "#F4E402", "#0072AE", "#9EC700" ];
  const monotone = [ "#ffffff","#dcdcdc","#808080","#000000" ];

  const color = [
    { dispName: "ﾊﾟｽﾃﾙ", value: "pastel", colorCode: pastel, apiRGB: "179,165,202" },
    { dispName: "ﾋﾞﾋﾞｯﾄｺｰﾙﾄﾞ", value: "vivid_cold", colorCode: vivid_cold, apiRGB: "0,115,135" },
    { dispName: "ﾋﾞﾋﾞｯﾄｳｫｰﾑ", value: "vivid_warm", colorCode: vivid_warm, apiRGB: "205,45,71" },
    { dispName: "ﾓﾉﾄｰﾝ", value: "monotone", colorCode: monotone, apiRGB: "150,150,150" },
    { dispName: "ﾈｲﾋﾞｰ", value: "navy", colorCode: "#121e5a", apiRGB: "18,30,90" },
    { dispName: "ｸﾞﾘｰﾝ", value: "green", colorCode: "#24ab23", apiRGB: "36,171,35"},
    { dispName: "ﾌﾞﾗｳﾝ", value: "brown", colorCode: "#8c6b3f", apiRGB: "140,107,63" },
    { dispName: "ﾚｯﾄﾞ", value: "red", colorCode: "#a31c38", apiRGB: "163,28,56" },
  ];

  const Shadow = {
    border: "1px solid #056b5e",
    boxShadow: "0px 5px 3px #95BAB5",
  };

  const containerStyle = {
    ...Shadow,
    width: `${ elementWidth }px`,
  };

  const handleColorClick = ( value ) => {
    setSelectedColor( value );
    onColorSelect( value );
  };

  const countMatchingCodes = ( apiData, filteredData ) => {
    return filteredData.reduce(( acc, data ) => {
      const matchingCodes = Object.values( apiData ).flat();
      if ( matchingCodes.includes( data.code ) ) {
        return acc + 1;
      }
      return acc;
    }, 0);
  };

  useEffect(() => {
    // API呼び出し処理
    const fetchData = async () => {
      const rgb = color.find( ( c ) => c.value === selectedColor ).apiRGB;
      let apiUrl;
      if ( selectedCurtainType === "drape" || selectedCurtainType === "lace" ) {
        apiUrl = `https://i-koike2.sakura.ne.jp/rgbcg.php?rgb=${ rgb }`;
      } else if ( selectedCurtainType === "roll" ) {
        apiUrl = `https://i-koike2.sakura.ne.jp/rgbcgroll.php?rgb=${ rgb }`;
      }
      try {
        const response = await fetch( apiUrl );
        const apiData = await response.json();
        const count = countMatchingCodes( apiData, filteredData );
        setMatchingCount( count );
      } catch ( error ) {
        console.error( "Error fetching data:", error );
      }
    };
    fetchData();
  }, [ selectedCurtainType, selectedColor, filteredData ]);

  useEffect(() => {
    if ( selectedColor ) {
      onColorSelect( selectedColor );
    }
  }, [ selectedColor, onColorSelect ]);

// 戻るボタンをクリックした時の処理
  const handleBackClick = () => {
    onBackClick();
  };
// 次へボタンをクリックした時の処理
  const handleNextClick = () => {
    onNextClick();
  };

  return(
    <div
      className="container curtain_type bg-white mt-3 mb-5"
      style={ containerStyle }
    >
      <div className="row">
        <div
          className="col-12 text-white text-center mb-3 py-3"
          style={{ background: "#056b5e" }}
        >
          簡単!カーテンシミュレーション
        </div>
      </div>
      <div className="row">
        <div className="col-10 px-0 d-flex justify-content-center">
          <div className="row w-100 mx-3">
            {/* 円グラフ */}
            <div className="col-2 px-0">
              <div className="pie5_5">
                <span
                  className="fw-bold"
                  style={{ color: "#056b5e" }}
                >
                  5
                </span>
                <span>
                  <small className="text-dark">
                    /5
                  </small>
                </span>
              </div>
            </div>
            {/* チェックマーク */}
            <div className="col-1 text-center px-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#056b5e"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
            </div>
            {/* STEP */}
            <div className="col-9 px-0">
              <div className="row">
                <div className="col-12 text-left">
                  STEP 05
                </div>
                <div
                  className="col-12 text-left fw-bold"
                  style={{ color: "#056b5e" }}
                >
                  カラー
                  <span
                    className="text-secondary"
                    style={{ fontSize:"9px" }}
                  >
                    ※トーンで選ぶこともできます。
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2">
          <p 
            className="text-end my-1"
            style={{ fontSize:"9px" }}
          >
            <nobr>
              { matchingCount }件
            </nobr>
          </p>
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        {/*  */}
        {color.map(( colorGroup, index ) => (
          <div
            className={`col-3 text-center color-panel d-flex justify-content-center my-3 ${
              selectedColor === colorGroup.value ? "selected" : ""
            }`}
            key={ index }
            onClick={() => handleColorClick( colorGroup.value )}
          >
            <div
              className="color-panelborder border position-relative"
              style={{
                width: panelWidth + "px",
                minWidth: "45px",
                maxWidth: "50px",
                height: panelWidth + "px",
                minHeight: "45px",
                maxHeight: "50px",
                borderRadius: "5px",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 2,
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                }}
              >
                {/* 改行コードで分割 */}
                {colorGroup.dispName.split( "\n" ).map(( line, i ) => (
                  <React.Fragment key={i}>
                    { i > 0 && <br /> }
                    <span style={{ color:"#3e3a39" }}>
                      { line }
                    </span>
                  </React.Fragment>
                ))}
              </div>
              {Array.isArray( colorGroup.colorCode ) ? (
                colorGroup.colorCode.map(( code, subIndex ) => (
                  <div
                    key={ subIndex }
                    style={{
                      backgroundColor: code,
                      width: "50%",
                      height: "50%",
                      position: "absolute",
                      top: subIndex < 2 ? "0" : "50%",
                      left: subIndex % 2 === 0 ? "0" : "50%",
                    }}
                  />
                ))
              ):(
                <div
                  style={{
                    backgroundColor: colorGroup.colorCode,
                    width: "100%",
                    height: "100%",
                    borderRadius: "4px",
                  }}
                />
              )}
              {selectedColor === colorGroup.value && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#056b5e"
                    className="bi bi-check-circle-fill bg-white rounded"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 col-12">
          <hr />
        </div>
      </div>
      <div className="row justify-content-around mx-3 py-3">
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px",
          }}
          onClick={ handleBackClick }
        >
          <span>
            戻る
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          >
          </div>
        </div>
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px",
          }}
          onClick={ handleNextClick }
        >
          <span>
            次へ
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          >
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExternalAPI;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LightCgSimulator from "./LightCgSimulator";
import LightPhotoSimulator from "./LightPhotoSimulator";
import axios from "axios";
import SideMenu from "./SideMenu";

function Simulator({
  // CG
    selectedRoomInfo,
    selectedRailInfo,
    selectedCurtainType,
    receivedNewFunctions,
    selectedData,
    selectedPriceRange,
    selectedColors,
  //PHOTO
    photoimage,
    selectedimage,
    selectedWindowType,
    selectedType,
    moveZoomData,
    simulatorSize,
    currentIndex
  })
{
  // console.log(currentIndex)
  const [ elementWidth, setElementWidth ] = useState( null );
  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768;
      const newElementWidth = isMobile ? screenWidth * 0.90 : screenWidth / 2.4;
      setElementWidth( newElementWidth );
    }
    handleResize();
    window.addEventListener( "resize", handleResize );
    return () => {
      window.removeEventListener( "resize", handleResize );
    };
  }, []);
  const Shadow = {
    border: "1px solid #056b5e",
    boxShadow: "0px 5px 3px #95BAB5",
  };
  const containerStyle = {
    ...Shadow,
    width: `${ elementWidth }px`,
  };
  const containerStyle2 = {
    width: `${ elementWidth }px`,
  };

  const CurecoURL = "https://i-koike.net/";
  const description = "https://www.i-koike.jp/description/cgct/attention.png"

  // STEP02_種類
  const curtainTypesOptions = [
    { dispName: "ドレープ", value: "drape", countEndpoint: "/api/drapes/count", dataEndpoint: "/api/drapes/" },
    { dispName: "レース", value: "lace", countEndpoint: "/api/laces/count", dataEndpoint: "/api/laces/" },
    { dispName: "ロールスクリーン", value: "roll", countEndpoint: "/api/roll_screens/count", dataEndpoint: "/api/roll_screens/" },
  ];
  const [ currentSelectedCurtainType, setCurrentSelectedCurtainType ] = useState( selectedCurtainType );
  const [ CurtainType, setCurtainType ] = useState( currentSelectedCurtainType );
  
  // STEP03_機能・模様
  const curtainData = {
    drape : [
      { dispName: "防炎", isActive: false, dbName: "fire", columnName: "functions" },
      { dispName: "形状記憶", isActive: false, dbName: "set", columnName: "functions" },
      { dispName: "遮光", isActive: false, dbName: "bo1aaa,bo1,bo2", columnName: "functions" },
      { dispName: "ウォッシャブル", isActive: false, dbName: "washable", columnName: "functions" },
      { dispName: "無地調", isActive: false, dbName: "Mj", columnName: "taste" },
      { dispName: "柄", isActive: false, dbName: "N,NG,S,SW,SS,V,C,CE,M,MS,Wa", columnName: "taste" },
    ],
    lace : [
      { dispName: "防炎", isActive: false, dbName: "fire", columnName: "functions" },
      { dispName: "形状記憶", isActive: false, dbName: "set", columnName: "functions" },
      { dispName: "見えにくい", isActive: false, dbName: "secret", columnName: "functions" },
      { dispName: "ウォッシャブル", isActive: false, dbName: "washable", columnName: "functions" },
      { dispName: "UVカット", isActive: false, dbName: "uvcut", columnName: "functions" },
      { dispName: "柄", isActive: false, dbName: "print", columnName: "functions" },
      { dispName: "カラー", isActive: false, dbName: "color", columnName: "functions" },
      { dispName: "裾柄レース", isActive: false, dbName: "embro", columnName: "functions" },
    ],
    roll : [
      { dispName: "遮光", isActive: false, dbName: "bo1aaa,bo1,bo2", columnName: "functions" },
      { dispName: "遮光なし", isActive: false, dbName: "", columnName: "" },
      { dispName: "見えにくい", isActive: false, dbName: "secret", columnName: "functions" },
      { dispName: "通常", isActive: false, dbName: "", columnName: "" },
    ],
  }
  const [ FunctionPatterns, setFunctionPatterns ] = useState( receivedNewFunctions || curtainData[currentSelectedCurtainType] );
  // 
  const [ currentSelectedPriceRange, setCurrentSelectedPriceRange ] = useState( selectedPriceRange );
  useEffect(() => {
    setCurrentSelectedPriceRange( selectedPriceRange );
  }, [ selectedPriceRange ]);
  const [ PriceRange, setPriceRange ] = useState( currentSelectedPriceRange );
  //
  const [ currentSelectedColors, setCurrentSelectedColors ] = useState( selectedColors );
  const pastel = [ "#F8B8B6", "#FFFBB6", "#ABC2DF", "#DCE8B5" ];
  const vivid_cold = [ "#F59096", "#FEF88C", "#6CABD1", "#CBDD8D" ];
  const vivid_warm = [ "#DB005C", "#F4E402", "#0072AE", "#9EC700" ];
  const monotone = [ "#ffffff","#dcdcdc","#808080","#000000" ];
  const color = [
    { dispName: "ﾊﾟｽﾃﾙ", value: "pastel", colorCode: pastel, apiRGB: "179,165,202" },
    { dispName: "ﾋﾞﾋﾞｯﾄｺｰﾙﾄﾞ", value: "vivid_cold", colorCode: vivid_cold, apiRGB: "0,115,135" },
    { dispName: "ﾋﾞﾋﾞｯﾄｳｫｰﾑ", value: "vivid_warm", colorCode: vivid_warm, apiRGB: "205,45,71" },
    { dispName: "ﾓﾉﾄｰﾝ", value: "monotone", colorCode: monotone, apiRGB: "150,150,150" },
    { dispName: "ﾈｲﾋﾞｰ", value: "navy", colorCode: "#121e5a", apiRGB: "18,30,90" },
    { dispName: "ｸﾞﾘｰﾝ", value: "green", colorCode: "#24ab23", apiRGB: "36,171,35"},
    { dispName: "ﾌﾞﾗｳﾝ", value: "brown", colorCode: "#8c6b3f", apiRGB: "140,107,63" },
    { dispName: "ﾚｯﾄﾞ", value: "red", colorCode: "#a31c38", apiRGB: "163,28,56" },
  ];
  useEffect(() => {
    setCurrentSelectedColors( selectedColors );
  }, [ selectedColors ]);
  const [ apiColor, setApiColor ] = useState( selectedColors );
  // const handleColorChange = ( color ) => {
  //   setCurrentSelectedColors( color );
  // };

  const [ CurtainNarrowdown, setCurtainNarrowdown ] =  useState([]);
  const [ FunctionPatternNarrowdown, setFunctionPatternNarrowdown ] =  useState([]);
  // console.log(FunctionPatternNarrowdown)
  const [ PriceRangeNarrowdown, setPriceRangeNarrowdown ] =  useState([]);
  const [ RefinementResult, setRefinementResult ] = useState([])

  const getEndpoint = ( curtainType ) => {
    switch ( curtainType ) {
      case 'drape':
        return '/api/drapes';
      case 'lace':
        return '/api/laces';
      case 'roll':
        return '/api/roll_screens';
      default:
        return '/api/drapes';
    }
  };
  
  const [ selectedCode, setSelectedCode ] = useState( null );
  const handleReceivedCode = ( code ) => {
    setSelectedCode( code );
  };

  const ECURL_drape = `https://www.i-koike-shop.net/products/${ selectedCode }`
  const ECURL_lace = `https://www.i-koike-shop.net/products/${ selectedCode }`


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // 部屋と家具
  const [ roomType, setRoomType ] = useState( selectedRoomInfo?.roomType );
  const [ finalroomType, setFinalroomType ] = useState( null )
  const [ furnitureNumber, setFurnitureNumber ] = useState( selectedRoomInfo?.furniture );
  const [ finalfurnitureNumber, setFinalfurnitureNumber ] = useState( null );
  // 種類
  const [ curtain, setCurtain ] = useState( selectedCurtainType );
  const [ finalCurtainType, setFinalCurtainType ] = useState( selectedCurtainType );
  useEffect(() => {
    async function fetchData() {
        try {
          let endpoint;
            if( finalCurtainType ){
              if ( finalCurtainType === 'drape' ) {
                endpoint = `/api/drapes/`;
              } else if ( finalCurtainType === 'lace' ) {
                endpoint = `/api/laces/`;
              } else if ( finalCurtainType === 'roll' ) {
                endpoint = `/api/roll_screens/`;
              }
              setCurrentSelectedCurtainType( finalCurtainType )
            }else if( selectedCurtainType !== null ){
              if ( selectedCurtainType === 'drape' ) {
                endpoint = `/api/drapes/`;
              } else if ( selectedCurtainType === 'lace' ) {
                endpoint = `/api/laces/`;
              } else if ( selectedCurtainType === 'roll' ) {
                endpoint = `/api/roll_screens/`;
              }
              setCurrentSelectedCurtainType( selectedCurtainType )
            }else{
              return;
            }
          const response = await axios.get( endpoint );
        } catch ( error ) {
          console.error("Error fetching data:", error);
        }
    }
    fetchData();
  }, [ finalCurtainType, selectedCurtainType, currentSelectedCurtainType ]);

  // レールカラー
  const [ rail ,setRail ] = useState( selectedRailInfo );
  const [ finalRailColor, setFinalRailColor ] = useState( null );

  // 機能・模様
  const [ finalFunctionPatterns, setFinalFunctionPatterns ] = useState( null );
  useEffect(() => {
    const curtainsData = async() => {
      if( finalCurtainType ){
        try {
          const response = await axios.get( curtainTypesOptions.find( type => type.value === finalCurtainType ).dataEndpoint )
          const data = response.data;
        } catch ( error ) {
          console.error( "Error fetching data:", error );
        }
      }else if(selectedCurtainType !== null){
        try {
          const response = await axios.get( curtainTypesOptions.find( type => type.value === selectedCurtainType ).dataEndpoint )
          const data = response.data;
        } catch ( error ) {
          console.error( "Error fetching data:", error );
        }
      }else{
        return;
      }
    };
    curtainsData();
    const functionspatterns = async () => {
      if ( Array.isArray( finalFunctionPatterns ) ) {
        const activeData = finalFunctionPatterns.filter(
          pattern => pattern && pattern.isActive
        );
        const selectedFunctions = activeData
          .filter( ( p ) => p.columnName === "functions" && p.dbName !== "" )
          .flatMap( ( p ) => p.dbName.split( "," ) );
        const selectedTastes = activeData
          .filter( ( p ) => p.columnName === "taste" && p.dbName !== "" )
          .flatMap( ( p ) => p.dbName.split( "," ));
        const endpoint = getEndpoint( finalCurtainType? finalCurtainType : selectedCurtainType);
        console.log(endpoint)
        const params = {};
        if ( selectedFunctions.length > 0 ) {
          params.functions = selectedFunctions;
        }
        if ( selectedTastes.length > 0 ) {
          params.tastes = selectedTastes;
        }
        try {
          const response = await axios.get( endpoint + "/functions", { params } );
          const data = response.data;
          setFunctionPatternNarrowdown(data);
        } catch ( error ) {
          console.error( "データの取得エラー:", error );
        }
      } else if(  Array.isArray( receivedNewFunctions ) ){
        const activeData = receivedNewFunctions.filter(
          pattern => pattern && pattern.isActive
        );
        const selectedFunctions = activeData
          .filter( ( p ) => p.columnName === "functions" && p.dbName !== "" )
          .flatMap( ( p ) => p.dbName.split( "," ) );
        const selectedTastes = activeData
          .filter( ( p ) => p.columnName === "taste" && p.dbName !== "" )
          .flatMap( ( p ) => p.dbName.split( "," ));
          const endpoint = getEndpoint( finalCurtainType? finalCurtainType : selectedCurtainType );
        const params = {};
        if ( selectedFunctions.length > 0 ) {
          params.functions = selectedFunctions;
        }
        if ( selectedTastes.length > 0 ) {
          params.tastes = selectedTastes;
        }
        try {
          const response = await axios.get( endpoint + "/functions", { params } );
          const data = response.data;
          setFunctionPatternNarrowdown(data);
        } catch ( error ) {
          console.error( "データの取得エラー:", error );
        }
      }else if(( receivedNewFunctions === null && finalFunctionPatterns === null )){
        if( selectedCurtainType === null ){
          return
        }else{
          if( Array.isArray( curtainData[ selectedCurtainType ] )){
            const activeData = curtainData[selectedCurtainType].filter(
              pattern => pattern && pattern.isActive
            );
            const selectedFunctions = activeData
              .filter( ( p ) => p.columnName === "functions" && p.dbName !== "" )
              .flatMap( ( p ) => p.dbName.split( "," ) );
            const selectedTastes = activeData
              .filter( ( p ) => p.columnName === "taste" && p.dbName !== "" )
              .flatMap( ( p ) => p.dbName.split( "," ));
            const endpoint = getEndpoint( selectedCurtainType );
            const params = {};
            if ( selectedFunctions.length > 0 ) {
              params.functions = selectedFunctions;
            }
            if ( selectedTastes.length > 0 ) {
              params.tastes = selectedTastes;
            }
            try {
              const response = await axios.get( endpoint + "/functions", { params } );
              const data = response.data;
              if( data ){
                setFunctionPatternNarrowdown( data );
              }else{
                return;
              }
            } catch ( error ) {
              console.error( "データの取得エラー:", error );
            }
          }else{
            return;
          }
        }
      }else{
        return;
      }
    };
    functionspatterns();
  },
    [ finalCurtainType, selectedCurtainType, finalFunctionPatterns, receivedNewFunctions, currentSelectedCurtainType ]
  );
  // 価格帯
  const [ pricerange, setPricerange ] = useState( selectedPriceRange );
  const [ finalPriceRange, setFinalPriceRange ] = useState( null );
  useEffect(() => {
    const priceRanges = async () => {
      if( finalPriceRange && ( FunctionPatternNarrowdown.length !== 0 ) ){
        const selectedRanges = finalPriceRange.split( "," ).map( Number );
        const filtered = FunctionPatternNarrowdown.filter( ( item ) => selectedRanges.includes( item.price ) );
        setPriceRangeNarrowdown( filtered );
      }else if( selectedPriceRange.length !== 0 && ( FunctionPatternNarrowdown.length !== 0 ) ){
        const selectedRanges = selectedPriceRange.split( "," ).map( Number );
        const filtered = FunctionPatternNarrowdown.filter( ( item ) => selectedRanges.includes( item.price ) );
        setPriceRangeNarrowdown( filtered );
      }else{
        return;
      }
    };
    priceRanges();
  },
    [ 
      selectedPriceRange,
      finalCurtainType,
      selectedCurtainType,
      finalFunctionPatterns,
      receivedNewFunctions,
      finalPriceRange,
      currentSelectedPriceRange,
      currentSelectedCurtainType,
      FunctionPatternNarrowdown
    ]
  );

  // カラー
  const [ colorApi, setColorApi ] = useState( selectedColors );
  const [ finalColor, setFinalColor ] = useState( null );
  useEffect(() => {
    const countMatchingCodes = ( apiData, PriceRangeNarrowdown ) => {
      if( finalColor ){
        const matchingCodes = Object.values( apiData ).flat();
        const matchingCodesSet = new Set( matchingCodes );
        const matchingCodesInPriceRange = PriceRangeNarrowdown.filter( data => matchingCodesSet.has( data.code ) );
        const matchedCodes = matchingCodesInPriceRange.map( data => data.code );
        setRefinementResult( matchedCodes )
      }else if( selectedColors.length !== 0 ){
        const matchingCodes = Object.values( apiData ).flat();
        const matchingCodesSet = new Set( matchingCodes );
        const matchingCodesInPriceRange = PriceRangeNarrowdown.filter( data => matchingCodesSet.has( data.code ) );
        const matchedCodes = matchingCodesInPriceRange.map( data => data.code );
        setRefinementResult( matchedCodes )
      }else{
        return;
      }
    };
    const colorData = async () => {
      if( finalColor ){
        try {
          const rgb = color.find( c => c.value === finalColor ).apiRGB;
          let apiUrl;
          if ( finalCurtainType === "drape" || finalCurtainType === "lace" ) {
            apiUrl = `https://i-koike2.sakura.ne.jp/rgbcg.php?rgb=${ rgb }`;
          } else if ( finalCurtainType === "roll" ) {
            apiUrl = `https://i-koike2.sakura.ne.jp/rgbcgroll.php?rgb=${ rgb }`;
          }
          const response = await fetch( apiUrl );
          const apiData = await response.json();
          countMatchingCodes( apiData, PriceRangeNarrowdown );
        } catch ( error ) {
          console.error("Error fetching data:", error);
        }
      }else if( selectedColors.length !== 0 ){
        try {
          const rgb = color.find( c => c.value === selectedColors ).apiRGB;
          let apiUrl;
          if ( selectedCurtainType === "drape" || selectedCurtainType === "lace" ) {
            apiUrl = `https://i-koike2.sakura.ne.jp/rgbcg.php?rgb=${ rgb }`;
          } else if ( selectedCurtainType === "roll" ) {
            apiUrl = `https://i-koike2.sakura.ne.jp/rgbcgroll.php?rgb=${ rgb }`;
          }
          const response = await fetch( apiUrl );
          const apiData = await response.json();
          countMatchingCodes( apiData, PriceRangeNarrowdown );
        } catch ( error ) {
          console.error("Error fetching data:", error);
        }
      }else{
        return;
      }
    };
    colorData();
  }, 
    [ 
      selectedCurtainType,
      finalCurtainType,
      receivedNewFunctions,
      finalFunctionPatterns,
      selectedColors,
      finalColor,
      selectedPriceRange,
      finalPriceRange,
      currentSelectedColors,
      currentSelectedPriceRange,
      PriceRangeNarrowdown,
      FunctionPatternNarrowdown
    ]
  );
  // 
  const handleSelection = ( type, furniture, curtaintype, railcolor, index, range, color ) => {
    if( window.location.pathname === '/light/cg' ){
      setRoomType( type );
      setFurnitureNumber( furniture );
      setCurtain( curtaintype )
      setRail( railcolor );
      setFunctionPatterns( index );
      setPricerange( range );
      setColorApi( color )
    }else{
      setRoomType( "" );
      setFurnitureNumber( "" );
      setCurtain( curtaintype );
      setRail( railcolor );
      setFunctionPatterns( index );
      setPricerange( range );
      setColorApi( color )
    }
  };
  const handleDecision = ( type, furniture, curtaintype, railcolor, index, range, color ) => {
    if( window.location.pathname === '/light/cg' ){
      setFinalroomType( type );
      setFinalfurnitureNumber( furniture );
      setFinalCurtainType( curtaintype );
      setFinalRailColor( railcolor );
      setFinalFunctionPatterns( index );
      setFinalPriceRange( range );
      setFinalColor( color );
    }else{
      setFinalroomType( type );
      setFinalfurnitureNumber( furniture );
      setFinalCurtainType( curtaintype );
      setFinalRailColor( railcolor );
      setFinalFunctionPatterns( index );
      setFinalPriceRange( range );
      setFinalColor( color );
    }
  };
  
  const [ sidemenuVisible, setSidemenuVisible ] = useState( false );
  const toggleSidemenu = () => {
    setSidemenuVisible( !sidemenuVisible );
  };
  const hideSidemenu = () => {
    setSidemenuVisible( !sidemenuVisible );
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return(
    <>
      <div
        className="HomeWord Simulator container"
        style={ containerStyle2 }
      >
        {/* PC */}
        <div className="row justify-content-center d-none d-md-block">
          <div className="col-12 w-100 text-center my-3">
            <img 
              src="https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/TOP/Cureco_logo.png"
              alt="カリコロゴ"
              className="text-center"
              style={{
                  height:"auto",
                  width:"300px"
              }}
            />
          </div>
          <div className="text-dark text-center small my-3">
            あなたのお部屋に合うカーテンは見つかりましたでしょうか?<br/>
            より詳しくシミュレートしたい方は、<br/>
            <Link
              className="fw-bold"
              to={ CurecoURL }
              style={{
                color:"#056b5e",
                textDecoration:"none"
              }}
            >
              カリコPRO（PC版）
            </Link>
            へ
          </div>
          <div className="col-12 text-dark text-center">
            <a
              href={ description }
              target="_blank"
              rel="noopener noreferrer"
            >
            </a>
          </div>
        </div>
        {/* phone */}
        <div className="row justify-content-center d-block d-md-none">
          <div className="col-12 w-100 text-center my-3">
            <img
              src="https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/TOP/Cureco_logo.png"
              alt="カリコロゴ"
              className="text-center"
              style={{
                  height:"auto",
                  width:"200px"
              }}
            />
          </div>
          <div className="text-dark text-center small my-3">
            あなたのお部屋に合うカーテンは<br/>
            見つかりましたでしょうか?<br/>
            より詳しくシミュレートしたい方は、<br/>
            <Link
              className="fw-bold"
              to={ CurecoURL }
              style={{
                color:"#056b5e",
                textDecoration:"none"
              }}
            >
              カリコPRO（PC版）
            </Link>
            へ
          </div>
          <div className="col-12 text-center">
          </div>
        </div>
      </div>
      <div
        className="container curtain_type bg-white mt-3 mb-5"
        style={ containerStyle }
      >
        <div className="row">
          <div
            className="col-12 text-white text-center mb-3 py-3"
            style={{ background: "#056b5e" }}
          >
            カーテン一覧
          </div>
        </div>
        <div className="row justify-content-center px-0 mx-0">
          <>
            <div className="CG_Simulator w-100">
              <div 
                className="col-12 btn text-center mt-3 mb-4" 
                onClick={ toggleSidemenu }
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: "#EEF5F1",
                  borderColor: "#046B5E",
                  color: "#046B5E",
                  fontSize: "10pt"
                }}
              >
                <p
                  className="w-100 text-center m-0 fw-bold"
                  style={{ alignItems: 'center' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                    fill="#EEF5F1"
                    stroke="#017668"
                    strokeWidth="50"
                  >
                    <path d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"/>
                  </svg>
                  &nbsp;&nbsp;条件を変更
                </p>
                <p className="right-align-svg m-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </p>
              </div>
            </div>
          </>
        </div>
        {/* {RefinementResult.length} */}
        <div className="row justify-content-center px-0">
          {window.location.pathname === '/light/cg' ? (
            <>
              <LightCgSimulator
                selectedRoomInfo={ selectedRoomInfo }
                selectedRailInfo={ selectedRailInfo }
                selectedCurtainType={ selectedCurtainType }
                receivedNewFunctions={ receivedNewFunctions }
                selectedData={ selectedData }
                selectedPriceRange={ selectedPriceRange }
                selectedColors={ selectedColors }
                // 
                CurtainType={CurtainType}
                // roomType={roomType}
                // furnitureNumber={furnitureNumber}
                PriceRange={ PriceRange }
                apiColor={ apiColor }
                FunctionPatterns={ FunctionPatterns }
                RefinementResult={ RefinementResult }
                PriceRangeNarrowdown={ PriceRangeNarrowdown }
                // currentSelectedCurtainType={currentSelectedCurtainType}
                // currentSelectedRoomType={currentSelectedRoomType}
                // currentSelectedFurnitureNumber={currentSelectedFurnitureNumber}
                onSelectCode={ handleReceivedCode }
                finalroomType={ finalroomType }
                finalfurnitureNumber={ finalfurnitureNumber }
                finalCurtainType={ finalCurtainType }
                finalRailColor={ finalRailColor }
                finalFunctionPatterns={ finalFunctionPatterns }
                finalPriceRange={ finalPriceRange }
                finalColor={ finalColor }
              />
            </>
          ):(
            <>
              <LightPhotoSimulator
                photoimage={ photoimage }
                selectedWindowType={ selectedWindowType }
                selectedimage={ selectedimage }
                selectedType={ selectedType }
                moveZoomData={ moveZoomData }
                selectedRailInfo={ selectedRailInfo }
                selectedCurtainType={ selectedCurtainType }
                receivedNewFunctions={ receivedNewFunctions }
                selectedData={ selectedData }
                selectedPriceRange={ selectedPriceRange }
                selectedColors={ selectedColors }
                // 
                CurtainType={ CurtainType }
                PriceRange={ PriceRange }
                apiColor={ apiColor }
                FunctionPatterns={ FunctionPatterns }
                RefinementResult={ RefinementResult }
                PriceRangeNarrowdown={ PriceRangeNarrowdown }
                currentSelectedCurtainType={ currentSelectedCurtainType }
                onSelectCode={ handleReceivedCode }
                onSelect={ handleSelection }
                onDecision={ handleDecision }
                finalCurtainType={ finalCurtainType }
                finalRailColor={ finalRailColor }
                finalFunctionPatterns={ finalFunctionPatterns }
                finalPriceRange={ finalPriceRange }
                finalColor={ finalColor }
                simulatorSize={ simulatorSize }
                currentIndex={currentIndex}
              />
            </>
          )}
        </div>
      </div>

      {
        sidemenuVisible && 
        <SideMenu
          hideSidemenu={ hideSidemenu }
          onSelect={ handleSelection }
          onDecision={ handleDecision }
          // 
          selectedRoomInfo={ selectedRoomInfo }
          selectedRailInfo={ selectedRailInfo }
          selectedCurtainType={ selectedCurtainType }
          receivedNewFunctions={receivedNewFunctions }
          selectedData={ selectedData }
          selectedPriceRange={ selectedPriceRange }
          selectedColors={ selectedColors }
          finalroomType={ finalroomType }
          finalfurnitureNumber={ finalfurnitureNumber }
          finalCurtainType={ finalCurtainType }
          finalRailColor={ finalRailColor }
          finalFunctionPatterns={ finalFunctionPatterns }
          finalPriceRange={ finalPriceRange }
          finalColor={ finalColor }
        />
      }
    </>
  )
}

export default Simulator;

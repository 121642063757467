import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import html2canvas from "html2canvas";
import { ImageProvider } from "../context/imageContext";
import { RoomArranger } from "../components/RoomArranger";
import { WindowArranger } from "../components/WindowArranger";
import { ConstructionArranger } from "../components/construction";
import { RailArranger } from "../components/RailArranger";
import LaceCurtain from "../components/LaceCurtain";
import DrapeCurtain from "../components/DrapeCurtain";
import SimulationImage from "../components/SimulationImage";
import { CustomToggleButtons } from '../parts/CustomToggleButtons';

export const CurtainSimulator = withRouter( props => {
  const { baseFrom, brandsData, pricesData, tastesData, roomImageFile } = props;
  const [ laceFunctions, setLaceFunctions ] = useState( { hits: [] } );
  const [ drapeFunctions, setDrapeFunctions ] = useState({ hits: [] });
  const [ tastesDatas, setTastesDatas ] = useState({ hits: [] });
  const [ selectedLaceFunctions, setSelectedLaceFunctions ] = useState( [] );
  const [ selectedLaceBrand, setSelectedLaceBrand ] = useState( null );
  const [ selectedLacePrice, setSelectedLacePrice ] = useState( null );
  const [ selectedDrapeFunctions, setSelectedDrapeFunctions ] = useState( [] );
  const [ selectedDrapeTastes, setSelectedDrapeTastes ] = useState( [] );
  const [ selectedDrapeBrand, setSelectedDrapeBrand ] = useState( null );
  const [ selectedDrapePrice, setSelectedDrapePrice ] = useState( null );
  const [ baseImgPath, setBaseImgPath ] = useState( "" );
  const [ furnitureImgPath, setFurnitureImgPath ] = useState( "" );
  const [ pointerImgPath, setPointerImgPath ] = useState( "" );
  const [ railImgPath, setRailImgPath ] = useState( "" );
  const [ laceImgPath, setLaceImgPath ] = useState( "" );
  const [ drapeImgPath, setDrapeImgPath ] = useState( "" );
  const [ roomBaseImageFile, setRoomBaseImageFile ] = useState( roomImageFile );
  const [ roomBaseImage, setRoomBaseImage ] = useState( null );
  const [ windowType, setWindowType ] = useState( "koshi" );
  const [ drapeCode, setDrapeCode ] = useState( null );
  const [ laceCode, setLaceCode ] = useState( null );
  const [ laceHidaType, setLaceHidaType ] = useState( "_15" );
  const [ drapeHidaType, setDrapeHidaType ] = useState( "_open" );
  
  // メンテナンスモード(0:メンテナンス中/1:通常)
  const maintenance = 1;
  // EC連携タイマー
  const EC_OPEN = new Date(2022, (2-1), 9 , 12 , 5);
  const OPENline = parseInt( EC_OPEN / 1000 );
  const dateCurrent = parseInt( new Date() / 1000 );
  //EC用URL
  // 本番環境
  const GET_EC_URL =  'https://www.i-koike-shop.net';
  // テスト環境
  // const GET_EC_URL =  'https://pre.i-koike-shop.net';
  

  const LaceCurtainStatusOptions = [
    { dispName:"1.5倍", value:"_15" },
    { dispName:"2.0倍", value:"_20" }
  ];
  const DrapeCurtainStatusOptions = [
    { dispName:"1.5倍", value:"_15" },
    { dispName:"2.0倍", value:"_20" },
    { dispName:"OPEN", value:"_open" }
  ];

  const [ saved , setSaved ] = useState(true);
  const CURTAIN_IMAGE_URL_BASE = "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/";
  let laceFuncOptions = [];
  laceFunctions.length &&
    laceFunctions.forEach( 
      ( func, index ) =>
      laceFuncOptions.push(
        { 
          id: func.name,
          name: func.disp_name,
          func_description: func.func_description,
        } 
      )
    );
  let drapeFuncOptions = [];
  drapeFunctions.length &&
    drapeFunctions.forEach( 
      ( func, index ) =>
      drapeFuncOptions.push(
        { 
          id: func.name,
          name: func.disp_name, 
          func_description: func.func_description,
        }
      )
    ); 

  useEffect( async () => {
    const result = await axios.get( "/api/functions", {
      params: {
        clothType: "lace",
      },
    });
    setLaceFunctions( result.data );
  }, [] );

  useEffect( async () => {
    const result = await axios.get("/api/functions", {
      params: {
        clothType: "drape_shade",
      },
    });
    setDrapeFunctions( result.data );
  }, [] );

  useEffect( () => {
    setSaved( false );
  }, [ baseImgPath, furnitureImgPath, railImgPath, drapeImgPath, pointerImgPath ]);
  useEffect(() => {
    if (!windowType || !laceCode || !laceHidaType) return;
    let imgName = 
      laceCode
      +
      '_'
      +
      (
        windowType === "hakidashi_small"?
        "1"
        :
        windowType === "hakidashi_big"?
        "2"
        :
        "3"
      ) 
      +
      laceHidaType
      +
      ".png";
    setLaceImgPath( CURTAIN_IMAGE_URL_BASE + "Laces/Laces/" + imgName );
  }, [ windowType, laceCode, laceHidaType ]);

  useEffect(() => {
    if ( !windowType || !drapeCode || !drapeHidaType ) return;
    let imgName = 
      drapeCode
      +
      '_'
      +
      (
        windowType === "hakidashi_small" ? 
        "1" 
        :
        windowType === "hakidashi_big"?
        "2"
        :
        "3"
      )
      +
      drapeHidaType
      +
      ".png";
    setDrapeImgPath( 
      CURTAIN_IMAGE_URL_BASE 
      +
      "DrapesShades/Drapes/"
      +
      imgName
    );
  }, [ windowType, drapeCode, drapeHidaType ]);

  // pointer
  const WINOW_POINTER = "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/";
  useEffect(() => {
    if ( !windowType ) return;
    let imgName2 = (
      windowType === "hakidashi_small"?
      "A"
      :
      windowType === "hakidashi_big"?
      "B"
      :
      "C"
    )
    +
    ".png";

    setPointerImgPath( 
      WINOW_POINTER
      +
      "Pointer/Curtain/"
      +
      imgName2
    );
  }, [ windowType ]);

  useEffect( () => {
    if ( !roomBaseImageFile ) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      setRoomBaseImage( reader.result );
    };
    if ( roomBaseImageFile ) {
      reader.readAsDataURL( roomBaseImageFile );
      setRoomBaseImage( reader.result );
    }
  }, []);

  let laceOptionsData = {};

  if ( laceFuncOptions.length ) {
    laceOptionsData = {
      functions: laceFuncOptions,
      brand: brandsData,
      price: pricesData,
    };
  }

  let laceSelectedSetters = {};

  laceSelectedSetters = {
    functions: setSelectedLaceFunctions,
    brand: setSelectedLaceBrand,
    price: setSelectedLacePrice,
    curtain: setLaceCode
  };

  let drapeOptionsData = {};

  if ( drapeFuncOptions.length ) {
    drapeOptionsData = {
      functions: drapeFuncOptions,
      brand: brandsData,
      price: pricesData,
      tastes: tastesData
    };
  }

  let drapeSelectedSetters = {};

  drapeSelectedSetters = {
    functions: setSelectedDrapeFunctions,
    tastes: setSelectedDrapeTastes,
    brand: setSelectedDrapeBrand,
    price: setSelectedDrapePrice,
    curtain: setDrapeCode
  };

  const cgImgPaths = {
    base: baseImgPath,
    furniture: furnitureImgPath,
    pointer:pointerImgPath,
    rail: railImgPath,
    drape: drapeImgPath,
  };

  const photoImgPaths = {
    rail: railImgPath,
    lace: laceImgPath,
    pointer:pointerImgPath,
    drape: drapeImgPath
  };
  const constructionImgPaths = {
    base: baseImgPath,
    rail: railImgPath,
    lace: laceImgPath,
    pointer:pointerImgPath,
    drape: drapeImgPath
  };

  const cgProducts = [
    { id:"img_base", src:baseImgPath, className:"RoomBase w-100" },
    { id:"img_funiture", src:furnitureImgPath, className:"Funiture w-100" },
    { id:"img_rale", src:railImgPath, className:"Rail w-100" },
    { id:"img_lace", src:laceImgPath, className:"Lace w-100" },
    { id:"img_drape", src:drapeImgPath, className:"Drape w-100" }
  ];

  const photoProducts = [
    { id:"img_rale", src:railImgPath, className:"Rail w-100" },
    { id:"img_pointer", src:pointerImgPath, className:"PointerBase w-100" },
    { id:"img_lace", src:laceImgPath, className:"Lace w-100" },
    { id:"img_drape", src:drapeImgPath, className:"Drape w-100" }
  ];
  const constructionProducts = [
    { id:"img_base", src:baseImgPath, className:"RoomBase w-100" },
    { id:"img_rale", src:railImgPath, className:"Rail w-100" },
    { id:"img_pointer", src:pointerImgPath, className:"PointerBase w-100" },
    { id:"img_lace", src:laceImgPath, className:"Lace w-100" },
    { id:"img_drape", src:drapeImgPath, className:"Drape w-100" }
  ];
  const imgPathSetters = {
    base: setBaseImgPath,
    furniture: setFurnitureImgPath,
    pointer: setPointerImgPath,
    rail: setRailImgPath,
    drape: setDrapeImgPath,
  };

  const onSave = () => {
    setSaved( true );
  }
  
  const onChangeWindowType = ( e ) => {
    setWindowType( e.target.value );
  }

  const onChangeLaceHidaType = ( e ) => {
    setLaceHidaType( e.target.value );
  }

  const onChangeDrapeHidaType = ( e ) => {
    setDrapeHidaType( e.target.value );
  }
  
  function downloadImage ( data ) {
		var fname ="image.png";
		var encdata= atob( data.replace(/^.*,/, '') );
		var outdata = new Uint8Array( encdata.length );
		for ( var i = 0; i < encdata.length; i++ ) {
			outdata[i] = encdata.charCodeAt( i );
		}
		var blob = new Blob([outdata], ["image/png"]);
		if ( window.navigator.msSaveBlob ) {
			window.navigator.msSaveOrOpenBlob( blob, fname );
		} else {
      document.getElementById("getImage").href = data;
			document.getElementById("getImage").download = fname;
			document.getElementById("getImage").click();
		}
  }
  const onClickSave = () => {
    document.querySelector( "#logo" ).style.display = "block";
    document.querySelector( "#date_time" ).style.display = "block";
    document.querySelector( "#products" ).style.display = "block";
    window.scrollTo(0,0);
    let canvasW = document.getElementById( "simulationArea" ).clientWidth;
    let canvasH = canvasW / 1.5;
    html2canvas(document.querySelector( "#simulationArea" ), {
        scrollX:-7,
        width: canvasW,
        height: canvasH,
        allowTaint : false,
        useCORS: true
    }).then(canvas => { 
        downloadImage( canvas.toDataURL() );
    });
  }
  const onClickAlert_drape = () => {
    alert("厚地を選択してください。")
  }
  const onClickAlert_lace = () => {
    alert("レースを選択してください。")
  }
  const onClickAlert_DL = () => {
    if( laceCode === null && drapeCode === null ){
      alert("ドレープ・レースを選択してください。")
    }else if (laceCode === null ){
      alert("レースを選択してください。")
    }else if (drapeCode === null ){
      alert("厚地を選択してください。")
    }
  }
  
  const [ dropClickRoom, setDropClickRoom ] = useState(0);
  const [ dropClickRail, setDropClickRail ] = useState(0);
  const [ dropClickDOC, setDropClickDOC ] = useState(0);
  const [ dropClickLOC, setDropClickLOC ] = useState(0);
  const [ dropClickConstruction, setDropClickConstruction ] = useState(0);
  const [ dropClickConstWindow, setDropClickConstWindow ] = useState(0);
  const [ dropClickWindow, setDropClickWindow ] = useState(0);

  
  const onClickDropRoom = () =>{
    setDropClickRoom( dropClickRoom + 1 )
  }
  const onClickDropRail = () =>{
    setDropClickRail( dropClickRail + 1 )
  }
  const onClickDropDOC = () =>{
    setDropClickDOC( dropClickDOC + 1 )
  }
  const onClickDropLOC = () =>{
    setDropClickLOC( dropClickLOC + 1 )
  }
  const onClickDropConstruction = () =>{
    setDropClickConstruction( dropClickConstruction + 1 )
  }
  const onClickDropConstWindow = () =>{
    setDropClickConstWindow( dropClickConstWindow + 1 )
  }
  const onClickDropWindow = () =>{
    setDropClickWindow( dropClickWindow + 1 )
  }

  const simulation = {
    height: "0px",
    width: "100%",
  };
  const bgstyle = {
    backgroundColor:"rgba(62,58,57,0.1)",
  };
  const ecbgstyle = {
    backgroundColor:"#046B5E",
  };

  return (
    <ImageProvider>
      <div className="CGSeletCurtain">
        <div className="container">
          <div className="row">
            {
            baseFrom === "cg"?
            (
              <div 
                className = "CGspace col-12 col-md-8 px-0"
                style = { simulation }
              >
                <SimulationImage
                  baseFrom = { baseFrom }
                  productsImage = { cgProducts }
                  productsCode = {
                    [ 
                      { name:"lace", code:laceCode },
                      { name:"drape", code:drapeCode }
                    ] 
                  }
                  onSaveHandler = { onSave }
                />
              </div>
            )
            : baseFrom === "photo"?
            (
              <SimulationImage
                baseFrom = { baseFrom }
                productsImage = { photoProducts } 
                productsCode = {
                  [ 
                    { name:"lace", code:laceCode },
                    { name:"drape", code:drapeCode }
                  ] 
                }
                roomBaseImage = { roomBaseImage }
                onSaveHandler={ onSave }
              />
              )
            :(
              <SimulationImage
                baseFrom = { baseFrom }
                productsImage = {constructionProducts}
                productsCode = { 
                  [ 
                    { name:"lace", code:laceCode },
                    { name:"drape", code:drapeCode } 
                  ] 
                }
                roomBaseImage = { baseImgPath }
                onSaveHandler = { onSave }
              />
            )
            }
            <div className="Choices col-12 col-md-4"
            style={ 
              ( window.innerWidth >= 767 )?
              {}
              :
              (
                baseFrom==="cg"?
                {
                  top:( window.innerWidth / 1.5 ),
                  scrollY:( window.innerWidth / 1.5 )
                }
                :
                {
                  top:( (window.innerWidth / 1.5)+25 )
                }
              )
            }
            >
              <div className="CGCurtain text-center bg-dark text-white py-1">
                { 
                  baseFrom === "cg"?
                  "背景:CG  カーテン"
                  :
                  baseFrom === "photo"?
                  "背景:写真  カーテン"
                  :
                  "背景:施工例写真  カーテン"
                }
              </div>
              { 
              baseFrom === "cg"?
              (
                <div style={ bgstyle }>
                  <div className="box_Room">
                    <label 
                      htmlFor="inbox_Room"
                      className="border border-dark text-center my-0"
                      onClick = { onClickDropRoom }
                      style={
                        dropClickRoom === 0?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        ((dropClickRoom % 2) == 0)?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        {
                          backgroundColor:"white"
                        }
                      }
                    >
                      {
                        dropClickRoom === 0?
                        <div className="text-white container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                              部屋(シミュレーション背景)
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg 
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-caret-down-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                        :
                        ((dropClickRoom % 2) == 0)?
                        <div className="text-white container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                              部屋(シミュレーション背景)
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg 
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-caret-down-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                        :
                        <div className="container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                              部屋(シミュレーション背景)
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-caret-up-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                      }
                    </label>
                    <input
                      type="checkbox"
                      id="inbox_Room"
                      className="on-off_Room"
                    />
                    <div 
                      className="pb-3"
                      style={ 
                        {
                          overflowY:"scroll",
                          height:"260px"
                        }
                      }
                    >
                      <RoomArranger
                        imagePathSetters = { imgPathSetters }
                      />
                      <WindowArranger
                        imagePathSetters = { { "base":setBaseImgPath } }
                        onWindowTypeChangeHandler = { onChangeWindowType }
                      />
                    </div>
                  </div>
                </div>
              )
              :
              baseFrom === "photo"?
              ( 
                <div className="box_Window">
                  <label 
                    htmlFor="inbox_Window"
                    className="border border-dark text-center my-0"
                    onClick = { onClickDropWindow }
                    style={
                      dropClickWindow === 0?
                      {
                        backgroundColor:"#6c757d"
                      }
                      :
                      ((dropClickWindow % 2) == 0)?
                      {
                        backgroundColor:"#6c757d"
                      }
                      :
                      {
                        backgroundColor:"white"
                      }
                    }
                  >
                    {
                      dropClickWindow === 0?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓サイズ
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg 
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      ((dropClickWindow % 2) == 0)?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100  text-left">
                            窓サイズ
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓サイズ
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-up-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    }
                  </label>
                  <input
                    type="checkbox"
                    id="inbox_Window"
                    className="on-off_Window"
                  />
                  <div>
                  <WindowArranger
                    hideFlags = { 
                      { 
                        floor:baseFrom === "photo",
                        wall:baseFrom === "photo"
                      }
                    }
                    onWindowTypeChangeHandler = { onChangeWindowType }
                  />
                  </div>
                </div>
              )
              :
              (
                <div style={ bgstyle }>
                  <div className="box_Construction">
                    <label 
                      htmlFor="inbox_Construction"
                      className="border border-dark text-center my-0"
                      onClick = { onClickDropConstruction }
                      style={
                        dropClickConstruction === 0?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        ((dropClickConstruction % 2) == 0)?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        {
                          backgroundColor:"white"
                        }
                      }
                    >
                    {
                      dropClickConstruction === 0?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            サンプル写真
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      ((dropClickConstruction % 2) == 0)?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            サンプル写真
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            サンプル写真
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-up-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    }
                    </label>
                    <input
                      type="checkbox"
                      id="inbox_Construction"
                      className="on-off_Construction"
                    />
                    <div
                      className="pb-3"
                      style={
                        {
                          overflowY:"scroll",
                          height:"260px"
                        }
                      }
                    >
                      <ConstructionArranger
                        imagePathSetters = { { "base":setBaseImgPath } }
                      />
                    </div>
                  </div>
                  <div className="box_ConstWindow">
                    <label 
                      htmlFor="inbox_ConstWindow"
                      className="border border-dark text-center my-0"
                      onClick = { onClickDropConstWindow }
                      style={
                        dropClickConstWindow === 0?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        ((dropClickConstWindow % 2) == 0)?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        {
                          backgroundColor:"white"
                        }
                      }
                    >
                    {
                      dropClickConstWindow === 0?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓形状
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-down-fill" 
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      ((dropClickConstWindow % 2) == 0)?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓形状
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓形状
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-caret-up-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    }
                    </label>
                    <input
                      type="checkbox"
                      id="inbox_ConstWindow"
                      className="on-off_ConstWindow"
                    />
                    <div
                      className="pb-3"
                      style={
                        {
                          overflowY:"scroll",
                          height:"260px"
                        }
                      }
                    >
                      <WindowArranger
                        hideFlags = {
                          { 
                            floor:baseFrom === "construction",
                            wall: baseFrom === "construction"
                          }
                        }
                        onWindowTypeChangeHandler = { onChangeWindowType }
                      />
                    </div>
                  </div>
                </div>
              )
              }
              <div className="box_Rail">
                <label 
                  htmlFor="inbox_Rail"
                  className="border border-dark text-center my-0"
                  onClick = { onClickDropRail }
                  style={
                    dropClickRail === 0?
                    {
                      backgroundColor:"#6c757d"
                    }
                    :
                    ((dropClickRail % 2) == 0)?
                    {
                      backgroundColor:"#6c757d"
                    }
                    :
                    {
                      backgroundColor:"white"
                    }
                  }
                >
                  {
                  dropClickRail === 0?
                  <div className="text-white container py-2">
                    <div className="row">
                      <div className="col-10 w-100 text-left">
                        レール
                      </div>
                      <div className="col-2 w-100 text-center">
                      <svg 
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-caret-down-fill" 
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                      </svg>
                      </div>
                    </div>
                  </div>
                  :
                  ((dropClickRail % 2) == 0)?
                  <div className="text-white container py-2">
                     <div className="row">
                      <div className="col-10 w-100 text-left">
                        レール
                      </div>
                      <div className="col-2 w-100 text-center">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                      </svg>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="container py-2">
                      <div className="row">
                        <div className="col-10 w-100 text-left">
                          レール
                        </div>
                        <div className="col-2 w-100 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-caret-up-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>
                        </div>
                      </div>
                  </div>
                  }
                </label>
                <input
                  type="checkbox"
                  id="inbox_Rail"
                  className="on-off_Rail"
                />
                <div
                  className="pb-3"
                  style={
                    {
                      overflowY:"scroll",
                      height:"260px"
                    }
                  }
                  id = "drape"
                >
                  <RailArranger
                    imagePathSetters = { { "rail":setRailImgPath } }
                  />
                </div>
              </div>
              {
                Object.keys( drapeOptionsData ).length
                &&
                (
                  <DrapeCurtain
                    optionsData = { drapeOptionsData }
                    selectedSetters = { drapeSelectedSetters }
                    roomBaseImage = { roomBaseImage }
                    baseImgPath = { baseImgPath }
                    baseFrom = { baseFrom }
                  />
                )
              }
              <div className="box_DOC">
                <label 
                  htmlFor="inbox_DOC"
                  className="border border-secondary text-center my-0"
                  onClick = { onClickDropDOC }
                  style={
                    dropClickDOC === 0?
                    {
                      backgroundColor:"#cce9dd"
                    }
                    :
                    ((dropClickDOC % 2) == 0)?
                    {
                      backgroundColor:"#cce9dd"
                    }
                    :
                    {
                      backgroundColor:"white"
                    }
                  }
                >
                {
                  dropClickDOC === 0?
                  <div className="text-dark container py-2">
                    <div className="row">
                      <div className="col-10 w-100 text-left">
                        厚地
                        &emsp;
                        閉
                        <span>
                          <small>
                            じる
                          </small>
                        </span>
                        (1.5倍・2.0倍)/開
                        <span>
                          <small>
                            ける
                          </small>
                        </span>
                      </div>
                      <div className="col-2 w-100 text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-caret-down-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                  :
                  ((dropClickDOC % 2) == 0)?
                  <div className="text-dark container py-2">
                    <div className="row">
                      <div className="col-10 w-100 text-left">
                        厚地
                        &emsp;
                        閉
                        <span>
                          <small>
                            じる
                          </small>
                        </span>
                        (1.5倍・2.0倍)/開
                        <span>
                          <small>
                            ける
                          </small>
                        </span>
                      </div>
                      <div className="col-2 w-100 text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-caret-down-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="container py-2">
                    <div className="row">
                      <div className="col-10 w-100 text-left">
                        厚地
                        &emsp;
                        閉
                        <span>
                          <small>
                            じる
                          </small>
                        </span>
                        (1.5倍・2.0倍)/開
                        <span>
                          <small>
                            ける
                          </small>
                        </span>
                      </div>
                      <div className="col-2 w-100 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-caret-up-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                }
                </label>
                <input
                  type="checkbox"
                  id="inbox_DOC"
                  className="on-off_DOC"
                />
                <div>
                  <CustomToggleButtons
                    title = "カーテン状態(ドレープ)"
                    name = "drapeHidaType"
                    options = { DrapeCurtainStatusOptions }
                    initValue = "_open"
                    onChangeHandler = { onChangeDrapeHidaType }
                  />
                </div>
              </div>
              {
                Object.keys( laceOptionsData ).length
                && 
                (
                  <LaceCurtain
                    optionsData = { laceOptionsData }
                    selectedSetters = { laceSelectedSetters }
                  />
                )
              }
              <div className="box_LOC">
                <label 
                  htmlFor="inbox_LOC"
                  className="border border-secondary text-center my-0"
                  onClick = { onClickDropLOC }
                  style={
                    dropClickLOC === 0?
                    {
                      backgroundColor:"#B7C581"
                    }
                    :
                    ((dropClickLOC % 2) == 0)?
                    {
                      backgroundColor:"#B7C581"
                    }
                    :
                    {
                      backgroundColor:"white"
                    }
                  }
                >
                {
                  dropClickLOC === 0?
                  <div className="text-dark container py-2">
                    <div className="row">
                      <div className="col-10 w-100 text-left">
                        レース  1.5倍/2.0倍
                      </div>
                      <div className="col-2 w-100 text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-caret-down-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                  :
                  ((dropClickLOC % 2) == 0)?
                  <div className="text-dark container py-2">
                    <div className="row">
                      <div className="col-10 w-100 text-left">
                        レース  1.5倍/2.0倍
                      </div>
                      <div className="col-2 w-100 text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-caret-down-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="container py-2">
                    <div className="row">
                      <div className="col-10 w-100 text-left">
                        レース  1.5倍/2.0倍
                      </div>
                      <div className="col-2 w-100 text-center">
                        <svg 
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-caret-up-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                }
                </label>
                <input
                  type="checkbox"
                  id="inbox_LOC"
                  className="on-off_LOC"
                />
                <div>
                  <CustomToggleButtons
                    title = "カーテン状態(レース)"
                    name = "laceHidaType"
                    options = { LaceCurtainStatusOptions }
                    initValue = "_15"
                    onChangeHandler = { onChangeLaceHidaType }
                  />
                </div>
              </div>
              <div
                className="container mt-0 py-1 border border-dark"
                style={ bgstyle }
              >
                <div className="row">
                  <div className="col-6">
                    <button 
                      id = "btnSave"
                      className = "w-100 btn btn-dark"
                      onClick = { onClickSave }
                    >
                      保 存
                    </button>
                    <a 
                      id="getImage" 
                      href=""
                      style={ { display:"none" } }
                      download="image.png"
                    >
                      画像保存
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      href="https://www.i-koike.jp/contact/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        id=""
                        className="w-100 btn btn-info"
                      >
                        来店予約
                      </button>
                    </a>
                  </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </ImageProvider>
  );
})


import React from 'react';

function NotFoundPage(){
  const handleBackClick = () => {
    window.location="/"
  };
  const handleBackClicklight = () => {
    window.location="/light/"
  };
  return(
    <div className="container NotFound">
      <div className="row">
        <div className="col-12 text-center my-5">
          <h1>
            NotFound
          </h1>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-12">
          申し訳ありません。アクセスしたページは見つかりませんでした。
        </div>
        <div className="col-12">
          The requested URL was not found on this server.
        </div>
        <div className="col-12">
          入力したアドレスが間違っているか、
        </div>
        <div className="col-12">
          ページが移動もしくは削除されているかもしれません。
        </div>
      </div>
      <div className="row justify-content-around">
        <div 
          className="col-5 btn btn-success border border-dark"
          onClick={ handleBackClick }
        >
          カリコTOPに戻る
        </div>
        <div 
          className="col-5 btn btn-info border border-dark"
          onClick={ handleBackClicklight }
        >
          カリコLightTOPに戻る
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage;

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Header from './components/Header';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";


ReactDOM.render(
    <BrowserRouter>
        <Header />
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);

reportWebVitals();

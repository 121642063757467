import React from 'react';
import { Link } from 'react-router-dom';
import Cg from '../img/HP/top_cg_img@2x.jpg';
import Photo from '../img/HP/top_photo_img@2x.jpg';
import Construction from '../img/HP/top_sekorei.jpg';
import PhotoIcon from '../img/HP/picture_icon.png';
import CGIcon from'../img/HP/cg_icon.png';
import CurtainIcon from '../img/HP/1_item_icon01.png';
import Shade from '../img/HP/1_item_icon02.png';
import RollScrean from'../img/HP/1_item_icon03.png';
import WallPaper from'../img/HP/1_item_icon04.png';

const style3 = {
  backgroundColor:"#B7C581",
};
// EC連携タイマー
const EC_OPEN = new Date(2022, (2-1), 9 , 12 , 5);
const OPENline = parseInt( EC_OPEN / 1000 );
const dateCurrent = parseInt( new Date() / 1000 );

const style4 = {
  backgroundColor:"#046B5E",
  color:"ffffff",
  padding:"5px"
};
const Part = [
  { 
    name:"photo",
    mainImg:Photo,
    title:"お客様の写真を背景",
    titleImg:PhotoIcon,
    // description:"",
    Icon1:CurtainIcon,
    Icon2:Shade,
    Icon3:RollScrean,
    Icon4:WallPaper 
  },
  { 
    name:"cg",
    mainImg:Cg,
    title:"色を合わせたCGを背景",
    titleImg:CGIcon,
    // description:"",
    Icon1:CurtainIcon,
    Icon2:Shade,
    Icon3:RollScrean,
    Icon4:WallPaper
  },
  { 
    name:"construction",
    mainImg:Construction,
    title:"部屋のサンプル写真を背景",
    titleImg:CGIcon,
    description:"",
    Icon1:CurtainIcon,
    Icon2:Shade,
    Icon3:RollScrean,
    Icon4:WallPaper
  },
];

const Item = [
  { Img:CurtainIcon, name:"ｶｰﾃﾝ", Ename:"curtain", EC:"1" },
  { Img:Shade,name:"ﾛｰﾏﾝｼｪｰﾄﾞ", Ename:"romanShade", EC:"1" },
  { Img:RollScrean, name:"ﾛｰﾙｽｸﾘｰﾝ", Ename:"rollScreen", EC:"0" },
  { Img:WallPaper, name:"ｱｸｾﾝﾄ壁紙", Ename:"wallPaper", EC:"0" }
]


function Menu() {
  return(
    <div className="container my-2">
      <div className="row justify-content-center">
      { Part.map( ( part, index ) => (
        <div
          className="col-md-4 col-12 px-0"
          key={ index }
          style={
            { 
              border: "solid",
              borderColor:"#046B5E"
            } 
          }
        >
          <div className="container px-0">
            <div className="row justify-content-center px-0 mx-0">
              <div className="col-12 px-0 mx-0">
                <img
                  src={ part.mainImg }
                  alt={ part.mainImg }
                  className="w-100"
                />
              </div>
            </div>
            <div className="container px-0 mx-0">
              <div 
                className="row justify-content-center py-2 mx-0 px-0"
                style={ style3 }
              >
                <div className="col-12 text-center text-white font-weight-bold px-0 mx-0">
                  <img
                    src={ part.titleImg }
                    alt={ part.titleImg }
                    style={ { maxHeight:"40px" } }
                  />
                  { part.title }
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                { Item.map( ( item, index ) => (
                    <Link 
                      className="col-3 text-center bg-light py-2 px-0"
                      key={ index }
                      to=
                      {
                        ( part.name === "cg" )?
                        ( "/simulator/" + item.Ename + "/" + part.name )
                        :
                        ( part.name === "photo" )?
                        ( "/imageSelector/" + item.Ename + "/" + part.name + "/simulator/root" )
                        :
                        ( "/simulator/" + item.Ename + "/" + part.name )
                      }
                    >
                      <div style={ { position:"relative" } }>
                        <img 
                          src={ item.Img }
                          alt={ item.Img }
                        />
                        {
                          ( ( part.name + item.name ) === "photoｱｸｾﾝﾄ壁紙" )?
                          <div
                          className="text-danger"
                          style={
                            { 
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              margin: 0,
                              padding: 0,
                              transform:"translate(-50%,-50%)",
                            }
                            }
                          >
                            <small className="font-weight-bold">
                              <nobr>
                                工事中
                              </nobr>
                            </small>
                          </div>
                          :
                          ( ( part.name + item.name ) === "constructionｱｸｾﾝﾄ壁紙" )?
                          <div
                            className="text-danger"
                            style={
                              { 
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                margin: 0,
                                padding: 0,
                                transform:"translate(-50%,-50%)",
                              }
                            }
                          >
                            <small className="font-weight-bold">
                              <nobr>
                                工事中
                              </nobr>
                            </small>
                          </div>
                          :
                          <>
                          </>
                        }
                      </div>
                      <div>
                        <small className="text-dark">
                          { item.name }
                        </small>
                      </div>
                    </Link>
                ))}
                {/* 220207EC連携_追加 */}
                { Item.map( ( item, index ) => (
                    <small className="text-white col-3 text-center py-2">
                      { 
                        item.EC === "0"?
                        (
                        <small></small>
                        )
                        :
                        (
                        <div
                          style = {
                          OPENline >= dateCurrent?
                          {display:"none"}
                          :
                          {display:"block"}
                          }
                        >
                        <small
                          className="font-weight-bold"
                          style={ style4 }
                        >
                          ｵﾝﾗｲﾝ販売
                        </small>
                        </div>
                        )
                      }
                    </small>
                ))}
                {/*  */}
              </div>
            </div>
          </div>
        </div>
      ))}
      </div>
    </div>
  );
}
export default Menu;



import React, { useEffect, useState, useRef } from "react";
import CgResponse from "./CgResponse";
import html2canvas from "html2canvas";
import Modal from 'react-modal';
import Img1 from "../img/HP/logo_2-1.gif"

function LightCgSimulator({
  selectedRoomInfo,
  selectedRailInfo,
  selectedCurtainType,
  receivedNewFunctions,
  selectedData,
  selectedPriceRange,
  selectedColors,
  initialDisplayed,
  CurtainType,
  roomType,
  furnitureNumber,
  PriceRange,
  apiColor,
  FunctionPatterns,
  RefinementResult,
  currentSelectedCurtainType,
  PriceRangeNarrowdown,
  currentSelectedRoomType,
  currentSelectedFurnitureNumber,
  onSelectCode,
  finalroomType,
  finalfurnitureNumber,
  finalCurtainType,
  finalRailColor,
  finalFunctionPatterns,
  finalPriceRange,
  finalColor,
}){
  const CgBaseImgUrl = "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/Simulation/Base/baseB_2_1.png";
  const getRoomImageUrl =
    ( finalroomType && finalfurnitureNumber ) ?
      `https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/Simulation/Funiture/${ finalroomType }_${ finalfurnitureNumber }.png`
    :
      `https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/Simulation/Funiture/${ selectedRoomInfo?.roomType }_${ selectedRoomInfo?.furniture }.png`;
  const getRailImageUrl =
    selectedRailInfo && ( finalRailColor === null ) ?
      `https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/Simulation/Rail/func_${ selectedRailInfo.railColours }_1.png`
    :
      `https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/Simulation/Rail/func_${ finalRailColor }_1.png`;
  
    const getCurtainTypePath = () => {
      if( finalCurtainType ){
        if ( finalCurtainType === "drape" ) {
          return "DrapesShades/Drapes";
        } else if ( finalCurtainType === "lace" ) {
          return "Laces/Laces";
        } else if ( finalCurtainType === "roll" ) {
          return "RollScreens/Rolls";
        }
      }else if( selectedCurtainType ){
        if( selectedCurtainType !== null ){
          if ( selectedCurtainType === "drape" ) {
            return "DrapesShades/Drapes";
          } else if ( selectedCurtainType === "lace" ) {
            return "Laces/Laces";
          } else if ( selectedCurtainType === "roll" ) {
            return "RollScreens/Rolls";
          }
        }else{
          return;
        }
      }
      return "";
    };
    const getImageSuffix = () => {
      if( finalCurtainType ){
        if ( finalCurtainType === "drape" ) {
          return "open";
        } else if ( finalCurtainType === "lace" ) {
          return "15";
        } else if ( finalCurtainType === "roll" ) {
          return "roll";
        }
      }else if( selectedCurtainType ){
        if( selectedCurtainType !== null ){
          if ( selectedCurtainType === "drape" ) {
            return "open";
          } else if ( selectedCurtainType === "lace" ) {
            return "15";
          } else if ( selectedCurtainType === "roll" ) {
            return "roll";
          }
        }else{
          return;
        }
      }
      return "";
    };
    const [ selectedCode, setSelectedCode ] = useState( null );
    const handleSelectedCode = ( code ) => {
      setSelectedCode( code );
      onSelectCode( code );
    };
    const imageUrl=
      currentSelectedCurtainType !== "lace" ?
        `https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/${ getCurtainTypePath( currentSelectedCurtainType )}/${ selectedCode }_1_${ getImageSuffix( currentSelectedCurtainType ) }.png`
      :
        `https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/${ getCurtainTypePath( currentSelectedCurtainType )}/${ selectedCode }_1_${ getImageSuffix( currentSelectedCurtainType ) }.png`;
    
    const simulatSaveAreaRef = useRef(null);
    const openModal = () => {
      setIsModalOpen( true );
    };
    const closeModal = () => {
      setIsModalOpen( false );
    };
    const [ imageSaved, setImageSaved ] = React.useState( false );
    useEffect(() => {
      if ( imageSaved ) {
        openModal();
      }
    }, [ imageSaved ]);
    const handleSaveButtonClick = async() => {
      let canvasW = document.getElementById( "SimulatSaveArea" ).clientWidth;
      let canvasH = document.getElementById( "SimulatSaveArea" ).clientHeight;
      if ( simulatSaveAreaRef.current ) {
        document.querySelector( "#logo" ).style.display = "block";
        document.querySelector( "#date_time" ).style.display = "block";
        document.querySelector( "#products" ).style.display = "block";
        openModal();
        html2canvas( simulatSaveAreaRef.current, {
          useCORS: true,
          allowTaint: false,
          scrollX: 0,
          scrollY: -window.scrollY,
          width: canvasW,
          height: canvasH
        }).then(( canvas ) => {
          const image = canvas.toDataURL( "image/png" ).replace( "image/png", "image/octet-stream" );
          const link = document.createElement( "a" );
          link.href = image;
          link.download = "screenshot.png";
          link.click();
        });
        document.querySelector( "#logo" ).style.display = "none";
        document.querySelector( "#date_time" ).style.display = "none";
        document.querySelector( "#products" ).style.display = "none";
      }
    };

    const DownloadModal = ( { isOpen, closeModal, setImageSaved, selectedCode } ) => {
      return (
        <Modal
          isOpen={ isOpen }
          onRequestClose={ closeModal }
          style={ {
            overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' },
            content: { width: '350px', height: '450px', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', padding:"0px" },
          } }
        >
          <div
            className="container"
            style={ { height:"450px" } }
          >
            <div
              className="row d-flex align-items-center justify-content-center"
              style={ { height:"100px" } }
            >
              <div
                className="col-12 text-center fw-bold"
                style={ { color: "#046B5E"} }
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-download"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                  </svg>
                </div>
                画像を保存しました
              </div>
            </div>
            <div
              className="row justify-content-around mx-3 px-0"
              style={ { height: "290px" } }
            >
              <div
                className="col-12 btn my-3 mx-0 px-0"
                style={ { backgroundColor: "#EEF5F1", borderColor: "#046B5E", color: "#046B5E", fontSize: "10pt", maxWidth:"300px" } }
                onClick={ () => { closeModal(); setImageSaved( false ); } }
              >
                同じ窓で次のカーテンを選ぶ
              </div>
              <div
                className="col-12 btn my-3 mx-0 px-0"
                style={ { backgroundColor: "#EEF5F1", borderColor: "#046B5E", color: "#046B5E", fontSize: "10pt", maxWidth:"300px" } }
              >
                <a href="/light/cg" style={{color: "#046B5E", fontSize: "10pt", textDecoration:"none"}}>
                  新しい窓で次のカーテンを選ぶ
                </a>
              </div>
              <div
                className="col-12 btn my-3 mx-0 px-0"
                style={ { backgroundColor: "#EEF5F1", borderColor: "#046B5E", color: "#046B5E", fontSize: "10pt", maxWidth:"300px" } }
              >
                <a
                  href="https://www.i-koike.jp/contact/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: "#046B5E", fontSize: "10pt", textDecoration:"none"}}
                >
                  来店予約
                </a>
              </div>
{/*
              <div
                className="col-12 btn my-3 mx-0 px-0"
                style={ { backgroundColor: "#EEF5F1", borderColor: "#046B5E", color: "#046B5E", fontSize: "10pt", maxWidth:"300px" } }
              >
                <div className="text-center">
                  <a
                    href={ "https://www.i-koike-shop.net/products/" + selectedCode }
                    target="_blank"
                    rel="noopener noreferrer"
                    tabIndex={-1}
                    style={{color: "#046B5E", fontSize: "10pt", textDecoration:"none"}}
                  >
                    ご購入はこちら
                  </a>
                </div>
              </div>
*/}
            </div>
            <div
              className="row"
              style={ { height:"10px" } }
            >
              <hr/>
            </div>
            <div
              className="row d-flex align-items-center"
              style={ { height:"50px" } }
            >
              <div
                className="col-12 btn text-center"
                onClick={ () => { closeModal(); setImageSaved( false ); } }
                style={ { color: "#046B5E", border: "none"} }
              >
                閉じる
              </div>
            </div>
          </div>
        </Modal>
      );
    };
    const [ isModalOpen, setIsModalOpen ] = useState( false );
    
  return(
    <div className="CgSimulator container">
      <div className="row justify-content-center">
        <div className="CG col-md-12 col-12 text-center">
          <>
            <div
              className="SimulatSaveArea"
              style={{ position: "relative" }}
              ref={ simulatSaveAreaRef }
              id="SimulatSaveArea"
            >
              <img
                src={ `${ CgBaseImgUrl }?timestamp=${ new Date().toLocaleString() }`}
                alt={ CgBaseImgUrl }
                className="CgBaseImg px-0 mx-0 w-100"
                crossOrigin="anonymous" 
              />
              {
                finalCurtainType ?
                  finalCurtainType !== "roll"?
                    <>
                      <img
                        src={ `${ getRailImageUrl }?timestamp=${ new Date().toLocaleString() }`}
                        alt={ getRailImageUrl }
                        className="rail-image w-100"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0
                        }}
                        crossOrigin="anonymous" 
                      />
                    </>
                  :
                    <></>
                :
                  selectedCurtainType !== "roll" ?
                    <>
                      <img
                        src={ `${ getRailImageUrl }?timestamp=${ new Date().toLocaleString() }`}
                        alt={ getRailImageUrl }
                        className="rail-image w-100 border"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0
                        }}
                        crossOrigin="anonymous" 
                      />
                    </>
                  :
                    <></>
              }
              <img
                src={ `${ imageUrl }?timestamp=${ new Date().toLocaleString() }`}
                alt="商品を選択してください"
                className="curtain-image w-100"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  fontSize:"6px",
                  color:"red"
                }}
                crossOrigin="anonymous" 
              />
              <img
                src={ `${ getRoomImageUrl }?timestamp=${ new Date().toLocaleString() }`}
                alt={ getRoomImageUrl }
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
                className="RoomImage px-0 mx-0 w-100"
                crossOrigin="anonymous" 
              />
              <div
                className="container"
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0
                }}
              >
                <div className="row d-flex align-items-center">
                  <div
                      id = "logo"
                      className = "col-4 px-0"
                      style = {
                        {
                          display:"none"
                        }
                      }
                    >
                      <img 
                        src = { Img1 }
                        alt = { Img1 }
                        style = {
                          {
                            height:"auto",
                          } 
                        } 
                        className="w-100"
                      />
                  </div>
                  <div
                    className="col-4 px-0"
                    id="date_time"
                    style = {
                      {
                        fontSize:"9px",
                        color:"#EEF5F1",
                        display:"none"
                      }
                    }
                  >
                    { new Date().toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }) }
                  </div>
                  <div
                    className="col-4"
                    id="products"
                    style = {
                      {
                        fontSize:"9px",
                        color:"#EEF5F1",
                        display:"none"
                      }
                    }
                  >
                    {
                      finalCurtainType?
                        finalCurtainType
                      :
                        selectedCurtainType
                    }
                    {
                      selectedCode?
                        <>
                          :{ selectedCode }
                        </>
                      :
                        <>:-</>
                    }
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
      <div className="row justify-content-end my-3">
        <div className="save col-md-4 col-4 text-center">
          <div
            className="px-0 mx-0 w-100 btn btn-dark justify-content-center d-flex align-items-center"
            style={{
              color:"#046B5E",
              backgroundColor:"#EEF5F1",
              borderColor: "#046B5E",
            }}
            onClick={ handleSaveButtonClick }
          >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-download"
            viewBox="0 0 16 16"
          >
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
          </svg>
            <p
              className="m-0 p-0"
              style={{ fontSize: "10pt" }}
            >
              &nbsp;
              保存
            </p>
          </div>
        </div>
      </div>
      <div className="Reservation">
        <a
          href="https://www.i-koike.jp/contact/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#046B5E",
            textDecoration:"none"
          }}
        >
          <div 
            className="col-12 btn text-center mt-3 mb-4" 
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: "#046B5E",
              borderColor: "#046B5E",
              color: "#ffffff",
              fontSize: "10pt"
            }}
          >
            <p
              className="w-100 text-center m-0"
              style={{ alignItems: 'center' }}
            >
              来店予約
            </p>
          </div>
        </a>
      </div>
      <div className="row">
        <div className="col-12">
          <CgResponse
            selectedCurtainType={ selectedCurtainType }
            receivedNewFunctions={ receivedNewFunctions }
            selectedRoomInfo={ selectedRoomInfo }
            selectedRailInfo={ selectedRailInfo }
            selectedData={ selectedData }
            selectedPriceRange={ selectedPriceRange }
            selectedColors={ selectedColors }
            // 
            CurtainType={ CurtainType }
            roomType={ roomType }
            furnitureNumber={ furnitureNumber }
            PriceRange={ PriceRange }
            apiColor={ apiColor }
            RefinementResult={ RefinementResult }
            currentSelectedCurtainType={ currentSelectedCurtainType }
            PriceRangeNarrowdown={ PriceRangeNarrowdown }
            currentSelectedRoomType={ currentSelectedRoomType }
            currentSelectedFurnitureNumber={ currentSelectedFurnitureNumber }
            onSelectCode={ handleSelectedCode }
            finalCurtainType={ finalCurtainType }
            finalRailColor={ finalRailColor }
            finalFunctionPatterns={ finalFunctionPatterns }
            finalPriceRange={ finalPriceRange }
            finalColor={ finalColor }
            finalroomType={ finalroomType }
            finalfurnitureNumber={ finalfurnitureNumber }
          />
        </div>
      </div>
      {isModalOpen && (
        <DownloadModal
          isOpen={ isModalOpen }
          closeModal={ closeModal }
          setImageSaved={ setImageSaved }
          selectedCode={ selectedCode }
        />
      )}
    </div>
  )
}

export default LightCgSimulator;


import React, { useState, useRef, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { CurtainSimulator } from '../Simulator/CurtainSimulator';
import { ShadeSimulator } from '../Simulator/ShadeSimulator';
import { RollSimulator } from '../Simulator/RollSimulator';
import { WallPaperSimulator } from '../Simulator/WallPaperSimulator';

const style = {

};

function Simulator(props) {

  const { productType, baseFrom } = useParams();

  const roomImageFile = baseFrom === "photo" ? props.location.state.roomImageFile : null;

  const [pricesData, setPricesData] = useState({ hits: [] });

  const [brandsData, setBrandsData] = useState({ hits: [] });

	const [tastesData, setTastesData] = useState({ hits: [] });

  let prices = [];

  let brands = [];

  let tastes = [];

  useEffect(async () => {
    const result = await axios.get("/api/brands");
    setBrandsData(result.data);
  }, []); 
  
  brandsData.length && brandsData.forEach((brand, index) =>
    brands.push({id: brand.name, name: brand.disp_name}));

  useEffect(async () => {
    const result = await axios.get("/api/prices");
    setPricesData(result.data);
  }, []); 
  
  pricesData.length && pricesData.forEach((price, index) =>
    prices.push({id: price.section, name: price.disp_value}));
  
  useEffect(async () => {
    const result = await axios.get("/api/tastes");
    setTastesData(result.data);
  }, []); 
  
  tastesData.length && tastesData.forEach( (taste, index) =>
    tastes.push(
      { id: taste.name, dispName: taste.disp_name, value:taste.name,
        image: process.env.PUBLIC_URL + "/img/Taste/" + taste.image,
        image2: process.env.PUBLIC_URL + "/img/Taste/" + taste.image2,
        image3: process.env.PUBLIC_URL + "/img/Taste/" + taste.image3
      }
    )
  );
  return (
    <div style={ style }>
      {
        productType === 'curtain' &&
        (
          <CurtainSimulator
            baseFrom={ baseFrom }
            brandsData={ brands }
            pricesData={ prices }
            tastesData={ tastes }
            roomImageFile={ roomImageFile }
          />
        )
      }
      {
        productType === 'romanShade' &&
        (
          <ShadeSimulator
            baseFrom={ baseFrom }
            brandsData={ brands }
            pricesData={ prices }
            tastesData={ tastes }
            roomImageFile={ roomImageFile }
          />
        )
      }
      {
        productType === 'rollScreen' &&
        (
          <RollSimulator
            baseFrom={ baseFrom }
            brandsData={ brands }
            pricesData={ prices }
            roomImageFile={ roomImageFile }
          />
        )
      }
      {
        productType === 'wallPaper' &&
        (
          <WallPaperSimulator
            baseFrom={ baseFrom }
            roomImageFile={ roomImageFile }
          />
        )
      }
    </div>
  );
}
export default Simulator;

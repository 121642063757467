import React, { useEffect, useState, useRef } from "react";

function CameraPhoto( props ){
  const DescriptionImg = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/DescriptionImg.png";
  const { onNextClick } = props;
  const { handleCameraNextClick } = props;
  const { onBackClick } = props;
  const [ selectedimage, setSelectedimage ] = useState( props.selectedimage );
  const fileInputRef = useRef( null );

  const handleImageChange = ( e ) => {
    const file = e.target.files[ 0 ];
    if ( file ) {
      const imageUrl = URL.createObjectURL( file );
      setSelectedimage( imageUrl );
      props.setSelectedimage( imageUrl );
    }
  };

  const [ elementWidth, setElementWidth ] = useState( null );
  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768;
      const newElementWidth = isMobile ? screenWidth * 0.90 : screenWidth / 2.4;
      setElementWidth( newElementWidth );
    }
    handleResize();
    window.addEventListener( "resize", handleResize );
    return () => {
      window.removeEventListener( "resize", handleResize );
    };
  }, []);

  const Shadow = {
    border: "1px solid #056b5e",
    boxShadow: "0px 5px 3px #95BAB5"
  };

  const containerStyle = {
    ...Shadow,
    width: `${ elementWidth }px`,
  };
  
  const handleButtonClick = () => {
    setSelectedimage();
    fileInputRef.current.click();
  };

  // 戻るボタンをクリックした時の処理
  const handleBackClick = () => {
    onBackClick();
  };
  // 次へボタンをクリックした時の処理
  const handleNextClick = () => {
    onNextClick();
  };
  
  const handleTakePhoto = () => {
    setSelectedimage(null);
    handleCameraNextClick();
  };

  return(
    <div
      className="container curtain_type bg-white mt-3 mb-5"
      style={ containerStyle }
    >
      <div className="row">
        <div
          className="col-12 text-white text-center mb-3 py-3"
          style={{ background: "#056b5e" }}
        >
          まずは基本設定
        </div>
      </div>
      <div className="row">
        <div
          className="col-12 text-center"
          style={{ color:"#056b5e" }}
        >
          写真を撮る（画像を選択）
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 col-12 mb-1">
          <hr />
        </div>
      </div>
      <div className="row justify-content-center px-0">
        <div className="col-12 mx-0 my-2 px-3 px-md-5">
          {selectedimage ? null : (
            <img 
              src={ DescriptionImg }
              alt="デフォルト画像"
              className="w-100 DescriptionImg"
              style={{ display: selectedimage ? 'none' : 'block' }}
            />
          )}
          {selectedimage && (
            <img 
              src={ selectedimage }
              alt="選択した画像"
              className="selected-image w-100"
            />
          )}
        </div>
        <div className="col-12 mx-0 my-2 px-3 px-md-5">
          <button
            className="btn w-100 text-center btn-dark"
            style={{
              fontSize: "10pt",
            }}
            onClick={ handleButtonClick }
          >
            <span
              style={{ lineHeight: "1.1" }}
            >
              <small>
                画像を選択
              </small>
            </span>
          </button>
          <input
            ref={ fileInputRef }
            type="file"
            accept="image/*"
            onChange={ handleImageChange }
            style={{ display: "none" }}
          />
        </div>
        <div className="col-12 mx-0 my-2 px-3 px-md-5">
          <button
            className="btn w-100 text-center btn-dark"
            style={{ fontSize: "10pt" }}
            onClick={ handleTakePhoto }
          >
            <span
              style={{ lineHeight: "1.1" }}
            >
              <small>
                写真を撮る
              </small>
            </span>
          </button>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 col-12">
          <hr />
        </div>
      </div>
      <div className="row justify-content-around mx-3 py-3">
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px",
          }}
          onClick={ handleBackClick }
        >
          <span>
            戻る
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          ></div>
        </div>
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px"
          }}
          onClick={ handleNextClick }
        >
          <span>
            次へ
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}
export default CameraPhoto;

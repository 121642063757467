import React, { useState, useEffect } from "react";
import axios from 'axios';
import ProductNumber from './ProductNumber';
import CustomDropDown from '../parts/CustomDropDown';
import { CustomCheckBoxes } from '../parts/CustomCheckBoxes';
import CustomScrollImageItems from '../parts/CustomScrollImageItems';

function LaceCurtain( props ) {

  const { optionsData, selectedSetters } = props;
  const [ functions, setFunctions ] = useState([]);
  const [ brand, setBrand ] = useState( "none" );
  const [ price, setPrice]  = useState( "0" );
  const functionsData = optionsData[ "functions" ];
  const brandsData = optionsData[ "brand" ];
  const pricesData = optionsData[ "price" ];
  const setSelectedFunctions = selectedSetters[ "functions" ];
  const setSelectedBrand = selectedSetters[ "brand" ];
  const setSelectedPrice = selectedSetters[ "price" ];
  const setSelectedCurtain = selectedSetters[ "curtain" ];
  const [ specifiedLace, setSpecifiedLace ] = useState( null );
  const [ specifiedLaceItems, setSpecfiedLaceItems ] = useState( [] );
  const [ choosedLaces, setChoosedLaces ] = useState( null );
  const [ laceCurtainItems, setLaceCurtainItems ] = useState( [] );
  const resetCurtainItems = ( curtains ) => {
    let items = [];
    const laces = !( curtains instanceof Array )? [ curtains ] : curtains;
    laces.forEach( ( lace, index ) =>
      items.push(
        {
          "name": lace.code,
          "dispName": lace.code,
          "image": "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/Laces/icon/" + lace.code + "-i.png",
          "value": lace.code
        }
      )  
    );
    return items;    
  }

  useEffect(() => {
    if ( specifiedLace === null ) {
      return false;
    }
    if ( specifiedLace === "" ) {
      alert( "対象の生地は存在しません。" );
    }

    let items = resetCurtainItems( specifiedLace === "" ? [] : specifiedLace );
    setSpecfiedLaceItems( items );
  }, [ specifiedLace ]); 
  
  useEffect(() => {
    if ( choosedLaces === null ) return false;
    if ( !choosedLaces.length ) {
      alert( "対象の生地は存在しません。" );
    }

    let items = resetCurtainItems( choosedLaces );
    setLaceCurtainItems( items );
  }, [ choosedLaces ]); 

  const onClickConfirm = () => {
    window.scrollBy( 0, 350 );
    let condition = {};
    let funcs = '';
    functions.map( ( f, idx ) => funcs += ( ( funcs !== '' ? ',' : '') + f ) );
    if ( funcs !== '' ) {
      condition[ "functions" ] = funcs;
    }
    setSelectedFunctions(functions);

    if ( brand !== 'none' ) {
      condition[ "brand" ] = brand;
    }

    if ( price !== '0' ) {
      condition[ "price" ] = price;
    }

    setSelectedPrice( price );

    axios.get( "/api/laces", {
      params: condition
    } ).then( result => {
      console.log( result.data )
      setChoosedLaces( result.data );
     } );
  };

  const onSelectLace = ( e ) => {
    setSelectedCurtain( e.target.value );
  }

  const [ dropClickLResult, setDropClickLResult ] = useState(0);
  const [ dropClickLFunc, setDropClickLFunc ] = useState(0);
  const [ dropClickLBPN, setDropClickLBPN ] = useState(0);

  const onClickDropLFunc = () =>{
    setDropClickLFunc( dropClickLFunc + 1 )
  }
  const onClickDropLBPN = () =>{
    setDropClickLBPN( dropClickLBPN + 1 )
  }
  
  const onClickDropLResult = () =>{
    setDropClickLResult( dropClickLResult + 1 )
  }

  const styleColor = {
    backgroundColor:"rgb(144, 165, 58)"
  }
  const styleColor2 = {
    backgroundColor:"gray",
  }
  
  const backStyleColor = {
    backgroundColor:"rgba(183,197,129,0.3)",
  }

  return(
    <div className = "LaceCurtain"
      style = { backStyleColor }
    >
      <div className="box_LFunc">
        <label 
          htmlFor="inbox_LFunc"
          className="border border-dark text-center my-0 w-100"
          onClick = { onClickDropLFunc }
          style={
            dropClickLFunc === 0?
            {
              backgroundColor:"#B7C581"
            }
            :
            ((dropClickLFunc % 2) == 0)?
            {
              backgroundColor:"#B7C581"
            }
            :
            {
              backgroundColor:"white"
            }
          }
        >
          {
            dropClickLFunc === 0?
            <div className="text-dark container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  レース  機能
                </div>
                <div className="col-2 w-100 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
                </div>
              </div>
            </div>
            :
            ((dropClickLFunc % 2) == 0)?
            <div className="text-dark container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  レース  機能
                </div>
                <div className="col-2 w-100 text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
            :
            <div className="container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  レース  機能
                </div>
                <div className="col-2 w-100 text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                    <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
          }
        </label>
        <input
          type="checkbox"
          id="inbox_LFunc"
          className="on-off_LFunc"
        />
        <div className="pb-3" style={{overflowY:"scroll",height:"260px"}}>
        <CustomCheckBoxes
        title = "機能"
        options = { functionsData }
        setSelected = { setFunctions }
      />
        </div>
      </div>

      <div className="box_LBPN">
        <label 
          htmlFor="inbox_LBPN"
          className="border border-dark text-center my-0 w-100"
          onClick = { onClickDropLBPN }
          style={
            dropClickLBPN === 0?
            {
              backgroundColor:"#B7C581"
            }
            :
            ((dropClickLBPN % 2) == 0)?
            {
              backgroundColor:"#B7C581"
            }
            :
            {
              backgroundColor:"white"
            }
          }
        >
        {
          dropClickLBPN === 0?
          <div className="text-dark container py-2">
            <div className="row">
              <div className="col-10 w-100 text-left">
                レース  ブランド/価格帯/品番検索
              </div>
              <div className="col-2 w-100 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                  <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
              </div>
            </div>
          </div>
          :
          ((dropClickLBPN % 2) == 0)?
          <div className="text-dark container py-2">
            <div className="row">
              <div className="col-10 w-100 text-left">
                レース  ブランド/価格帯/品番検索
              </div>
              <div className="col-2 w-100 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                  <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
              </div>
            </div>
          </div>
          :
          <div className="container py-2">
            <div className="row">
              <div className="col-10 w-100 text-left">
                レース  ブランド/価格帯/品番検索
              </div>
              <div className="col-2 w-100 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                  <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                </svg>
              </div>
            </div>
          </div>
        }
        </label>
        <input
          type="checkbox"
          id="inbox_LBPN"
          className="on-off_LBPN"
        />
        <div>
          <CustomDropDown
            title = "Brand"
            options = { brandsData }
            setSelected = { setBrand }
          />
          <CustomDropDown 
            title = "Price"
            options = { pricesData }
            defaultId = "0" 
            setSelected = { setPrice }
          />
          <ProductNumber 
            apiPath = "/api/laces/"
            choosedSetter = { setSpecifiedLace }
          />
          <CustomScrollImageItems
            name = "targetProduct"
            title = "対象商品"
            options = { specifiedLaceItems }
            multiSelectable = { false }
            onClick = { onSelectLace }
          />
        </div>
      </div>
      <div className="box_Lresult">
        <label 
          htmlFor="inbox_Lresult"
          className="border border-dark text-center my-0 w-100"
          onClick = { onClickDropLResult }
          style={
            dropClickLResult === 0?
            {
              backgroundColor:"#B7C581"
            }
            :
            ((dropClickLResult % 2) == 0)?
            {
            backgroundColor:"#B7C581"
            }
            :
            {
              backgroundColor:"white"
            }
          }
        >
          {
            dropClickLResult === 0?
            <div className="text-dark container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  レース  結果表示
                </div>
                <div className="col-2 w-100 text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
            :
            ((dropClickLResult % 2) == 0)?
            <div className="text-dark container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  レース  結果表示
                </div>
                <div className="col-2 w-100 text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
            :
            <div className="container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  レース  結果表示
                </div>
                <div className="col-2 w-100 text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                    <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
          }
        </label>
        <input
          type="checkbox"
          id="inbox_Lresult"
          className="on-off_Lresult"
        />
        <div>
          <div className="container">
            <div className="row justify-content-center">
              <div 
                id = "btnConfirm"
                className = "col-11 text-center btn text-white font-weight-bold my-3 py-3" 
                onClick = { onClickConfirm }
                style = { styleColor2 }
              >
              レースカーテンをみる
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center text-white">
            <div
              className = "w-100 py-1"
              style = { styleColor }
            >
              レース一覧
            </div>
            <CustomScrollImageItems
              title = "lace"
              name = "targetProduct"
              options = { laceCurtainItems }
              multiSelectable = { false }
              onClick = { onSelectLace }
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LaceCurtain;

import React, { useState, useEffect } from "react";
import axios from 'axios';

function ProductNumber( props ) {
  const { apiPath, choosedSetter } = props;

  const [ targetCode, setTargetCode ] = useState( null );

  const handleChange = e => {
    setTargetCode(e.currentTarget.value);
  }

  const onClickInquiry = () => {
    if ( !targetCode ) {
      alert( "品番が入力されていません。" );
      return false;
    }
    choosedSetter( null );

    const path = apiPath + targetCode;
    axios.get( path, {
    }).then( result => {
      const targetCodes =  targetCode;
      const targetArray = targetCode.split( ',' );
      const resItems = result.data;
      const reqItems = targetArray;
      const testAB = [];
      for( var i = 0; i <= resItems.length - 1; i++ ){
        if( reqItems[ i ] === resItems[ i ].code ){
          testAB.push( resItems[ i ] )
        }
        else{
          for( var a = 0; a <= resItems.length - 1; a++ ){
              if( reqItems[ i ] === resItems[ a ].code ){
              testAB.push( resItems[ a ] )
            }
          }
        }
      }
      if( testAB.length === reqItems.length ){
        choosedSetter( testAB );
      }else{
        alert( "一部の品番が該当しませんでした" )
        choosedSetter( testAB );
      }
    });
  }

  return(
    <div className="container">
      <div className="row justify-content-around align-items-center py-1 border border-secondary">
          <div className="col-8 w-100 px-0">
            <input
              type = "text"
              id = "productCode"
              placeholder = "品番指定の場合"
              className = "w-100"
              onChange = { handleChange }
            />
          </div>
          <div className="col-3 w-100 px-0">
            <button
              id = "btnInquiry"
              className = "btn w-100 py-1 border text-secondary" 
              onClick = { onClickInquiry }
            >
              決定
            </button>
          </div>
      </div>
    </div>
  );
}

export default ProductNumber;
import React, { useContext, useEffect } from "react";
import Reducer from "./reducer";
const ImageContext = React.createContext();

function ImageProvider( { children } ) {
  const [ state, dispatch ] = Reducer();

  return (
    <ImageContext.Provider value={ { state, dispatch } }>
      <div>
        { children }
      </div>
    </ImageContext.Provider>
  );
}

function useImage() {
  const context = useContext( ImageContext );
  if ( context === undefined ) {
    throw new Error( "useImage must be used with in a ImageProvider" );
  }
  return [ context.state, context.dispatch ];
}

export { ImageProvider, useImage };

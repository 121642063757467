import React, { useEffect, useState } from "react";

function RoomSelect({
  onSelect,
  onNextClick,
}){
  const bed_1 = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/bed_1.jpg";
  const bed_2 = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/bed_2.jpg";
  const living_1 = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/living_1.jpg";
  const living_2 = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/living_2.jpg";
  const [ elementWidth, setElementWidth ] = useState( null );
  const [ selectedRoomType, setSelectedRoomType ] = useState( "bed" );
  const [ selectedFurniture, setSelectedFurniture ] = useState( 1 );

  // 部屋タイプと家具数の選択肢を定義
  const roomType = [
    { roomName: "寝室", value: "bed" },
    { roomName: "リビング", value: "living" }
  ];
  const furnitureNumber = [
    { name: "A", value: 1 },
    { name: "B", value: 2 }
  ];
  // 画像URLを定義
  const images = {
    bed_1: bed_1,
    bed_2: bed_2,
    living_1: living_1,
    living_2: living_2
  };

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768;
      const newElementWidth = isMobile ? screenWidth * 0.90 : screenWidth / 2.4;
      setElementWidth( newElementWidth );
    }
    handleResize();
    window.addEventListener( "resize", handleResize );
    if ( selectedRoomType === "bed" && selectedFurniture === 1 ) {
      onSelect({ roomType: "bed", furniture: 1 });
    }
    return () => {
      window.removeEventListener( "resize", handleResize );
    };
  }, []);

  const Shadow = {
    border: "1px solid #056b5e",
    boxShadow: "0px 5px 3px #95BAB5"
  };
  const containerStyle = {
    ...Shadow,
    width: `${ elementWidth }px`,
  };
  // 部屋と家具が選択されたときに呼び出す関数
  const handleSelection = ( type, furniture ) => {
    setSelectedRoomType( type );
    setSelectedFurniture( furniture );
    onSelect({ roomType: type, furniture: furniture });
  };

  // 戻るボタンをクリックした時の処理
  const handleBackClick = () => {
    window.location="/light/"
  };
  // 次へボタンをクリックした時の処理
  const handleNextClick = () => {
    onNextClick();
  };

  return(
    <div
      className="container curtain_type bg-white mt-3 mb-5"
      style={ containerStyle }
    >
      <div className="row">
        <div
          className="col-12 text-white text-center mb-3 py-3"
          style={{ background: "#056b5e" }}
        >
          まずは基本設定
        </div>
      </div>
      <div className="row">
        <div
          className="col-12 text-center"
          style={{ color:"#056b5e" }}
        >
          部屋と家具
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 col-12 mb-3">
          <hr />
        </div>
      </div>
      <div className="row justify-content-center px-0">
        <>
        {roomType.map(( type ) =>
          furnitureNumber.map(( furniture ) => (
            <div
              className={
                `col-md-3 col-6 text-center
                  ${ 
                    selectedRoomType === type.value && selectedFurniture === furniture.value
                    ? "selected"
                    : "" 
                  }`
              }
              key={ `${ type.value }-${ furniture.value }` }
              onClick={ () => handleSelection( type.value, furniture.value ) }
            >
              <img
                src={ images[`${ type.value }_${ furniture.value }`] }
                alt={ images[`${ type.value }_${ furniture.value }`] }
                className={ `w-100 ${ type.value }_${ furniture.value }` }
                style={{
                  border: `${
                    selectedRoomType === type.value && selectedFurniture === furniture.value
                      ? "3px solid #056b5e"
                      : "1px solid #6c757d"
                  }`,
                  // borderRadius: "5px",
                }}
              />
              <p className="mt-3">
                <small>
                  { type.roomName }_{ furniture.name }
                </small>
              </p>
            </div>
          ))
        )}
        </>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 col-12">
          <hr />
        </div>
      </div>
      <div className="row justify-content-around mx-3 py-3">
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px",
          }}
          onClick={ handleBackClick }
        >
          <span>
            戻る
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          >
          </div>
        </div>
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px"
          }}
          onClick={ handleNextClick }
        >
          <span>
            次へ
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          >
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoomSelect

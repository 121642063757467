import React, { useState, useEffect } from "react";
import ImageController from "./ImageController";
import ImageCapturer from "./ImageCapture";
import "./Simulator.css";

function MoveZoomInOut( props ) {
  const [ elementWidth, setElementWidth ] = useState( null );
  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768;
      const newElementWidth = isMobile ? screenWidth * 0.90 : screenWidth / 2.4;
      setElementWidth( newElementWidth );
    }
    handleResize();
    window.addEventListener( "resize", handleResize );
    return () => {
      window.removeEventListener( "resize", handleResize );
    };
  }, []);
  const Shadow = {
    border: "1px solid #056b5e",
    boxShadow: "0px 5px 3px #95BAB5",
  };
  const containerStyle = {
    ...Shadow,
    width: `${ elementWidth }px`,
  };
  const containerStyle2 = {
    width: `${ elementWidth }px`,
  };
  const {
    photoimage,
    selectedimage,
    selectedWindowType,
    selectedType,
    moveZoomData,
    setMoveZoomData,
    onMoveZoomChange,
    onNextClick,
    onBackClick,
  } = props;
  const { scale: moveZoomScale, position: moveZoomPosition, BaseWidth: BaseWidth, BaseHeight: BaseHeight } = moveZoomData;

  const handleDragStateChange = ( position ) => { setMoveZoomData(( prevData ) => ({ ...prevData, position: position }));};
  const handleZoomChange = ( scale ) => { setMoveZoomData(( prevData ) => ({ ...prevData, scale: scale }));};
  const handleImageLoad = ( size ) => {
    if( size.width !== BaseWidth ){
      setMoveZoomData(( prevData ) => ({ ...prevData, BaseWidth: size.width }));
    }
    if( size.height !== BaseHeight ){
      setMoveZoomData(( prevData ) => ({ ...prevData, BaseHeight: size.height }));
    }
  };

  useEffect(() => {
    props.onMoveZoomDataChange( moveZoomData );
  }, [ moveZoomData, props ]);

  // 戻るボタンをクリックした時の処理
  const handleBackClick = () => {
    onBackClick();
  };
  // 次へボタンをクリックした時の処理
  const handleNextClick = () => {
    onNextClick();
  };
  const handleSizeChange = ( size ) => {
    props.onSizeChange( size );
  };

  return (
    <div
      className="container MoveZoomInOut bg-white mt-3 mb-5"
      style={ containerStyle }
    >
      <div className="row">
        <div
          className="col-12 text-white text-center mb-3 py-3"
          style={{ background: "#056b5e" }}
        >
          まずは基本設定
        </div>
      </div>
      <div className="row">
        <div
          className="col-12 text-center"
          style={{ color:"#056b5e" }}
        >
          窓枠の設定
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 col-12 mb-1">
          <hr />
        </div>
      </div>
      <div className="row justify-content-center">
        <ImageController
          windowType = { selectedType }
          onDragStateChange = { handleDragStateChange }
          onZoomChange = { handleZoomChange }
          onImageLoad = { handleImageLoad }
          onSizeChange={handleSizeChange}
        >
          <ImageCapturer
            selectedimage = { selectedimage }
            photoimage = { photoimage }
          />
        </ImageController>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 col-12 mb-1">
          <hr />
        </div>
      </div>
      <div className="row justify-content-around mx-3 py-3">
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px",
          }}
          onClick={ handleBackClick }
        >
          <span>
            戻る
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          >
          </div>
        </div>
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px",
          }}
          onClick={ handleNextClick }
        >
          <span>
          次へ
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          >
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoveZoomInOut;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function PhotoFlow({
  onNextClick,
  // onBackClick,
}) {
  const [ elementWidth, setElementWidth ] = useState( null );
  const DescriptionImg = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/DescriptionImg.png";
  const hakidashi_b = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/hakidashi_b.png";
  const hakidashi_s = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/hakidashi_s.png";
  const koshimado = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/koshimado.png";
  const zoomImage = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/zoomImage.png";
  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768;
      const newElementWidth = isMobile ? screenWidth * 0.90 : screenWidth / 2.4;
      setElementWidth( newElementWidth );
    }
    handleResize();
    window.addEventListener( "resize", handleResize );
    return () => {
      window.removeEventListener( "resize", handleResize );
    };
  }, []);

  const circleStyle = {
    width: '20px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: '#056b5e',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
  };

  const Shadow = {
    borderRadius:"10px",
    boxShadow: "0px 5px 3px #95BAB5"
  };

  const containerStyle = {
    ...Shadow,
    width: `${ elementWidth }px`,
  };

  // 戻るボタンをクリックした時の処理
  const handleBackClick = () => {
    window.location="/light"
  };
  // 次へボタンをクリックした時の処理
  const handleNextClick = () => {
    onNextClick();
  };

  return(
    <div
      className="container curtain_type bg-white mt-3 mb-5"
       style={ containerStyle }
    >
      <div className="row m-3 justify-content-center">
        <div
          className="col-12 text-white text-center mb-3 py-2 rounded-pill mt-5 d-flex align-items-center justify-content-center"
          style={{ background: "#056b5e" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 512 512"
            fill="white"
          >
            <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/>
          </svg>
          &nbsp;
          画像設定方法について
        </div>
      </div>
      {/* 1 */}
      <div className="row m-3 justify-content-center">
        <div
          className="col-1 text-center"
          style={ circleStyle }
        >
          1
        </div>
        <div
          className="col-11"
          style={{ fontSize:"16px" }}
        >
          窓の画像を選択もしくは写真を撮影してください。
          <p
            className="my-1"
            style={{ fontSize:"6px" }}
          >
            ※窓に向かって正面の画像をご準備ください。
          </p>
        </div>
        <div
          className="col-12 text-center mt-3"
          style={{ color: "#056b5e" }}
        >
          写真を撮る（画像選択）
        </div>
        <div className="col-md-12 col-12 px-0">
          <hr />
        </div>
        <div className="col-12 text-center px-0 w-100">
          <img 
            src={ DescriptionImg }
            className="w-100"
          />
        </div>
        <div className="col-12 text-center btn btn-dark my-3">
          <p className="w-100 my-1">
            画像を選択
          </p>
        </div>
        <div className="col-12 text-center btn btn-dark">
          <p className="w-100 my-1">
            写真を撮る
          </p>
        </div>
        <div
          className="col-12 text-center"
          style={{
            fontSize:"36px",
            color:"rgb(204, 233, 221)"
          }}
        >
          ▼
        </div>
      </div>
      {/* 2 */}
      <div className="row m-3 justify-content-center">
        <div
          className="col-1 text-center"
          style={ circleStyle }
        >
          2
        </div>
        <div
          className="col-11"
          style={{ fontSize:"16px" }}
        >
          窓サイズを選択してください。
        </div>
        <div
          className="col-12 text-center mt-3"
          style={{ color: "#056b5e" }}
        >
          写真のサイズ
        </div>
        <div className="col-md-12 col-12 px-0">
          <hr />
        </div>
        <div className="col-4 text-center p-3">
          <img 
            src={ koshimado }
            className="w-100"
            style={{ border: "2px solid rgb(204, 233, 221)" }}
          />
          <p
            className="mt-3 mb-0"
            style={{ fontSize: "10pt" }}
          >
            腰窓
          </p>
          <small style={{ fontSize: "6pt" }}>
            1間腰高窓
          </small>
        </div>
        <div className="col-4 text-center p-3">
          <img 
            src={ hakidashi_s }
            className="w-100"
            style={{ border: "3px solid #056b5e" }}
          />
          <p
            className="mt-3 mb-0"
            style={{ fontSize: "10pt" }}
          >
            掃き出し
          </p>
          <small style={{ fontSize: "6pt" }}>
            1間掃出窓
          </small>
        </div>
        <div className="col-4 text-center p-3 ">
          <img 
            src={ hakidashi_b }
            className="w-100"
            style={{ border: "2px solid rgb(204, 233, 221)" }}
          />
          <p 
            className="mt-3 mb-0"
            style={{ fontSize: "10pt" }}
          >
            掃き出し
          </p>
          <small style={{ fontSize: "6pt"}}>
            1間掃出窓
          </small>
        </div>
        <div
          className="col-12 text-center"
          style={{
            fontSize:"36px",
            color:"rgb(204, 233, 221)"
          }}
        >
          ▼
        </div>
      </div>
      {/* 3 */}
      <div className="row m-3 justify-content-center">
        <div
          className="col-1 text-center"
          style={ circleStyle }
        >
          3
        </div>
        <div
          className="col-11"
          style={{ fontSize:"16px" }}
        >
          緑線を画像の窓枠に合わせてください。
        </div>
        <div
          className="col-12 text-center mt-3"
          style={{ color: "#056b5e" }}
        >
          窓枠の設定
        </div>
        <div className="col-md-12 col-12 px-0">
          <hr />
        </div>
        <div className="col-12 text-center px-0 w-100">
          <img 
            src={ zoomImage }
            className="w-100"
          />
          <p
            className="my-3 mb-0"
            style={{ fontSize:"13px" }}
          >
            ピンチイン・アウトまたはドラッグしながら、
            <br className="d-block d-md-none"/>
            緑線を画像の窓枠に合わせてください。
          </p>
        </div>
      </div>
      {/* あとがき */}
      <div className="row mx-3 mb-1 justify-content-center">
        <div className="col-md-12 col-12 px-0">
          <hr />
        </div>
        <div className="col-12 text-center px-0 w-100">
          <p
            className="my-3"
            style={{ fontSize:"13px" }}
          >
            上記の設定をしていただくと、
            <br className="d-block d-md-none"/>
            スムーズにシミュレーションが可能です。
          </p>
        </div>
      </div>
      <div className="row justify-content-around mx-3 py-3">
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px"
          }}
          onClick={ handleBackClick }
        >
          <span>
            TOP
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          ></div>
        </div>
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px"
          }}
          onClick={ handleNextClick }
        >
          <span>
            シミュレーション
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default PhotoFlow;

import React, { useState, useEffect, useRef } from "react";
import Webcam from 'react-webcam';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Howl } from 'howler';

function Camera({
  onImageCapture,
  onWindowTypeSelect,
  onNextClick,
  onBackClick,
  currentIndex,
}) {
  const koshi = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/koshimado.png";
  const hakidashi_small = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/hakidashi_s.png";
  const hakidashi_big = "https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/hakidashi_b.png";
  const [ confirmationImage, setConfirmationImage ] = useState( null );
  const webcamRef = useRef( null );
  const windowTypeRef = useRef( null );
  const [ imgHeight, setImgHeight ] = useState( null );
  const [ imgWidth, setImgWidth ] = useState( null );
  const [ windowWidth, setWindowWidth ] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    // ウィンドウサイズが変更されたときに実行される関数を設定
    window.addEventListener('resize', handleResize);
    // コンポーネントがアンマウントされたときにイベントリスナーをクリーンアップ
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // useEffect(() => {
  //   const imgElement = document.querySelector( "img.windowType.px-0.mx-0.w-100" );
  //   console.log(windowWidth)
  //   const isMobile = (windowWidth <= 768);
  //   console.log(isMobile)
  //   if(isMobile === false){
  //     console.log("A")
  //     console.log("imgHeight:",imgHeight)
  //   }else{
  //     console.log("B")
  //   }
  //   if ( imgElement ) {
  //     console.log(imgWidth)
  //     const newImgHeight = ( imgElement.clientWidth / 3 ) * 2;
  //     if(imgElement.clientWidth > 0){
  //       setImgWidth( imgElement.clientWidth );
  //     }
  //     if(imgElement.clientHeight > 0){
  //       setImgHeight( newImgHeight );
  //     }
  //   }
  // }, [ currentIndex,windowWidth ]);
// windowTypeの選択肢
  const [ selectedWindowType, setSelectedWindowType ] = useState( "koshi" );
  const windowTypes = [
    { dispName: "腰窓", size: "1間腰高窓", img: `${ process.env.PUBLIC_URL }/3_size_type03_unselected_icon.8900a403.jpg`, value: "koshi" },
    { dispName: "掃き出し", size: "1間掃出窓", img: `${ process.env.PUBLIC_URL }/3_size_type01_unselected_icon.9ad32063.jpg`, value: "hakidashi_small" },
    { dispName: "掃き出し", size: "1間半掃出窓", img: `${ process.env.PUBLIC_URL }/3_size_type02_unselected_icon.a3d5f971.jpg`, value: "hakidashi_big" }
  ];

// Camera.jsのコンポーネントのstyle設定
  const [ elementWidth, setElementWidth ] = useState( null );
  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768;
      const newElementWidth = isMobile ? screenWidth * 0.90 : screenWidth / 2.4;
      setElementWidth( newElementWidth );
      const newImgHeight = ( newElementWidth / 3 ) * 2;
      setImgWidth( newElementWidth );
      setImgHeight( newImgHeight );
    }
    handleResize();
    window.addEventListener( "resize", handleResize );
    return () => {
      window.removeEventListener( "resize", handleResize );
    };
  }, []);
  const Shadow = {
    border: "1px solid #056b5e",
    boxShadow: "0px 5px 3px #95BAB5"
  };
  const containerStyle = {
    ...Shadow,
    width: `${ elementWidth }px`,
  };

// モーダル画面
  const [ showPreview, setShowPreview ] = useState( false );
  const [ capturedImage, setCapturedImage ] = useState( null );
  const [ isFacingModeUser, setIsFacingModeUser ] = useState( true );
  const [ cameraAccessError, setCameraAccessError ] = useState( false );

// captureをした時の挙動
  const [ isCaptureDone, setIsCaptureDone ] = useState( false );
  const capture = () => {
    if ( webcamRef.current && !cameraAccessError ) {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImage( imageSrc );
      setShowPreview( true );
      onImageCapture( imageSrc );
      setConfirmationImage( imageSrc )
// シャッター音を再生する
      // const sound = new Howl({
      //   src: [ shutterSound ],
      //   volume: 1.0,
      // });
      // sound.play();
      setIsCaptureDone( true );
    }
  };

// モーダル画面をclose
  const handleClosePreview = () => {
    setShowPreview( false );
    setCapturedImage( null );
  };

// captureした画像の保存
  const handleSave = () => {
    if ( capturedImage ) {
      const downloadLink = document.createElement( 'a' );
      downloadLink.href = capturedImage;
      downloadLink.download = 'captured_image.jpg';
      document.body.appendChild( downloadLink );
      downloadLink.click();
      document.body.removeChild( downloadLink );
    }
    handleClosePreview();
  };

  const handleWindowTypeSelect = ( value ) => {
    setSelectedWindowType( value );
    onWindowTypeSelect( value );
  };

// 戻るボタンをクリックした時の処理
  const handleBackClick = () => {
    onBackClick();
  };
// 次へボタンをクリックした時の処理
  const handleNextClick = () => {
    if (isCaptureDone) {
      onNextClick();
    } else {
      alert("画像をキャプチャしてください。");
    }
  };
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );
  const handleOrientationChange = () => {
    setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
  };
  useEffect(() => {
    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);


  return (
    <div 
      className="container bg-white mt-3 mb-5"
      style={ containerStyle }
    >
      <div className="row">
        <div
          className="col-12 text-white text-center mb-3 py-3"
          style={{ background: "#056b5e" }}
        >
          まずは基本設定
        </div>
      </div>
      <div className="row">
        <div
          className="col-12 text-center"
          style={{ color:"#056b5e" }}
        >
          写真を撮る
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 col-12 mb-1">
          <hr />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="camera col-md-12 col-12 text-center px-0">
          {cameraAccessError ? (
            <p className="text-danger">
              カメラにアクセスできません。カメラへのアクセスを許可し、ページを更新してください。
              <br/>
              <span style={{ fontSize:"6px" }}>
                Cant access your camera. Please allow camera access and refresh the page.
              </span>
            </p>
          ) : (
            <>
              <div style={{ position: "relative" }}>
                <Webcam
                  audio={ false }
                  ref={ webcamRef }
                  screenshotFormat="image/jpeg"
                  videoConstraints={{
                    facingMode: "environment",
                    aspectRatio:(isPortrait ?(imgHeight/imgWidth):(imgWidth / imgHeight))
                  }}
                  className="webcam px-0 mx-0 w-100"
                  playsInline
                />
                <img
                  ref={ windowTypeRef }
                  src={ `https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/${ selectedWindowType }.png` }
                  alt={ `https://mado-simulator-2.s3.ap-northeast-1.amazonaws.com/Light/${ selectedWindowType }.png` }
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                  className="windowType px-0 mx-0 w-100"
                />
                <div
                  className="confirmationImage"
                  style={{
                    position: "absolute",
                    opacity: 0.75,
                    bottom:15,
                    right:10
                  }}
                >
                  {confirmationImage ? (
                    <img
                      src={ confirmationImage }
                      alt="Captured"
                      style={{
                        width: "auto",
                        height: "50px",
                        display: "block",
                      }}
                      className="border border-warning"
                    />
                  ) : (
                    <div
                      className="border border-warning justify-content-center d-flex align-items-center"
                      style={{
                        width: "90px",
                        height: "60px",
                        backgroundColor: "gray",
                        position: "relative"
                      }}
                    >
                      <p 
                        className="text-white m-0"
                        style={{ fontSize: "6px" }}
                      >
                        Photo
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="row justify-content-center d-flex align-items-center">
        <div className="col-12 d-flex justify-content-center">
          <div
            className="circle"
            onClick={ capture }
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              backgroundColor: "red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30px"
              height="30px"
              viewBox="0 0 512 512"
              fill="#ffffff"
            >
              <path
                d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="row justify-content-between px-3 mt-3">
        {windowTypes.map(( windowType ) => (
          <div
            key={ windowType.value }
            className="col-4 mx-0 my-1 px-3 px-md-3"
            onClick={() => handleWindowTypeSelect( windowType.value )}
          >
            <img
              src={
                windowType.value === "koshi"
                  ? koshi
                  : windowType.value === "hakidashi_small"
                  ? hakidashi_small
                  : hakidashi_big
              }
              alt={ windowType.value }
              className={`w-100 mx-0 px-0 ${ windowType.value }`}
              style={{
                border: windowType.value === selectedWindowType ? "3px solid #046B5E" : "2px solid rgb(204, 233, 221)",
              }}
            />
            <p className="mt-1 text-center">
              { windowType.dispName }
              <br/>
              <span style={{ fontSize: "9px" }}>
                { windowType.size }
              </span>
            </p>
          </div>
        ))}
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 col-12">
          <hr />
        </div>
      </div>
      <div className="row justify-content-around mx-3 py-3">
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px"
          }}
          onClick={ handleBackClick }
        >
          <span>
            戻る
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          ></div>
        </div>
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px",
          }}
          onClick={ handleNextClick }
        >
          <span>
            次へ
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          ></div>
        </div>
      </div>

      <Modal
        show={ showPreview }
        onHide={ handleClosePreview }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {capturedImage && (
            <>
              <div
                style={{ 
                  position: "relative",
                  width: "100%",
                  height: "300px"
                }}
              >
                <img
                  src={ capturedImage }
                  alt="Captured"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="container">
            <div className="row justify-content-around mx-3 py-3">
              <div
                className="col-5 btn border border-secondary Item-Content-Title py-2 d-flex align-items-center justify-content-around"
                style={{
                  position: "relative",
                  fontSize:"12px",
                }}
                onClick={ handleClosePreview }
              >
                <span>
                  デバイスに<br/>
                  保存せず次へ
                </span>
                <div
                  style={{
                    width: 0,
                    height: 0,
                    borderStyle: "solid",
                    borderWidth: "0 0 10px 10px",
                    borderColor: "transparent transparent #3e3a39 transparent",
                    position: "absolute",
                    bottom: 3,
                    right: 3,
                  }}
                ></div>
              </div>
              <div
                className="col-5 btn border border-secondary Item-Content-Title py-2 d-flex align-items-center justify-content-around"
                style={{
                  position: "relative",
                  fontSize:"12px",
                }}
                onClick={ handleSave }
              >
                <span>
                  デバイスに<br/>
                  写真を保存
                </span>
                <div
                  style={{
                    width: 0,
                    height: 0,
                    borderStyle: "solid",
                    borderWidth: "0 0 10px 10px",
                    borderColor: "transparent transparent #3e3a39 transparent",
                    position: "absolute",
                    bottom: 3,
                    right: 3,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Camera;

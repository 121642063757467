import React, { useState, useEffect } from "react";
const TitleBar = {
  borderLeft:"10px solid #3F8257",
};

export const CustomCheckBoxes = ( props ) => {
  const { title, options, setSelected } = props;
  const [ checkedItems, setCheckedItems ] = useState( [] );
  const handleChange = e => {
    if ( checkedItems.length && checkedItems.includes( e.target.value ) ) {
      setCheckedItems( checkedItems.filter( item => item !== e.target.value ) );
    } else {
      setCheckedItems( [ ...checkedItems, e.target.value ] );
    }
  }
  useEffect( () => {
		if ( setSelected ) {
      setSelected( checkedItems );
		}
  }, [ checkedItems ]); 
  

  // クリアボタン
  const handleClear = e =>{
    var boxes = document.getElementsByName( options.length );
    var cnt = boxes.length;
    setCheckedItems( [] );
    setSelected( [] );
    for( var i = 0; i < cnt; i++ ){
      if( boxes.item( i ).checked ){
        boxes.item( i ).checked = false;
      }
    } 
  }

  return(
    <div className="container">
      <div className="row">
        <div
          className = "col-6"
          style = { TitleBar }
        >
          <div className="Floor_color font-weight-bold text-secondary">
            { title }
          </div>
        </div>
        <div className="col-6 text-right">
          <div
            className = "btn btn-secondary my-0 py-0"
            onClick = { handleClear }
          >
            <small className="">
              { title }のクリア
            </small>
          </div>
        </div>
        {
          options.map( ( option, index ) => (
            <div
              className = "col-12"
              key = { index }
            >
              <input
                className = { option.name }
                name = { options.length }
                value = { option.id }
                type = "checkbox"
                id = { option.id + "_" + options.length }
                onChange = { handleChange }
              />
              <label
                htmlFor = { option.id+"_" + options.length }
                className = { option.id+" my-0 py-0" }
              >
                <div className="row my-0 py-0">
                  <div className="col-12 my-0 py-0">
                    {option.name}
                  </div>
                </div>
              </label>
              <div 
                className = "col-12 my-0 py-0"
                style = {
                  {
                    fontSize:"9px",
                    lineHeight:"1.1"
                  }
                }
                htmlFor = { option.id+"_" + options.length }
              >
                { option.func_description }
              </div>
            </div>
          ) )
        }
      </div>
    </div>
  );
};

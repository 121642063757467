import React, { useState, useEffect } from "react";
import axios from 'axios';

import CustomScrollImageItems from '../parts/CustomScrollImageItems';


function RoomTaste( props ) {

	const { tastesData, setSelected } = props;
  return (
    <div className="container">
      {
        tastesData.length&&
        (
          <CustomScrollImageItems
            name = "taste_type"
            title = "Taste"
            options = { tastesData }
            multiSelectable = { true }
            setSelected = { setSelected }
          />
        )
			}
    </div>
  );
}

export default RoomTaste;
import React, { useState, useRef, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { ImageProvider } from "../context/imageContext";
import html2canvas from "html2canvas";
import { RoomArranger } from '../components/RoomArranger';
import { WindowArranger } from '../components/WindowArranger';
import SimulationImage from '../components/SimulationImage';
import ProductNumber from '../components/ProductNumber';
import RoomTaste from '../components/RoomTaste';
import ColorSelector from '../components/ColorSelector';
import { ItemSelectorDivideByColor } from '../components/ItemSelectorDivideByColor';
import { CustomCheckBoxes } from '../parts/CustomCheckBoxes';
import CustomDropDown from '../parts/CustomDropDown';
import CustomScrollImageItems from '../parts/CustomScrollImageItems';
import { ConstructionArranger } from "../components/construction";

export const ShadeSimulator = withRouter(props => {
  // メンテナンスモード(0:メンテナンス中/1:通常)
  const maintenance = 1;
  // EC連携タイマー
  const EC_OPEN = new Date(2022, (2-1), 9 , 12 , 5);
  const OPENline = parseInt( EC_OPEN / 1000 );
  const dateCurrent = parseInt( new Date() / 1000 );
  //EC用URL 
  // 本番環境
  const GET_EC_URL =  'https://www.i-koike-shop.net';
  // テスト環境
  // const GET_EC_URL =  'https://pre.i-koike-shop.net';

  const { baseFrom, brandsData, pricesData, tastesData, roomImageFile } = props;
  const [ shadeFunctions, setShadeFunctions ] = useState( { hits: [] } );
  const [ selectedFunctions, setSelectedFunctions ] = useState( [] );
  const [ selectedTastes, setSelectedTastes ] = useState( [] );
  const [ selectedBrand, setSelectedBrand ] = useState( null );
  const [ selectedPrice, setSelectedPrice ] = useState( "0" );
  const [ rgb, setRGB ] = useState( null );
  const [ baseImgPath, setBaseImgPath ] = useState( "" );
  const [ furnitureImgPath, setFurnitureImgPath ] = useState( "" );
  const [ shadeImgPath, setShadeImgPath ] = useState( "" );
  const [ pointerImgPath, setPointerImgPath ] = useState( "" );
  const [ roomBaseImageFile, setRoomBaseImageFile ] = useState( roomImageFile );
  const [ roomBaseImage, setRoomBaseImage] = useState( null );
  const imgPathSetters = {
    base: setBaseImgPath,
    furniture: setFurnitureImgPath,
    shade: setShadeImgPath
  };
  const cgProducts = [
    { id:"img_base", src:baseImgPath, className:"RoomBase w-100" },
    { id:"img_funiture", src:furnitureImgPath, className:"Funiture w-100" },
    { id:"img_shade", src:shadeImgPath, className:"Shade w-100" }
  ];
  const photoProducts = [
    {id:"img_shade", src:shadeImgPath, className:"Shade w-100" },
    {id:"img_pointer", src:pointerImgPath, className:"PointerBase w-100"},
  ];
  const constructionProducts = [
    {id:"img_shade", src:shadeImgPath, className:"Shade w-100"},
    {id:"img_base", src:baseImgPath, className: "RoomBase w-100"},
    {id:"img_pointer", src:pointerImgPath, className:"PointerBase w-100"},
  ];
  const [ windowType, setWindowType ] = useState( "koshi" );
  const [ shadeCode, setShadeCode ] = useState( null );
  const [ specifiedShade, setSpecifiedShade ] = useState( null );
  const [ specifiedShadeItems, setSpecfiedShadeItems ] = useState( [] );
  const [ choosedColor1Shades, setChoosedColor1Shades ] = useState( null );
  const [ choosedColor2Shades, setChoosedColor2Shades ] = useState( null );
  const [ choosedColor3Shades, setChoosedColor3Shades ] = useState( null );
  const [ resetColor, setResetColor ] = useState( {"color1":false, "color2":false, "color3":false} );
  const [ choosedShades, setChoosedShades ] = useState( null );
  const [ choosedShadeItems, setChoosedShadeItems ] = useState( [] );
  const [ selectedShades, setSelectedShades ] = useState( [] );
  const [ saved, setSaved ] = useState(true);

  const SHADE_IMAGE_URL_BASE = "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/DrapesShades/Shades/";
  useEffect(() => {
    if ( !windowType || !shadeCode ) return;
    let imgName = shadeCode + '_' +
                 ( windowType === "hakidashi_small" ? "1" : windowType === "hakidashi_big" ? "2" : "3" ) + "_shade.png";

    setShadeImgPath( SHADE_IMAGE_URL_BASE + imgName );
  }, [ windowType, shadeCode ]);

  useEffect( () => {
    if ( !roomBaseImageFile ) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      setRoomBaseImage( reader.result );
    };
    if ( roomBaseImageFile ) {
      reader.readAsDataURL( roomBaseImageFile );
      setRoomBaseImage( reader.result );
    } else {
      setRoomBaseImage( null );
    }
  }, []);

  let shadeFuncOptions = [];
  shadeFunctions.length && shadeFunctions.forEach(( func, index ) =>
    shadeFuncOptions.push( { id:func.name, name:func.disp_name, func_description:func.func_description } ) );
  const resetShadeItems = ( romanShades ) => {
    let items = [];
    const shades = !( romanShades instanceof Array )? [ romanShades ] : romanShades;

    shades.forEach(( shade, index ) =>
      items.push({
        "name": shade.code,
        "dispName": shade.code,
        "image": "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/DrapesShades/icon/" + shade.code + "-i.png",
        "value": shade.code
      })  
    );
    return items;    
  }
  
  useEffect(async () => {
    const result = await axios.get("/api/functions", {
      params: {
         clothType: 'drape_shade'
      }      
    });
    // console.log( result );
    setShadeFunctions( result.data );
  }, []); 
  
  useEffect( () => {
    setSaved( false );
  }, [ baseImgPath, furnitureImgPath ]); 

  useEffect(() => {
    if ( specifiedShade === null ) {
      return false;
    }
    if ( specifiedShade === "" ) {
      alert("対象の生地は存在しません。");
    }
    let items = resetShadeItems( specifiedShade === "" ? [] : specifiedShade );
    setSpecfiedShadeItems( items );
  }, [ specifiedShade ]); 

  // pointer
  const WINOW_POINTER = "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/";
  useEffect(() => {
    if ( !windowType ) return;
    let imgName2 = ( windowType === "hakidashi_small" ? "A" : windowType === "hakidashi_big" ? "B" : "C") + ".png";
    setPointerImgPath(WINOW_POINTER + "Pointer/Shade/" + imgName2 );
  }, [ windowType ]);
  const [alertcounter, setAlertcounter ] = useState(0);
  useEffect(() => {
    if (choosedColor1Shades && !choosedColor1Shades.length &&
        choosedColor2Shades && !choosedColor2Shades.length &&
        choosedColor3Shades && !choosedColor3Shades.length) {
          setAlertcounter(alertcounter+1)
        return false;
    }
  }, [choosedColor1Shades, choosedColor2Shades, choosedColor3Shades]); 

  useEffect(() => {
    if (choosedShades === null) return false;
    if (!choosedShades.length) {
      alert("該当の生地がありません。\n条件を変更し、もう一度お試しください。");
    }

    let items = resetShadeItems( choosedShades );
    setChoosedShadeItems( items );
  }, [ choosedShades ]); 

  useEffect(() => {
    if ( !windowType || !shadeCode ) return;
    let imgName = shadeCode + '_' + (windowType === "hakidashi_small" ? "1" : windowType === "hakidashi_big" ? "2" : "3") + "_shade.png";
  }, [ windowType, shadeCode ]);

  const onSave = () => {
    setSaved( true );
  }

  const GET_GROUPED_PRODUCTS_FROM_RGB_URL =  'https://i-koike2.sakura.ne.jp/rgbcg.php?rgb=';
  const queryShades = ( codes, condition, setter ) => {
    if ( codes && codes.length ) {
      condition[ "codes" ] = codes.join( "," );
    }
    axios.get( "/api/roman_shades", {
      params: condition
    }).then( result => {
      if( codes === null ){
        setter( result.data )
      }
      else{
      const resItems = result.data;
      // console.log( resItems );
      const reqItems = codes;
      // console.log( reqItems );
      const TESTab = [];

      for( var i = 0; i <= reqItems.length - 1; i++ ){
        for( var a = 0; a <= resItems.length - 1; a++ ){
          if( reqItems[ i ] === resItems[ a ].code ){
            console.log( reqItems[ i ] + "/"+resItems[ a ].code )
            TESTab.push( resItems[ a ] )
          }
        }
      }
      setter( TESTab )
    }
     });
  }

  const onClickConfirm = () => {
    let funcs = '';
    selectedFunctions.map( (f, idx) => funcs += ( (funcs !== '' ? ',' : '' ) + f) );
    let tsts = '';
    selectedTastes.map((t, idx) => tsts += ( ( tsts !== '' ? ',' : '' ) + t) );

    let condition = {};
    if ( funcs !== '' ) {
      condition[ "functions" ] = funcs;
    }
    if ( tsts !== '' ) {
      condition[ "tastes" ] = tsts;
    }
    if ( selectedBrand !== 'none' ) {
      condition[ "brand" ] = selectedBrand;
    }
    if ( selectedPrice !== '0' ) {
      condition[ "price" ] = selectedPrice;
    }

    if (rgb) {
      setChoosedColor1Shades( null );
      setChoosedColor2Shades( null );
      setChoosedColor3Shades( null );
      axios.get( "/api/queryProducts", {
       params: {
         url: GET_GROUPED_PRODUCTS_FROM_RGB_URL + [ rgb.r, rgb.g, rgb.b ].join( "," )
       }      
     }).then(result => {
        // console.log( GET_GROUPED_PRODUCTS_FROM_RGB_URL + [ rgb.r, rgb.g, rgb.b ] )
      //  console.log( result.data );
       const colors = [ "color1", "color2", "color3" ];
       colors.forEach( ( color, idx ) => {
         let setter = color === "color1"
                  ? setChoosedColor1Shades
                  : color === "color2"
                  ? setChoosedColor2Shades
                  : setChoosedColor3Shades;
         if ( !result.data[color].length ) {
           setter( [] );
          //  console.log( "test1" )
         } else {
           queryShades( result.data[color], condition, setter );
          //  console.log( "test2" )
         }
       });
      });
    } else {
      queryShades( null, condition, setChoosedShades );
    }
  }; 
  const ecbgstyle = {
    backgroundColor:"#046B5E",
  };
  const simulation = {
    height: "0px",
    width: "100%",
  };
  const bgstyle = {
    backgroundColor:"rgba(62,58,57,0.1)",
  };

  const onChangeWindowType = ( e ) => {
    setWindowType( e.target.value );
  }

  const onSelectShade = ( e ) => {
    setShadeCode( e.target.value );
  }
  const styleColor = {
    backgroundColor:"#046B5E",
  }
  const backStyleColor = {
    backgroundColor:"rgba(4,107,94,0.3)",
  }
  const onClickAlert_shade = () => {
    alert("生地を選択してください。")
  }

  function downloadImage ( data ) {
		var fname ="image.png";
		var encdata= atob( data.replace(/^.*,/, '') );
		var outdata = new Uint8Array( encdata.length );
		for ( var i = 0; i < encdata.length; i++ ) {
			outdata[ i ] = encdata.charCodeAt( i );
		}
		var blob = new Blob([ outdata ], [ "image/png" ]);
		
		if (window.navigator.msSaveBlob) {
			window.navigator.msSaveOrOpenBlob( blob, fname );
		} else {
      document.getElementById( "getImage" ).href = data;
			document.getElementById( "getImage" ).download = fname;
			document.getElementById( "getImage" ).click();
		}
  }
  const onClickSave = () => {
    document.querySelector( "#logo" ).style.display = "block";
    document.querySelector( "#date_time" ).style.display = "block";
    document.querySelector( "#products" ).style.display = "block";
    window.scrollTo(0,0);
    let canvasW = document.getElementById( "simulationArea" ).clientWidth;
    let canvasH = canvasW / 1.5;
    html2canvas( document.querySelector( "#simulationArea" ), {
        scrollX:-7,
        width: canvasW,
        height: canvasH,
        allowTaint : false,
        useCORS: true
    }).then( canvas => { 
        downloadImage( canvas.toDataURL() );
    });
  }
  const test = ( rgb === null )?{}:{ background:'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + 1 + ')', textShadow:"1px 1px 0 #000" } ;

  const [ dropClickRoom, setDropClickRoom ] = useState(0);
  const [ dropClickColor, setDropClickColor ] = useState(0);
  const [ dropClickTaste, setDropClickTaste ] = useState(0);
  const [ dropClickFunc, setDropClickFunc ] = useState(0);
  const [ dropClickBN, setDropClickBN ] = useState(0);
  const [ dropClickResult, setDropClickResult ] = useState(0);
  const [ dropClickConstruction, setDropClickConstruction ] = useState(0);
  const [ dropClickConstWindow, setDropClickConstWindow ] = useState(0);
  const [ dropClickWindow, setDropClickWindow ] = useState(0);
  
  const onClickDropRoom = () =>{
    setDropClickRoom( dropClickRoom + 1 )
  }
  const onClickDropColor = () =>{
    setDropClickColor( dropClickColor + 1 )
  }
  const onClickDropTaste = () =>{
    setDropClickTaste( dropClickTaste + 1 )
  }
  const onClickDropFunc = () =>{
    setDropClickFunc( dropClickFunc + 1 )
  }
  const onClickDropBN = () =>{
    setDropClickBN( dropClickBN + 1 )
  }
  const onClickDropResult = () =>{
    setDropClickResult( dropClickResult + 1 )
  }
  const onClickDropConstruction = () =>{
    setDropClickConstruction( dropClickConstruction + 1 )
  }
  const onClickDropConstWindow = () =>{
    setDropClickConstWindow( dropClickConstWindow + 1 )
  }
  const onClickDropWindow = () =>{
    setDropClickWindow( dropClickWindow + 1 )
  }

  return(
    <ImageProvider>
    <div className="CGSelectShade">
      <div className="container">
        <div className="row">
          {baseFrom === "cg"?
            (
              <div
                className="CGspace col-12 col-md-8 px-0"
                style={ simulation }
              >
              <SimulationImage
                baseFrom={ baseFrom }
                productsImage={ cgProducts }
                productsCode={ [ { name: "shade", code: shadeCode } ] }
                onSaveHandler={ onSave }
              />
            </div>
            )
            :baseFrom === "photo"?
            (
              <SimulationImage
                baseFrom={ baseFrom }
                productsImage={ photoProducts } 
                productsCode={ [ { name: "shade", code: shadeCode } ] }
                roomBaseImage={ roomBaseImage }
                onSaveHandler={ onSave }
              />
            )
            :(
              <SimulationImage
                baseFrom={ baseFrom }
                productsImage={ constructionProducts } 
                productsCode={ [ { name: "shade", code: shadeCode } ] }
                roomBaseImage={ baseImgPath }
                onSaveHandler={ onSave }
              />
            )
          }
          <div className="col-12 col-md-4"
          style={ ( window.innerWidth >= 767 )?
            {
            }:
            (
              baseFrom === "cg"?{ top:( window.innerWidth/1.5 ), scrollY:"0px"}:{ top:( window.innerWidth / 1.5 ) }
            )
          }
          >
            <div className="CGCurtain text-center bg-dark text-white py-1">
              {
              baseFrom === "cg" ?
              "背景:CG  ローマンシェード":baseFrom === "photo" ? 
              "背景:写真  ローマンシェード"
              :"背景:施工例  ローマンシェエード"
              }
            </div>
            {
             baseFrom === "cg" ?
              (
                <div style={ bgstyle }>
                  <div className="box_Room">
                    <label 
                      htmlFor="inbox_Room"
                      className="border border-dark text-center my-0"
                      onClick = { onClickDropRoom }
                      style={
                        dropClickRoom === 0?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        ((dropClickRoom % 2) == 0)?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        {
                          backgroundColor:"white"
                        }
                      }
                    >
                      {
                        dropClickRoom === 0?
                        <div className="text-white container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                              部屋(シミュレーション背景)
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                        :
                        ((dropClickRoom % 2) == 0)?
                        <div className="text-white container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                            部屋(シミュレーション背景)
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                        :
                        <div className="container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                              部屋(シミュレーション背景)
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                      }
                    </label>
                    <input
                      type="checkbox"
                      id="inbox_Room"
                      className="on-off_Room"
                    />
                    <div className="pb-3" style={{overflowY:"scroll",height:"260px"}}>
                      <RoomArranger
                        imagePathSetters={ imgPathSetters }
                      />
                      <WindowArranger
                        imagePathSetters={ { "base": setBaseImgPath } }
                        onWindowTypeChangeHandler={ onChangeWindowType } 
                      />
                    </div>
                  </div>
                </div>
              )
              :baseFrom === "photo"?
              (
                <div style={ bgstyle }>
                  <div className="box_Window">
                  <label 
                    htmlFor="inbox_Window"
                    className="border border-dark text-center my-0"
                    onClick = { onClickDropWindow }
                    style={
                      dropClickWindow === 0?
                      {
                        backgroundColor:"#6c757d"
                      }
                      :
                      ((dropClickWindow % 2) == 0)?
                      {
                        backgroundColor:"#6c757d"
                      }
                      :
                      {
                        backgroundColor:"white"
                      }
                    }
                  >
                    {
                      dropClickWindow === 0?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓形状
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      ((dropClickWindow % 2) == 0)?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓形状
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓形状
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    }
                  </label>
                  <input
                    type="checkbox"
                    id="inbox_Window"
                    className="on-off_Window"
                  />
                  <div className="pb-3">
                  <WindowArranger
                    hideFlags = { 
                      { 
                        floor:baseFrom === "photo",
                        wall:baseFrom === "photo"
                      }
                    }
                    onWindowTypeChangeHandler = { onChangeWindowType }
                  />
                  </div>
                </div>
                </div>
              )
              :(
                <div style={bgstyle}>
                  <div className="box_Construction">
                    <label 
                      htmlFor="inbox_Construction"
                      className="border border-dark text-center my-0"
                      onClick = { onClickDropConstruction }
                      style={
                        dropClickConstruction === 0?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        ((dropClickConstruction % 2) == 0)?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        {
                          backgroundColor:"white"
                        }
                      }
                    >
                    {
                      dropClickConstruction === 0?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            サンプル写真
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      ((dropClickConstruction % 2) == 0)?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            サンプル写真
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            サンプル写真
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    }
                    </label>
                    <input
                      type="checkbox"
                      id="inbox_Construction"
                      className="on-off_Construction"
                    />
                    <div className="pb-3" style={{overflowY:"scroll",height:"260px"}}>
                      <ConstructionArranger
                        imagePathSetters = { { "base":setBaseImgPath } }
                      />
                    </div>
                  </div>
                  <div className="box_ConstWindow">
                    <label 
                      htmlFor="inbox_ConstWindow"
                      className="border border-dark text-center my-0"
                      onClick = { onClickDropConstWindow }
                      style={
                        dropClickConstWindow === 0?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        ((dropClickConstWindow % 2) == 0)?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        {
                          backgroundColor:"white"
                        }
                      }
                    >
                    {
                      dropClickConstWindow === 0?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓サイズ
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      ((dropClickConstWindow % 2) == 0)?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓サイズ
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            窓サイズ
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    }
                    </label>
                    <input
                      type="checkbox"
                      id="inbox_ConstWindow"
                      className="on-off_ConstWindow"
                    />
                    <div>
                      <WindowArranger
                        hideFlags={ {floor: true, wall: true} }
                        onWindowTypeChangeHandler={ onChangeWindowType }
                      />
                    </div>
                  </div>
                </div>
               )
            }
              
              <div style={ backStyleColor }>
                <div className="box_ColorPicker">
                  <label 
                    htmlFor="inbox_ColorPicker"
                    className="border border-dark text-center my-0"
                    onClick = { onClickDropColor}
                    style={
                      dropClickColor === 0?
                      {
                        backgroundColor:"#cce9dd"
                      }
                      :
                        test
                    }
                  >
                    {
                      dropClickColor === 0?
                      <div className="text-dark container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            シェード  カラー
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      ((dropClickColor % 2) == 0)?
                      <div className="text-dark container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            シェード  カラー
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            シェード  カラー
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    }
                  </label>
                  <input
                    type="checkbox"
                    id="inbox_ColorPicker"
                    className="on-off_ColorPicker"
                  />
                  <div className="pb-3" style={{overflowY:"scroll",height:"315px"}}>
                    <ColorSelector
                      colorSetter = { setRGB } 
                      roomBaseImage = { roomBaseImage }
                      baseImgPath = { baseImgPath }
                      baseFrom = { baseFrom }
                    />
                  </div>
                </div>
                <div className="box_Taste">
                  <label 
                    htmlFor="inbox_Taste"
                    className="border border-dark text-center my-0"
                    onClick = { onClickDropTaste }
                    style={
                      dropClickTaste === 0?
                      {
                        backgroundColor:"#cce9dd"
                      }
                      :
                      ((dropClickTaste % 2) == 0)?
                      {
                        backgroundColor:"#cce9dd"
                      }
                      :
                      {
                        backgroundColor:"white"
                      }
                    }
                  >
                    {
                      dropClickTaste === 0?
                      <div className="text-dark container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            シェード  テイスト
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      ((dropClickTaste % 2) == 0)?
                      <div className="text-dark container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            シェード  テイスト
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            シェード  テイスト
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    }
                  </label>
                  <input
                    type="checkbox"
                    id="inbox_Taste"
                    className="on-off_Taste"
                  />
                  <div className="pb-3" style={{overflowY:"scroll",height:"260px"}}>
                    <RoomTaste
                      tastesData={ tastesData }
                      setSelected={ setSelectedTastes }
                    />
                  </div>
                </div>
                {
                  shadeFuncOptions.length &&
                  (
                    <div className="box_func">
                      <label 
                        htmlFor="inbox_func"
                        className="border border-dark text-center my-0 w-100"
                        onClick = { onClickDropFunc }
                        style={
                          dropClickFunc === 0?
                          {
                            backgroundColor:"#cce9dd"
                          }
                          :
                          ((dropClickFunc % 2) == 0)?
                          {
                            backgroundColor:"#cce9dd"
                          }
                          :
                          {
                            backgroundColor:"white"
                          }
                        }
                      >
                      {
                        dropClickFunc === 0?
                        <div className="text-dark container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                              シェード  機能
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                        :
                        ((dropClickFunc % 2) == 0)?
                        <div className="text-dark container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                              シェード  機能
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                        :
                        <div className="container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                              シェード  機能
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                      }
                      </label>
                      <input
                        type="checkbox"
                        id="inbox_func"
                        className="on-off_func"
                      />
                      <div className="pb-3" style={{overflowY:"scroll",height:"260px"}}>
                      <CustomCheckBoxes
                        title="機能"
                        options={ shadeFuncOptions }
                        setSelected={ setSelectedFunctions }
                      />
                      </div>
                    </div>
                  )
                }
                <div className="box_BN">
                  <label 
                    htmlFor="inbox_BN"
                    className="border border-dark text-center my-0 w-100"
                    onClick = { onClickDropBN }
                    style={
                      dropClickBN === 0?
                      {
                        backgroundColor:"#cce9dd"
                      }
                      :
                      ((dropClickBN % 2) == 0)?
                      {
                        backgroundColor:"#cce9dd"
                      }
                      :
                      {
                        backgroundColor:"white"
                      }
                    }
                  >
                    {
                      dropClickBN === 0?
                      <div className="text-dark container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                          シェード  ブランド/品番検索
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      ((dropClickBN % 2) == 0)?
                      <div className="text-dark container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            シェード  ブランド/品番検索
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                          シェード  ブランド/品番検索
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                      </div>
                    }
                  </label>
                  <input
                    type="checkbox"
                    id="inbox_BN"
                    className="on-off_BN"
                  />
                  <div>
                    <CustomDropDown
                      title="Brand"
                      options={ brandsData }
                      setSelected={ setSelectedBrand }
                    />
                    <ProductNumber
                      apiPath="/api/roman_shades/"
                      choosedSetter={ setSpecifiedShade }
                    />
                    <CustomScrollImageItems
                      name="targetProduct"
                      title="対象商品"
                      options={ specifiedShadeItems }
                      multiSelectable={ false }
                      onClick={ onSelectShade }
                    />
                  </div>
                </div>
                <div className="box_result">
                  <label 
                    htmlFor="inbox_result"
                    className="border border-dark text-center my-0 w-100"
                    onClick = { onClickDropResult }
                    style={
                      dropClickResult === 0?
                      {
                        backgroundColor:"#cce9dd"
                      }
                      :
                      ((dropClickResult % 2) == 0)?
                      {
                        backgroundColor:"#cce9dd"
                      }
                      :
                      {
                        backgroundColor:"white"
                      }
                    }
                  >
                  {
                    dropClickResult === 0?
                    <div className="text-dark container py-2">
                      <div className="row">
                        <div className="col-10 w-100 text-left">
                        シェード  結果表示
                        </div>
                        <div className="col-2 w-100 text-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                    :
                    ((dropClickResult % 2) == 0)?
                    <div className="text-dark container py-2">
                      <div className="row">
                        <div className="col-10 w-100 text-left">
                          シェード  結果表示
                        </div>
                        <div className="col-2 w-100 text-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="container py-2">
                      <div className="row">
                        <div className="col-10 w-100 text-left">
                        シェード  結果表示
                        </div>
                        <div className="col-2 w-100 text-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                            <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                  }
                  </label>
                  <input
                    type="checkbox"
                    id="inbox_result"
                    className="on-off_result"
                  />
                  <div>
                    <div className="container my-3">
                      <div className="row justify-content-center">
                        <button 
                          id="btnConfirm"
                          className="col-8 text-center btn btn-secondary py-3 font-weight-bold"
                          onClick={ onClickConfirm }
                          style={ test }
                        >
                          プレーンシェードをみる
                        </button>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-12 text-white text-center">
                        <div 
                          className="w-100 py-1 mb-3"
                          style={ styleColor }
                        >
                          ローマンシェード一覧
                        </div>
                        {
                          rgb && choosedColor1Shades && choosedColor2Shades && choosedColor3Shades &&
                          (
                            <ItemSelectorDivideByColor 
                              color1Data={ choosedColor1Shades }
                              color2Data={ choosedColor2Shades }
                              color3Data={ choosedColor3Shades }
                              iconURL="https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/DrapesShades/icon/"
                              selectedSetter={ setShadeCode }
                            />
                          )
                        }
                        {
                          !rgb && choosedShades &&
                          (
                            <CustomScrollImageItems 
                              name="choosedProducts"
                              title="抽出商品"
                              options={ choosedShadeItems }
                              multiSelectable={ false }
                              onClick={ onSelectShade }
                            />
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div
                className="container mt-0 py-3 border border-dark"
                style={ bgstyle }
              >
                <div className="row">
                  <div className="col-6">
                    <button
                      id="btnSave"
                      className="w-100 btn btn-dark"
                      onClick={ onClickSave }
                    >
                      保 存
                    </button>
                    <a 
                      id="getImage"
                      href="" 
                      style={ { display: "none" } }
                      download="image.png"
                    >
                      画像保存
                    </a>
                  </div>
                  <div className="col-6">
                    <a 
                      href="https://www.i-koike.jp/contact/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button 
                        id=""
                        className="w-100 btn btn-info"
                      >
                        来店予約
                      </button>
                    </a>
                  </div>
                </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </ImageProvider>
  );
})


import React from "react";
import html2canvas from "html2canvas";
import ImageController from "../components/ImageController";
import ImageCapturer from '../components/ImageCapturer';
import "./simulator.css";
import Img1 from "../img/HP/logo_2-1.gif"

const ImageContents = ( props ) => {
  const { baseFrom, productsImage, productsCode, roomBaseImage, baseImgPath } = props;
  return (
    <>
      <div 
        className = "container align-items-end"
        style = {
          {
            zIndex: "501",
            position:"absolute"
          }
        }
      >
        <div
          className = "row d-flex align-items-center"
          style = {
            {
              zIndex:"502",
              backgroundColor:"white",
              opacity:"0.5"
            }
          }
        >
          <div
            id = "date_time"
            className = "datetimer col-4 text-secondary"
            style = {
              {
                display:"none",
                lineHeight:"100%"
              }
            }
          >
            <small style = { { lineHeight:"100%" } } >
              { new Date().toLocaleString() }
            </small>
          </div>
          <div
            id = "products"
            className = "col-4 text-secondary align-items-center text-left"
            style = { { display: "none" } }
          >
            <div className="container text-left">
              <div className="row">
                {
                  productsCode.length && productsCode.map( ( product, index ) => (
                    <div
                      className = "col-12 col-md-6 w-100 text-left"
                      key = { index }
                      style = { {lineHeight:"100%"} }
                    >
                      <small>
                        { product.name }
                        :
                        { product.code }
                      </small>
                    </div>
                  ) )
                }
              </div>
            </div>
          </div>
          <div
            id = "logo"
            className = "col-4 w-100 align-items-center"
            style = {
              {
                display: "none",
                opacity:"0.5"
              }
            }
          >
            <img 
              src = { Img1 }
              alt = "NoImage"
              className = "w-100"
              style = {
                {
                  maxHeight:"30px",
                  width:"auto"
                } 
              } 
            />
          </div>
        </div>
      </div>
      {
        baseFrom === "photo" &&
        roomBaseImage &&
        (
          <ImageController
            showZoom
            title = { baseFrom }
          >
            <ImageCapturer
              baseimage = { roomBaseImage }
            />
          </ImageController>
        )
      }
      {
        baseFrom === "construction"&&
        (
          <ImageController showZoom>
            <ImageCapturer baseImage={ baseImgPath } />
              {
                productsImage.map( ( image, index ) => (
                    image.src && (
                    <img 
                      id = { image.id }
                      src = { image.src+"?" + new Date() }
                      alt = "NoImage"
                      className = { image.className }
                      crossOrigin = "anonymous"
                      style={
                        image.id === "img_rale"?
                        {
                          display:"none",
                          useCORS:true,
                        }
                        :image.id === "img_drape"?
                        {
                          display:"none",
                          useCORS:true,
                        }
                        :image.id === "img_lace"?
                        {
                          display:"none",
                          useCORS:true,
                        }
                        :image.id === "img_shade"?
                        {
                          display:"none",
                          useCORS:true,
                        }
                        :image.id === "img_roll"?
                        {
                          display:"none",
                          useCORS:true,
                        }
                        :image.id === "img_wallpaper"?
                        {
                          display:"none",
                          useCORS:true,
                        }
                        :image.id === "img_pointer"?
                        {
                          display:"none",
                          useCORS:true,
                        }
                        :
                        {
                          pointerEvents:"none",
                          useCORS:true,
                        }
                      }
                      key = { index }
                    />
                    )
                  )
                )
              }
          </ImageController>
        )
      }
      {
      baseFrom === "construction"?
      (
        <>
          {
            productsImage.map( ( image, index ) => (
              image.src && (
              <img 
                id = { image.id }
                src = { image.src + "?" + new Date() }
                alt = "NoImage"
                className = { image.className }
                crossOrigin = "anonymous"
                style={
                  image.id === "img_base"?
                  {
                    display:"none",
                    useCORS: true,
                  }
                  :
                  {
                    pointerEvents:"none",
                    useCORS:true,
                  }
                }
                key={ index }
              />
              ) 
            ) )
          }
        </>
      )
      :
      (
        <>
          {
            productsImage.map( ( image, index ) => (
              image.src && 
              (
                <img 
                  id={ image.id }
                  src={ image.src + "?" + new Date() }
                  alt = "NoImage"
                  className={ image.className }
                  crossOrigin="anonymous"
                  style={
                    { 
                      pointerEvents: "none",
                      useCORS:true 
                    }
                  }
                  key={ index }
                />
              )
            ))
          }
        </>
      )
      }
    </>
  )
}


function SimulationImage( props ) {
  const { baseFrom, productsImage, productsCode, roomBaseImage, onSaveHandler } = props;
	function downloadImage ( data ) {
		var fname ="image.png";
		var encdata= atob(data.replace(/^.*,/, ''));
		var outdata = new Uint8Array( encdata.length );
		for ( var i = 0; i < encdata.length; i++ ) {
			outdata[i] = encdata.charCodeAt( i );
		}
		var blob = new Blob( [ outdata ], [ "image/png" ] );
		
		if ( window.navigator.msSaveBlob ) {
			window.navigator.msSaveOrOpenBlob( blob, fname );
		} else {
      document.getElementById( "getImage" ).href = data;
			document.getElementById( "getImage" ).download = fname;
			document.getElementById( "getImage" ).click();
		}
  }
  
  const onClickSave = () => {
    document.querySelector( "#logo" ).style.display = "block";
    document.querySelector( "#date_time" ).style.display = "block";
    document.querySelector( "#products" ).style.display = "block";
    window.scrollTo(0,0);
    let canvasW = document.getElementById( "simulationArea" ).clientWidth;
    let canvasH = canvasW / 1.5;
    html2canvas( document.querySelector( "#simulationArea" ), {
        width: canvasW,
        height: canvasH,
        allowTaint : false,
        useCORS: true
    } ).then( canvas => { 
        downloadImage( canvas.toDataURL() );
    } );
    onSaveHandler();
  }
  return baseFrom === "cg"?
  (
    <div className="SimulationCGImg">
      <div className="container">
        <div className="row">
          <div
            id = "simulationArea"
            className = "simulationArea col-12 w-100 px-0 text-center mx-0"
          >
            <ImageContents
              baseFrom = { baseFrom }
              productsImage = { productsImage }
              productsCode = { productsCode }
              roomBaseImage = { roomBaseImage }
            />
          </div>
        </div>
      </div>
    </div>
  )
  : baseFrom === "photo"?
  (
    <>
      <div 
        id = "simulationArea"
        className = "CGspace col-12 col-md-8 px-0"
        style = { { width: "100%" } }
      >
        <ImageContents
          baseFrom = { baseFrom }
          productsImage = { productsImage }
          productsCode = { productsCode }
          roomBaseImage = { roomBaseImage }
        />
      </div>
    </>
  )
  :(
    <>
      <div
        id = "simulationArea"
        className = "CGspace col-12 col-md-8 px-0 w-100"
      >
        <ImageContents
          baseFrom = { baseFrom }
          productsImage = { productsImage }
          productsCode = { productsCode }
          roomBaseImage = { roomBaseImage }
        />
      </div>
    </>
  );
}

export default SimulationImage;
import React from 'react';

const styleSTT = {
  backgroundColor: "#B7C581",
};
const TitleBar = {
  borderLeft:"10px solid #046B5E",
};

const ThanksTO =[
  {
    affiliation:"カラーサイエンス共同研究",
    name:"国立高等専門学校機構　沼津工業高等専門学校　高矢昌紀 准教授",
    description:"",
  },
  {
    affiliation:"テイスト監修",
    name:"一社)　日本テキスタイルデザイン協会理事長　株式会社Ｆａｂ・４・Ｗｏｒｋs　大場麻美代表",
    description:"",
  },
  {
    affiliation:"カラーシステム",
    name:"一社)　日本色彩研究所",
    description:"本シミュレーションの、「色の体系的分類は、PCCS系統色名分類、及び「PCCS Color Calc」を使用しております。それらにカーテン性質上多く分布するエリアに独自でトーンを追加と色相の詳細化を行っています。」",
  },
  {
    affiliation:"シミュレーター制作",
    name:"株式会社　イマイ",
    description:"CG作成からアプリケーション作成、クラウド連携などのIT環境の構築を行なっています。商品提案から見積もり、発注まで一気通貫に行えるシステムなど、様々な要望に対応可能です。",
  },
  {
    affiliation:"アナリティクス監修",
    name:"株式会社　ジオロケーションテクノロジー　ウェブ解析士マスター　世良直也氏",
    description:"国内で唯一のIP Geolocation事業を展開する株式会社Geolocation Technologyに在籍。一般社団法人ウェブ解析士協会認定のウェブ解析士の中で最上位であるマスターを保持しており、以前より当社のWeb解析にアドバイスいただいております。",
  },
]


function Thanks() {
  return(   
    <div className="container my-3">
      <div className="row">
        <div 
          className = "col-12 text-center font-italic my-3"
          style = { styleSTT }
        >
          <h3 className="text-white my-2">
            Special Thanks to
          </h3>
        </div>
      </div>
      {
        ThanksTO.map( ( thank, index ) => (
          <div
            className = "row my-3"
            key = { index }
          >
            <div
              className = "col-md-4 col-12 font-weight-bold font-italic text-dark"
              style = { TitleBar }
            >
              { thank.affiliation }
            </div>
            <div className="col-md-8 col-12 text-dark">
              { thank.name }
            </div>
            <div className="col-4">         
            </div>
            <div className="col-md-8 col-12 text-secondary">
              <small>
                { thank.description }
              </small>
            </div>
          </div>
        ) )
      }
    </div>
  );
}

export default Thanks;
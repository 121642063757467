import React from 'react';
import noimage from '../img/noimage.png'

function Screen() {
  return(
<>
  <img
    src=""
    alt="no.png"
    onError={
      (e) => e.target.src = noimage
    }
    id="noimage"
    style={ { width:"100px" } }
  />
</>
);
}

export default Screen;
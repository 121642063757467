import React from "react";
import { withRouter } from 'react-router-dom';

export const WebCamTop = withRouter( props => {

  const onClickCam = () => {
    props.history.push( "/webCam" );
  }
  return(
    <div className="container">
      <div className="row justify-content-center">
      <div 
        className="col-6 btn btn-info"
        onClick = { onClickCam }
      >
        試してみる
      </div>
    </div>
    </div>
  );
})
import React, { useEffect, useState, useRef } from "react";

function RailColor({
  onSelect,
  onNextClick,
  onBackClick
}){
  const [ elementWidth, setElementWidth ] = useState( null );
  const [ panelWidth, setPanelWidth ] = useState( null );
  const col2Ref = useRef( null );
  const [ selectedRail, setSelectedRail ] = useState( "WH" );

  useEffect(() => {
    if ( col2Ref.current ) {
      const col2Width = col2Ref.current.offsetWidth;
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768;
      const newcol2Width = isMobile ? col2Width / 1.8 : col2Width / 2.4;
      setPanelWidth( newcol2Width );
    }
  }, []);
  const railColours = [
    { name: "ﾎﾜｲﾄ", value: "WH" },
    { name: "ｱｯｼｭｸﾞﾚｲﾝ", value: "GR" },
    { name: "ﾅﾁｭﾗﾙ", value: "NU" },
    { name: "ﾌﾞﾗｳﾝ", value: "BR" },
    { name: "ﾀﾞｰｸﾌﾞﾗｳﾝ", value: "DBR" },
  ];
  const colorPanel = [
    { name: "ﾎﾜｲﾄ", value: "WH",background: "#ffffff" },
    { name: "ｱｯｼｭｸﾞﾚｲﾝ", value: "GR",background: "#D8D8D8"},
    { name: "ﾅﾁｭﾗﾙ", value: "NU",background:"#FDEAC6" },
    { name: "ﾌﾞﾗｳﾝ", value: "BR",background:"#9F7A63" },
    { name: "ﾀﾞｰｸﾌﾞﾗｳﾝ", value: "DBR",background:"#362700" }
  ]
  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768;
      const newElementWidth = isMobile ? screenWidth * 0.90 : screenWidth / 2.4;
      setElementWidth( newElementWidth );
    }
    handleResize();
    window.addEventListener( "resize", handleResize );
    if ( selectedRail === "WH" ) {
      onSelect({ railColours: "WH" });
    }
    return () => {
      window.removeEventListener( "resize", handleResize );
    };
  }, []);
  const Shadow = {
    border: "1px solid #056b5e",
    boxShadow: "0px 5px 3px #95BAB5",
  };
  const containerStyle = {
    ...Shadow,
    width: `${ elementWidth }px`,
  };
  const centerAlignStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const handleSelection = ( railcolor ) => {
    // console.log(railcolor)
    setSelectedRail( railcolor );
    onSelect({ railColours: railcolor });
  };

  // 戻るボタンをクリックした時の処理
  const handleBackClick = () => {
    onBackClick();
  };
  // 次へボタンをクリックした時の処理
  const handleNextClick = () => {
    onNextClick();
  };

  return(
    <div
      className="container curtain_type bg-white mt-3 mb-5"
      style={ containerStyle }
    >
      <div className="row">
        <div
          className="col-12 text-white text-center mb-3 py-3"
          style={{ background: "#056b5e" }}
        >
          簡単!カーテンシミュレーション
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-10 px-0 d-flex justify-content-center">
          <div className="row w-100 mx-3">
            {/* 円グラフ */}
            <div className="col-2 px-0">
              <div className="pie2_5">
                <span
                  className="fw-bold"
                  style={{ color: "#056b5e" }}
                >
                  2
                </span>
                <span>
                  <small className="text-secondary">
                    /5
                  </small>
                </span>
              </div>
            </div>
            {/* チェックマーク */}
            <div className="col-1 text-center px-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#056b5e"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
            </div>
            {/* STEP */}
            <div className="col-9 px-0">
              <div className="row">
                <div className="col-12 text-left">
                  STEP 02
                </div>
                <div
                  className="col-12 text-left fw-bold"
                  style={{ color: "#056b5e" }}
                >
                  カーテンレールの色
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2">
        </div>
      </div>
      <div className="row justify-content-center px-0 my-3">
        <div className="col-12">
          <div className="row justify-content-center">
            {railColours.map(( railcolor, index ) => (
              colorPanel.map(( color ) => (
                railcolor.value === color.value ? (
                  <div
                    className={`col-2 text-center color-panel px-0 ${ selectedRail === railcolor.value ? "selected" : "" }`}
                    key={`${ railcolor.value }-${ color.value }`}
                    ref={ col2Ref }
                    style={ centerAlignStyle }
                    onClick={() => handleSelection( railcolor.value )}
                  >
                    <div
                      className="color-panelborder border"
                      key={ index }
                      style={{
                        backgroundColor: color.background,
                        width: panelWidth + "px",
                        minWidth: "45px",
                        maxWidth: "50px",
                        height: panelWidth + "px",
                        minHeight: "45px",
                        maxHeight: "50px",
                        borderRadius: "5px",
                        position: "relative",
                      }}
                    >
                      {selectedRail === railcolor.value && (
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#056b5e"
                            className="bi bi-check-circle-fill bg-white rounded"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null
              ))
            ))}
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 col-12">
          <hr />
        </div>
      </div>
      <div className="row justify-content-around mx-3 py-3">
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px"
          }}
          onClick={ handleBackClick }
        >
          <span>
            戻る
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          ></div>
        </div>
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px"
          }}
          onClick={ handleNextClick }
        >
          <span>
            次へ
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default RailColor;

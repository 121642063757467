import React, { useState, useEffect } from "react";
import CustomScrollImageItems from '../parts/CustomScrollImageItems';

export const ItemSelectorDivideByColor = (props) => {
  
  const {color1Data, color2Data, color3Data, iconURL, selectedSetter,timer} = props;

  const [ rgb, setRGB ] = useState( null );
  const [ color1Items, setColor1Items ] = useState([]);
  const [ color2Items, setColor2Items ] = useState([]);
  const [ color3Items, setColor3Items ] = useState([]);
  const [ resetColor, setResetColor ] = useState( { "color1": false, "color2": false, "color3": false } );
  // スクロール画像部品に渡す項目群を更新
  const resetItems = ( data ) => {
    let items = [];
    const products = !( data instanceof Array )? [ data ] : data;
    products.forEach( ( product, index ) =>
      items.push(
        {
          "name": product.code,
          "dispName": product.code,
          "image": iconURL + product.code + "-i.png",
          "value": product.code
        }
      )  
    );
    return items; 
  }

  useEffect(() => {
    let items = resetItems( color1Data );
    setColor1Items( items );
  }, [ color1Data ]); 

  useEffect(() => {
    let items = resetItems( color2Data );
    setColor2Items( items );
  }, [ color2Data ]); 

  useEffect(() => {
    let items = resetItems( color3Data );
    setColor3Items( items );
  }, [ color3Data ]); 

  const onSelectItem = ( e ) => {
    for ( let color in resetColor ) {
      resetColor[color] = ( color !== e.target.name.substring( 0, 6 ) );
    } 
    selectedSetter( e.target.value );
  }

  return(
    <div id="choosedProducts">
      <CustomScrollImageItems
        name = "color1Products"
        title = ""
        options = { color1Items }
        multiSelectable = { false }
        modeReset = { resetColor["color1"] }
        onClick = { onSelectItem }
        timer = {timer}
      />
      <CustomScrollImageItems
        name = "color2Products"
        title = "同色相"
        options = { color2Items }
        multiSelectable = { false }
        modeReset = { resetColor["color2"] }
        onClick = { onSelectItem }
        timer = {timer}
      />
      <CustomScrollImageItems
        name = "color3Products"
        title = "同トーン"
        options = { color3Items }
        multiSelectable = { false }
        modeReset = { resetColor["color3"] }
        onClick = { onSelectItem }
        timer = {timer}
      />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import axios from 'axios';

function FunctionPattern({
  selectedCurtainType,
  onApiData,
  onSelectedData,
  onNextClick,
  onBackClick,
  newFunctions,
}){
  // 状態管理用の変数を定義
  const [ elementWidth, setElementWidth ] = useState( null );
  const [ selectedItems, setSelectedItems ] = useState( [] );
  const [ apiDataLength, setApiDataLength ] = useState( 0 );
  // ウィンドウのリサイズ時に実行される処理
  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      const isMobile = screenWidth <= 768;
      const newElementWidth = isMobile ? screenWidth * 0.90 : screenWidth / 2.4;
      setElementWidth( newElementWidth );
    }
    // 初回実行
    handleResize();
    // イベントリスナーの登録と解除
    window.addEventListener( "resize", handleResize );
    return () => {
      window.removeEventListener( "resize", handleResize );
    };
  }, []);

  // カーテンの種類や選択されたアイテムが変更された時に実行される処理
  useEffect(() => {
    if ( selectedItems.every( ( item ) => !item ) ) {
      const fetchData = async () => {
        const endpoint = getEndpoint( selectedCurtainType );
        try {
          const response = await axios.get( endpoint );
          const data = response.data;
          setApiDataLength( data.length );
      if ( onApiData ) {
        onApiData( data );
      }
        } catch ( error ) {
          console.error( "Error fetching data. Endpoint:", endpoint );
          console.error( "Error details:", error );
        }
      };
      fetchData();
    }
  }, [ selectedItems, selectedCurtainType ]);

  // スタイル定義
  const Shadow = {
    border: "1px solid #056b5e",
    boxShadow: "0px 5px 3px #95BAB5",
  };
  const containerStyle = {
    ...Shadow,
    width: `${ elementWidth }px`,
  };

  const curtainData = {
    drape : [
      { dispName: "防炎", isActive: false, dbName: "fire", columnName: "functions" },
      { dispName: "形状記憶", isActive: false, dbName: "set", columnName: "functions" },
      { dispName: "遮光", isActive: false, dbName: "bo1aaa,bo1,bo2", columnName: "functions" },
      { dispName: "ウォッシャブル", isActive: false, dbName: "washable", columnName: "functions" },
      { dispName: "無地調", isActive: false, dbName: "Mj", columnName: "taste" },
      { dispName: "柄", isActive: false, dbName: "N,NG,S,SW,SS,V,C,CE,M,MS,Wa", columnName: "taste" },
    ],
    lace : [
      { dispName: "防炎", isActive: false, dbName: "fire", columnName: "functions" },
      { dispName: "形状記憶", isActive: false, dbName: "set", columnName: "functions" },
      { dispName: "見えにくい", isActive: false, dbName: "secret", columnName: "functions" },
      { dispName: "ウォッシャブル", isActive: false, dbName: "washable", columnName: "functions" },
      { dispName: "UVカット", isActive: false, dbName: "uvcut", columnName: "functions" },
      { dispName: "柄", isActive: false, dbName: "print", columnName: "functions" },
      { dispName: "カラー", isActive: false, dbName: "color", columnName: "functions" },
      { dispName: "裾柄レース", isActive: false, dbName: "embro", columnName: "functions" },
    ],
    roll : [
      { dispName: "遮光", isActive: false, dbName: "bo1aaa,bo1,bo2", columnName: "functions" },
      { dispName: "遮光なし", isActive: false, dbName: "", columnName: "" },
      { dispName: "見えにくい", isActive: false, dbName: "secret", columnName: "functions" },
      { dispName: "通常", isActive: false, dbName: "", columnName: "" },
    ],
  }

  // 選択されたカーテンのデータを取得
  const selectedData = curtainData[ selectedCurtainType ] || [];
  // ボタンがクリックされた時の処理
  const handleButtonClick = async( index ) => {
    const newSelectedItems = [ ...selectedItems ];
    newSelectedItems[ index ] = !newSelectedItems[ index ];
    setSelectedItems( newSelectedItems );
    // 選択されたアイテムのデータを取得
    const selectedItemsData = selectedData.filter(( item, i ) => newSelectedItems[ i ]);
    // 選択されたアイテムのisActiveを更新する
    const updatedSelectedData = selectedData.map((item, i) => {
      if ( newSelectedItems[ i ] ) {
        return { ...item, isActive: true }; // 選択されたものはtrueに設定
      } else {
        return { ...item, isActive: false }; // 選択されていないものはfalseに設定
      }
    });
    // 選択された機能と模様を取得
    const selectedFunctions = selectedItemsData
      .filter( ( p ) => p.columnName === "functions" && p.dbName !== "" )
      .flatMap( ( p ) => p.dbName.split( "," ) );
    const selectedTastes = selectedItemsData
      .filter( ( p ) => p.columnName === "taste" && p.dbName !== "" )
      .flatMap( ( p ) => p.dbName.split( "," ));

    const endpoint = getEndpoint( selectedCurtainType );
    const params = {};
    if ( selectedFunctions.length > 0 ) {
      params.functions = selectedFunctions;
    }
    if ( selectedTastes.length > 0 ) {
      params.tastes = selectedTastes;
    }
    try {
      const response = await axios.get( endpoint + "/functions", { params } );
      const data = response.data;
      setApiDataLength( data.length ); 
      if ( onApiData ) {
        onApiData( data );
      }
    } catch ( error ) {
      console.error( '\u001b[32m' +"データの取得エラー:", error );
    }
    if ( onSelectedData ) {
      onSelectedData( selectedItemsData, updatedSelectedData );
    }
  };

  const getEndpoint = ( curtainType ) => {
    switch ( curtainType ) {
      case 'drape':
        return '/api/drapes';
      case 'lace':
        return '/api/laces';
      case 'roll':
        return '/api/roll_screens';
      default:
        return '/api/drapes';
    }
  };

// 戻るボタンをクリックした時の処理
  const handleBackClick = () => {
    onBackClick();
  };
// 次へボタンをクリックした時の処理
  const handleNextClick = () => {
    onNextClick();
  };

  return(
    <div
      className="container curtain_type bg-white mt-3 mb-5"
      style={ containerStyle }
    >
      <div className="row">
        <div
          className="col-12 text-white text-center mb-3 py-3"
          style={{ background: "#056b5e" }}
        >
          簡単!カーテンシミュレーション
        </div>
      </div>
      <div className="row">
        <div className="col-10 px-0 d-flex justify-content-center">
          <div className="row w-100 mx-3">
            {/* 円グラフ */}
            <div className="col-2 px-0">
              <div className="pie3_5">
                <span
                  className="fw-bold"
                  style={{ color: "#056b5e" }}
                >
                  3
                </span>
                <span>
                  <small className="text-dark">
                    /5
                  </small>
                </span>
              </div>
            </div>
            {/* チェックマーク */}
            <div className="col-1 text-center px-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#056b5e"
                className="bi bi-check-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
            </div>
            {/* STEP */}
            <div className="col-9 px-0">
              <div className="row">
                <div className="col-12 text-left">
                  STEP 03
                </div>
                <div
                  className="col-12 text-left fw-bold"
                  style={{ color: "#056b5e" }}
                >
                  機能・模様
                  <span
                    className="text-secondary"
                    style={{ fontSize:"9px" }}
                  >
                    ※複数選択可
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2">
          <p 
            className="text-end my-1"
            style={{ fontSize:"9px" }}
          >
            <nobr>
              { apiDataLength }件
            </nobr>
          </p>
        </div>
      </div>
      <div className="row justify-content-center px-0 mt-3">
        {/*  */}
        { selectedData.map(( item, index ) => (
          <div
            className="col-6 mx-0 my-2 px-3 px-md-5"
            key={ index }
          >
            <button
              className={ `btn w-100 text-center` }
              style={{
                fontSize: "10pt",
                outline: selectedItems[ index ] ? "1px solid #056b5e" : "1px solid #056b5e",
                outlineOffset: "-1px",
                backgroundColor: selectedItems[ index ] ? "#056b5e" : "#EEF5F1",
                color:selectedItems[ index ]? "#ffffff" : "#056b5e",
              }}
              onClick={() => handleButtonClick( index )}
            >
              <span style={{ lineHeight: "1.1" }}>
                <small>
                  { item.dispName }
                </small>
              </span>
            </button>
          </div>
        ))}
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12 col-12">
          <hr />
        </div>
      </div>
      <div className="row justify-content-around mx-3 py-3">
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px",
          }}
          onClick={ handleBackClick }
        >
          <span>
            戻る
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          >
          </div>
        </div>
        <div
          className="col-5 btn border border-secondary Item-Content-Title py-2"
          style={{
            position: "relative",
            fontSize:"12px",
          }}
          onClick={ handleNextClick }
        >
          <span>
            次へ
          </span>
          <div
            style={{
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "0 0 10px 10px",
              borderColor: "transparent transparent #3e3a39 transparent",
              position: "absolute",
              bottom: 3,
              right: 3,
            }}
          >
          </div>
        </div>
      </div>
    </div>
  )
}

export default FunctionPattern;

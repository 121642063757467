import React, { useState, useRef, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { ImageProvider } from "../context/imageContext";
import { RoomArranger } from '../components/RoomArranger';
import { FrontWallArranger } from "../components/FrontWallArranger";
import SimulationImage from '../components/SimulationImage';
import ProductNumber from '../components/ProductNumber';
import ColorSelector from '../components/ColorSelector';
import { ItemSelectorDivideByColor } from '../components/ItemSelectorDivideByColor';
import { CustomCheckBoxes } from '../parts/CustomCheckBoxes';
import CustomScrollImageItems from '../parts/CustomScrollImageItems';
import { ConstructionArranger } from "../components/construction";
import html2canvas from "html2canvas";

export const WallPaperSimulator = withRouter( props => {
  const { baseFrom, roomImageFile } = props;
  // console.log(baseFrom)
  const [ paperFunctions, setPaperFunctions ] = useState( { hits: [] } );
  const [ selectedFunctions, setSelectedFunctions ] = useState( [] );
  const [ selectedPaper, setSelectedPaper ] = useState( null );
  const [ rgb, setRGB ] = useState( null );
  const [ baseImgPath, setBaseImgPath ] = useState( "" );
  const [ furnitureImgPath, setFurnitureImgPath ] = useState( "" );
  const [ wallpaperImgPath, setWallpaperImgPath ] = useState( "" );
  const [ pointerImgPath, setPointerImgPath ]  = useState( "" );
  const imgPathSetters = {
    base: setBaseImgPath,
    furniture: setFurnitureImgPath,
    wallpaper: setWallpaperImgPath
  };
  // console.log(baseImgPath);
  const [ roomBaseImageFile, setRoomBaseImageFile ] = useState( roomImageFile );
  const [ roomBaseImage, setRoomBaseImage ] = useState( null );
  const cgProducts = [
    { id:"img_base", src:baseImgPath, className:"RoomBase w-100" },
    { id:"img_funiture", src:furnitureImgPath, className:"Funiture w-100" },
    { id:"img_wallpaper", src:wallpaperImgPath, className:"Wallpaper w-100" }
  ];
  const photoProducts = [
    { id:"img_wallpaper", src:wallpaperImgPath, className:"Wallpaper w-100" },
    { id:"img_pointer", src:pointerImgPath, className:"PointerBase w-100" },
  ];
  const constructionProducts = [
    { id:"img_base", src:baseImgPath, className:"RoomBase w-100" },
    { id:"img_wallpaper", src:wallpaperImgPath, className:"Wallpaper w-100" },
    { id:"img_pointer", src:pointerImgPath, className:"PointerBase w-100" },
  ];
  const [ windowType, setWindowType ] = useState( "koshi" );
  const [ wallpaperCode, setWallpaperCode ] = useState( null) ;
  const [ specifiedPaper, setSpecifiedPaper ] = useState( null );
  const [ specifiedPaperItems, setSpecfiedPaperItems ] = useState( [] );
  const [ selectedSpecifiedPaper, setSelectedSpecifiedPaper ] = useState( [] );
  const [ choosedColor1Papers, setChoosedColor1Papers ] = useState( null );
  const [ choosedColor2Papers, setChoosedColor2Papers ] = useState( null );
  const [ choosedColor3Papers, setChoosedColor3Papers ] = useState( null );
  const [ resetColor, setResetColor ] = useState( { "color1":false, "color2":false, "color3":false } );
  const [ choosedPapers, setChoosedPapers ] = useState( null );
  const [ choosedPaperItems, setChoosedPaperItems ] = useState( [] );
  const [ saved, setSaved ] = useState( true );
  const WALLPAPER_IMAGE_URL_BASE = "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/WallPapers/WallPapers/";
  useEffect( () => {
        if ( !wallpaperCode ) return;
        let imgName = wallpaperCode + "_wall.png";
        setWallpaperImgPath( WALLPAPER_IMAGE_URL_BASE + imgName );
      }, [ windowType, wallpaperCode ] );
  useEffect( () => {
    if ( !roomBaseImageFile ) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      setRoomBaseImage( reader.result );
    };
    if ( roomBaseImageFile ) {
      reader.readAsDataURL( roomBaseImageFile );
      setRoomBaseImage( reader.result) ;
    } else {
      setRoomBaseImage( null );
    }
  }, []);
  let paperFuncOptions = [];
  paperFunctions.length && paperFunctions.forEach( ( func, index ) =>
  paperFuncOptions.push( { id: func.name, name: func.disp_name } ) );
  
  const GET_GROUPED_PRODUCTS_FROM_RGB_URL = 'https://i-koike2.sakura.ne.jp/rgbcgwall.php?rgb=';
  const resetPaperItems = (papers) => {
      let items = [];
      console.log(items)
      const wallPapers = !(papers instanceof Array)? [papers] : papers;
      wallPapers.forEach((paper, index) =>
        items.push({
          "name": paper.code,
          "dispName": paper.code,
          "image": "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/WallPapers/icon/" + paper.code + "-i.png",
          "value": paper.code
        })  
      );
      return items;    
    }
  useEffect( () => {
    setSaved( false );
  }, [ baseImgPath, furnitureImgPath ] ); 
  useEffect(() => {
    if ( specifiedPaper === null ) {
      return false;
    }
    if ( specifiedPaper === "" ) {
      alert( "対象の生地は存在しません。" );
    }
    let items = resetPaperItems( specifiedPaper === "" ? [] : specifiedPaper );
    setSpecfiedPaperItems( items );
  }, [ specifiedPaper ]); 

  const [ alertcounter, setAlertcounter ] = useState( 0 );
  useEffect(() => {
    if (choosedColor1Papers && !choosedColor1Papers.length &&
        choosedColor2Papers && !choosedColor2Papers.length &&
        choosedColor3Papers && !choosedColor3Papers.length) {
          setAlertcounter(alertcounter+1)
          if( ( alertcounter % 3 ) === 0 ){
            alert( "該当の生地がありません。\n条件を変更し、もう一度お試しください" );
          }
          if( ( alertcounter % 3 ) !== 0 ){
            alert( "該当の生地がありません。\n条件を変更し、もう一度お試しください" );
          }
        return false;
    }
  }, [ choosedColor1Papers, choosedColor2Papers, choosedColor3Papers ]); 
  useEffect(() => {
    if ( choosedPapers === null ) return false;
    if ( !choosedPapers.length ) {
      alert( "対象の生地は存在しません。" );
    }
    let items = resetPaperItems( choosedPapers );
    setChoosedPaperItems( items );
  }, [ choosedPapers ]); 
  const onSave = () => {
    setSaved( true );
  }
  const queryWallpapers = ( codes, condition, setter ) => {
    console.log( codes )
    if ( codes && codes.length ) {
      condition[ "codes" ] = codes.join( "," );
    }
    axios.get( '/api/wallpapers', {
      params: condition
    }).then( result => {
      if( codes === null ){
        setter( result.data )
      }
      else{
      const resItems = result.data;
      const reqItems = codes;
      console.log( reqItems )
      const TESTab = [];
      for( var i = 0; i <= resItems.length - 1; i++ ){
        if(reqItems[ i ] === resItems[ i ].code){
          TESTab.push( resItems[ i ] )
        }
        else{
          for( var a = 0; a <= resItems.length - 1; a++ ){
              if( reqItems[ i ] === resItems[ a ].code ){
              TESTab.push( resItems[ a ] )
            }
          }
        }
      }
      setter( TESTab )
    }
    });
  }
  const onClickConfirm = () => {
    let condition = {};
    if ( rgb ) {
      setChoosedColor1Papers( null );
      setChoosedColor2Papers( null );
      setChoosedColor3Papers( null );
      axios.get( "/api/queryProducts", {
       params: {
         url: GET_GROUPED_PRODUCTS_FROM_RGB_URL + [ rgb.r, rgb.g, rgb.b ].join( "," )
       }
     }).then(result => {
      console.log( "確認用:" + GET_GROUPED_PRODUCTS_FROM_RGB_URL + [ rgb.r, rgb.g, rgb.b ] )
       const colors = [ "color1", "color2", "color3" ];
       colors.forEach( ( color, idx)  => {
         let setter = color === "color1"
                  ? setChoosedColor1Papers
                  : color === "color2"
                  ? setChoosedColor2Papers
                  : setChoosedColor3Papers;
         if ( !result.data[color].length ) {
           setter( [] );
         } else {
          queryWallpapers( result.data[ color ], condition, setter );
         }
       });
      });
    } else {
      queryWallpapers( null, condition, setChoosedPapers );
    }
  }; 
    const onChangeWindowType = ( e ) => {
    setWindowType( e.target.value );
  }
  const onSelectPaper = ( e ) => {
        setWallpaperCode( e.target.value );
      }
  const simulation = {
    height: "00px",
    width: "100%",
  };
  const choise = {
    width: "100%",
  };
  const bgstyle = {
    backgroundColor:"rgba(62,58,57,0.1)",
  };
  const styleColor = {
    backgroundColor:"#046B5E",
  }
  const backStyleColor = {
    backgroundColor:"rgba(4,107,94,0.3)",
  }
  function downloadImage ( data ) {
		var fname ="image.png";
		var encdata= atob( data.replace(/^.*,/, '') );
		var outdata = new Uint8Array( encdata.length );
		for ( var i = 0; i < encdata.length; i++ ) {
			outdata[ i ] = encdata.charCodeAt( i );
		}
		var blob = new Blob( [ outdata ], [ "image/png" ] );
		
		if ( window.navigator.msSaveBlob)  {
			// IE用
			window.navigator.msSaveOrOpenBlob( blob, fname );
		} else {
      document.getElementById( "getImage" ).href = data;
			document.getElementById( "getImage" ).download = fname;
			document.getElementById( "getImage" ).click();
		}
  }
  const onClickSave = () => {
    document.querySelector( "#logo" ).style.display = "block";
    document.querySelector( "#date_time" ).style.display = "block";
    document.querySelector( "#products" ).style.display = "block";
    window.scrollTo(0,0);
    let canvasW = document.getElementById( "simulationArea" ).clientWidth;
    let canvasH = canvasW / 1.5;
    html2canvas( document.querySelector( "#simulationArea" ), {
        scrollX:-7,
        width: canvasW,
        height: canvasH,
        allowTaint : false,
        useCORS: true
    }).then( canvas => { 
        downloadImage( canvas.toDataURL() );
    });
  }

  const testColor = ( rgb === null )?{}:{ background:'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + 1 + ')', textShadow:"1px 1px 0 #000" };

  const [ dropClickRoom, setDropClickRoom ] = useState(0);
  const [ dropClickConstruction, setDropClickConstruction ] = useState(0);
  const [ dropClickColor, setDropClickColor ] = useState(0);
  const [ dropClickNumber, setDropClickNumber ] = useState(0);
  const [ dropClickResult, setDropClickResult ] = useState(0);
  const onClickDropRoom = () =>{
    setDropClickRoom( dropClickRoom + 1 )
  }
  const onClickDropConstruction = () =>{
    setDropClickConstruction( dropClickConstruction + 1 )
  }
  const onClickDropColor = () =>{
    setDropClickColor( dropClickColor + 1 )
  }
  const onClickDropNumber = () =>{
    setDropClickNumber( dropClickNumber + 1 )
  }
  const onClickDropResult = () =>{
    setDropClickResult( dropClickResult + 1 )
  }

  return(
    <ImageProvider>
      <div className="CGSeletPapers">
        <div className="container">
          <div className="row">
            {
              baseFrom === "cg" ?
              (
                <div
                  className="CGspace col-12 col-md-8 px-0"
                  style={ simulation }
                >
                  <SimulationImage
                    baseFrom={ baseFrom }
                    productsImage={ cgProducts }
                    productsCode={ [ { name:"wallpapers", code:wallpaperCode } ] }
                    onSaveHandler={ onSave }
                  />
                </div>
              ):
              baseFrom === "photo"?
              (
                <SimulationImage
                  baseFrom={ baseFrom }
                  productsImage={ photoProducts } 
                  productsCode={ [ { name: "roll", code:wallpaperCode } ] }
                  roomBaseImage={ roomBaseImage }
                  onSaveHandler={ onSave }
                />
              ):
              (
                <>
                  <SimulationImage
                    baseFrom={ baseFrom }
                    productsImage={ constructionProducts } 
                    productsCode={ [ { name:"roll", code:wallpaperCode } ] }
                    roomBaseImage={ roomBaseImage }
                    onSaveHandler={ onSave }
                  />
                </>
              )
            }
            {
            /* <div className="Choices col-12 col-md-4" style={choise}> */}
            <div className="Choices col-12 col-md-4"
              style={
                ( window.innerWidth >= 767 )?
                {
                  // 横並びの時
                }
                :
                (
                  baseFrom==="cg"?
                  {
                    top:( window.innerWidth / 1.5 ), scrollY:"0px"
                  }
                  :
                  {
                    top:( window.innerWidth / 1.5 )
                  }
                )
              }
            >
              <div className="CGPaper text-center bg-dark text-white py-1">
                {
                  baseFrom === "cg"?
                    "背景:CG  アクセントクロス":
                    baseFrom === "photo"?
                    "背景:写真  アクセントクロス"
                    :
                    "背景:施工例  アクセントクロス"
                }
              </div>
              {
                baseFrom === "cg" ?
                (
                  <div style={ bgstyle }>
                    <div className="box_Room">
                    <label 
                      htmlFor="inbox_Room"
                      className="border border-dark text-center my-0"
                      onClick = { onClickDropRoom }
                      style={
                        dropClickRoom === 0?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        ((dropClickRoom % 2) == 0)?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        {
                          backgroundColor:"white"
                        }
                      }
                    >
                      {
                        dropClickRoom === 0?
                        <div className="text-white container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                            部屋(シミュレーション背景)
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                          {/* 部屋(シミュレーション背景)
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                          </svg> */}
                        </div>
                        :
                        ((dropClickRoom % 2) == 0)?
                        <div className="text-white container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                              部屋(シミュレーション背景)
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                          {/* 部屋(シミュレーション背景)
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                          </svg> */}
                        </div>
                        :
                        <div className="container py-2">
                          <div className="row">
                            <div className="col-10 w-100 text-left">
                              部屋(シミュレーション背景)
                            </div>
                            <div className="col-2 w-100 text-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                              </svg>
                            </div>
                          </div>
                          {/* 部屋(シミュレーション背景)
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                            <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                          </svg> */}
                        </div>
                      }
                    </label>
                    <input
                      type="checkbox"
                      id="inbox_Room"
                      className="on-off_Room"
                    />
                    <div className="pb-3" style={{overflowY:"scroll",height:"260px"}}>
                      <RoomArranger
                        imagePathSetters={ imgPathSetters }
                      />
                      <FrontWallArranger
                        imagePathSetters={ { "base": setBaseImgPath } }
                        onWindowTypeChangeHandler={ onChangeWindowType }
                      /> 
                    </div>
                  </div>
                    {/* <RoomArranger
                      imagePathSetters={ imgPathSetters }
                    />
                    <FrontWallArranger
                      imagePathSetters={ { "base": setBaseImgPath } }
                      onWindowTypeChangeHandler={ onChangeWindowType }
                    /> */}
                  </div>
                )
                :baseFrom ==="photo"?
                (
                  <>
                  </>
                )
                :
                (
                  <>
                  <div className="box_Construction">
                    <label 
                      htmlFor="inbox_Construction"
                      className="border border-dark text-center my-0"
                      onClick = { onClickDropConstruction }
                      style={
                        dropClickConstruction === 0?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        ((dropClickConstruction % 2) == 0)?
                        {
                          backgroundColor:"#6c757d"
                        }
                        :
                        {
                          backgroundColor:"white"
                        }
                      }
                    >
                    {
                      dropClickConstruction === 0?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            サンプル写真
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* サンプル写真
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> */}
                      </div>
                      :
                      ((dropClickConstruction % 2) == 0)?
                      <div className="text-white container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            サンプル写真
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* サンプル写真
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> */}
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            サンプル写真
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* サンプル写真
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg> */}
                      </div>
                    }
                    </label>
                    <input
                      type="checkbox"
                      id="inbox_Construction"
                      className="on-off_Construction"
                    />
                    <div className="pb-3" style={{overflowY:"scroll",height:"260px"}}>
                      <ConstructionArranger
                        imagePathSetters = { { "base":setBaseImgPath } }
                      />
                    </div>
                  </div>
                  {/* <ConstructionArranger
                    imagePathSetters={ { "base": setBaseImgPath } }
                  /> */}
                  </>
                )
              }
              {/* <div className="row">
                <div className="col-12 text-center text-white">
                  <h3
                    className="w-100 mb-0"
                    style={ styleColor }
                  >
                    WallPaper
                  </h3>
                </div>
              </div> */}
              <div style={ backStyleColor }>
                <div className="box_ColorPicker">
                  <label 
                    htmlFor="inbox_ColorPicker"
                    className="border border-dark text-center my-0"
                    onClick = { onClickDropColor}
                    style={
                      dropClickColor === 0?
                      {
                        backgroundColor:"#cce9dd"
                      }
                      :
                      testColor
                    }
                  >
                    {
                      dropClickColor === 0?
                      <div className="text-dark container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            アクセントクロス  カラー
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* アクセントクロス  カラー
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> */}
                      </div>
                      :
                      ((dropClickColor % 2) == 0)?
                      <div className="text-dark container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            アクセントクロス  カラー
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* アクセントクロス  カラー
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> */}
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            アクセントクロス  カラー
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* アクセントクロス  カラー
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg> */}
                      </div>
                    }
                  </label>
                  <input
                    type="checkbox"
                    id="inbox_ColorPicker"
                    className="on-off_ColorPicker"
                  />
                  <div className="pb-3" style={{ overflowY: "scroll", height: "315px" }}>
                    <ColorSelector
                      colorSetter = { setRGB }
                      roomBaseImage = { roomBaseImage }
                      baseImgPath = { baseImgPath }
                      baseFrom = { baseFrom }
                    />
                  </div>
                </div>
                {/* <ColorSelector
                  colorSetter={ setRGB }
                /> */}
                <div className="box_Number">
                  <label 
                    htmlFor="inbox_Number"
                    className="border border-dark text-center my-0 w-100"
                    onClick = { onClickDropNumber }
                    style={
                      dropClickNumber === 0?
                      {
                        backgroundColor:"#cce9dd"
                      }
                      :
                      ((dropClickNumber % 2) == 0)?
                      {
                        backgroundColor:"#cce9dd"
                      }
                      :
                      {
                        backgroundColor:"white"
                      }
                    }
                  >
                    {
                      dropClickNumber === 0?
                      <div className="text-dark container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            アクセントクロス  品番検索
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* アクセントクロス<br/>品番検索
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> */}
                      </div>
                      :
                      ((dropClickNumber % 2) == 0)?
                      <div className="text-dark container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            アクセントクロス  品番検索
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* アクセントクロス<br/>品番検索
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> */}
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            アクセントクロス  品番検索
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* アクセントクロス<br/>品番検索
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg> */}
                      </div>
                    }
                  </label>
                  <input
                    type="checkbox"
                    id="inbox_Number"
                    className="on-off_Number"
                  />
                  <div>
                  <ProductNumber
                    apiPath="/api/wallpapers/"
                    choosedSetter={ setSpecifiedPaper }
                  />
                  <CustomScrollImageItems
                    name="targetProduct"
                    title="対象商品"
                    options={ specifiedPaperItems }
                    multiSelectable={ false }
                    onClick={ onSelectPaper }
                  />
                  </div>
                </div>
                {/* <ProductNumber
                  apiPath="/api/wallpapers/"
                  choosedSetter={ setSpecifiedPaper }
                />
                <CustomScrollImageItems
                  name="targetProduct"
                  title="対象商品"
                  options={ specifiedPaperItems }
                  multiSelectable={ false }
                  onClick={ onSelectPaper }
                /> */}
                <div className="box_result">
                  <label 
                    htmlFor="inbox_result"
                    className="border border-dark text-center my-0 w-100"
                    onClick = { onClickDropResult }
                    style={
                      dropClickResult === 0?
                      {
                        backgroundColor:"#cce9dd"
                      }
                      :
                      ((dropClickResult % 2) == 0)?
                      {
                        backgroundColor:"#cce9dd"
                      }
                      :
                      {
                        backgroundColor:"white"
                      }
                    }
                  >
                    {
                      dropClickResult === 0?
                      <div className="text-dark container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            アクセントクロス  結果表示
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* ロールスクリーン  結果表示
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> */}
                      </div>
                      :
                      ((dropClickResult % 2) == 0)?
                      <div className="text-dark container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                          アクセントクロス  結果表示
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* ロールスクリーン  結果表示
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> */}
                      </div>
                      :
                      <div className="container py-2">
                        <div className="row">
                          <div className="col-10 w-100 text-left">
                            アクセントクロス  結果表示
                          </div>
                          <div className="col-2 w-100 text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                          </div>
                        </div>
                        {/* ロールスクリーン  結果表示
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg> */}
                      </div>
                    }
                  </label>
                  <input
                    type="checkbox"
                    id="inbox_result"
                    className="on-off_result"
                  />
                  <div>
                    <div className="container my-3">
                      <div className="row justify-content-center">
                        <button
                          id="btnConfirm"
                          className="col-8 text-center btn btn-secondary py-3 font-weight-bold"
                          onClick={ onClickConfirm }
                          style={ testColor }
                        >
                          アクセントクロスをみる
                        </button>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-12 text-secondary text-center">
                          <h3>
                            アクセントクロス一覧
                          </h3>
                          {
                            rgb && choosedColor1Papers && choosedColor2Papers && choosedColor3Papers &&
                            (
                              <ItemSelectorDivideByColor
                                color1Data={ choosedColor1Papers }
                                color2Data={ choosedColor2Papers }
                                color3Data={ choosedColor3Papers }
                                iconURL="https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/WallPapers/icon/"
                                selectedSetter={ setWallpaperCode }
                              />
                            )
                          }
                          {
                          !rgb && choosedPapers &&
                          (
                            <CustomScrollImageItems
                              name="choosedProducts"
                              title="抽出商品"
                              options={ choosedPaperItems }
                              multiSelectable={ false }
                              onClick={ onSelectPaper }
                            />
                          )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="container my-3">
                  <div className="row justify-content-center">
                    <button
                      id="btnConfirm"
                      className="col-8 text-center btn btn-secondary py-3 font-weight-bold"
                      onClick={ onClickConfirm }
                      style={ testColor }
                    >
                      アクセントクロスをみる
                    </button>
                  </div>
                </div>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12 text-secondary text-center">
                      <h3>
                        アクセントクロス一覧
                      </h3>
                      {
                        rgb && choosedColor1Papers && choosedColor2Papers && choosedColor3Papers &&
                        (
                          <ItemSelectorDivideByColor
                            color1Data={ choosedColor1Papers }
                            color2Data={ choosedColor2Papers }
                            color3Data={ choosedColor3Papers }
                            iconURL="https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/WallPapers/icon/"
                            selectedSetter={ setWallpaperCode }
                          />
                        )
                      }
                      {
                      !rgb && choosedPapers &&
                      (
                        <CustomScrollImageItems
                          name="choosedProducts"
                          title="抽出商品"
                          options={ choosedPaperItems }
                          multiSelectable={ false }
                          onClick={ onSelectPaper }
                        />
                      )
                      }
                    </div>
                  </div>
                </div> */}
              </div>
              <div
                className="container mt-0 py-3 border border-dark"
                style={ bgstyle }
              >
                <div className="row">
                  <div className="col-6">
                  <button
                    id="btnSave"
                    className="w-100 btn btn-dark"
                    onClick={ onClickSave }
                  >
                    保　存
                  </button>
                    <a
                      id="getImage"
                      href=""
                      style={ { display: "none" } }
                      download="image.png"
                    >
                      画像保存
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      href="https://www.i-koike.jp/contact/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        id=""
                        className="w-100 btn btn-info"
                      >
                        来店予約
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ImageProvider>
  );
})

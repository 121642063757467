import React, { useEffect, useState } from "react";
import TopWord from "./top_word";
// import Header from "./Header";
import RoomSelect from "./RoomSelect";
import CurtainType from "./CurtainType";
import RailColor from "./RailColor";
import FunctionPattern from "./FunctionPattern";
import PriceRange from "./PriceRange";
import ExternalAPI from "./ExternalAPI";
import LightSimulator from "./LightSimulator";

function CG() {

// RoomSelect.js
  const [ selectedRoomInfo, setSelectedRoomInfo ] = useState( null );
  const handleRoomSelect = ( roomInfo ) => {
    setSelectedRoomInfo( roomInfo );
  };

// RailColor.js
  const [ selectedRailInfo, setSelectedRailInfo ] = useState( null );
  const handleRailSelect = ( railInfo ) => {
    setSelectedRailInfo( railInfo );
  }

// CurtainType.js
  const [ selectedCurtainType, setSelectedCurtainType ] = useState( null );
  const handleCurtainTypeSelect = ( value ) => {
    setSelectedCurtainType( value );
  };

// FunctionPattern.jsで絞り込みされたデータ
  const [ apiData, setApiData ] = useState( null );
  const handleApiData = ( data ) => {
    setApiData( data );
  };
// FunctionPattern.jsで選択したボタンの内容
  const [ selectedData, setSelectedData ] = useState( [] );
  const [ receivedNewFunctions, setReceivedNewFunctions ] = useState( null );
  const handleSelectedData = ( data, newFunctions ) => {
    setSelectedData( data );
    setReceivedNewFunctions( newFunctions );
  };

// PriceRange.jsで選択したボタンの内容
  const [ selectedPriceRange, setSelectedPriceRange ] = useState( [] );
  const updateSelectedPriceRange = ( selectedRange ) => {
    setSelectedPriceRange( selectedRange );
  };
  const [ filteredData, setFilteredData ] = useState( [] );
  const updateFilteredData = ( data ) => {
    setFilteredData( data );
  };

// ExternalAPI.jsで選択したボタンの内容
  const [ selectedColors, setSelectedColors ] = useState( [] );
  const handleColorSelect = ( selectedColor ) => {
    setSelectedColors( selectedColor );
  };

// 戻る・進むボタンの管理
  const [ currentIndex, setCurrentIndex ] = useState( 0 );
// 次へボタン
  const handleNextClick = () => {
    if(( currentIndex === 1 && selectedCurtainType === "roll" ) || ( currentIndex === 4 && selectedCurtainType === "lace" )){
      setCurrentIndex( currentIndex + 2 );
    }else{
      setCurrentIndex( currentIndex + 1 );
    }
  };
// 戻るボタン
  const handleBackClick = () => {
    if( currentIndex === 3 && selectedCurtainType === "roll" ){
      setCurrentIndex( currentIndex - 2 );
    }else{
      setCurrentIndex( currentIndex - 1 );
    }
  };

  const [changes, setChanges] = useState( null );
  const handleChangesFromSimulator = ( newChanges ) => {
    console.log('Handle Changes called');
    console.log('Current changes:', changes);
    console.log('New changes:', newChanges);
    
    // changesと新しい値が同じでない場合にのみ変更をセットする
    if (changes !== newChanges) {
      console.log('Changes from Simulator:', newChanges);
      setChanges(newChanges);
    }
  };

  return (
    <div className="CG">
      <div
        className="container"
        style={{ display: currentIndex === 6 ? "none" : "block" }}
      >
        <div className="row">
          <TopWord />
        </div>
      </div>
      <div style={{ display: currentIndex === 0 ? "block" : "none" }}>
        <RoomSelect
          onSelect={ handleRoomSelect }
          onNextClick={ handleNextClick }
          onBackClick={ handleBackClick }
        />
      </div>
      <div style={{ display: currentIndex === 1 ? "block" : "none" }}>
        <CurtainType
          onSelectCurtainType={ handleCurtainTypeSelect }
          onNextClick={ handleNextClick }
          onBackClick={ handleBackClick }
        />
      </div>
      <div style={{ display: currentIndex === 2 ? "block" : "none" }}>
        <RailColor
          onSelect={ handleRailSelect }
          onNextClick={ handleNextClick }
          onBackClick={ handleBackClick }
        />
      </div>
      <div style={{ display: currentIndex === 3 ? "block" : "none" }}>
        <FunctionPattern
          selectedCurtainType={ selectedCurtainType }
          onApiData={ handleApiData }
          onSelectedData={ handleSelectedData }
          onNextClick={ handleNextClick }
          onBackClick={ handleBackClick }
        />
      </div>
      <div style={{ display: currentIndex === 4 ? "block" : "none" }}>
        <PriceRange
          apiData={ apiData }
          updateFilteredData={ updateFilteredData }
          updateSelectedPriceRange={ updateSelectedPriceRange }
          onNextClick={ handleNextClick }
          onBackClick={ handleBackClick }
        />
      </div>
      <div style={{ display: currentIndex === 5 ? "block" : "none" }}>
        <ExternalAPI
          selectedCurtainType={ selectedCurtainType }
          filteredData={ filteredData }
          onColorSelect={ handleColorSelect }
          onNextClick={ handleNextClick }
          onBackClick={ handleBackClick }
        />
      </div>
      <div style={{ display: currentIndex === 6 ? "block" : "none" }}>
        <LightSimulator
          selectedRoomInfo={ selectedRoomInfo }
          selectedRailInfo={ selectedRailInfo }
          selectedCurtainType={ selectedCurtainType }
          receivedNewFunctions={ receivedNewFunctions }
          selectedData={ selectedData }
          selectedPriceRange={ selectedPriceRange }
          selectedColors={ selectedColors }
          handleChanges={ handleChangesFromSimulator }
        />
      </div>
    </div>
  );
}

export default CG;


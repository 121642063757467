
import React, { useState, useEffect, useRef, useCallback } from "react";
import "./simulator.css";

function ExsampleImageColorSwatch({ onGetColor = () => {}, onSetColor = () => {} },props) {
  const canvasRef = useRef();
  const [state, setState] = useState({
    color: { r: 0, g: 0, b: 0, hex: "" },
  });
  const { onClick } = props;
  const [ sImg, setSImg ] = useState( "sample_1" );
  
  const drawCanvas = useCallback(() => {
    let canvas = canvasRef.current;
    let context = canvas.getContext( "2d" );
    let Img = new Image();
    Img.crossOrigin = "Anonymous";
    Img.src = "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/ExampleImages/" + sImg + ".jpg";
    Img.onload = () => {
      canvas.width = Img.width;
      canvas.height = Img.height;
      context.drawImage(Img, 0, 0, Img.width, Img.height);
    };
  }, [ canvasRef ]);

  useEffect(() => {
    drawCanvas();
  }, [ drawCanvas ]);

  const getColor = ( e ) => {
    let canvas = canvasRef.current;
    let context = canvas.getContext( "2d" );
    let rect = canvas.getBoundingClientRect();
    let clientX, clientY;
    if ( e.touches ) {
      clientX = e.touches[ 0 ].clientX;
      clientY = e.touches[ 0 ].clientY;
    } else {
      clientX = e.clientX;
      clientY = e.clientY;
    }
    let x = clientX - rect.left;
    let y = clientY - rect.top;
    x = x * ( canvas.width / rect.width );
    y = y * ( canvas.height / rect.height );
    // 小池さん指定レンジ
    const Range = 5;
    const colorRange = ( Range * 2 ) + 1;
    let color = context.getImageData( x, y, colorRange, colorRange ).data;
    if ( color[ 0 ] !== 0 && color[ 1 ] !== 0 && color[ 2 ] !== 0 ) {
      onGetColor({
        r: color[ 0 ],
        g: color[ 1 ],
        b: color[ 2 ],
        a: color[ 3 ],
        hex: rgbToHex( color[ 0 ], color[ 1 ], color[ 2 ] ),
      });
      setState({
        ...state,
        color: {
          r: color[ 0 ],
          g: color[ 1 ],
          b: color[ 2 ],
          a: color[ 3 ],
          hex: rgbToHex( color[ 0 ], color[ 1 ], color[ 2 ] ),
        },
      });
    }
    return false;
  };

  function componentToHex( c ) {
    var hex = c.toString( 16 );
    return hex.length === 1 ? "0" + hex : hex;
  }
  function rgbToHex( r, g, b ) {
    return "#" + componentToHex( r ) + componentToHex( g ) + componentToHex( b );
  }
  const SampleImage = [
    { name:"A", value:"sample_1", check:true, },
    { name:"B", value:"sample_2", check:false, },
    // { name:"C", value:"sample_3", check:false, },
    // { name:"D", value:"sample_4", check:false, },
    // { name:"E", value:"sample_5", check:false, },
    // { name:"F", value:"sample_6", check:false, },
    // { name:"G", value:"sample_7", check:false, },
    // { name:"H", value:"sample_8", check:false, },
    // { name:"I", value:"sample_9", check:false, },
    // { name:"J", value:"sample_10", check:false, },
    // { name:"K", value:"sample_11", check:false, },
    // { name:"L", value:"sample_12", check:false, },
    // { name:"M", value:"sample_13", check:false, },
    // { name:"N", value:"sample_14", check:false, },
    // { name:"O", value:"sample_15", check:false, },
  ]
  const horizontalListStyle = {
    whiteSpace: "nowrap",
    backgroundColor:"white",
  };
  const handleClick = e => {
    let value = e.target.value;
    setSImg( value );
    let canvas = canvasRef.current;
    let context = canvas.getContext( "2d" );
    let Img = new Image();
    Img.crossOrigin = "Anonymous";
    Img.src = "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/ExampleImages/" + value + ".jpg";
    Img.onload = () => {
      canvas.width = Img.width;
      canvas.height = Img.height;
      context.drawImage( Img, 0, 0, Img.width, Img.height );
    };
  };
  const handleChange = e => {
    let value = e.target.value;
    setSImg( value );
    let canvas = canvasRef.current;
    let context = canvas.getContext( "2d" );
    let Img = new Image();
    Img.crossOrigin = "Anonymous";
    Img.src = "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/ExampleImages/" + value + ".jpg";
    // alert(Img.src)
    Img.onload = () => {
      canvas.width = Img.width;
      canvas.height = Img.height;
      context.drawImage( Img, 0, 0, Img.width, Img.height );
    };
  };

  return (
    <div>
      <div className="container my-3">
        <div className="row">
          <div className="col-12 align-self-center">
            <div className="Floor_color font-weight-bold text-secondary">
            </div>
          </div>
          <ul
            className="horizontal-list px-0 w-100 mx-1 my-0"
            style={ horizontalListStyle }
            data-simplebar
            data-simplebar-auto-hide="false"
          >
            {
              SampleImage.map( ( Img,index ) => (
                <div
                  className="col-5 w-100 text-center px-1 mt-1"
                  style={ { display: "inline-block" } }
                  key={ index }
                >
                  <input
                    className = "radio-input"
                    type = 'radio'
                    name = "name"
                    onClick = { handleClick }
                    onChange = { handleChange }
                    value = { Img.value }
                    id = { Img.value }
                  />
                  <label
                    htmlFor={ Img.value }
                    className={ "w-100 text-center" }
                  >
                    <img
                      src = { 
                        "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/ExampleImages/"
                        +
                        Img.value
                        +
                        ".jpg?"
                        +
                        new Date() 
                      }
                      alt = {
                        "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/ExampleImages/"
                        +
                        Img.value
                        +
                        ".jpg"
                      }
                      className = "w-100 mx-0 px-0"
                      style = { { useCORS:true, } }
                      crossOrigin = "anonymous"
                    />
                  </label>
                </div>
              ))
            }
          </ul>
        </div>
      </div>
      <div className="processed-image-container">
        <div className="processed-image-base">
          <canvas
            ref = { canvasRef }
            id = "result-canvas"
            style = {
              {
                border:"1px solid #333",
                width:"100%",
                cursor:"crosshair",
              }
            }
            onClick={
              () => {
                onSetColor( state.color );
              }
            }
            onMouseMove={
              ( e ) => {
                getColor( e );
              }
            }
            onTouchMove={ getColor }
          >
          </canvas>
        </div>
      </div>
    </div>
  );
}

export default ExsampleImageColorSwatch;

import React from "react";
import NoImage from "../img/noimage.png"

const Shadow = {
  boxShadow: "0px 5px 3px #95BAB5"
}

function HowToUse(){
  return(
    <div
      className="mt-5"
      style={{ backgroundColor:"rgb(222, 236, 237)" }}
    >
      {/* カリコとは?_PC */}
      <div className="container d-none d-md-block">
        <div className="row align-items-center justify-content-center WhatCureco">
          <div
            className="col-12 bg-white my-5 rounded"
            style={ Shadow }
          >
            <div className="container my-5">
              <div className="row">
                <div className="col-6 px-0">
                  <div className="fw-bold">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 16 16"
                      fill="#046B5E"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                    </svg>
                    &nbsp;&nbsp;&nbsp;カリコとは?
                  </div>
                  <p style={{ fontSize:"14px" }}>
                    テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。
                  </p>
                </div>
                <div className="col-6 px-0">
                  <div className="container">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-5 text-center">
                        <img
                          src={ NoImage }
                          alt={ NoImage }
                          className="w-100"
                        />
                        <p 
                          className="my-3"
                          style={{ fontSize:"15px" }}
                        >
                          自宅の写真を撮って・・・
                        </p>
                      </div>
                      <div
                        className="col-2 text-center"
                        style={{
                          fontSize:"36px",
                          color:"rgb(204, 233, 221)"
                        }}
                      >
                        ▶︎
                      </div>
                      <div className="col-5 text-center">
                        <img
                          src={ NoImage }
                          alt={ NoImage }
                          className="w-100"
                        />
                        <p 
                          className="my-3"
                          style={{ fontSize:"15px" }}
                        >
                          色々なカーテンに着せ替えてみよう!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* カリコとは?_ケイタイ */}
      <div className="container d-block d-md-none">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-12 bg-white my-5 rounded"
            style={ Shadow }
          >
            <div className="container my-5">
              <div className="row">
                <div className="col-12 text-right px-0">
                  <div className="fw-bold">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 16 16"
                      fill="#046B5E"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                    </svg>
                    &nbsp;&nbsp;&nbsp;カリコとは?
                  </div>
                </div>
                <div className="col-12 px-0">
                  <div className="container">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-12 text-center">
                        <img
                          src={ NoImage }
                          alt={ NoImage }
                          className="w-100"
                        />
                        <p
                          className="my-3"
                          style={{ fontSize:"12px" }}
                        >
                          自宅の写真を撮って・・・
                        </p>
                      </div>
                      <div
                        className="col-12 text-center"
                        style={{
                          fontSize:"36px",
                          color:"rgb(204, 233, 221)"
                        }}
                      >
                        ▼
                      </div>
                      <div className="col-12 text-center">
                        <img
                          src={ NoImage }
                          alt={ NoImage }
                          className="w-100"
                        />
                        <p
                          className="my-3"
                          style={{ fontSize:"12px" }}
                        >
                          色々なカーテンに着せ替えてみよう!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-right px-0">
                  <p style={{ fontSize:"14px" }}>
                    テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowToUse;

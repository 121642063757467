import React, { useState } from "react";
import ColorPickerImage from './ColorPickerImage';
import ColorSwatch from './ColorSwatch';
import BackImgColorSwatch from './BackImgColorSwatch';
import ExsampleImageColorSwatch from './ExsampleImageColorSwatch';
export const RgbSelector = ( props ) => {

  const { colorFrom, rgbSetter, baseImgPath, roomBaseImage } = props;
  const [ colorSwatchState, setSwatchState ] = useState( {} );
  const [ colorSwatchHoverState, setSwatchHoverState ] = useState( {} );
  const [ colorImageState, setImageState ] = useState( {} );
  const [ colorImageHoverState, setImageHoverState ] = useState( {} );
  return (
    <div className="bg-white border border-secondary">
      {
        colorFrom === 'palette' &&
        (
          <div className="d-flex flex-column div_margined">
            <ColorSwatch
              onGetColor={
                ( color ) => {
                  setSwatchHoverState( color );
                }
              }
              onSetColor={
                ( color ) => {
                  setSwatchState( color );
                  rgbSetter( color );
                }
              }
            />
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div
                  className = "col-6 w-100 px-0"
                  id = 'rgb_disp'
                >
                  <div 
                    style = { 
                      { 
                        height:30,
                        width:"100%",
                        border:"1px solid black",
                        backgroundColor:colorSwatchHoverState.hex,
                      }
                    } 
                    className="text-center"
                  >
                    <div className="py-0">
                      <div style= { { fontSize:"3px" } } >
                        カーソル色
                      </div>
                    </div>
                    <div 
                      className = "text-center py-0"
                      style = { { fontSize:"3px" } }
                    >
                      R:{ colorSwatchHoverState.r }
                      G:{ colorSwatchHoverState.g }
                      B:{ colorSwatchHoverState.b } 
                    </div>
                  </div>
                </div>
                <div
                  className = "col-6 w-100 px-0"
                  id = 'rgb_disp2'
                >
                  <div 
                    style = {
                      { 
                        height:30,
                        width:"100%",
                        border:"1px solid black",
                        backgroundColor:colorSwatchState.hex,
                      } 
                    } 
                    className="text-center"
                  >
                    <div className="my-0 py-0">
                      <div style = { { fontSize:"3px" } } >
                        選択した色
                      </div>
                    </div>
                    <div
                      className = "text-center"
                      style = { { fontSize:"3px" } }
                    >
                      R:{ colorSwatchState.r }
                      G:{ colorSwatchState.g }
                      B:{ colorSwatchState.b }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        colorFrom === 'photo' &&
        (
          <div>
            <ColorPickerImage
              onGetColor={
                ( color ) => {
                  setImageHoverState( color );
                }
              }
              onSetColor={
                ( color ) => {
                  setImageState( color );
                  rgbSetter( color );
                }
              }
            />
            <div className="container">
              <div className="row">
                <div className="col-6 w-100 text-center">
                  <div
                    style = {
                      {
                        height:30,
                        border:"1px solid black",
                        backgroundColor:colorImageHoverState.hex,
                      }
                    }
                  >
                    <div className="my-0 py-0">
                      <div style = { { fontSize:"3px" } } >
                        カーソル色
                      </div>
                    </div >
                    {
                      colorImageHoverState &&
                      (
                        <div
                          className = "text-center"
                          style = { { fontSize:"3px" } }
                        >
                          R:{ colorImageHoverState.r }
                          G:{ colorImageHoverState.g }
                          B:{ colorImageHoverState.b }
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className="col-6 w-100 text-center">
                  <div 
                    style = {
                      {
                        height:30,
                        border:"1px solid black",
                        backgroundColor:colorImageState.hex
                      } 
                    }
                  >
                    <div className="my-0 py-0">
                      <div style = { { fontSize:"3px" } }>
                        選択した色
                      </div>
                    </div>
                    {
                      colorImageState &&
                      (
                        <div
                          className = "text-center"
                          style = { { fontSize:"3px" } }
                        >
                          R:{ colorImageState.r }
                          G:{ colorImageState.g }
                          B:{ colorImageState.b } 
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        colorFrom === 'image' &&
        (
          <div className="d-flex flex-column div_margined">
            <ExsampleImageColorSwatch
              onGetColor={
                ( color ) => {
                  setSwatchHoverState( color );
                }
              }
              onSetColor={
                ( color ) => {
                  setSwatchState( color );
                  rgbSetter( color );
                }
              }
            />
            <div className="container">
              <div className="row justify-content-center">
                <div
                  className="col-6 w-100 px-0"
                  id='rgb_disp'
                >
                  <div
                    style = {
                      {
                        height:30,
                        width:"100%",
                        border:"1px solid black",
                        backgroundColor:colorSwatchHoverState.hex,
                      }
                    }
                    className="text-center"
                  >
                    <div className="my-0 py-0">
                        <div style = { { fontSize:"3px" } }>
                          カーソル色
                        </div>
                    </div >
                    <div
                      className = "text-center"
                      style = { { fontSize:"3px" } }
                    >
                      R:{ colorSwatchHoverState.r }
                      G:{ colorSwatchHoverState.g }
                      B:{ colorSwatchHoverState.b }
                    </div>
                  </div>
                </div>
                <div
                  className="col-6 w-100 px-0"
                  id='rgb_disp2'
                >
                  <div
                    style = {
                      {
                        height:30,
                        width:"100%",
                        border:"1px solid black",
                        backgroundColor:colorSwatchState.hex,
                      }
                    }
                    className="text-center"
                  >
                    <div
                      className = "my-0 py-0"
                      style = { { fontSize:"3px" } }
                    >
                      <div style = { { fontSize:"3px" } }>
                        選択した色
                      </div>
                    </div>
                    <div 
                      className = "text-center my-0 py-0"
                      style = { { fontSize:"3px" } }
                    >
                      R:{ colorSwatchState.r }
                      G:{ colorSwatchState.g }
                      B:{ colorSwatchState.b }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        colorFrom === 'back' &&
        (
          <div className="d-flex flex-column div_margined">
            <BackImgColorSwatch
              onGetColor={
                ( color ) => {
                  setSwatchHoverState( color );
                }
              }
              onSetColor={
                ( color ) => {
                  setSwatchState( color );
                  rgbSetter( color );
                }
              }
              roomBaseImage = { roomBaseImage === null? baseImgPath : roomBaseImage }
            />
            <div className="container">
              <div className="row justify-content-center">
                <div
                  className="col-6 w-100 px-0"
                  id='rgb_disp'
                >
                  <div
                    style = {
                      {
                        height:30,
                        width:"100%",
                        border:"1px solid black",
                        backgroundColor:colorSwatchHoverState.hex,
                      }
                    }
                    className="text-center"
                  >
                    <div className="my-0 py-0">
                        <div style = { { fontSize:"3px" } }>
                          カーソル色
                        </div>
                    </div >
                    <div
                      className = "text-center"
                      style = { { fontSize:"3px" } }
                    >
                      R:{ colorSwatchHoverState.r }
                      G:{ colorSwatchHoverState.g }
                      B:{ colorSwatchHoverState.b }
                    </div>
                  </div>
                </div>
                <div
                  className="col-6 w-100 px-0"
                  id='rgb_disp2'
                >
                  <div
                    style = {
                      {
                        height:30,
                        width:"100%",
                        border:"1px solid black",
                        backgroundColor:colorSwatchState.hex,
                      }
                    }
                    className="text-center"
                  >
                    <div
                      className = "my-0 py-0"
                      style = { { fontSize:"3px" } }
                    >
                      <div style = { { fontSize:"3px" } }>
                        選択した色
                      </div>
                    </div>
                    <div 
                      className = "text-center my-0 py-0"
                      style = { { fontSize:"3px" } }
                    >
                      R:{ colorSwatchState.r }
                      G:{ colorSwatchState.g }
                      B:{ colorSwatchState.b }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};


import React, { useState, useRef, useEffect } from "react";
import { useParams, withRouter } from 'react-router-dom';

export const FileSelectorPage = withRouter(props => {
  const { productType, baseFrom, onSelectedPath, onCanceledPath } = useParams();
  const AVOID_DIRECT_OPEN_DIALOG_BROWSERS = [ "safari", "chrome", "crios" ];
  useEffect( () => {
    let ua = window.navigator.userAgent.toLowerCase();
    AVOID_DIRECT_OPEN_DIALOG_BROWSERS.map( 
      ( browser, index ) => {
        if ( ua.indexOf( browser ) != -1 ) return;
      }
    );
    let selectImage = document.getElementById( 'select_label' );
    if ( !selectImage ) return;
  
    let event = document.createEvent( "MouseEvents" );
    event.initEvent( "click", true, false );
    selectImage.dispatchEvent( event );
  }, [ document.getElementById( 'select_label' ) ] );
  
  const onClickBackToTop = () => {
    props.history.push( "/" );
 }

 return(
    <div className="page-container">
      <h3 className="text-center my-3 text-secondary font-weight-bold">
        写真からのシミュレーション
      </h3>
      <div 
        style={
          {
            display:"flex",
            flexDirection:"column",
          }
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-12 my-3">
              <label
                htmlFor = "fileSelector"
                className = "btn btn-dark w-100 py-3"
              >
                背景の画像データを選択してください
              </label>
            </div>
            <div className="col-12 my-3">
              <button
                id = "btnBackToTop"
                className = "btn btn-info py-3"
                onClick = { onClickBackToTop }
              >
                トップに戻る
              </button>
            </div>
            {
              baseFrom === "photo"?
              (
                <div 
                  className = "processed-image-container" 
                  style = { { display: "none" } }
                >
                  <input
                    id = "fileSelector"
                    type = "file"
                    accept = "image/*"
                    onChange = {
                      ( e ) => {
                        let file = e.target.files[ 0 ];
                        if ( file ) {
                          props.history.push(
                            {
                              pathname: 
                                "/"
                                +
                                onSelectedPath
                                +
                                "/"
                                +
                                productType
                                +
                                "/"
                                +
                                baseFrom,
                              state: { roomImageFile: file }
                            }
                          );
                        } else {
                          props.history.push(
                            {
                              pathname: onCanceledPath === "root"?
                              "/"
                              :
                              onCanceledPath
                            }
                          );
                        }
                      }
                    }
                  />
                </div>
              )
              :
              (
                <>
                </>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
})

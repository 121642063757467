import React from "react";
import NoImage from "../img/noimage.png"
import Youtube from "../pages/YouTube";

const Shadow = {
  boxShadow: "0px 5px 3px #95BAB5"
}

function SimulationFlow(){
  return(
    <div style={{ backgroundColor:"rgb(222, 236, 237)" }}>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-12 bg-white my-5 rounded"
            style={ Shadow }
          >
            <div className="container my-3">
              <div className="row">
                <div className="col-12 px-0">
                  <div className="fw-bold">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                  >
                    <circle cx="16" cy="16" r="15" fill="#046B5E" />
                    <svg
                      x="8" 
                      y="8" 
                      width="32" 
                      height="32" 
                      viewBox="0 0 32 32" 
                      fill="#FFFFFF" 
                      className="bi bi-phone-fill"
                    >
                      <path d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z"/>
                    </svg>
                  </svg>
                    &nbsp;&nbsp;&nbsp;シミュレーションの流れ
                  </div>
                </div>
                {/* STEP説明 */}
                <div className="container my-5">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-md-3 col-12">
                      <div
                        className="text-left fw-bold" 
                        style={{ color:"#046B5E" }}
                      >
                        STEP&nbsp;01
                      </div>
                      <img
                        src={ NoImage }
                        className="w-100 px-3"
                      />
                      <p
                        className="mt-3" 
                        style={{ fontSize:"12px" }}
                      >
                        テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。
                      </p>
                    </div>
                    <div className="col-md-1 text-center">
                      <div 
                        className="d-block d-md-none"
                        style={{ 
                          fontSize:"36px", 
                          color:"rgb(204, 233, 221)" 
                        }}
                      >
                        ▼
                      </div>
                      <div
                        className="d-none d-md-block"
                        style={{
                          fontSize:"36px",
                          color:"rgb(204, 233, 221)"
                        }}
                      >
                        ▶︎
                      </div>
                    </div>
                    <div className="col-md-3 col-12">
                      <div
                        className="text-left fw-bold"
                        style={{ color:"#046B5E" }}
                      >
                        STEP&nbsp;02
                      </div>
                      <img
                        src={ NoImage }
                        className="w-100 px-3"
                      />
                      <p
                        className="mt-3"
                        style={{ fontSize:"12px" }}
                      >
                        テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。
                      </p>
                    </div>
                    <div className="col-md-1 text-center">
                      <div
                        className=" d-block d-md-none"
                        style={{
                          fontSize:"36px",
                          color:"rgb(204, 233, 221)"
                        }}
                      >
                        ▼
                      </div>
                      <div
                        className="d-none d-md-block"
                        style={{
                          fontSize:"36px",
                          color:"rgb(204, 233, 221)"
                        }}
                      >
                        ▶︎
                      </div>
                    </div>
                    <div className="col-md-3 col-12">
                      <div
                        className="text-left fw-bold"
                        style={{ color:"#046B5E" }}
                      >
                        STEP&nbsp;03
                      </div>
                      <img
                        src={ NoImage }
                        className="w-100 px-3"
                      />
                      <p
                        className="mt-3"
                        style={{ fontSize:"12px" }}
                      >
                        テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。
                      </p>
                    </div>
                  </div>
                </div>
                {/* YouTube */}
                <Youtube/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimulationFlow;

import React, { useState, useEffect } from "react";
export const CustomToggleButtons = (props) => {
  const { title, name, options, initValue, onChangeHandler } = props;

  const TitleBar = {
    borderLeft: "10px solid #3F8257",
  };

  const handleChange = ( event ) => {
    onChangeHandler( event );
  };


  return(
    <div 
      className="container w-100"
      style={
        title==="カーテン状態(ドレープ)"?
        {
          backgroundColor:"rgba(4, 107, 94, 0.3)"
        }:
        {
          backgroundColor:"rgba(183, 197, 129, 0.3)"
        }
      }
    >
      <div className="row justify-content-between">
        <div 
          className="col-12 font-weight-bold text-secondary"
          style={ TitleBar }
        >
          { title }
        </div>
        <div  className="col-12 text-secondary">
          <div className="row justify-content-around">
            {
              options.map( ( option, index ) => (
                <div 
                  className="col-4 w-100 px-0"
                  key={ index }
                >
                  <input
                    className="radio-input"
                    type="radio"
                    name={ name }
                    onChange={ handleChange }
                    value={ option.value }
                    id={ name + option.value }
                    defaultChecked={ option.value === initValue }
                  />
                  <label
                    className="btn btn-secondary w-100 text-center mx-0"
                    htmlFor={ name + option.value }
                  >
                  <span className="text-white">
                      { option.dispName }
                  </span>
                    {
                    option.dispName === "1.5倍"?
                    (
                      <div>
                        <small>
                          生地少なめ
                        </small>
                      </div>
                    )
                    :option.dispName === "2.0倍"?
                    (
                      <div>
                        <small>
                          生地多め
                        </small>
                      </div>
                    )
                    :
                    (
                      <div></div>
                    )
                    }
                  </label>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

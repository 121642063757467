import React, { useEffect, useState, useRef } from "react";

function PhotoResponse({
  selectedimage,
  photoimage,
  selectedRailInfo,
  RefinementResult,
  currentSelectedCurtainType,
  PriceRangeNarrowdown,
  onSelectCode,
  finalCurtainType,
  finalRailColor,
  finalFunctionPatterns,
  finalPriceRange,
  finalColor,
  selectedCurtainType,
  moveZoomData,
  selectedColors,
  simulatorSize,
  currentIndex
}){
  // console.log(RefinementResult)
  const codesArray = PriceRangeNarrowdown.map( item => item.code );
  const getRailImageUrl =
    selectedRailInfo && ( finalRailColor === null ) ?
      `https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/Simulation/Rail/func_${ selectedRailInfo.railColours }_1.png`
    :
      `https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/Simulation/Rail/func_${ finalRailColor }_1.png`;

  const getCurtainTypePath = () => {
    if( finalCurtainType ){
      if ( finalCurtainType === "drape" ) {
        return "DrapesShades/Drapes";
      } else if ( finalCurtainType === "lace" ) {
        return "Laces/Laces";
      } else if ( finalCurtainType === "roll" ) {
        return "RollScreens/Rolls";
      }
    }else if( selectedCurtainType ){
      if( selectedCurtainType !== null ){
        if ( selectedCurtainType === "drape" ) {
          return "DrapesShades/Drapes";
        } else if ( selectedCurtainType === "lace" ) {
          return "Laces/Laces";
        } else if ( selectedCurtainType === "roll" ) {
          return "RollScreens/Rolls";
        }
      }else{
        return;
      }
    }
  }
  const getImageSuffix = () => {
    if( finalCurtainType ){
      if ( finalCurtainType === "drape" ) {
        return "20";
      } else if ( finalCurtainType === "lace" ) {
        return "15";
      } else if ( finalCurtainType === "roll" ) {
        return "roll";
      }
    }else if( selectedCurtainType ){
      if( selectedCurtainType !== null ){
        if ( selectedCurtainType === "drape" ) {
          return "20";
        } else if ( selectedCurtainType === "lace" ) {
          return "15";
        } else if ( selectedCurtainType === "roll" ) {
          return "roll";
        }
      }else{
        return;
      }
    }
  };
  // icon
  const getCurtainTypeIconPath = () => {
    if( finalCurtainType ){
      if ( finalCurtainType === "drape" ) {
        return "DrapesShades/icon";
      } else if ( finalCurtainType === "lace" ) {
        return "Laces/icon";
      } else if ( finalCurtainType === "roll" ) {
        return "RollScreens/icon";
      }
    }else if( selectedCurtainType ){
      if( selectedCurtainType !== null ){
        if ( selectedCurtainType === "drape" ) {
          return "DrapesShades/icon";
        } else if ( selectedCurtainType === "lace" ) {
          return "Laces/icon";
        } else if ( selectedCurtainType === "roll" ) {
          return "RollScreens/icon";
        }
      }else{
        return;
      }
    }
  };
  // ページネーション
  const paginateData = ( data, itemsPerPage ) => {
    if ( !data ) return [];
    const pageCount = Math.ceil( data.length / itemsPerPage );
    const paginatedData = [];
    for ( let i = 0; i < pageCount; i++ ) {
      const startIndex = i * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      paginatedData.push( data.slice( startIndex, endIndex ) );
    }
    return paginatedData;
  };
  const itemsPerPagePC = 9;
  const itemsPerPageMobile = 8;
  const isMobile = window.matchMedia( "(max-width: 768px)" ).matches;
  let paginatedData;
  if ( finalCurtainType ) {
    if ( finalCurtainType === "drape" || finalCurtainType === "roll" ) {
      paginatedData = isMobile ?
        paginateData( RefinementResult, itemsPerPageMobile )
      :
        paginateData( RefinementResult, itemsPerPagePC );
    } else {
      paginatedData = isMobile ?
        paginateData( codesArray, itemsPerPageMobile )
      :
        paginateData( codesArray, itemsPerPagePC );
    }
  } else if ( selectedCurtainType === "drape" || selectedCurtainType === "roll" ) {
    paginatedData = isMobile ?
      paginateData( RefinementResult, itemsPerPageMobile )
    :
      paginateData( RefinementResult, itemsPerPagePC );
  } else {
    paginatedData = isMobile ?
      paginateData( codesArray, itemsPerPageMobile )
    :
      paginateData( codesArray, itemsPerPagePC );
  }
  const [ currentPage, setCurrentPage ] = useState( 0 );
  const [ selectedCode, setSelectedCode ] = useState( null );
  const handleCurtainItemClick = ( code ) => {
    setSelectedCode( code );
    onSelectCode( code );
  };
  const [ hoveredItems, setHoveredItems ] = useState( {} );
  const handleMouseEnter = (itemCode) => {
    setHoveredItems( ( prev ) => ( { ...prev, [ itemCode ]: true } ) );
  };
  const handleMouseLeave = ( itemCode ) => {
    if ( selectedCode !== itemCode ) {
      setHoveredItems( ( prev ) => ( { ...prev, [ itemCode ]: false } ) );
    }
  };
  const handlePrevPage = () => {
    setCurrentPage( ( prevPage ) => prevPage - 1 );
  };
  const handleNextPage = () => {
    setCurrentPage( ( prevPage ) => prevPage + 1 );
  };

  const pageButtonStyle = {
    backgroundColor: "#ffffff",
    borderColor: "#ffffff",
    color: "#000000",
    fontSize: "10pt",
    borderRadius: "50%",
    width: "35px",
    height: "35px",
  };

  const activePageButtonStyle = {
    backgroundColor: "#046B5E",
    color: "#FFFFFF",
    fontSize: "10pt",
    borderRadius: "50%",
    width: "35px",
    height: "35px",
  };

  const renderPageButtons = () => {
    const pageButtons = Array.from( Array( paginatedData.length ).keys() );
    if ( pageButtons.length <= 10 ) {
      return pageButtons.map(( pageNumber ) => (
        <div
          key={ pageNumber }
          onClick={ () => setCurrentPage( pageNumber ) }
          className={
            currentPage === pageNumber ?
              "active text-center d-flex justify-content-center align-items-center"
            :
              "text-center d-flex justify-content-center align-items-center"
          }
          style={
            currentPage === pageNumber ?
              activePageButtonStyle
            :
              pageButtonStyle
          }
        >
          { pageNumber + 1 }
        </div>
      ));
    } else {
      const firstThreePages = pageButtons.slice( 0, 2 );
      const lastThreePages = pageButtons.slice( -2 );
      const middlePagesStart = Math.max( currentPage - 1, 3 );
      const middlePagesEnd = Math.min(
        currentPage + 1,
        paginatedData.length - 4
      );
      return (
        <>
          {firstThreePages.map(( pageNumber ) => (
            <div
              key={ pageNumber }
              onClick={ () => setCurrentPage( pageNumber ) }
              className={
                currentPage === pageNumber ?
                  "active text-center d-flex justify-content-center align-items-center"
                :
                  "text-center d-flex justify-content-center align-items-center"
              }
              style={
                currentPage === pageNumber ?
                  activePageButtonStyle
                :
                  pageButtonStyle
              }
            >
              { pageNumber + 1 }
            </div>
          ))}
          { middlePagesStart > 3 && <span>...</span> }
          { pageButtons
            .slice( middlePagesStart, middlePagesEnd + 1 )
            .map(( pageNumber ) => (
              <div
                key={ pageNumber }
                onClick={ () => setCurrentPage( pageNumber ) }
                className={
                  currentPage === pageNumber ?
                      "active text-center d-flex justify-content-center align-items-center"
                    :
                      "text-center d-flex justify-content-center align-items-center"
                }
                style={
                  currentPage === pageNumber ?
                    activePageButtonStyle
                  :
                    pageButtonStyle
                }
              >
                { pageNumber + 1 }
              </div>
            ))}
          { middlePagesEnd < paginatedData.length - 4 && <span>...</span> }
          { lastThreePages.map(( pageNumber ) => (
            <div
              key={ pageNumber }
              onClick={ () => setCurrentPage( pageNumber ) }
              className={
                currentPage === pageNumber ?
                  "active text-center d-flex justify-content-center align-items-center"
                :
                  "text-center d-flex justify-content-center align-items-center"
              }
              style={
                currentPage === pageNumber ?
                  activePageButtonStyle
                :
                  pageButtonStyle
              }
            >
              { pageNumber + 1 }
            </div>
          ))}
        </>
      );
    }
  };
  const [ imageTransform, setImageTransform ] = useState( moveZoomData );
  const railImageRef = useRef( null );
  const [ simulationAreaSize, setSimulationAreaSize ] = useState({ width: 0, height: 0 });
  useEffect(() => {
    const railElement = document.querySelector(".item.col-md-4.col-12.text-center.my-3");
    if( railElement && (currentIndex === 10) ){
      const railStyle = window.getComputedStyle( railElement );
      const width = parseFloat( railStyle.width );
      const height = width/1.5;
      setSimulationAreaSize({ width, height });
    }
  }, [ currentIndex ]);
  useEffect(()=>{
    const pssElement = document.querySelector(".simulationArea.photo");
    const pss = document.querySelector(".container.mt-1");
    if ( pssElement ) {
      const computedStyles = window.getComputedStyle( pssElement );
      const width = parseFloat( computedStyles.width );
      const computedStyle = window.getComputedStyle( pss );
      const widths = parseFloat( computedStyle.width );
      const ww = widths/width;
      setImageTransform(
        // `scale(${ moveZoomData.scale }) 
        // translate(${( moveZoomData.position.x / moveZoomData.scale ) / ww }px, 
        // ${ ( moveZoomData.position.y / moveZoomData.scale ) / ww }px)`
      );
    }
  },[ moveZoomData, selectedCurtainType, finalCurtainType, selectedColors, photoimage, selectedimage ]);
  const imageSTyles = {
    transform: `${ imageTransform }`,
    top: "-50px",
    left:"50%"
  }
  return(
    <div className="container mt-1">
      <div className="row justify-content-end d-flex">
        <div className="col-12 justify-content-end d-flex">
        {
            finalCurtainType !== null ?
              (
                finalCurtainType !== "lace" ?
                  <div
                    style={
                      RefinementResult.length === 0 ?
                        { color:"red" }
                      :
                        { color:"#3e3a39" }
                    }
                  >
                    { RefinementResult.length }件
                  </div>
                :
                  <div
                    style={
                      codesArray.length === 0 ?
                        { color:"red" }
                      :
                        { color:"#3e3a39" }
                    }
                  >
                    { codesArray.length }件
                  </div>
              )
            :
              (
                selectedCurtainType !== "lace" ?
                  <div
                    style={
                      RefinementResult.length === 0 ?
                        { color:"red" }
                      :
                        { color:"#3e3a39" }
                    }
                  >
                    { RefinementResult.length }件
                  </div>
                :
                  <div
                    style={
                      codesArray.length === 0 ?
                        { color:"red" }
                      :
                        { color:"#3e3a39" }
                      }
                  >
                    { codesArray.length }件
                  </div>
              )
          }
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="row curtain_list">
          {paginatedData[ currentPage ] &&
            paginatedData[ currentPage ].map(( item, index ) => {
              const imageUrl=
                currentSelectedCurtainType !== "lace" ?
                `https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/${ getCurtainTypePath( currentSelectedCurtainType )}/${ item }_1_${ getImageSuffix( currentSelectedCurtainType ) }.png`
                :
                `https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/${ getCurtainTypePath( currentSelectedCurtainType )}/${ item }_1_${ getImageSuffix( currentSelectedCurtainType ) }.png`;
              // icon
              const getIconImageUrl = `https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/${ getCurtainTypeIconPath( currentSelectedCurtainType )}/${ item }-i.png`
              return (
                <div
                  className={ `item col-md-4 col-12 text-center my-3 ${ selectedCode === item ? "selected" : "" }` }
                  key={ index }
                  onClick={ () => handleCurtainItemClick( item ) }
                  onMouseEnter={ () => handleMouseEnter( item ) }
                  onMouseLeave={ () => handleMouseLeave( item ) }
                  style={{
                    borderWidth: selectedCode === item ? "3px" : "2px",
                    borderStyle: selectedCode === item ? "solid" : "transparent",
                    transition: "border-color 0.3s ease-in-out",
                    cursor: "pointer",
                    borderColor: hoveredItems[ item ] && selectedCode === item ? "#056b5e" : "transparent",
                  }}
                >
                  <div
                    className="simulationArea photo w-100"
                    style={{ 
                      position: "relative",
                      overflow: "hidden",
                      width: `${ simulationAreaSize.width }px`,
                      height: `${ simulationAreaSize.width/1.5 }px`,
                    }}
                    id="simulationArea"
                  >
                    <div
                      className="p-0 paginatedData"
                      style={{
                        position: "relative",
                      }}
                    >
                      {!photoimage ?
                        <img
                          src={ selectedimage }
                          alt={ selectedimage }
                          className="selectedImage px-0 mx-0 w-100"
                          style={ imageSTyles }
                        />
                      :
                        <img
                          src={ photoimage }
                          alt={ photoimage }
                          className="PhotoImage px-0 mx-0 w-100"
                          style={ imageSTyles }
                        />
                      }
                      {
                        finalCurtainType ?
                          finalCurtainType !== "roll"?
                            <>
                              <img
                                src={ getRailImageUrl }
                                alt={ getRailImageUrl }
                                ref={ railImageRef }
                                className="rail-image w-100 border"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0
                                }}
                                id="rail"
                              />
                            </>
                          :
                            <>
                              <img
                                src={ getRailImageUrl }
                                alt={ getRailImageUrl }
                                ref={ railImageRef }
                                className="rail-image w-100 border"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  zIndex:-1
                                }}
                                id="rail"
                              />
                            </>
                        :
                          selectedCurtainType !== "roll" ?
                            <>
                              <img
                                src={ getRailImageUrl }
                                alt={ getRailImageUrl }
                                ref={ railImageRef }
                                className="rail-image w-100 border"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0
                                }}
                                id="rail"
                              />
                            </>
                          :
                            <>
                              <img
                                src={ getRailImageUrl }
                                alt={ getRailImageUrl }
                                ref={ railImageRef }
                                className="rail-image w-100 border"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  zIndex:-1
                                }}
                                id="rail"
                              />
                            </>
                      }
                      <img
                        src={ imageUrl }
                        alt={`${ item }の画像がありません`}
                        className="curtain-image w-100"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          fontSize:"6px",
                          color:"red"
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mx-0 d-flex justify-content-center align-items-center">
                    <div className="col-4 offset-4 d-flex justify-content-center">
                      <small>
                        { item }
                      </small>
                    </div>
                    <div className="col-4 px-0 d-flex justify-content-end">
                      <img
                        src={ getIconImageUrl }
                        // alt={`${ item }の画像がありません`}
                        className="w-100"
                        style={{
                          fontSize:"6px",
                          color:"red"
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row align-items-center justify-content-center  mt-3 mb-3 pb-5">
            <div className="col-12 w-100">
              <div className="pagination d-flex justify-content-center">
                <div
                  onClick={ handlePrevPage }
                  disabled={ currentPage === 0 }
                  style={ pageButtonStyle }
                  className="d-flex justify-content-center align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-double-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                    />
                  </svg>
                </div>
                { renderPageButtons() }
                <div
                  onClick={ handleNextPage }
                  disabled={ currentPage === paginatedData.length - 1 }
                  style={ pageButtonStyle }
                  className="d-flex justify-content-center align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-double-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default PhotoResponse;

import { useReducer } from "react";

const reducer = ( state, action ) => {
  switch ( action.type ) {
    case "setRailSize": {
      return { ...state, railSize: action.val };
    }
    case "setRailColor": {
      return { ...state, railColor: action.val };
    }

    default:
      return state;
  }
};

const initState = {
  railColor: "func_WH",
  railSize: "_1",
};

const Reducer = () => {
  const [ state, dispatch ] = useReducer( reducer, initState );
  return [ state, dispatch ];
};

export default Reducer;
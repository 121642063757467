import React, { useState, useRef, useMemo, useEffect, cloneElement } from "react";


import "./simulator.css";

var previousTouch = null;
function ImageController( { children, showZoom } ) {
  const objectRef = useRef();
  const [ zoomState, setZoom ] = useState( 
    {
      zoom: 1,
      minZoom: 0.1,
      maxZoom: 3,
    }
  );

  const [ objectState, setObject ] = useState(
    {
      width: 0,
      height: 0,
    }
  );

  const [ dragState, setDrag ] = useState(
    {
      dragged: false,
      position: { x: 0, y: 0 },
      lastPosition: { x: 0, y: 0 },
      startPosition: { x: 0, y: 0 },
    }
  );

  const objectSize = useMemo( () => {
    return {
      originalWidth: objectState.width,
      originalHeight: objectState.height,
      width: objectState.width * ( zoomState.zoom ),
      height: objectState.height * zoomState.zoom,
      widthDiff: ( objectState.width - objectState.width * zoomState.zoom ) / 0.25,
      heightDiff: ( objectState.height - objectState.height * zoomState.zoom ) / 0.25,
    };
  }, [ objectState, zoomState.zoom ]);


  useEffect(() => {
    if ( objectRef.current && objectRef.current.offsetWidth > 0 ) {
      let width = objectRef.current.offsetWidth * 100;
      let height = objectRef.current.offsetHeight * 100;
      setObject( { width:width, height:height } );
    }
    
  }, [ objectRef.current ]);


  useEffect(() => {
    let currentZoom = parseFloat( zoomState.zoom );
    let x = dragState.position.x;
    let y = dragState.position.y;
    // if ( currentZoom <= 1 ) {
    //   if ( x <= -objectSize.widthDiff ) x = -objectSize.widthDiff;
    //   if ( y <= -objectSize.heightDiff ) y = -objectSize.heightDiff;
    //   if ( x >= objectSize.widthDiff ) x = objectSize.widthDiff;
    //   if ( y >= objectSize.heightDiff ) y = objectSize.heightDiff;
    // } else if ( currentZoom > 1 ) {
    //   if (x <= -objectSize.originalWidth / ( 2 / ( currentZoom - 1 ) ) )
    //     x = -objectSize.originalWidth / ( 2 / ( currentZoom - 1 ) );
    //   if (x >= objectSize.originalWidth / ( 2 / ( currentZoom - 1 ) ) )
    //     x = objectSize.originalWidth / ( 2 / ( currentZoom - 1 ) );
    //   if (y <= -objectSize.originalHeight / ( 2 / ( currentZoom - 1 ) ) )
    //     y = -objectSize.originalHeight / ( 2 / ( currentZoom - 1 ) );
    //   if (y >= objectSize.originalHeight / ( 2 / ( currentZoom - 1 ) ) )
    //     y = objectSize.originalHeight / ( 2 / ( currentZoom - 1 ) );
    // }
    if ( x !== dragState.position.x || y !== dragState.position.y ) {
      setDrag({
        ...dragState,
        position: {
          x: x,
          y: y,
        },
      });
    }
  }, [ dragState.position, zoomState.zoom ]);
  const startDrag = ( e ) => {
    setDrag({
      ...dragState,
      dragged: true,
    });
    return false;
  };

  const dragObject = ( e ) => {
    let movX = 0;
    let movY = 0;
    let currentZoom = parseFloat( zoomState.zoom );
    if ( dragState.dragged ) {
      if ( !e.touches ) {
        movX = e.movementX;
        movY = e.movementY;
      } else if ( e.touches.length == 1 ) {
        let touch = e.touches[0];
        if ( previousTouch ) {
          movX = touch.pageX - previousTouch.pageX;
          movY = touch.pageY - previousTouch.pageY;
        }
        previousTouch = touch;
      } else if ( e.touches.length == 2 ) {
        let touch = e.touches[0];
        let diffX = 0;
        if ( previousTouch ) {
          diffX = touch.pageX - previousTouch.pageX;
        }
        previousTouch = touch;
        let diff;
        diff = diffX;
        let zoom = currentZoom + diff / 25;
        if ( zoom < zoomState.minZoom ) {
          zoom = zoomState.minZoom;
        }
        if ( zoom > zoomState.maxZoom ) {
          zoom = zoomState.maxZoom;
        }

        setZoom( { ...zoomState, zoom: zoom } );
      }

      let x = dragState.position.x + movX;
      let y = dragState.position.y + movY;
      // if ( zoomState.zoom <= 1 ) {
      //   if ( x <= -objectSize.widthDiff ) x = -objectSize.widthDiff;
      //   if ( y <= -objectSize.heightDiff ) y = -objectSize.heightDiff;
      //   if ( x >= objectSize.widthDiff ) x = objectSize.widthDiff;
      //   if ( y >= objectSize.heightDiff ) y = objectSize.heightDiff;
      // } else {
      //   if ( x <= -objectSize.originalWidth / ( 2 / ( currentZoom - 1 ) ) )
      //   x = -objectSize.originalWidth / ( 2 / ( currentZoom - 1 ) );
      //   if ( x >= objectSize.originalWidth / ( 2 / ( currentZoom - 1 ) ) )
      //   x = objectSize.originalWidth / ( 2 / ( currentZoom - 1 ) );
      //   if ( y <= -objectSize.originalHeight / ( 2 / ( currentZoom - 1 ) ) )
      //   y = -objectSize.originalHeight / ( 2 / ( currentZoom - 1 ) );
      //   if ( y >= objectSize.originalHeight / ( 2 / ( currentZoom - 1 ) ) )
      //   y = objectSize.originalHeight / ( 2 / ( currentZoom - 1 ) );
      // }
      setDrag({
        ...dragState,
        position: {
          x: x,
          y: y,
        },
      });
    }
    return false;
  };

  const endDrag = ( e ) => {
    previousTouch = null;
    setDrag({
      ...dragState,
      dragged: false,
    });
    return false;
  };

  const onZoom = ( e ) => {
    let val = e.target.value;
    setZoom( { ...zoomState, zoom: val } );
  };
  
  return (
    <>
      <div className="processed-component-base bg-white">
        <div
          ref = { objectRef }
          style = {
            {
            transform:
            `
              translateX( ${ dragState.position.x }px )
              translateY( ${ dragState.position.y }px )
              scale( ${ zoomState.zoom } )
            `,
          }}
        >
          { children }
        </div>

        <div
          className = "component-controller"
          onMouseMove = { dragObject }
          onMouseLeave = { endDrag }
          onMouseDown = { startDrag }
          onMouseUp = { endDrag }
          onDrag = { dragObject }
          onTouchStart = { startDrag }
          onTouchMove = { dragObject }
          onTouchEnd = { endDrag }
        >
        </div>
      </div>

      {
        showZoom && 
        (
          <div className="container bg-white mb-3">
            <div className="row justify-content-between">
              <div className="col-12 w-100 px-0 mx-0">
                <input
                  type = "range"
                  className = "full"
                  showZoom
                  step = { 0.01 }
                  value = { zoomState.zoom }
                  min = { zoomState.minZoom }
                  max = { zoomState.maxZoom }
                  onChange = { onZoom }
                  style={ { zIndex:3, position:"relative" } }
                />
              </div>
            </div>
            <div 
              className="row"
              style={
                { 
                  lineHeight:"0px",
                  marginTop:-5,
                  zIndex:2
                } 
              }
            >
            </div>
          </div>
        )
      }
    </>
  );
}

export default ImageController;

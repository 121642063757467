import React from 'react';
import { withRouter } from 'react-router-dom';

const imgParts = [
  { id: 1, minTitle: "同じ生地をカーテンとシェード", img1: "LineUp3.png" },
  { id: 2, minTitle: "アクセントクロスとカーテン(CG編)", img1: "LineUp1.png" },
  { id: 3, minTitle: "色を合わせたカーテンとロールスクリーン ", img1: "LineUp2.png" },
]

export const LineUp = withRouter( props => {
  const onClickCompare = () => {
    props.history.push( "/compareImages" );
  }

  return (
    <div className="container text-center border border-danger bg-light mt-4">
      <div className="row">
        <div className="col-12">
          <h4>
            シミュレーション結果を複数見比べることができます
          </h4>
        </div>
      </div>
      <div className="row w-100 mx-0 px-0">
        {
          imgParts.map(( sample, index ) => (
            <div
              className="col-md-4 col-12 w-100 mx-0 px-0"
              key={ index }
            >
              <div className="container">
                <div className="row w-100 mx-0 px-0">
                  <div className="col-12 px-0 mx-0">
                    { sample.minTitle }
                  </div>
                  <div className="col-12 w-100 border px-0">
                    <img
                      src={ sample.img1 }
                      alt={ sample.img1 }
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
      <div
        className="row justify-content-around"
      >
        <div
          className="col-4 btn btn-outline-success rounded-pill my-3 text-dark font-weight-bold w-100 mx-0 border border-success"
          onClick={ onClickCompare }
        >
          試してみる
        </div>
      </div>
    </div>
  );
})

import React from 'react';
import Menu from './menu';
import HomeWord from '../components/HomeWord';
import WhatCuReco from "../pages/WhatCuReco";
import SimulationFlow from "../pages/SimulationFlow";


function Light() {
  return(
    <>
      <div className="container px-0">
        <div className="row px-0 mx-0">
          <div className="col-12 px-0 mx-0">
            <HomeWord/>
            <Menu/>
          </div>
        </div>
      </div>
      <WhatCuReco/>
      <SimulationFlow/>
    </>
  )

}
export default Light;

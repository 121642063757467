import React, { useState, useEffect } from "react";
import axios from 'axios';

import ProductNumber from './ProductNumber';
import RoomTaste from './RoomTaste';
import ColorSelector from './ColorSelector';
import { ItemSelectorDivideByColor } from './ItemSelectorDivideByColor';
import { CustomCheckBoxes } from '../parts/CustomCheckBoxes';
import CustomDropDown from '../parts/CustomDropDown';
import CustomScrollImageItems from '../parts/CustomScrollImageItems';


function DrapeCurtain( props ) {
  
  const { optionsData, selectedSetters, roomBaseImage, baseFrom, baseImgPath } = props;
  const [ functions, setFunctions ] = useState( [] );
  const [ tastes, setTastes ] = useState( [] );
  const [ brand, setBrand ] = useState( "none" );
  const [ price, setPrice ] = useState( "0" );
  const [ rgb, setRGB ] = useState( null );
  const [ specifiedDrape, setSpecifiedDrape ] = useState( null );
  const [ specifiedDrapeItems, setSpecfiedDrapeItems ] = useState( [] );
  const [ choosedColor1Drapes, setChoosedColor1Drapes ] = useState( null );
  const [ choosedColor2Drapes, setChoosedColor2Drapes ] = useState( null );
  const [ choosedColor3Drapes, setChoosedColor3Drapes ] = useState( null );
  const resetColor = { "color1":false, "color2":false, "color3": false };
  const [ choosedDrapes, setChoosedDrapes ] = useState( null );
  const [ choosedDrapeItems, setChoosedDrapeItems ] = useState( [] );
  const functionsData = optionsData[ "functions" ];
  const tastesData = optionsData[ "tastes" ];
  const brandsData = optionsData[ "brand" ];
  const pricesData = optionsData[ "price" ];
  const setSelectedFunctions = selectedSetters[ "functions" ];
  const setSelectedTastes = selectedSetters[ "tastes" ];
  const setSelectedBrand = selectedSetters[ "brand" ];
  const setSelectedPrice = selectedSetters[ "price" ];
  const setSelectedCurtain = selectedSetters[ "curtain" ];

  const resetDrapeItems = ( drapeCurtains, codes ) => {
    let items = [];
    const drapes = !( drapeCurtains instanceof Array )? [ drapeCurtains ] : drapeCurtains;
    drapes.forEach( ( drape, index ) =>
      items.push(
        {
          "name": drape.code,
          "dispName": drape.code,
          "image": "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/DrapesShades/icon/" + drape.code + "-i.png",
          "value": drape.code
        }
      )  
    );
    return items; 
  }

  useEffect(() => {
    if ( specifiedDrape === null ) {
      return false;
    }
    if ( specifiedDrape === "" ) {
      alert( "該当の生地は存在しません。" );
    }
    let items = resetDrapeItems( specifiedDrape === "" ? [] : specifiedDrape );
    setSpecfiedDrapeItems( items );
  }, [ specifiedDrape ] ); 

  const [ alertcounter, setAlertcounter ] = useState( 0 );
  useEffect(() => {
    if (
        (
          choosedColor1Drapes && !choosedColor1Drapes.length &&
          choosedColor2Drapes && !choosedColor2Drapes.length &&
          choosedColor3Drapes && !choosedColor3Drapes.length
        ) === true 
       ){
        setAlertcounter( alertcounter + 1 )
        return false;
        }
  }, [ choosedColor1Drapes, choosedColor2Drapes, choosedColor3Drapes ] ); 

  useEffect(() => {
    if ( choosedDrapes === null ) return false;
    if ( !choosedDrapes.length ) {
      alert( "該当の生地がありません。\n条件を変更し、もう一度お試しください。" );
      return false
    }
    let items = resetDrapeItems( choosedDrapes );
    setChoosedDrapeItems( items );
  }, [ choosedDrapes ]); 

  // API送受信用
  const GET_GROUPED_PRODUCTS_FROM_RGB_URL =  'https://i-koike2.sakura.ne.jp/rgbcg.php?rgb=';
  const queryDrapes = ( codes, condition, setter ) => {
    if ( codes && codes.length ) {
      condition[ "codes" ] = codes.join( "," );
    }
    axios.get( "/api/drapes", {
      params: condition
    }).then( result => {
      if( codes === null ){
        setter( result.data )
      }
      else{
      const resItems = result.data;
      const reqItems = codes;
      const TESTab = [];

      for( var i = 0; i <= reqItems.length - 1; i++ ){
        for(var a = 0; a <= resItems.length - 1; a++ ){
          if( reqItems[ i ] === resItems[ a ].code){
            TESTab.push( resItems[ a ] )
          }
        }
      }
      setter( TESTab )
    }
     });
  }
  const [ clickNumber, setClickNumber ] = useState( 0 );

  const onClickConfirm = () => {
    window.scrollBy( 0,350 );
    setClickNumber( clickNumber + 1 )
    let funcs = '';
    functions.map( ( f, idx ) => funcs += ( ( funcs !== '' ? ',' : '' ) + f) );
    setSelectedFunctions( functions );
    let tsts = '';
    tastes.map( ( t, idx ) => tsts += ( ( tsts !== '' ? ',' : '') + t ) );
    setSelectedTastes( tastes );
    setSelectedBrand( brand );
    setSelectedPrice( price );
    let condition = {};
    if ( funcs !== '' ) {
      condition[ "functions" ] = funcs;
    }
    if ( tsts !== '' ) {
      condition[ "tastes" ] = tsts;
    }
    if ( brand !== 'none' ) {
      condition[ "brand" ] = brand;
    }
    if ( price !== '0' ) {
      condition[ "price" ] = price;
    }
    if ( rgb ) {
      axios.get("/api/queryProducts", {
       params: {
         url: GET_GROUPED_PRODUCTS_FROM_RGB_URL + [ rgb.r, rgb.g, rgb.b ].join( "," )
       }      
     }).then( result => {
      // console.log( "確認用:" + GET_GROUPED_PRODUCTS_FROM_RGB_URL + [ rgb.r, rgb.g, rgb.b ] )
       const colors = [ "color1", "color2", "color3" ];
       colors.forEach( ( color, idx ) => {
         let setter = color === "color1"
                  ? setChoosedColor1Drapes
                  : color === "color2"
                  ? setChoosedColor2Drapes
                  : setChoosedColor3Drapes;

         if ( !result.data[ color ].length ) {
           setter([]);
         } 
         else {
           queryDrapes( result.data[ color ], condition, setter );
         }

       });
      });
    } else {
      queryDrapes( null, condition, setChoosedDrapes );
    }
  }; 
  const test = 
    ( 
      rgb === null )?
      {}
      :
      { 
        background:
        'rgba('
        +
        rgb.r
        +
        ','
        +
        rgb.g
        +
        ','
        +
        rgb.b
        +
        ','
        +
        1
        +
        ')',
        textShadow:"1px 1px 0 #000" 
      };

  const onSelectDrape = ( e ) => {
    for ( let color in resetColor ) {
      resetColor[ color ] = ( color !== e.target.name.substring( 0, 6 ) );
    }
    setSelectedCurtain( e.target.value );
  }

  const [ dropClickColor, setDropClickColor ] = useState(0);
  const [ dropClickTaste, setDropClickTaste ] = useState(0);
  const [ dropClickFunc, setDropClickFunc ] = useState(0);
  // BPN =>Brand_Price_Number
  const [ dropClickBPN, setDropClickBPN ] = useState(0);
  const [ dropClickResult, setDropClickResult ] = useState(0);

  const onClickDropColor = () =>{
    setDropClickColor( dropClickColor + 1 )
  }
  const onClickDropTaste = () =>{
    setDropClickTaste( dropClickTaste + 1 )
  }
  const onClickDropFunc = () =>{
    setDropClickFunc( dropClickFunc + 1 )
  }
  const onClickDropBPN = () =>{
    setDropClickBPN( dropClickBPN + 1 )
  }
  const onClickDropResult = () =>{
    setDropClickResult( dropClickResult + 1 )
  }

  const styleColor = {
    backgroundColor:"#046B5E",
  }
  const backStyleColor = {
    backgroundColor:"rgba(4,107,94,0.3)",
  }
  
  return(
    <div
      className="DrapeCurtain"
      style={ backStyleColor }
    >
      <div className="box_ColorPicker">
        <label 
          htmlFor="inbox_ColorPicker"
          className="border border-dark text-center my-0"
          onClick = { onClickDropColor}
          style={
            dropClickColor === 0?
            {
              backgroundColor:"#cce9dd"
            }
            :
            test
          }
        >
          {
            dropClickColor === 0?
            <div className="text-dark container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  厚地&emsp;カラー
                </div>
                <div className="col-2 w-100 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
            :
            ((dropClickColor % 2) == 0)?
            <div className="text-dark container py-2">
              <div className="row">
                <div className="col-10 text-left w-100">
                厚地&emsp;カラー
                </div>
                <div className="col-2 w-100 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
            :
            <div className="container py-2">
              <div className="row">
                <div className="col-10 text-left w-100">
                  厚地&emsp;カラー
                </div>
                <div className="col-2 text-center w-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-up-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
          }
        </label>
        <input
          type="checkbox"
          id="inbox_ColorPicker"
          className="on-off_ColorPicker"
        />
        <div
          className="pb-1"
          style={
            {
              overflowY:"scroll",
              height:"315px"
            }
          }
        >
          <ColorSelector
            colorSetter = { setRGB } 
            roomBaseImage = { roomBaseImage }
            baseImgPath = { baseImgPath }
            baseFrom = { baseFrom }
          />
        </div>
      </div>
      <div className="box_Taste">
        <label 
          htmlFor="inbox_Taste"
          className="border border-dark text-center my-0"
          onClick = { onClickDropTaste }
          style={
            dropClickTaste === 0?
            {
              backgroundColor:"#cce9dd"
            }
            :
              ((dropClickTaste % 2) == 0)?
            {
              backgroundColor:"#cce9dd"
            }
            :
            {
              backgroundColor:"white"
            }
          }
        >
          {
            dropClickTaste === 0?
            <div className="text-dark container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  厚地&emsp;テイスト
                </div>
                <div className="col-2 w-100 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
            :
            ((dropClickTaste % 2) == 0)?
            <div className="text-dark container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  厚地&emsp;テイスト
                </div>
                <div className="col-2 w-100 text-center">
                  <svg 
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
            :
            <div className="container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  厚地&emsp;テイスト
                </div>
                <div className="col-2 w-100 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-up-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
          }
        </label>
        <input
          type="checkbox"
          id="inbox_Taste"
          className="on-off_Taste"
        />
        <div
          className="pb-3"
          style={
            {
              overflowY:"scroll",
              height:"260px"
            }
          }
        >
          <RoomTaste
            tastesData = { tastesData }
            setSelected = { setTastes }
          />
        </div>
      </div>

      <div className="box_func">
        <label 
          htmlFor="inbox_func"
          className="border border-dark text-center my-0 w-100"
          onClick = { onClickDropFunc }
          style={
            dropClickFunc === 0?
            {
              backgroundColor:"#cce9dd"
            }
            :
              ((dropClickFunc % 2) == 0)?
            {
              backgroundColor:"#cce9dd"
            }
            :
            {
              backgroundColor:"white"
            }
          }
        >
          {
            dropClickFunc === 0?
            <div className="text-dark container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  厚地&emsp;機能
                </div>
                <div className="col-2 w-100 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
            :
            ((dropClickFunc % 2) == 0)?
            <div className="text-dark container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  厚地&emsp;機能
                </div>
                <div className="col-2 w-100 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
            :
            <div className="container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  厚地&emsp;機能
                </div>
                <div className="col-2 w-100 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-up-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
          }
        </label>
        <input
          type="checkbox"
          id="inbox_func"
          className="on-off_func"
        />
        <div 
          className="pb-3"
          style={
            {
              overflowY:"scroll",
              height:"260px"
            }
          }
        >
          <CustomCheckBoxes
            title = "機能"
            options = { functionsData }
            setSelected = { setFunctions }
          />
        </div>
      </div>

      <div className="box_BPN">
        <label 
          htmlFor="inbox_BPN"
          className="border border-dark text-center my-0 w-100"
          onClick = { onClickDropBPN }
          style={
            dropClickBPN === 0?
            {
              backgroundColor:"#cce9dd"
            }
            :
            ((dropClickBPN % 2) == 0)?
            {
              backgroundColor:"#cce9dd"
            }
            :
            {
              backgroundColor:"white"
            }
          }
        >
          {
            dropClickBPN === 0?
            <div className="text-dark container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  厚地&emsp;ブランド/価格帯/品番検索
                </div>
                <div className="col-2 w-100 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
            :
            ((dropClickBPN % 2) == 0)?
            <div className="text-dark container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  厚地&emsp;ブランド/価格帯/品番検索
                </div>
                <div className="col-2 w-100 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
            :
            <div className="container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  厚地&emsp;ブランド/価格帯/品番検索
                </div>
                <div className="col-2 w-100 text-center">
                  <svg 
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-up-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
          }
        </label>
        <input
          type="checkbox"
          id="inbox_BPN"
          className="on-off_BPN"
        />
        <div>
          <CustomDropDown
            title = "Brand"
            options = { brandsData }
            setSelected = { setBrand }
          />
          <CustomDropDown
            title = "Price"
            options = { pricesData }
            defaultId = "0"
            setSelected = { setPrice }
          />
          <ProductNumber
            apiPath = "/api/drapes/"
            choosedSetter = { setSpecifiedDrape }
          />
          <CustomScrollImageItems
            name = "targetProduct"
            title = "対象商品"
            options = { specifiedDrapeItems }
            multiSelectable = { false }
            onClick = { onSelectDrape }
          />
        </div>
      </div>
      <div className="box_result">
        <label 
          htmlFor="inbox_result"
          className="border border-dark text-center my-0 w-100"
          onClick = { onClickDropResult }
          style={
            dropClickResult === 0?
            {
              backgroundColor:"#cce9dd"
            }
            :
            ((dropClickResult % 2) == 0)?
            {
              backgroundColor:"#cce9dd"
            }
            :
            {
              backgroundColor:"white"
            }
          }
        >
          {
            dropClickResult === 0?
            <div className="text-dark container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  厚地&emsp;結果表示
                </div>
                <div className="col-2 w-100 text-center">
                  <svg 
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
            :
            ((dropClickResult % 2) == 0)?
            <div className="text-dark container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  厚地&emsp;結果表示
                </div>
                <div className="col-2 w-100 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
            :
            <div className="container py-2">
              <div className="row">
                <div className="col-10 w-100 text-left">
                  厚地&emsp;結果表示
                </div>
                <div className="col-2 w-100 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-caret-up-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                  </svg>
                </div>
              </div>
            </div>
          }
        </label>
        <input
          type="checkbox"
          id="inbox_result"
          className="on-off_result"
        />
        <div>
          <div className="container my-3">
            <div className="row justify-content-center">
              <button
                id = "btnConfirm"
                className = "col-12 text-center btn btn-secondary py-3 font-weight-bold"
                onClick = { onClickConfirm }
                style={ test }
              >
                厚地カーテンをみる
              </button>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 text-white text-center">
              <div
                className="w-100 py-1 mb-3"
                style= { styleColor }
                id="drape"
              >
                ドレープ一覧
              </div>
              {
                rgb && 
                choosedColor1Drapes && 
                choosedColor2Drapes && 
                choosedColor3Drapes &&
                (
                  <ItemSelectorDivideByColor
                    color1Data = { choosedColor1Drapes }
                    color2Data = { choosedColor2Drapes }
                    color3Data = { choosedColor3Drapes }
                    iconURL = "https://mado-simulator-2.s3-ap-northeast-1.amazonaws.com/DrapesShades/icon/"
                    selectedSetter = { setSelectedCurtain }
                  />
                )
              }
              {
                !rgb &&
                choosedDrapes && 
                (
                  <CustomScrollImageItems
                    name = "choosedProducts"
                    title = "抽出商品"
                    options = { choosedDrapeItems }
                    multiSelectable = { false }
                    onClick = { onSelectDrape }
                  />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DrapeCurtain;